import styled from '@emotion/styled'
import { Dialog } from '@mui/material'

export const StaticDiv = styled.div`
    background: white;
    position: sticky;
    width: 100%;
    top: 0;
    left: inherit;
    z-index: 3;

    @media (max-width: 480px) {
        display: none;
    }
`

export const AllCardContainer = styled.div`
    margin-top: 1em;
    padding: 2rem .5rem;
    
    @media (max-width: 480px) {
        margin-top: 0;
    }
`

export const TitleSinFiltro = styled.div`
    display: none;
    margin-top: 3rem;

    @media (max-width: 480px) {
        display:block;
    }
`

export const StyledPaper = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 8px;
        max-width: 560px;
        padding: 60px;
        @media (max-width: 480px) {
            max-width: 380px;
            padding: 36px 0;
            margin: 10px;
        }
    }
`
