import styled from '@emotion/styled'
import { useContext } from 'react'
import { ColorContext } from '../contexts/ColorContext'

const MySpanButton = ({ children, ...props }: any) => {
  const { theme } = useContext(ColorContext)
  const { secondary } = theme

  const MySpanButton = styled.span`
    border: 1px solid transparent;
    border-radius: 32px;
    color: ${secondary};
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding: 10px 20px;
    text-transform: none;
    transition: all ease .2s;

    &:hover{
        background-color: white;
        color: ${secondary};
        border: 1px solid ${secondary};
    }
`

  return (
        <MySpanButton
            {...props}>
            {children}
        </MySpanButton>
  )
}

export default MySpanButton
