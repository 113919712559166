/* eslint-disable @typescript-eslint/no-misused-promises */
import { useContext, useEffect, useState } from 'react'
import LoadingContext from '../../../contexts/LoadingContext'
import NotificationContext from '../../../contexts/NotificationContext'
import { deleteVDR, getVDR } from '../../../core/apiVDR'
import { VDRTable } from './VDRTable'
import { Grid } from '@mui/material'
import { ChipFolderTag } from './StyledComponents'
import { Paragraph } from '../../Paragraph'
import { MyButton } from '../../MyButton'
import ClientContext from '../../../contexts/ClientContext'
import { useTranslation } from 'react-i18next'

interface Props {
  id: string
  type: 'Empresa' | 'Activo'
  canSeeConfidential?: boolean
  canDelete?: boolean
  canEdit?: boolean
  canLoad?: boolean
  requestAccess?: () => void
  canAccess?: boolean
  issuerData: any
  canShowRequest?: boolean
  reloadIssuer: () => Promise<void>
  showHeaders: boolean
}

export const VDRDialog = ({ showHeaders, canShowRequest = true, reloadIssuer, canSeeConfidential = true, id, canDelete = true, canEdit = true, canLoad = true, requestAccess, canAccess = true, type, issuerData }: Props) => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { isConfidentiality } = useContext(ClientContext)
  const [vdrs, setVdrs] = useState<any[]>([])
  const [t] = useTranslation()

  const type_files = [
    { folder: 'Legal', label: t('legal') },
    { folder: 'Financiero', label: t('financial') },
    { folder: 'Negocio', label: t('business') },
    { folder: 'ESG', label: t('ESG') },
    { folder: 'Otros', label: t('others') }
  ]

  const loadData = async () => {
    const resp = await getVDR(id)
    if (resp?.success) {
      if (resp?.extraRecords) {
        const company = resp?.extraRecords?.map((e: any) => ({ ...e, type: 'Empresa' }))
        const asset = resp?.records?.map((e: any) => ({ ...e, type: 'Activo' }))
        const allDocs = [...company, ...asset]
        setVdrs(allDocs)
      } else {
        setVdrs(resp?.records?.map((e: any) => ({ ...e, type })) || [])
      }
    } else setVdrs([])
  }

  const delVDR = async (file: any, typeFolder: string, fileId: string) => {
    showLoading()
    const resp = await deleteVDR(fileId, file, typeFolder)
    if (resp?.success) showToast('success', t('toasts.VDR_deleted'))
    await loadData()
    hideLoading()
  }

  useEffect(() => {
    async function init () {
      await loadData()
    }
    void init()

    return () => {
      setVdrs([])
    }
    // eslint-disable-next-line
    }, [])

  return (
        <>
            <Grid container spacing={2}>
            { showHeaders && type_files.map(({ folder, label }) =>
              <Grid key={folder} item md={3} sm={6} xs={6}>
                <ChipFolderTag >
                  <a href={'#' + folder}>
                    <Paragraph bold type='small'>
                      { label }
                    </Paragraph>
                  </a>
                </ChipFolderTag>
              </Grid>
            )}
            <Grid item xs={12} />
            { !(!canAccess && canShowRequest && isConfidentiality)
              ? type_files.map(({ folder, label }) =>
                <Grid key={folder} item md={12} sm={12} xs={12}>
                  <VDRTable
                    id={id}
                    reloadData={loadData}
                    reloadIssuer={reloadIssuer}
                    canSeeConfidential={canSeeConfidential}
                    issuerData={issuerData}
                    data={vdrs}
                    key={folder}
                    label={label}
                    folder={folder}
                    type={type}
                    canEdit={canEdit}
                    canDelete={canDelete}
                    deleteVDR={delVDR}
                    canLoad={canLoad}
                  />
                </Grid>
              )
              : <Grid item mt={2}>
                <MyButton fullWidth onMouseUp={requestAccess}>
                  {t('access_request')}
                </MyButton>
              </Grid>
            }
            </Grid>
        </>
  )
}
