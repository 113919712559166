import { useContext, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyTable } from '../../../../components'
import { MyTextAction } from '../../../../components/MyTextLink'
import { AssetsContext } from '../../../../contexts/AssetsContext'
import { ColorContext } from '../../../../contexts/ColorContext'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { formatCurrency, formatNumber } from '../../../../core/utils'
import { useClientConfig } from '../../../../hooks/useClientConfig'

interface Props {
  openEditForm: (mode: any, data: any) => void
}

export const PendingAssets = ({ openEditForm }: Props) => {
  const { filterData } = useContext(AssetsContext)
  const [rows, setRows] = useState<any>([])
  const { total_tokens_actions, tokens_actions_price } = useContext(IdiomContext)
  const { theme } = useContext(ColorContext)
  const { assetTypeLabel } = useClientConfig()
  const { t, i18n: { language } } = useTranslation()
  const { primary } = theme

  useLayoutEffect(() => {
    setRows(
      Array.from(filterData.pendientes, (asset: any) => {
        const { token_quantity, price, token_price, completedSteps, bidAmount } = asset
        const currency = asset?.currency
        return {
          ...asset,
          currency,
          bidAmount: formatCurrency(bidAmount, currency, true),
          token_price: formatCurrency(token_price, currency, true),
          price_format: formatCurrency(price, currency, true),
          token_quantity: formatNumber(token_quantity),
          isCompleted: completedSteps ? t('sended') : t('not_sended'),
          options:
            <MyTextAction
                color={primary}
                onClick={() => openEditForm('editar', asset)}
            >
                {t('edit')}
            </MyTextAction>
        }
      }))
    // eslint-disable-next-line
    }, [filterData.pendientes, language])

  const headers = [
    {
      header: assetTypeLabel('singular', 'uppercase'),
      key: 'name'
    },
    {
      header: total_tokens_actions,
      key: 'token_quantity'
    },
    {
      header: tokens_actions_price,
      key: 'token_price'
    },
    {
      header: t('currency'),
      key: 'currency'
    },
    {
      header: t('valuation'),
      key: 'price_format'
    },
    {
      header: t('bid_amount'),
      key: 'bidAmount'
    },
    {
      header: 'Status',
      key: 'isCompleted'
    },
    {
      header: t('options'),
      key: 'options'
    }
  ]

  return (
        <MyTable
            headerData={headers}
            rowData={rows}
        />
  )
}
