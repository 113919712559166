import { useCallback, useEffect, useState } from 'react'
import { getMaintenance } from '../core/apiMaintenance'

export const useMaintenance = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isMaintenance, setIsMaintenance] = useState(false)

  const loadMaintenance = useCallback(async () => {
    const resp = await getMaintenance()
    setIsMaintenance(resp.records?.[0].mode)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    async function init () {
      await loadMaintenance()
    }
    void init()
  }, [loadMaintenance])

  return { isLoading, isMaintenance }
}
