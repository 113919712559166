import { MyTransition } from '../../../../../components/MyTransition'
import { Paragraph } from '../../../../../components/Paragraph'
import { StyledPaper } from '../../Styles/StylesComponents'
import { DialogTitle, DialogContent, Grid, DialogActions } from '@mui/material'
import { MyButton } from '../../../../../components/MyButton'
import { useClientConfig } from '../../../../../hooks/useClientConfig'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ConfidentialDocRequest = ({ isOpen, onClose, onSubmit }: Props) => {
  const { assetTypeLabel } = useClientConfig()
  const { t } = useTranslation()

  return (
    <StyledPaper
            open={isOpen}
            TransitionComponent={MyTransition}
            onClose={onClose}
        >
            <DialogTitle style={{ textAlign: 'center' }}>
                <Paragraph>
                    <strong>{t('investor_user.access_confidential_docs.title')}</strong>
                </Paragraph>
            </DialogTitle>
            <DialogContent style={{ padding: 20 }}>
                <Paragraph style={{ textAlign: 'justify' }}>{t('investor_user.access_confidential_docs.description', { asset: assetTypeLabel('plural', 'lowercase') })}</Paragraph>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <MyButton
                            fullWidth
                            onMouseUp={onSubmit}
                        >{t('investor_user.access_confidential_docs.send')}</MyButton>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <MyButton
                            fullWidth
                            color='secondary'
                            onMouseUp={onClose}
                        >{t('cancel')}</MyButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </StyledPaper >
  )
}
