import ApartmentIcon from '@mui/icons-material/Apartment'
import { SidebarTabsProps } from '../SidebarTabs'
import GridViewIcon from '@mui/icons-material/GridView'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import PaidIcon from '@mui/icons-material/Paid'
import { MaterialIcon } from '../SidebarOptions'
import { useContext, useLayoutEffect } from 'react'
import { AssetsContext } from '../../../contexts/AssetsContext'
import { InvestorsContext } from '../../../contexts/InvestorsContext'
import { TransactionsContext } from '../../../contexts/TransactionsContext'
import { Transaction } from '../../../types/types'
import { useClientConfig } from '../../../hooks/useClientConfig'
import { useTranslation } from 'react-i18next'

export const Client = (): SidebarTabsProps[] => {
  const { loadAssetsAndFilter, filterData } = useContext(AssetsContext)
  const { transactions, loadTransactionsByClient } = useContext(TransactionsContext)
  const {
    loadAcceptedInvestors,
    loadPendingInvestors,
    pendingInvestors
  } = useContext(InvestorsContext)
  const { assetTypeLabel } = useClientConfig()
  const [t] = useTranslation()

  useLayoutEffect(() => {
    async function init () {
      await Promise.all([
        loadAssetsAndFilter(),
        loadAcceptedInvestors(),
        loadPendingInvestors(),
        loadTransactionsByClient()
      ])
    }
    void init()
    // eslint-disable-next-line
  }, [])

  return [
    {
      index: 1,
      navigateTo: 'clients',
      Icon: <ApartmentIcon {...MaterialIcon} />,
      title: t('companies')
    },
    {
      index: 2,
      navigateTo: 'clients-assets',
      Icon: <GridViewIcon {...MaterialIcon} />,
      title: assetTypeLabel('plural', 'capitalize'),
      numberAlert: filterData.pendientes.filter((asset: any) => !!asset?.completedSteps).length
    },
    {
      index: 3,
      navigateTo: 'clients-investors',
      Icon: <GroupsOutlinedIcon {...MaterialIcon} />,
      title: t('investors'),
      numberAlert: pendingInvestors.length
    },
    {
      index: 4,
      navigateTo: 'clients-payments',
      Icon: <PaidIcon {...MaterialIcon} />,
      title: t('payment_validation'),
      numberAlert: transactions.filter((el: Transaction) => el.pay_confirmed === 'pending').length
    }
  ]
}
