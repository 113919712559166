import { Grid, Paper } from '@mui/material'
import { Formik } from 'formik'
import { useContext, useState } from 'react'
import { fullWidth } from '../../layout/LayoutConfig'
import { MyTextInput } from '../MyTextInput'
import { MyTextAction } from '../MyTextLink'
import { VDRDetails } from './VDRDetails'
import * as yup from 'yup'
import { MyButton } from '../MyButton'
import LoadingContext from '../../contexts/LoadingContext'
import { updateIssuer } from '../../core/apiIssuers'
import NotificationContext from '../../contexts/NotificationContext'
import { ColorContext } from '../../contexts/ColorContext'
import ClientContext from '../../contexts/ClientContext'
import { useTranslation } from 'react-i18next'
import UserContext from '../../contexts/UserContext'

interface Props {
  linkVdr: string
  issuer_id: string
  reloadLink: () => Promise<void>
  disabled: any
}

export const LinkVDR = ({ linkVdr = '', issuer_id, reloadLink, disabled }: Props) => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const { theme } = useContext(ColorContext)
  const { client_id } = useContext(ClientContext)
  const { t } = useTranslation()
  const { reloadIssuerUser, issuer } = useContext(UserContext)
  const { primary } = theme

  const handleUpdateIssuer = async (linkVdr: string, errors?: any) => {
    showLoading()
    if (linkVdr.length && JSON.stringify(errors).length === 2) {
      const resp = await updateIssuer(issuer_id, { linkVdr }, client_id)
      if (resp?.success) {
        reloadIssuerUser({ ...issuer, linkVdr })
        showToast('success', t('toasts.link_vdr_success'))
        await reloadLink()
      }
    } else {
      showToast('error', t('toasts.link_vdr_error'))
    }
    hideLoading()
  }

  return (
    <>
      <VDRDetails
        reloadIssuer={reloadLink}
        disabled={disabled}
        issuerId={issuer_id}
        isOpen={isDetailsOpen}
        onClose={() => setIsDetailsOpen(false)}
      />
      <Paper elevation={5}>
        <Formik key={linkVdr} initialValues={{ linkVdr }} validationSchema={validationSchema} onSubmit={ async values => await handleUpdateIssuer(values.linkVdr)}>
          {({ values, errors }) => (
            <Grid container p={3} justifyContent="flex-end" spacing={2}>
              <Grid item >
                <MyTextAction color={primary} onClick={() => setIsDetailsOpen(true)}>{t('access_external_documentation')}</MyTextAction>
              </Grid>
              <Grid item {...fullWidth}>
                <MyTextInput
                  label={t('your_link_vdr')}
                  name='linkVdr'
                />
              </Grid>
              <Grid item >
                <MyButton onMouseUp={async () => await handleUpdateIssuer(values.linkVdr, errors)}>{t('save_changes')}</MyButton>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Paper>
    </>
  )
}

const validationSchema = yup.object().shape({
  linkVdr: yup.string().url('url').required('required_field')
})
