import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { MyTextInput } from '../../components'
import { createAccountValidationSchema } from '../../validations/createAccountValidationSchema'
import { useContext } from 'react'
import ClientContext from '../../contexts/ClientContext'
import { MyButton } from '../../components/MyButton'
import { sendEvent } from '../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../core/GA/GoogleAnalyticsEvents'
import { Paragraph } from '../../components/Paragraph'
import { TextButtonLink } from '../../components/TextButtonLink'
import { LoginFormLayout } from '../../layout/InitialLayout'
import { LoginTitle } from '../../components/Title'
import { MyFlexContainer } from '../../components/common/MyFlexContainer'
import { useTranslation } from 'react-i18next'

const initialValues = {
  username: ''
}

interface Props {
  onSubmit: (values: any) => Promise<any>
}

export const RegisterAccountForm = ({ onSubmit }: Props) => {
  const { client } = useContext(ClientContext)
  const { t } = useTranslation()

  return (
      <Formik
        initialValues={initialValues}
        validationSchema={createAccountValidationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <LoginFormLayout>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item sm={12} md={12} xs={12}>
                <LoginTitle>{t('login_page.register.title')}</LoginTitle>
              </Grid>
              <Grid item sm={12} md={12} xs={12}>
                <Paragraph>{t('login_page.register.subtitle')}</Paragraph>
              </Grid>
              <Grid item sm={12} md={10} xs={10}>
                <MyTextInput
                    label={t('email')}
                    name='username'
                    type='email'
                    placeholder='email@dominio.com'
                />
              </Grid>
              <Grid item sm={12} md={10} xs={10} mt={4}>
                <MyButton fullWidth type='submit'>
                {t('login_page.register.register_me')}
                </MyButton>
              </Grid>
              <Grid item sm={12} md={12} xs={12}>
                <MyFlexContainer style={{ justifyContent: 'center' }}>
                  <Paragraph type='small'>{t('login_page.register.have_account')}</Paragraph>
                  <TextButtonLink
                    onClick={() => sendEvent(GA_EVENTS.login)}
                    to={`/${client?.id || client?.client?.id}`}
                  >
                    {t('login_page.register.change_to_login')}
                  </TextButtonLink>
                </MyFlexContainer>
              </Grid>
            </Grid>
          </LoginFormLayout>
        )}
      </Formik>
  )
}
