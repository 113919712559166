import { useContext, useLayoutEffect, useState } from 'react'
import { MyRenderTable } from '../../../../components/Tables/MyRenderTable'
import { MyTextAction } from '../../../../components/MyTextLink'
import ClientContext from '../../../../contexts/ClientContext'
import { ColorContext } from '../../../../contexts/ColorContext'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { TransactionsContext } from '../../../../contexts/TransactionsContext'
import { timezoneFormatWithHour } from '../../../../core/timezoneFormat'
import { formatCurrency, formatNumber } from '../../../../core/utils'
import { Transaction } from '../../../../types/types'
import { useClientConfig } from '../../../../hooks/useClientConfig'
import { ReviewHistoricalBuyRequest } from './ReviewHistoricalBuyRequest'
import { FilterTableHOC } from '../../../../components/Tables/FilterTableHOC'
import { useTranslation } from 'react-i18next'

export const HistoricalBuyAsset = () => {
  const [transactionsNotReviewed, setTransactionsNotReviewed] = useState<any[]>([])
  const [reviewBuyRequest, setReviewBuyRequest] = useState({ isOpen: false, data: null })
  const { transactions } = useContext(TransactionsContext)
  const { timezone } = useContext(ClientContext)
  const { tokens_actions_request, tokens_actions_price } = useContext(IdiomContext)
  const { theme } = useContext(ColorContext)
  const { assetTypeLabel } = useClientConfig()
  const { t, i18n: { language } } = useTranslation()
  const { primary } = theme

  const openReviewBuyAsset = (data: any) => {
    setReviewBuyRequest({ isOpen: true, data })
  }

  const closeReviewBuyAsset = () => {
    setReviewBuyRequest({ isOpen: false, data: null })
  }

  useLayoutEffect(() => {
    const filterData = transactions.filter(transaction => transaction.authorized === 'approved')
    setTransactionsNotReviewed(Array.from(filterData, (transaction: Transaction) => {
      const { asset, amount, tokens } = transaction
      const { token_price } = asset
      const currency = asset?.currency
      const payFormat = formatCurrency(amount, currency, true)
      const tokensFormat = formatNumber(tokens)
      const token_priceFormat = formatCurrency(+token_price, currency, true)

      return {
        assetName: asset.name,
        token_operation: tokensFormat,
        payment_operation: payFormat,
        token_quantity: token_priceFormat,
        date: timezoneFormatWithHour(timezone, transaction?.createdAt),
        currency,
        options:
            <MyTextAction color={primary} onClick={() => openReviewBuyAsset({
              ...transaction,
              payFormat: amount,
              tokensFormat,
              currency,
              token_priceFormat: +token_price
            })}>
                {t('see_details')}
            </MyTextAction>
      }
    }))

    return () => setTransactionsNotReviewed([])
    // eslint-disable-next-line
    }, [transactions, language])

  const headerData = [
    {
      header: assetTypeLabel('singular', 'capitalize'),
      key: 'assetName'
    },
    {
      header: tokens_actions_request,
      key: 'token_operation'
    },
    {
      header: t('currency'),
      key: 'currency'
    },
    {
      header: t('total_investment'),
      key: 'payment_operation'
    },
    {
      header: tokens_actions_price,
      key: 'token_quantity'
    },
    {
      header: t('purchase_date'),
      key: 'date'
    },
    {
      header: t('options'),
      key: 'options'
    }
  ]

  return (
    <>
      <ReviewHistoricalBuyRequest
          open={reviewBuyRequest.isOpen}
          data={reviewBuyRequest.data}
          onClose={closeReviewBuyAsset}
      />
      <FilterTableHOC
        labelToFilter='date'
        labelToFilterType='date'
        rows={transactionsNotReviewed}
      >
        {({ dataFilter, key }) =>
          <MyRenderTable
              key={key}
              headerData={headerData}
              rowData={ dataFilter}
          />
      }
    </FilterTableHOC>
    </>
  )
}
