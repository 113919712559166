import { Acceptance } from '../types/types'
import { UseFetch } from './UseFetch'

interface PostBody {
  clientId: string
  investor_id: string
  issuer_id: string
}

export interface PostAcceptanceResponse {
  success: boolean
  records: Acceptance
  message: string
}

export const postAcceptance = async ({ clientId, investor_id, issuer_id }: PostBody): Promise<PostAcceptanceResponse> => {
  const init = {
    body: { investor_id, issuer_id },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('post', 'endpoint', `/acceptances/${clientId}`, init)
}

interface GetBody {
  clientId: string
  investor_id: string
  issuer_id: string
}

export interface GetAcceptanceResponse {
  success: boolean
  records: Acceptance[]
  message: string
}

export const getAcceptances = async ({
  clientId,
  investor_id,
  issuer_id
}: GetBody): Promise<GetAcceptanceResponse> => {
  const init = {
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('get', 'endpoint', `/acceptances/${clientId}/${investor_id}/${issuer_id}`, init)
}

interface PutBody {
  clientId: string
  investor_id: string
  issuer_id: string
  status: 'NO_CONFLICT' | 'REQUESTED' | 'REJECTED' | 'APPROVED'
  name?: string
  email?: string
}

export interface PutAcceptanceResponse {
  success: boolean
  records: Acceptance
  message: string
}

export const putAcceptance = async ({
  clientId,
  investor_id,
  issuer_id,
  status,
  name = 'default',
  email = 'default@example.co'
}: PutBody): Promise<PutAcceptanceResponse> => {
  const init = {
    body: { status, investor: { email, name } },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('put', 'endpoint', `/acceptances/${clientId}/${investor_id}/${issuer_id}`, init)
}

interface PutFirstTimeBody {
  clientId: string
  investor_id: string
  issuer_id: string
  name?: string
  email?: string
}

export interface PutAcceptanceResponseFirstTime {
  success: boolean
  records: Acceptance
  message: string
}

export const putAcceptanceFirstTime = async ({
  clientId,
  investor_id,
  issuer_id,
  name = 'default',
  email = 'default@example.co'
}: PutFirstTimeBody): Promise<PutAcceptanceResponseFirstTime> => {
  const init = {
    body: { investor: { email, name } },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('put', 'endpoint', `/acceptances/${clientId}/${investor_id}/${issuer_id}`, init)
}

interface GetByIssuerAndStatusBody {
  clientId: string
  status: 'NO_CONFLICT' | 'REQUESTED' | 'REJECTED' | 'APPROVED'
  issuer_id: string
}

export interface GetByIssuerAndStatusAcceptanceResponse {
  success: boolean
  records: Acceptance[] | null
  message: string
}

export const getByIssuerAndStatusAcceptances = async ({
  clientId,
  status,
  issuer_id
}: GetByIssuerAndStatusBody): Promise<GetAcceptanceResponse> => {
  const init = {
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('get', 'endpoint', `/acceptances/${clientId}/?issuer_id=${issuer_id}&status=${status}`, init)
}

interface GetAllRecordsByInvestorBody {
  clientId: string
  investor_id: string
}

export interface GetAllRecordsByInvestorResponse {
  success: boolean
  records: Acceptance[]
  message: string
}

export const getAllRecordsByInvestor = async ({
  clientId,
  investor_id
}: GetAllRecordsByInvestorBody): Promise<GetAllRecordsByInvestorResponse> => {
  const init = {
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('get', 'endpoint', `/acceptances/${clientId}/${investor_id}`, init)
}
