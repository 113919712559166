import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { usePagination } from '../../hooks/usePagination'
import { Paragraph } from '../Paragraph'
import { colorPalette } from '../../conf/ColorConfig'
import { MobileTable } from './MobileTable'
import { useResponsive } from '../../hooks/useResponsive'

interface HeaderDataProps {
  header: string
  key: string
}

export interface TableProps {
  title?: string | JSX.Element
  headerData: HeaderDataProps[]
  rowData: any[]
}

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#FFF',
    fontWeight: 900,
    borderBottom: `1px solid ${colorPalette.basics.HEX_738099}`
  }
}))

export const StyledTableRow = styled(TableRow)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '1rem',
  '&:nth-of-type(even)': {
    backgroundColor: colorPalette.basics.HEX_F2F7FF
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const TitleTable = styled('strong')`
  color: ${colorPalette.basics.HEX_738099};
  text-transform: uppercase;
`

function getLabel (obj: any) {
  if (typeof obj === 'object' && obj?.join) {
    return obj.join(', ')
  }
  return obj
}
export const MyTable = ({ title, headerData, rowData }: TableProps) => {
  const { MyPagination, filterData } = usePagination(rowData)
  const { isMobileDevice } = useResponsive()

  if (isMobileDevice) return <MobileTable headerData={headerData} rowData={rowData} />
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerData.map((e, index) =>
                <StyledTableCell key={index}>
                  <Paragraph type='small'>
                    <TitleTable>
                      {e.header}
                    </TitleTable>
                  </Paragraph>
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterData.map((e, index: number) =>
              <StyledTableRow hover key={index}>
                {headerData.map(({ key }, index) =>
                  <TableCell key={index}>
                    {getLabel(e[key])}
                  </TableCell>
                )}
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <MyPagination />
    </>
  )
}
