import { createContext, useState } from 'react'

const ClientContext = createContext<any>({})

const ClientProvider = ({ children }: any) => {
  const [client, setClient] = useState<any>(() => JSON.parse(localStorage.getItem('client') ?? '{}'))

  const handleSetClient = (value: any) => {
    setClient(value)
    localStorage.setItem('client', JSON.stringify({ client: value }))
  }

  const clientRefresh = () => {
    setClient(
      JSON.parse(localStorage.getItem('client') ?? '{}')
    )
  }

  const getClient = () => {
    return JSON.parse(localStorage.getItem('client') ?? '{}')
  }

  const clientClear = () => {
    setClient({})
    localStorage.setItem('client', JSON.stringify({}))
  }

  const personTerminology = () => {
    return client?.client?.configuration?.personTypeTerminology
  }

  const clientId = () => {
    clientRefresh()
    const myClient = getClient()
    return myClient?.client?.id || ''
  }
  const getPersonType = (type: number) => {
    const terminology = personTerminology()
    if (terminology === 'latam') {
      if (type === 1) return 'natural'
      else if (type === 2) return 'física'
    } else if (terminology === 'mx') {
      if (type === 1) return 'física'
      else if (type === 2) return 'moral'
    }
  }

  const currencyOptions = client?.client?.configuration?.currencyOptions || ''
  const client_config = client?.client?.configuration || ''
  const logo = client?.client?.files?.logo?.path
  const timezone = client?.client?.configuration?.regionalConf?.timezone
  const mandatory_vdr = client?.client?.configuration?.mandatoryVdr || false
  const typeformUrl = client?.client?.configuration?.typeformUrl || ''
  const usageConditions = client?.client?.files?.usageConditions?.path || ''
  const declarationFile = client?.client?.files?.declaration?.path || ''
  const politicsFile = client?.client?.files?.policies?.path || ''
  const disclaimer = client?.client?.texts?.disclaimer || client?.disclaimer
  const client_id = client?.client?.id || ''
  const login_client_id = client?.id || ''
  const vdrInstructions = client?.client?.texts?.vdrInstructions || []
  const paymentInstructions = client?.client?.texts?.paymentInstructions || []
  const onbHasAdditionalFields = client?.client?.configuration?.onbHasAdditionalFields
  const assetsType = client?.client?.configuration?.assetsType || 'ALL'
  const purchaseDisclaimer = client?.client?.texts?.purchaseDisclaimer || ''
  const documentsDisclaimer = client?.client?.texts?.documentsDisclaimer || ''
  const isConfidentiality = client?.client?.configuration?.confidentiality

  const data = {
    client,
    handleSetClient,
    clientRefresh,
    clientClear,
    personTerminology,
    clientId,
    getPersonType,
    currencyOptions,
    client_config,
    logo,
    timezone,
    mandatory_vdr,
    typeformUrl,
    usageConditions,
    disclaimer,
    politicsFile,
    declarationFile,
    client_id,
    vdrInstructions,
    onbHasAdditionalFields,
    paymentInstructions,
    assetsType,
    purchaseDisclaimer,
    documentsDisclaimer,
    isConfidentiality,
    login_client_id
  }
  return (
    <ClientContext.Provider value={data}>
      {children}
    </ClientContext.Provider>
  )
}

export { ClientProvider }
export default ClientContext
