import { AssetCard, LoadingCard } from '../../../../components/Card/AssetCard'
import styled from '@emotion/styled'
import { useContext, memo } from 'react'
import { AssetsContext } from '../../../../contexts/AssetsContext'
import { useTranslation } from 'react-i18next'

interface Props {
  assets: any[]
  onShowMore: (data: any) => void
}

const Container = styled.div`
  display: grid;
  grid-template-columns:repeat(auto-fill, minmax(353px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  gap: 1rem;
`

const InformativeTextContainer = styled.div`
  text-align: center;
`

const CardSkeleton = ['s1', 's2', 's3', 's4', 's5', 's6']

export const AssetsList = memo(({ assets, onShowMore }: Props) => {
  const { isAssetsLoading } = useContext(AssetsContext)
  const { t } = useTranslation()

  const getAssetList = () => {
    if (isAssetsLoading) {
      return <Container>
        { CardSkeleton.map(el =>
          <LoadingCard variant='rectangular' key={el}/>
        )}
      </Container>
    }
    if (assets.length && !isAssetsLoading) {
      return <Container>
        { assets.map((e: any, index: number) =>
          <div key={index} style={{ height: '100%' }}>
            <AssetCard {...{ ...e, onShowMore }} />
          </div>
        )}
      </Container>
    }
    return <InformativeTextContainer>
      <h6>{t('non_results')}</h6>
    </InformativeTextContainer>
  }

  return getAssetList()
})
