import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { MyTable } from '../../../components'
import { MyFlexContainer } from '../../../components/common/MyFlexContainer'
import EditIcon from '@mui/icons-material/Edit'
import { ColorContext } from '../../../contexts/ColorContext'
import { Client } from '../../../types/types'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { IconButton } from '@mui/material'

const headerData = [
  { header: 'Nombre', key: 'name' },
  { header: 'Correo principal', key: 'users' },
  { header: 'Acciones', key: 'actions' }
]

interface ShowDialogProps {
  mode: 'create' | 'edit'
  data: Client | null
  isOpen: boolean
}

interface Props {
  data: Client[]
  handleEdit: (values: ShowDialogProps) => void
}

export const ClientTable = ({ data, handleEdit }: Props) => {
  const [rows, setRows] = useState<Client[]>([])
  const { theme } = useContext(ColorContext)
  const navigate = useNavigate()
  const { primary } = theme

  useEffect(() => {
    setRows(Array.from(data, client => {
      const dataToEdit: ShowDialogProps = { mode: 'edit', data: client, isOpen: true }
      return {
        ...client,
        actions:
        <MyFlexContainer>
          <IconButton onClick={() => navigate('/Dashboard/admins-user-master')}>
            <ManageAccountsIcon htmlColor={primary} />
          </IconButton>
          <IconButton onClick={() => handleEdit(dataToEdit)}>
            <EditIcon htmlColor={primary} />
          </IconButton>
        </MyFlexContainer>
      }
    }))
    // eslint-disable-next-line
  }, [data, handleEdit])

  return (
    <MyTable title='Clientes' rowData={rows} headerData={headerData} />
  )
}
