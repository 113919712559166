import { styled } from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { colorPalette } from '../../conf/ColorConfig'
import { Paragraph } from '../Paragraph'
import { AvailableLabelAndColor } from './AssetCard'

export const CardFront = ({ ...props }: any) => {
  const { t } = useTranslation()
  const {
    name,
    logo,
    additional_data,
    issuer_logo,
    usingLogo
  } = props

  const { backgroundColor, text: AvailableText } = AvailableLabelAndColor(props?.status || 'PENDING')

  return (
    <CardPosition>
      <ImgContainer>
        <AvailableLabel color={backgroundColor}>
          <Paragraph colorType='white' type='caption'>
            <strong>
              { AvailableText }
            </strong>
          </Paragraph>
        </AvailableLabel>
        <Img
          src={usingLogo ? issuer_logo : logo}
          alt='logo'
        />
      </ImgContainer>
      <BodyAndFooter>
        <BodyContent>
          <div>
            <Paragraph>
              <strong>
                {name || 'Nombre'}
              </strong>
            </Paragraph>
          </div>
          <AdditionalDataContainer>
            { additional_data.map((e: any, index: number) => {
              if (index >= 0 && index < 2) {
                return (
                  <AdditionalDataLabel key={index}>
                    <div>
                      <Paragraph type='caption'>{ t(e.title) }</Paragraph>
                    </div>
                    <div>
                      <Paragraph type='caption'>{ t(e.description) }</Paragraph>
                    </div>
                  </AdditionalDataLabel>
                )
              }
              return <Fragment key={index} />
            })}
          </AdditionalDataContainer>
        </BodyContent>
        <FooterContainer>
          <Paragraph type='caption' style={{ fontWeight: 500, color: colorPalette.basics.HEX_00266A }}>
            {t('see_more')}
          </Paragraph>
        </FooterContainer>
      </BodyAndFooter>
    </CardPosition>
  )
}
const CardPosition = styled('div')`
  position: relative;
  height: 100%;
`
const ImgContainer = styled('div')`
  position: relative;
`
const Img = styled('img')`
  width: 100%;
  height: 265px;
  object-fit: cover;
`
const AvailableLabel = styled('div')<{ color: string }>`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: ${({ color }) => color};
  padding: 4px 16px;
  border-radius: 8px 8px 0 0;
`
const BodyAndFooter = styled('div')`
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
`
const BodyContent = styled('div')`
  overflow-wrap: break-word;
  padding: 16px;
  margin-bottom: 32px;
`
const AdditionalDataContainer = styled('div')`
  display: flex;
  justify-content: space-around;
`
const AdditionalDataLabel = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  word-break: break-all;
  width: 50%;
`
const FooterContainer = styled('div')`
  text-align: center;
  height: 32px;
  background-color: ${colorPalette.primary.HEX_BED2FE};
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
`
