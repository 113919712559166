import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyTable } from '../../../../../components'
import { FilterTableHOC } from '../../../../../components/Tables/FilterTableHOC'
import ClientContext from '../../../../../contexts/ClientContext'
import { IdiomContext } from '../../../../../contexts/IdiomContext'
import { timezoneFormatWithHour } from '../../../../../core/timezoneFormat'
import { formatCurrency, formatNumber } from '../../../../../core/utils'
import { useClientConfig } from '../../../../../hooks/useClientConfig'
import { Transaction } from '../../../../../types/types'

interface Props {
  approvedTransactions: Transaction[]
}

export const ApprovedPurchasedTable = ({ approvedTransactions }: Props) => {
  const { tokens_actions_price, tokens_actions_request } = useContext(IdiomContext)
  const { timezone } = useContext(ClientContext)
  const { assetTypeLabel } = useClientConfig()
  const [rows, setRows] = useState<any[]>([])
  const [t] = useTranslation()

  useEffect(() => {
    setRows(Array.from(approvedTransactions, (transaction: Transaction) => {
      const { asset, tokens, amount, investor } = transaction
      const { token_price } = asset
      const { first_names, fathers_name, mothers_name } = investor
      const currency = asset?.currency
      const payFormat = formatCurrency(amount, currency, true)
      const tokensFormat = formatNumber(tokens)
      const token_priceFormat = `${formatCurrency(+token_price, currency, true)}`

      return {
        currency,
        investor_name: `${first_names} ${fathers_name} ${mothers_name}`,
        assetName: asset.name,
        token_operation: tokensFormat,
        payment_operation: payFormat,
        token_quantity: token_priceFormat,
        date: timezoneFormatWithHour(timezone, transaction?.updatedAt)
      }
    }))
  }, [timezone, approvedTransactions])

  const headerData = [
    {
      header: t('investors'),
      key: 'investor_name'
    },
    {
      header: assetTypeLabel('singular', 'uppercase'),
      key: 'assetName'
    },
    {
      header: t('currency'),
      key: 'currency'
    },
    {
      header: tokens_actions_request,
      key: 'token_operation'
    },
    {
      header: t('purchase_value'),
      key: 'payment_operation'
    },
    {
      header: tokens_actions_price,
      key: 'token_quantity'
    },

    {
      header: t('approbated_date'),
      key: 'date'
    }
  ]

  return (
    <FilterTableHOC
      labelToFilter='date'
      labelToFilterType='date'
      rows={rows}
    >
      {({ dataFilter, key }) =>
        <MyTable
          key={key}
          headerData={headerData}
          rowData={dataFilter}
          title=''
        />
      }
    </FilterTableHOC>
  )
}
