import { useContext, useState } from 'react'
import LoadingContext from '../../../../contexts/LoadingContext'
import { AssetDetails } from './Details/AssetDetails'
import { AssetsList } from './AssetsList'
import { FilterProps, initialFilter } from './coreFilter'
import { Filters } from './Filters'
import { PaginationAsset } from './PaginationAsset'
import { RequestVDR } from './Details/RequestVDR'
import NotificationContext from '../../../../contexts/NotificationContext'
import BuyDialog from './Buy/BuyDialog'
import { useAcceptances } from '../../../../hooks/useAcceptances'
import { useNavigate } from 'react-router-dom'
import { scrollToTop } from '../../../../core/utils'
import { sendEvent } from '../../../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../../../core/GA/GoogleAnalyticsEvents'
import { AllCardContainer, StaticDiv, TitleSinFiltro } from '../Styles/StylesComponents'
import { AssetsContext } from '../../../../contexts/AssetsContext'
import { Title } from '../../../../components/Title'
import { OrderAssetsFilter } from './OrderAssetsFilter'
import { useTranslation } from 'react-i18next'

export const DashboardInvestor = () => {
  const { canAccess, acceptConditions } = useAcceptances()
  const [filterData, setFilterData] = useState<FilterProps>(initialFilter)
  const [showAssetDetails, setShowAssetDetails] = useState(false)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const [filterAssets, setFilterAssets] = useState<any[]>([])
  const [requestVDR, setRequestVDR] = useState(false)
  const [assetData, setAssetData] = useState<any>()
  const { assets, loadAssetToInvestorDashboard } = useContext(AssetsContext)
  const [buyAsset, setBuyAsset] = useState<boolean>(false)
  const [beforeAccept, setBeforeAccept] = useState<'buy' | 'details'>('details')
  const [orderBy, setOrderBy] = useState<string>('asc')
  const { t } = useTranslation()
  const [isConfidentiality, setIsConfidentiality] = useState<boolean>(false)

  const navigate = useNavigate()

  const openRequestVDR = () => {
    setRequestVDR(true)
  }
  const closeRequestVDR = () => {
    setRequestVDR(false)
  }

  const openCompleteDataFlow = () => {
    navigate('/Dashboard/investors-onb')
  }

  const openAssetDetails = async (data: any) => {
    setAssetData(data)
    setBeforeAccept('details')
    const resp = await canAccess(data?.issuer_id, openCompleteDataFlow)
    if (resp === 'yes') {
      sendEvent({ ...GA_EVENTS.asset_details, label: data?.name })
      setShowAssetDetails(true)
      scrollToTop()
    } else if (resp === 'no') openRequestVDR()
  }

  const openBuyAsset = async (data: any, flagIsConfidentiality: boolean) => {
    setBeforeAccept('buy')
    setAssetData(data)
    setIsConfidentiality(flagIsConfidentiality)
    const resp = await canAccess(data?.issuer_id, openCompleteDataFlow)
    if (resp === 'yes') {
      sendEvent({ ...GA_EVENTS.click_buy_now, label: data?.name })
      setBuyAsset(true)
    } else if (resp === 'no') openRequestVDR()
  }

  const closeBuyAsset = () => {
    sendEvent(GA_EVENTS.click_cancel_purchase)
    setBuyAsset(false)
  }

  const onAcceptVDR = async () => {
    showLoading()
    const resp = await acceptConditions(assetData.issuer_id)
    if (!resp) {
      showToast('error', 'Error')
      return
    }
    closeRequestVDR()
    if (beforeAccept === 'details') {
      setShowAssetDetails(true)
      scrollToTop()
    } else setBuyAsset(true)
    hideLoading()
  }

  const closeAssetDetails = () => {
    setShowAssetDetails(false)
    setAssetData({})
  }

  return (
        <>
            {!showAssetDetails
              ? <>
                    <TitleSinFiltro>
                      <Title>{t('investor_user.investment_opportunities')}</Title>
                    </TitleSinFiltro>
                    <StaticDiv >
                      <div style={{ paddingTop: '2rem' }}>
                        <Title>{t('investor_user.investment_opportunities')}</Title>
                      </div>
                      <Filters setData={setFilterData} data={filterData} />
                    </StaticDiv>
                </>
              : <AssetDetails
                    assetData={assetData}
                    issuerId={assetData?.issuer_id}
                    onBuy={openBuyAsset}
                    onClose={closeAssetDetails}
                />
            }
            {!showAssetDetails
              ? <AllCardContainer>
                    <>
                        <OrderAssetsFilter orderBy={orderBy} setOrderBy={setOrderBy} />
                        <AssetsList assets={filterAssets} onShowMore={openAssetDetails} />
                        <PaginationAsset
                            data={assets}
                            setData={setFilterAssets}
                            filters={filterData}
                            orderBy={orderBy}
                            filterData={filterAssets}
                        />
                    </>
                </AllCardContainer>
              : <></>
            }
            <BuyDialog
                refreshTokens={loadAssetToInvestorDashboard}
                isOpen={buyAsset}
                asset={assetData}
                onClose={closeBuyAsset}
                goDashboard={closeAssetDetails}
                canAccess={isConfidentiality}
            />
            <RequestVDR
                assetName={assetData?.name}
                open={requestVDR}
                onClose={closeRequestVDR}
                onAccept={onAcceptVDR}
            />
        </>
  )
}
