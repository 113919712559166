import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { MyButton } from '../../../components/MyButton'
import UserContext from '../../../contexts/UserContext'
import { useContext } from 'react'
import { Paragraph } from '../../../components/Paragraph'
import { useTranslation } from 'react-i18next'

export const BackButton = () => {
  const { user_rol } = useContext(UserContext)
  const navigate = useNavigate()
  const [t] = useTranslation()

  return (
    <MyButton color='secondary' onClick={() => navigate(`/Dashboard/${user_rol}`)}>
      <div style={{ display: 'flex', gap: '10px' }}>
          <ArrowBackIcon/>
          <Paragraph>{t('return')}</Paragraph>
      </div>
    </MyButton>
  )
}
