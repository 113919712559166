import { Dialog, Grid } from '@mui/material'
import { useContext } from 'react'
import { MyTransition } from '../../../../components/MyTransition'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { MyButton } from '../../../../components/MyButton'
import ClientContext from '../../../../contexts/ClientContext'
import { timezoneFormatWithHour } from '../../../../core/timezoneFormat'
import { Paragraph } from '../../../../components/Paragraph'
import { LabelModalRow } from '../../Cliente/Autorizar/Activos/AssetBuyReview'
import { useResponsive } from '../../../../hooks/useResponsive'
import { useClientConfig } from '../../../../hooks/useClientConfig'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../../../core/utils'

interface Props {
  open: boolean
  onClose: () => void
  data: any
}

export const ReviewHistoricalBuyRequest = ({ open, onClose, data }: Props) => {
  const { tokens_actions_request, tokens_actions_price_by } = useContext(IdiomContext)
  const { timezone } = useContext(ClientContext)
  const { isMobileDevice } = useResponsive()
  const { assetTypeLabel } = useClientConfig()
  const { t } = useTranslation()

  return (
    <Dialog
      PaperProps={{ style: { padding: isMobileDevice ? '14px 16px' : '32px 48px', maxWidth: 650 } }}
      open={open}
      onClose={onClose}
      TransitionComponent={MyTransition}
    >
      <Grid container spacing={1} >
        <Grid item xs={12} sm={12} textAlign='center'>
          <Paragraph bold>{t('purchase_details')}</Paragraph>
        </Grid>
        <Grid item xs={6} sm={6} md={6} textAlign='right' mt={2}>
          <Paragraph bold>{t('investor_data')}</Paragraph>
        </Grid>
        <Grid item xs={6} />
        <LabelModalRow title={t('full_name')} label={`${data?.investor?.first_names} ${data?.investor?.fathers_name} ${data?.investor?.mothers_name}`} />
        <LabelModalRow title={t('country')} label={t(`countries.${data?.investor?.additional_data?.country}`)} />
        <LabelModalRow title={t('sum_to_invest')} label={formatCurrency(data?.payFormat, data?.asset?.currency)} />
        <Grid item xs={12} sm={6} md={6} textAlign='right' mt={2}>
          <Paragraph bold>{t('purchase_information')}</Paragraph>
        </Grid>
        <Grid item xs={6} />
        <LabelModalRow title={assetTypeLabel('singular', 'capitalize')} label={data?.asset?.name} />
        <LabelModalRow title={tokens_actions_request} label={data?.tokensFormat} />
        <LabelModalRow title={tokens_actions_price_by} label={formatCurrency(data?.token_priceFormat, data?.asset?.currency)} />
        <LabelModalRow title={t('purchase_date')} label={timezoneFormatWithHour(timezone, data?.createdAt)} />
        <Grid item xs={4} sm={4} />
        <Grid item xs={4} sm={4} mt={2}>
          <MyButton
            color='secondary'
            fullWidth
            onMouseUp={onClose}
          >{t('close')}</MyButton>
        </Grid>
      </Grid>
    </Dialog>
  )
}
