import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const MyTextLink = styled(Link)`
    color: ${props => props.color};
    opacity: 0.5;
    &:hover {
        color: ${props => props.color};
        opacity: 1;
    }
`
export const MyTextAction = styled('div')`
    color: ${props => props.color};
    font-size: 12px;
    opacity: 0.5;

    cursor: pointer;
    &:hover {
        color: ${props => props.color};
        opacity: 1;
    }
`
export const MyDangerTextAction = styled('div')`
    color: ${props => props.color};
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        color: ${props => props.color};
        opacity: 1;
    }
`
