import { Fragment } from 'react'
import { Grid } from '@mui/material'
import { MyTextInput } from '../MyTextInput'
import { MyButton } from '../MyButton'

export interface DynamicData {
  title: string
  language?: string
  body: string
}

interface Props {
  data: DynamicData[] | any
  setData: React.Dispatch<any>
  labelOne: string
  labelTwo: string
  labelThree?: string
  labelAdd: string
}

const MyDynamicDataGenerator = ({ data, setData, labelAdd, labelOne, labelTwo, labelThree }: Props) => {
  const addNewData = () => {
    setData([...data, {}])
  }

  const removeData = (idEnlace: number) => {
    const dataList = [...data]
    dataList.splice(idEnlace, 1)
    setData(dataList)
  }

  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target
    const dataList = [...data]
    dataList[index][name] = value
    setData(dataList)
  }

  return (
    <>
      {data.length >= 1 && <Grid container spacing={2} mb={1} style={{ padding: '1rem 0' }}>
        {
          data?.map(({ title, body, language }: DynamicData, index: number) => (
              <Fragment key={index}>
                <Grid item xs={12} sm={4} alignSelf='flex-end'>
                  <MyTextInput
                    name='title'
                    value={title}
                    label={labelOne}
                    onChange={(e: any) => handleChange(e, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} alignSelf='flex-end'>
                  <MyTextInput
                    name='body'
                    value={body}
                    label={labelTwo}
                    onChange={(e: any) => handleChange(e, index)}
                  />
                </Grid>
                {labelThree !== undefined &&
                <Grid item xs={12} sm={2} alignSelf='flex-end'>
                  <MyTextInput
                    name='language'
                    value={language}
                    label={labelThree}
                    onChange={(e: any) => handleChange(e, index)}
                  />
                </Grid>
                }
                <Grid item xs={12} sm={2} alignSelf='flex-end'>
                  <MyButton
                    fullWidth
                    color="info"
                    onMouseUp={() => removeData(index)}
                  >
                    Remover
                  </MyButton>
                </Grid>
              </Fragment>

          )
          )
        }
      </Grid>}
      <MyButton
        fullWidth
        onMouseUp={addNewData}

      >
        {labelAdd}
      </MyButton>
    </>
  )
}

export default MyDynamicDataGenerator
