import { useContext } from 'react'
import { Grid } from '@mui/material'
import { Paragraph } from '../../../../components/Paragraph'
import { useClientConfig } from '../../../../hooks/useClientConfig'
import { MyButton } from '../../../../components/MyButton'
import { AssetFormContext } from './DialogAssetForm'
import { MyButtonIcon } from '../../../../components'
import { AssetPreview } from '../../../../components/AssetDetails/AssetPreview'
import { MainVDR } from '../../../../components/VDR/MainVDR'
import UserContext from '../../../../contexts/UserContext'
import { colorPalette } from '../../../../conf/ColorConfig'
import { useScrollToTop } from '../../../../hooks/useScrollToTop'
import { useTranslation } from 'react-i18next'

interface Props {
  handleFinishForm: () => Promise<void>
}

export const FinalStepForm = ({ handleFinishForm }: Props) => {
  const { onClose, previousStep, assetData, mode, assetCurrency } = useContext(AssetFormContext)
  const { issuer_id, VDR_ID, issuer } = useContext(UserContext)
  const { assetTypeLabel, formOfVerb } = useClientConfig()
  const { t, i18n: { language } } = useTranslation()
  useScrollToTop()

  const title = language === 'es'
    ? `¡Tu ${assetTypeLabel('singular', 'lowercase')} está configurad${formOfVerb()}!`
    : `Your ${assetTypeLabel('singular', 'lowercase')} is ready!`
  const issuer_logo = issuer?.logo
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} mb={2}>
        <MyButtonIcon
            size='small'
            icon='backArrow'
            onClick={previousStep}
            text={t('back')}
            type='secondary'
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Paragraph type='subheading'><strong>{title}</strong></Paragraph>
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Paragraph>{t('company_user.create_asset.subtitle_six')}</Paragraph>
      </Grid>
      <Grid item >
          <MyButton
              color='secondary'
              onMouseUp={onClose}
          >{t('cancel')}</MyButton>
      </Grid>
      <Grid item >
          <MyButton
              onMouseUp={handleFinishForm}
          >{t('send_now')}
          </MyButton>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        mt={4}
        p={4}
        borderRadius={8}
        border={`1px solid ${colorPalette.basics.HEX_738099}`}
      >
        <div style={{ padding: 16 }}>
          <AssetPreview
            assetData={{
              ...assetData,
              issuer,
              issuer_id,
              issuer_logo,
              logo: mode === 'crear' ? issuer_logo : assetData?.logo,
              status: 'PENDING',
              currency: assetCurrency,
              contract: { tokensAvailable: assetData?.token_quantity }
            }}
            canViewConfidentialLabels
            VDRComponent={
              <MainVDR
                  type='Activo'
                  issuer_id={issuer_id}
                  vdr_id={`${VDR_ID()}/${assetData.id}`}
                  canDelete={false}
                  canEdit={false}
                  canLoad={false}
                  linkAsset={assetData?.linkVdr}
                  canAccess
                  canSeeConfidential
              />
            }
          />
        </div>
      </Grid>

    </Grid>
  )
}
