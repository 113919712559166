import { useContext } from 'react'
import UserContext from '../../contexts/UserContext'
import { ChangePassword } from './ChangePassword'
import { GeneralConfig } from './GeneralConfig'
import { IssuerProfile } from './UpdateProfile/IssuerProfile'
import { Grid } from '@mui/material'
import { TabsContainer } from '../../components/DashboardTabs/TabsContainer'
import { Title } from '../../components/Title'
import { useTranslation } from 'react-i18next'

export const ConfigurationDialog = () => {
  const { user_rol } = useContext(UserContext)
  const { t } = useTranslation()

  const TabsWithoutCompanyProfile = [
    { title: t('settings_page.my_profile'), component: <GeneralConfig /> },
    { title: t('settings_page.change_password'), component: <ChangePassword /> }
  ]

  const TabsWithCompanyProfile = [
    { title: t('settings_page.my_profile'), component: <GeneralConfig /> },
    { title: t('settings_page.change_password'), component: <ChangePassword /> },
    { title: t('settings_page.update_profile'), component: <IssuerProfile /> }
  ]

  return (
    <Grid container mt={3} spacing={2}>
        <Grid item xs={12} sm={12} md={12} ml={6} mb={2}>
            <Title>{t('configuration')}</Title>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
        {
            user_rol === 'issuers'
              ? <TabsContainer tabs={TabsWithCompanyProfile} />
              : <TabsContainer tabs={TabsWithoutCompanyProfile} />
        }
        </Grid>
    </Grid>
  )
}
