import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MyButton } from '../../../../components/MyButton'
import { Paragraph } from '../../../../components/Paragraph'

interface Props {
  onFinish: () => Promise<void>
}

export const FormConfirmationPage = ({ onFinish }: Props) => {
  const [t] = useTranslation()
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={12} xs={12} mb={3}>
          <Paragraph type='subheading'><strong>{t('create_company_form.page_three.title')}</strong></Paragraph>
      </Grid>
      <Grid item sm={12} md={9} xs={12}>
          <Paragraph>{t('create_company_form.page_three.description')}</Paragraph>
      </Grid>
      <Grid item sm={12} />
      <Grid item sm={6} md={2} xs={12} mt={3}>
          <MyButton onClick={onFinish}>
            {t('exit')}
          </MyButton>
      </Grid>
    </Grid>
  )
}
