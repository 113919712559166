import { useContext, useEffect, useState } from 'react'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { TabPanel, MyCustomTab } from '../../../components'
import { CreateClientForm } from './CreateClientForm'
import { SetupClient } from './SetupClient'
import { ConfirmationPage } from '../../Shared/ConfirmationPage'
import LoadingContext from '../../../contexts/LoadingContext'
import NotificationContext from '../../../contexts/NotificationContext'
import { DynamicData } from '../../../components/LinkGenerators/MyDynamicDataGenerator'
import { createClient } from '../../../core/apiClients'
import { uploadImg } from '../../../core/apiUploadDocs'
import { IdiomContext } from '../../../contexts/IdiomContext'
import { Client } from '../../../types/types'

const headers = [
  {
    title: 'Datos',
    subtitle: 'Paso 1 de 3',
    iconUnsolved: <RotateLeftIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  },
  {
    title: 'Configuración',
    subtitle: 'Paso 2 de 3',
    iconUnsolved: <RotateLeftIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  },
  {
    title: 'Confirmación',
    subtitle: 'Paso 3 de 3',
    iconUnsolved: <CheckCircleOutlineIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  }
]

interface Props {
  onClose: () => void
  refreshTable: () => Promise<any>
  data: Client | null
  mode: 'edit' | 'create'
}

const clientValues = {
  clientName: '',
  mainUser: '',
  mainLastNameFather: '',
  mainLastNameMother: '',
  mainEmail: '',

  secondUser: '',
  secondLastNameFather: '',
  secondLastNameMother: '',
  secondEmail: '',

  threeUser: '',
  threeLastNameFather: '',
  threeLastNameMother: '',
  threeEmail: '',
  logo: '',

  wallet: '',
  private_key: ''
}

const setupClientValues = {
  asset_currency: '',
  token_currency: '',
  expected_return: false,
  flow_periodicity: false,
  confidentiality: false,
  purchase_currency: '',
  manual_buyback: false,
  income_distribution: false,
  mandatory_vdr: false,
  type: '',
  person_type_terminology: '',
  timezone: '',
  usageConditions: '',
  send_email_address: '',
  receive_email_address: '',
  investorPersonFormId: '',
  investorRepresentativeFormId: '',
  issuerPersonFormId: '',
  issuerRepresentativeFormId: '',

  disclaimerTitle: '',
  disclaimerDescription: '',

  color1: '#28406A',
  color2: '#598BFE',
  color3: '#62C6E9',

  politicsFile: '',
  declarationFile: '',
  onbHasAdditionalFields: false,
  assetsType: ''
}

export const DialogCreateClient = ({ onClose, refreshTable, data, mode = 'create' }: Props) => {
  const [clientData, setClientData] = useState<any>(clientValues)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { authFlowErrors } = useContext(IdiomContext)
  const [indexTab, setIndexTab] = useState<number>(0)

  const nextStep = () => { setIndexTab(v => v + 1) }
  const previousStep = () => { setIndexTab(v => v - 1) }

  const createUser = (values: any) => {
    setClientData(values)
    nextStep()
  }

  const handleCreateClient = async (setUpValues: any) => {
    showLoading()
    const parseBuyData = setUpValues.buyData.map((e: DynamicData) => {
      return { field: e.title, value: e.body, language: e.language }
    })
    const vdrInstructions = setUpValues.vdrInstructions.map((e: DynamicData) => {
      return { title: e.title, description: e.body, language: e.language }
    })
    const resp = await createClient(clientData, setUpValues, parseBuyData, vdrInstructions)
    if (resp?.success) {
      await uploadImg(resp.records[0].logoSignedUrl, clientData.logo)
      await uploadImg(resp.records[0].usageSignedUrl, setUpValues.usageConditions)
      await uploadImg(resp.records[0].politicsSignedUrl, setUpValues.politicsFile)
      await uploadImg(resp.records[0].declarationSignedUrl, setUpValues.declarationFile)
      showToast('success', 'El cliente fue creado con éxito')
      nextStep()
    } else {
      const errorCode = resp?.errors?.[0]?.code
      if (errorCode) showToast('error', authFlowErrors[errorCode])
      else showToast('error', 'Error')
    }
    hideLoading()
  }

  const setupUser = async (values: any) => {
    await handleCreateClient(values)
  }

  const onFinish = async () => {
    await refreshTable()
    onClose()
  }

  useEffect(() => {
    if (mode === 'create') setClientData(clientValues)
    else setClientData(data)
  }, [mode, data])

  return (
    <MyCustomTab headersTab={headers} indexTab={indexTab}>
      <TabPanel key={0} value={indexTab} index={0}>
        <CreateClientForm
          initialValues={clientData}
          handleSubmit={createUser}
          onClose={onClose}
        />
      </TabPanel>
      <TabPanel key={1} value={indexTab} index={1}>
        <SetupClient
          initialValues={setupClientValues}
          handleSubmit={setupUser}
          previousStep={previousStep}
        />
      </TabPanel>
      <TabPanel key={2} value={indexTab} index={2}>
        <ConfirmationPage onFinish={onFinish} />
      </TabPanel>
    </MyCustomTab>
  )
}
