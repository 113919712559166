import { Dialog, Grid } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MyButton } from '../../../../components/MyButton'
import { MyTransition } from '../../../../components/MyTransition'
import { Paragraph } from '../../../../components/Paragraph'
import LoadingContext from '../../../../contexts/LoadingContext'
import { DialogProps } from '../../../../hooks/useDialog'
import { useResponsive } from '../../../../hooks/useResponsive'
import { useVDR } from '../../../../hooks/useVDR'

interface Props extends DialogProps {
  reloadTable: () => Promise<void>
}

export const DialogDeleteFile = ({ isOpen, onClose, data, reloadTable }: Props) => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { deleteVdrFile } = useVDR()
  const { isMobileDevice } = useResponsive()
  const { t } = useTranslation()

  const onDelete = async () => {
    showLoading()
    await deleteVdrFile(data?.id, data?.file, data?.folder)
    await reloadTable()
    hideLoading()
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={MyTransition}
      PaperProps={{ style: { padding: isMobileDevice ? '24px 16px' : '32px 48px', maxWidth: 450 } }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12} md={12} textAlign='center'>
          <Paragraph bold>{t('delete_doc')}</Paragraph>
        </Grid>
        <Grid item sm={12} xs={12} md={12} textAlign='center' mb={2}>
          <Paragraph>{t('delete_doc_confirmation')}</Paragraph>
        </Grid>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item >
            <MyButton
              onClick={onDelete}
            >
              {t('delete')}
            </MyButton>
          </Grid>
          <Grid item>
            <MyButton
              color='secondary'
              onClick={onClose}
            >
              {t('cancel')}
            </MyButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}
