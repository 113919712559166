import { Grid, Skeleton } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyTable } from '../../../../components'
import { MyButton } from '../../../../components/MyButton'
import { MyTextAction } from '../../../../components/MyTextLink'
import { Paragraph } from '../../../../components/Paragraph'
import { ColorContext } from '../../../../contexts/ColorContext'
import UserContext from '../../../../contexts/UserContext'
import { useDialog } from '../../../../hooks/useDialog'
import { useVDR } from '../../../../hooks/useVDR'
import { VDRFile } from '../../../../types/types'
import { DialogDeleteFile } from './DialogDeleteFile'
import { DialogUploadFiles } from './DialogUploadFiles'

interface RowProps extends VDRFile {
  actions: JSX.Element
}

export const VDRCompany = () => {
  const { getVdrByCompany, isLoading, files } = useVDR()
  const { closeDialog, data, isOpen, openDialog } = useDialog()
  const [showDialogUpload, setShowDialogUpload] = useState(false)
  const { theme: { secondary } } = useContext(ColorContext)
  const { issuer } = useContext(UserContext)
  const [rows, setRows] = useState<RowProps[]>([])
  const { t, i18n: { language } } = useTranslation()

  const onOpenDialogUpload = () => setShowDialogUpload(true)
  const onCloseDialogUpload = async () => {
    setShowDialogUpload(false)
    issuer?.typeVdr !== 'externo' && await getVdrByCompany()
  }

  useEffect(() => {
    async function init () {
      await getVdrByCompany()
    }
    issuer?.typeVdr !== 'externo' && init()
  }, [issuer])

  const HEADERS = [
    { header: t('full_name'), key: 'file' },
    { header: t('category'), key: 'folder' },
    { header: t('see_doc'), key: 'download' },
    { header: t('options'), key: 'actions' }
  ]

  useEffect(() => {
    setRows(Array.from(files, file => {
      return {
        ...file,
        download:
        <a href={'https://' + file.url} target='_blank' rel="noreferrer noopener">
          <MyTextAction color={secondary}>
            {t('download')}
          </MyTextAction>
        </a>,
        actions:
          <MyTextAction
            onClick={() => openDialog(file)}
            color={secondary}
          >
            {t('delete')}
          </MyTextAction>
      }
    }))

    return () => setRows([])
  }, [files, language])

  return (
    <>
    <DialogUploadFiles
      isOpen={showDialogUpload}
      onClose={onCloseDialogUpload}
      isLinkVdr={issuer?.typeVdr === 'externo'}
    />
    <DialogDeleteFile
      reloadTable={getVdrByCompany}
      isOpen={isOpen}
      onClose={closeDialog}
      data={data}
    />
    <Grid container spacing={2}>
      { issuer?.typeVdr !== 'externo'
        ? <>
          <Grid item xs={6} sm={8} md={9} />
          <Grid item xs={6} md={3} sm={4} textAlign='right'>
            <MyButton
              onMouseUp={onOpenDialogUpload}
            >{t('load_docs')}</MyButton>
          </Grid>
        </>
        : <>
          <Grid item xs={12} md={12} sm={12}>
            <MyButton
              onMouseUp={onOpenDialogUpload}
            >{t('load_link')}</MyButton>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <Paragraph><strong>{t('current_link')}:</strong> {issuer?.linkVdr}</Paragraph>
          </Grid>
        </>
      }
      {
        issuer?.typeVdr !== 'externo'
          ? <Grid item sm={12} xs={12} md={12} >
          { isLoading
            ? <Skeleton height={400} variant='rectangular'/>
            : <MyTable headerData={HEADERS} rowData={rows}/>
          }
        </Grid>
          : null
      }
    </Grid>
    </>
  )
}
