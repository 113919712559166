export const colorPalette = {
  basics: {
    HEX_F2F7FF: '#F2F7FF',
    HEX_E6EAF2: '#E6EAF2',
    HEX_CFD7E6: '#CFD7E6',
    HEX_ADB8CC: '#ADB8CC',
    HEX_8F9BB3: '#8F9BB3',
    HEX_738099: '#738099',
    HEX_3D4D6A: '#3D4D6A',
    HEX_28406A: '#28406A',
    HEX_13326A: '#13326A',
    HEX_00266A: '#00266A'
  },
  primary: {
    HEX_BED2FE: '#BED2FE',
    HEX_8CAEFE: '#8CAEFE',
    HEX_598BFE: '#598BFE',
    HEX_1F53CC: '#1F53CC',
    HEX_083499: '#083499'
  },
  secondary: {
    HEX_BFDEE9: '#BFDEE9',
    HEX_90D2E9: '#90D2E9',
    HEX_62C6E9: '#62C6E9',
    HEX_33BAE9: '#33BAE9',
    HEX_05AEE9: '#05AEE9'
  },
  complementary: {
    success: '#53C753',
    warning: '#F4C752',
    error: '#D35443',
    white: '#FFFFFF',
    black: '#000000'
  }
}
