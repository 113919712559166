import React, { useContext } from 'react'
import { styled } from '@mui/material'
import MySpanButton from '../../MySpanButton'
import NotificationContext from '../../../contexts/NotificationContext'
import { useTranslation } from 'react-i18next'

const Input = styled('input')({
  display: 'none'
})

interface Props {
  onSubmit: (files: any[]) => Promise<void>
  folder: string
}

export const VDRButton = ({ folder, onSubmit }: Props) => {
  const { showToast } = useContext(NotificationContext)
  const { t } = useTranslation()
  const handleUploadDoc = async (
    { currentTarget, target }: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = currentTarget.files
    if (files?.length) {
      let canPass = true
      for (let i = 0; i < files.length; i++) {
        if (files[i].name.length > 60) {
          canPass = false
          break
        }
      }
      if (canPass) {
        await onSubmit(Array.from(files ?? []))
      } else {
        showToast('error', t('toasts.VDR_file_restriction'))
        target.value = ''
      }
    }
    if (target.value) {
      target.value = ''
    }
  }

  return (
        <label htmlFor={'input-' + folder}>
            <MySpanButton>
                {t('load_new_doc')}
            </MySpanButton>
            <Input
                id={'input-' + folder}
                multiple
                accept='image/*, audio/*, video/*, .xlsx, .csv, .pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                type="file"
                onChange={handleUploadDoc}
            />
        </label>
  )
}
