import { useContext } from 'react'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import { ColorContext } from '../../contexts/ColorContext'
import ClientContext from '../../contexts/ClientContext'
import UserContext from '../../contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import logoQx from '../../assets/logo-primary-30x217.png'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import { colorPalette } from '../../conf/ColorConfig'
import { UserSidebarData } from '../../components/UserSidebarData'
import { SidebarTabs, SidebarTabsProps } from './SidebarTabs'
import { ButtonBottom, DrawerHeader, LogoContainer, SidebarFooter, UserRefContainer } from './StyledComponents'
import { MaterialIcon } from './SidebarOptions'
import { walletUrlEnv } from '../../core/utils'
import { useTranslation } from 'react-i18next'
import { MyNavLink } from '../../components/MyNavLink'

const drawerWidth = 320

export interface SidebarProps {
  options: SidebarTabsProps[]
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const DesktopSidebar = ({ options, open, setOpen }: SidebarProps) => {
  const { theme } = useContext(ColorContext)
  const { client, clientId } = useContext(ClientContext)
  const { userRol, user, handleLogOutUser } = useContext(UserContext)
  const navigate = useNavigate()
  const [t] = useTranslation()

  const handleDrawer = () => setOpen(prev => !prev)

  const logOutFunction = () => {
    handleLogOutUser()
    navigate(`/${clientId() || 'admin-qx'}`, { replace: true })
  }

  const routeToWallet = () => {
    window.open(`${walletUrlEnv}${user?.user?.wallets?.[0]?.address}#tokentxns`)
  }

  const redirectLogo = () => {
    const rol = userRol()
    if (rol !== 'investors') navigate(`/Dashboard/${rol}`)
    else navigate('/Dashboard/investors-purchases')
  }

  return (
      <Drawer variant="permanent" open={open} PaperProps={{ style: { backgroundColor: theme.primary, overflow: 'overlay' } }}>
        <DrawerHeader isOpen={open}>
          <IconButton onClick={handleDrawer}>
            <MenuIcon htmlColor={colorPalette.complementary.white}/>
          </IconButton>
          <LogoContainer
            src={ userRol() === 'admins' ? logoQx : client?.client?.files?.logo?.path || ''}
            onClick={redirectLogo}
            alt="Cargando..."
            isOpen={open}
          />
        </DrawerHeader>
        <UserRefContainer isOpen={open}>
          <UserSidebarData user={user?.user} isDrawerOpen={open} />
        </UserRefContainer>
        <List>
          {options.map((option: SidebarTabsProps, index: number) =>
              <SidebarTabs {...option} key={index} isDrawerOpen={open} />
          )}
        </List>
        <SidebarFooter>
          {user?.user?.group === 'investors'
            ? <ButtonBottom
                  isOpen={open}
                  title={t('wallet')}
                  Icon={<AccountBalanceWalletOutlinedIcon {...MaterialIcon} />}
                  onClick={routeToWallet}
                  notPointer={!user?.user?.wallets?.[0]?.address}
              />
            : <></>
          }
          <MyNavLink to='/Dashboard/Configuration' icon='Settings' isOpen={open}>
            {t('configuration')}
          </MyNavLink>
          <ButtonBottom
              isOpen={open}
              notPointer={false}
              title={t('log_out')}
              Icon={<LogoutIcon {...MaterialIcon} />}
              onClick={logOutFunction}
          />
        </SidebarFooter>
      </Drawer>
  )
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)
