import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Paragraph } from '../../../components/Paragraph'
import { Title } from '../../../components/Title'

export const TaxPage = () => {
  const { t } = useTranslation()
  const TEXTS = [
    t('tax_page.one'),
    t('tax_page.two'),
    t('tax_page.three'),
    t('tax_page.four')
  ]

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12} mt={2} mb={1}>
        <Title>{ t('tax') }</Title>
      </Grid>
      <Grid container spacing={2} mx='8%' my='4%'>
        <Grid item sm={12} xs={12}>
          <Paragraph bold type='subheading'>{ t('tax_page.dear') }</Paragraph>
        </Grid>
        { TEXTS.map((text, index) =>
          <Grid item sm={12} xs={12} key={index}>
            <Paragraph type='subheading'>{text}</Paragraph>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
