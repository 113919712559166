import { DynamicData } from '../../../../components/LinkGenerators/MyDynamicDataGenerator'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import NotificationContext from '../../../../contexts/NotificationContext'
import { ConfirmationPage } from '../../../Shared/ConfirmationPage'
import LoadingContext from '../../../../contexts/LoadingContext'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { TabPanel, MyCustomTab } from '../../../../components'
import { createClient } from '../../../../core/apiClients'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { uploadImg } from '../../../../core/apiUploadDocs'
import { StepOneEditClient } from './StepOneEditClient'
import { StepTwoEditClient } from './StepTwoEditClient'
import { Client } from '../../../../types/types'
import { useContext, useState } from 'react'

const headers = [
  {
    title: 'Datos',
    subtitle: 'Paso 1 de 3',
    iconUnsolved: <RotateLeftIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  },
  {
    title: 'Configuración',
    subtitle: 'Paso 2 de 3',
    iconUnsolved: <RotateLeftIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  },
  {
    title: 'Confirmación',
    subtitle: 'Paso 3 de 3',
    iconUnsolved: <CheckCircleOutlineIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  }
]

interface Props {
  onClose: () => void
  refreshTable: () => Promise<any>
  data: Client | null
  mode: 'edit' | 'create'
}

export const EditClientForm = ({ onClose, refreshTable, data, mode = 'create' }: Props) => {
  const [clientData, setClientData] = useState<any>(transformData(data))
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { authFlowErrors } = useContext(IdiomContext)
  const [indexTab, setIndexTab] = useState<number>(0)

  const nextStep = () => { setIndexTab(v => v + 1) }
  const previousStep = () => { setIndexTab(v => v - 1) }

  const createUser = (values: any) => {
    setClientData(values)
    nextStep()
  }

  const handleCreateClient = async (setUpValues: any) => {
    showLoading()
    const parseBuyData = setUpValues.buyData.map((e: DynamicData) => {
      return { field: e.title, value: e.body, language: e.language }
    })
    const vdrInstructions = setUpValues.vdrInstructions.map((e: DynamicData) => {
      return { title: e.title, description: e.body, language: e.language }
    })
    const resp = await createClient(clientData, setUpValues, parseBuyData, vdrInstructions)
    if (resp?.success) {
      await uploadImg(resp.client.logoSignedUrl, clientData.logo)
      await uploadImg(resp.client.usageSignedUrl, setUpValues.usageConditions)
      await uploadImg(resp.client.politicsSignedUrl, setUpValues.politicsFile)
      await uploadImg(resp.client.declarationSignedUrl, setUpValues.declarationFile)
      showToast('success', 'El cliente fue creado con éxito')
      nextStep()
    } else {
      const errorCode = resp?.errors?.[0]?.code
      if (errorCode) showToast('error', authFlowErrors[errorCode])
      else showToast('error', 'Error al crear el cliente')
    }
    hideLoading()
  }

  const setupUser = async (values: any) => {
    await handleCreateClient(values)
  }

  const onFinish = async () => {
    await refreshTable()
    onClose()
  }

  return (
    <MyCustomTab headersTab={headers} indexTab={indexTab}>
      <TabPanel key={0} value={indexTab} index={0}>
        <StepOneEditClient
          initialValues={clientData}
          handleSubmit={createUser}
          onClose={onClose}
        />
      </TabPanel>
      <TabPanel key={1} value={indexTab} index={1}>
        <StepTwoEditClient
          initialValues={clientData}
          handleSubmit={setupUser}
          previousStep={previousStep}
        />
      </TabPanel>
      <TabPanel key={2} value={indexTab} index={2}>
        <ConfirmationPage onFinish={onFinish} />
      </TabPanel>
    </MyCustomTab>
  )
}

const transformData = (data: Client | null) => {
  const vdrInstructions = data?.vdrInstructions.map(({ title, description, language }) => { return { title, body: description, language } })
  const paymentInstructions = data?.paymentInstructions.map(({ value, field, language }) => { return { title: field, body: value, language } })
  return {
    ...data,
    mainEmail: data?.users[0],
    secondEmail: data?.users?.[1] ?? '',
    threeEmail: data?.users?.[2] ?? '',

    currencyOptions: data?.configuration.currencyOptions,
    flow_periodicity: data?.configuration.flow_periodicity,
    manual_buyback: data?.configuration.manual_buyback,
    mandatory_vdr: data?.configuration.mandatory_vdr,
    onbHasAdditionalFields: data?.onbHasAdditionalFields,

    send_email_address: data?.configuration.send_email_address,
    receive_email_address: data?.configuration.receive_email_address,

    person_type_terminology: data?.configuration.person_type_terminology,
    timezone: data?.regional_config.timezone,
    usageConditions: data?.usageConditions,
    politicsFile: data?.politics,
    declarationFile: data?.declaration,
    typeformUrl: data?.typeformUrl,

    disclaimerTitle: data?.disclaimer.title,
    disclaimerDescription: data?.disclaimer.body,

    color1: data?.setupColors.color1,
    color2: data?.setupColors.color2,
    color3: data?.setupColors.color3,

    disclaimerLinks: ((data?.disclaimer?.links) != null) || [],
    vdrInstructions,
    paymentInstructions
  }
}
