import * as yup from 'yup'

export const createClientValidationSchema = yup.object().shape({
  clientName: yup.string().required('required_field'),
  mainUser: yup.string().required('required_field'),
  mainLastNameFather: yup.string().required('required_field'),
  mainLastNameMother: yup.string().required('required_field'),
  mainEmail: yup.string().email('email_format_not_valid').required('required_field'),

  secondUser: yup.string(),
  secondLastNameFather: yup.string(),
  secondLastNameMother: yup.string(),
  secondEmail: yup.string().email('email_format_not_valid'),

  threeUser: yup.string(),
  threeLastNameFather: yup.string(),
  threeLastNameMother: yup.string(),
  threeEmail: yup.string().email('email_format_not_valid'),
  logo: yup.string().required('required_field'),
  wallet: yup.string().required('required_field'),
  private_key: yup.string().required('required_field')
})
