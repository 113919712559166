export const GA_EVENTS = {
  investor_register: { action: 'Investor registry' },
  investor_send_register: { action: 'Investor send registry' },
  investor_back_to_login: { action: 'Investor back to login' },
  login: { action: 'Login' },
  forgot_password: { action: 'Forgot password' },
  request_recover_password: { action: 'Request recover password' },
  recover_password: { action: 'Recover password' },
  onb_start: { action: 'Onboarding - Start' },
  onb_step_one: { action: 'Onboarding - Step 1 of 4' },
  onb_step_two: { action: 'Onboarding - Step 2 of 4' },
  onb_step_three: { action: 'Onboarding - Step 3 of 4' },
  onb_step_four: { action: 'Onboarding - Step 4 of 4' },
  asset_details: { action: 'Click - Asset Details' },
  click_vdr_label: { action: 'Click - VDR' },
  click_vdr_watch_file: { action: 'Click - VDR watch file' },
  click_vdr_download_file: { action: 'Click - VDR download file' },
  click_buy_now: { action: 'Click - Buy Now' },
  click_cancel_purchase: { action: 'Click - Cancel Purchase' },
  click_send_purchase: { action: 'Click - Send Purchase' }
}
