import { TabsContainerProps } from './TabsContainer'
import styled from '@emotion/styled'
import { useState, useContext } from 'react'
import { ColorContext } from '../../contexts/ColorContext'
import { colorPalette } from '../../conf/ColorConfig'
import { scrollToTop } from '../../core/utils'

const Container = styled.div`
  display: flex;
  margin-left: 48px;
  margin-bottom: 50px;
  gap: 2rem;
`

const StyledTab = styled.div<{ color: string, isActive: boolean, notification?: number }>`
  color: ${({ color, isActive }) => isActive ? color : colorPalette.basics.HEX_ADB8CC};
  padding: 10px;
  border-bottom: ${({ isActive }) => isActive ? '2px' : '0'} solid ${({ color }) => color};
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ notification }) => notification && `
    ::after {
      content: '${notification}';
      margin-left: 12px;
      color: white;
      background-color: ${colorPalette.complementary.error};
      font-weight: 400;
      border-radius: 50%;
      font-size: 0.8rem;
      width: 25px;
      height: 25px;
      display: flex;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `}
`

export const DesktopTabs = ({ tabs }: TabsContainerProps) => {
  const [currentTab, setCurrentTab] = useState<number>(0)
  const { theme } = useContext(ColorContext)

  const onChangeTab = (index: number) => {
    scrollToTop()
    setCurrentTab(index)
  }
  return (
    <>
      <Container>
        { tabs.map(({ title, notification = 0 }, index) =>
          <StyledTab
            key={title}
            isActive={currentTab === index}
            color={theme.primary}
            onClick={() => onChangeTab(index)}
            notification={notification}
          >
            {title}
          </StyledTab>
        )}
      </Container>
      <div>{ tabs[currentTab].component }</div>
    </>
  )
}
