export interface UserInputProps {
  label?: string
  email?: string
  name: string
  fatherLastName: string
  motherLastName: string
}

export const UserInputES: UserInputProps = {
  fatherLastName: 'Apellido paterno',
  label: '',
  email: 'Correo electrónico',
  motherLastName: 'Apellido materno',
  name: 'Nombre(s)'
}

export const UserInputEN: UserInputProps = {
  fatherLastName: 'Paternal surname',
  label: '',
  email: 'Email',
  motherLastName: 'Mother\'s last name',
  name: 'Names(s)'
}
