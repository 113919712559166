import logo from '../../assets/logo-disclaimer.png'
import styled from '@emotion/styled'
import { Paragraph } from '../../components/Paragraph'

const DisclaimerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const QXContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 480px) {
      justify-content: center;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
  max-width: 165px;

  @media (max-width: 480px) {
      align-items: flex-start;
      max-width: 125px;
  }

  .qx-logo {
    display: flex;
    width: 100%; 
    flex-direction: column; 
    align-items: end; 
    margin-right: 3;

    @media (max-width: 480px) {
      align-items: center;
  }
  }
`
export const EquityQXLogo = () => {
  return (
    <DisclaimerContainer>
      <QXContainer>
        <Container>
          <div className='qx-logo'>
            <Paragraph type='caption'>
              Powered by
            </Paragraph>
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <img src={logo} alt='logo' height={22}/>
          </div>
        </Container>
      </QXContainer>
    </DisclaimerContainer>
  )
}
