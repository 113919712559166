import { Issuers } from './SidebarRolData/Issuers'
import { Investors } from './SidebarRolData/Investors'
import { Client } from './SidebarRolData/Client'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import { useContext } from 'react'
import { IdiomContext } from '../../contexts/IdiomContext'
import { SidebarTabsProps } from './SidebarTabs'
import { colorPalette } from '../../conf/ColorConfig'

export const MaterialIcon = {
  htmlColor: colorPalette.complementary.white,
  sx: { fontSize: '1.363rem' }
}

export const SidebarOptions = (): SidebarTabsProps[] => {
  const myUser = JSON.parse(localStorage.getItem('user') ?? '{}')
  const rol = myUser?.user?.group || ''
  const { commonText } = useContext(IdiomContext)
  const { customer } = commonText

  if (rol === 'admins') {
    return [
      {
        index: 1,
        navigateTo: `${rol}`,
        Icon: <GroupsOutlinedIcon {...MaterialIcon} />,
        title: customer
      }
    ]
  } else if (rol === 'clients') return Client()
  else if (rol === 'issuers') return Issuers()
  else return Investors()
}
