import { Grid, MenuItem, Select } from '@mui/material'

interface Props {
  setData: (values: any) => void
  value: any
  items: any[]
}

export const MySelectIdiom = ({ setData, value, items = [] }: Props) => {
  return (
        <Grid item sm={12}>
            <Select
                style={{ backgroundColor: '#FFF' }}
                size="small"
                variant='standard'
                fullWidth
                value={value}
                onChange={event => setData(event.target.value)}>
                {
                    items.map((e, index) =>
                        <MenuItem
                            key={index}
                            value={e.value}
                        >{e.label}</MenuItem>
                    )
                }
            </Select>
        </Grid>
  )
}
