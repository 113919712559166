import { Form, Formik, FormikValues } from 'formik'
import { createClientValidationSchema } from '../../../validations/createClientValidationSchema'
import { Accordion, AccordionSummary, Grid } from '@mui/material'
import { MyTextInput, MyUploadInput } from '../../../components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import { MyButton } from '../../../components/MyButton'
import { Paragraph } from '../../../components/Paragraph'

interface Props {
  initialValues: any
  handleSubmit: (values: any) => void
  onClose: (values: FormikValues) => void
}

export const CreateClientForm = ({ handleSubmit, onClose, initialValues }: Props) => {
  return (
    <Formik
      validationSchema={createClientValidationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <Form>
            <Paragraph><strong>Datos del cliente</strong></Paragraph>
            <Paragraph>
              Por favor, ingresa los datos necesarios para dar de alta a un nuevo cliente.
            </Paragraph>
            <Grid container spacing={2} mb={2} mt={1}>
              <Grid item sm={12} xs={12}>
                <MyTextInput
                  label='Nombre de cliente'
                  name='clientName'
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Paragraph>Datos del usuario principal</Paragraph>
              </Grid>
              <Grid item sm={4} xs={12}>
                <MyTextInput
                  label='Nombre(s)'
                  name='mainUser'
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <MyTextInput
                  label='Apellido paterno'
                  name='mainLastNameFather'
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <MyTextInput
                  label='Apellido materno'
                  name='mainLastNameMother'
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <MyTextInput
                  label='Correo electrónico'
                  name='mainEmail'
                  placeholder='usuario@ejemplo.com'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item sm={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Paragraph>Datos del usuario 2 (opcional)</Paragraph>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item sm={4} xs={12}>
                        <MyTextInput
                          label='Nombre(s)'
                          name='secondUser'
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <MyTextInput
                          label='Apellido paterno'
                          name='secondLastNameFather'
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <MyTextInput
                          label='Apellido materno'
                          name='secondLastNameMother'
                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <MyTextInput
                          label='Correo electrónico'
                          name='secondEmail'
                          placeholder='usuario@ejemplo.com'
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item sm={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Paragraph>Datos del usuario 3 (opcional)</Paragraph>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item sm={4} xs={12}>
                        <MyTextInput
                          label='Nombre(s)'
                          name='threeUser'
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <MyTextInput
                          label='Apellido paterno'
                          name='threeLastNameFather'
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <MyTextInput
                          label='Apellido materno'
                          name='threeLastNameMother'
                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        <MyTextInput
                          label='Correo electrónico'
                          name='threeEmail'
                          placeholder='usuario@ejemplo.com'
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={12}>
                <MyTextInput
                  name='wallet'
                  label='Dirección de la wallet del cliente'
                  placeholder='Ingresa la wallet del cliente'
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MyTextInput
                  name='private_key'
                  label='Llave privada de la wallet'
                  placeholder='0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFEBAAEDCE6AF48A03BBFD25E8CD0364140'
                />
              </Grid>
              <Grid item sm={4} md={4} xs={12}>
                <MyUploadInput
                  name='logo'
                  title='Sube el logotipo del cliente'
                  labelText='Arrastra tu archivo aquí o haz click para subirlo'
                  accept={['.jpg', '.png']}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <MyButton color='secondary' fullWidth onMouseUp={onClose}>
                  Cancelar
                </MyButton>
              </Grid>
              <Grid item sm={4}>
                <MyButton fullWidth type='submit'>
                  Siguiente
                </MyButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
