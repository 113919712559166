import * as yup from 'yup'

export const digitalizationValidationSchema = yup.object().shape({
  confirmValues: yup.bool().oneOf([true], 'must_check_to_continue'),
  bidAmount: yup.number().typeError('').min(0.001, 'bigger_than_zero').max(999999999999999, 'big_number').required('required_field'),
  token_quantity: yup.number().typeError('').positive().integer('integer').max(999999999999999, 'big_number').required('required_field'),
  minimun_token: yup.number().typeError('').integer('integer').positive('positive').max(yup.ref('maximum_token'), 'min_tokens').required('required_field'),
  maximum_token: yup.number().typeError('').integer('integer').positive().max(yup.ref('token_quantity'), 'max_tokens').required('required_field'),
  title_return: yup.string(),
  value_return: yup.string(),
  token_price: yup.number().typeError('').integer('integer'),
  external_url: yup.string().url('url')
})

export const digitalizationValidationSchemaEquity = yup.object().shape({
  confirmValues: yup.bool().oneOf([true], 'must_check_to_continue'),
  bidAmount: yup.number().typeError('').min(0.001, 'bigger_than_zero').max(999999999999999, 'big_number').required('required_field'),
  share_offer: yup.number().typeError('').positive('positive').integer('integer').max(yup.ref('token_quantity'), 'max_tokens').required('required_field'),
  token_quantity: yup.number().typeError('').positive('bigger_than_zero').integer('integer').max(999999999999999, 'big_number').required('required_field'),
  minimun_token: yup.number().typeError('').integer('integer').positive('bigger_than_zero').max(yup.ref('maximum_token'), 'min_than_max').required('required_field'),
  maximum_token: yup.number().typeError('').integer('integer').positive('bigger_than_zero').max(yup.ref('share_offer'), 'min_than_share_offer').required('required_field'),
  title_return: yup.string(),
  value_return: yup.string(),
  token_price: yup.number().typeError('').min(0.001, 'bigger_than_zero'),
  external_url: yup.string().url('url'),
  tax_ruling: yup.string().required('required_field'),
  price: yup.number().typeError('').min(0.001, 'bigger_than_zero').max(999999999999999, 'big_number').required('required_field')
})
