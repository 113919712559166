import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import { useTranslation } from 'react-i18next'

export const useAssetCreationHeaders = () => {
  const { t } = useTranslation()
  const headers = [
    {
      title: t('company_user.create_asset.title_one'),
      subtitle: t('company_user.create_asset.step_one'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {

      title: t('company_user.create_asset.title_two'),
      subtitle: t('company_user.create_asset.step_two'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {

      title: t('company_user.create_asset.title_three'),
      subtitle: t('company_user.create_asset.step_three'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {

      title: t('company_user.create_asset.title_four'),
      subtitle: t('company_user.create_asset.step_four'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {

      title: t('company_user.create_asset.title_five'),
      subtitle: t('company_user.create_asset.step_five'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {

      title: t('company_user.create_asset.title_six'),
      subtitle: t('company_user.create_asset.step_six'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    }
  ]

  return {
    headers
  }
}
