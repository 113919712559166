import { UseFetch } from './UseFetch'

export const getClients = async (): Promise<any> => {
  return await UseFetch('get', 'endpoint', '/clients', {})
}

export const createClient = async (
  dataClient: any,
  dataSetupClient: any,
  paymentInstructions: string,
  vdrInstructions: any
): Promise<any> => {
  const users = [
    {
      email: dataClient.mainEmail,
      first_names: dataClient.mainUser,
      fathers_name: dataClient.mainLastNameFather,
      mothers_name: dataClient.mainLastNameMother
    }
  ]

  if (dataClient.secondUser !== '') {
    users.push({
      email: dataClient.secondEmail,
      first_names: dataClient.secondUser,
      fathers_name: dataClient.secondLastNameFather,
      mothers_name: dataClient.secondLastNameMother
    })
  }

  if (dataClient.threeUser !== '') {
    users.push({
      email: dataClient.threeEmail,
      first_names: dataClient.threeUser,
      fathers_name: dataClient.threeLastNameFather,
      mothers_name: dataClient.threeLastNameMother
    })
  }

  const { color1, color2, color3 } = dataSetupClient
  const id = String(dataClient.clientName)
    .toLocaleLowerCase()
    .replace(/\s+/g, '')
  const client = {
    id,
    name: dataClient.clientName,
    users,
    configuration: {
      sendEmailAddress: dataSetupClient.send_email_address,
      receiveEmailAddress: dataSetupClient.receive_email_address,
      regionalConf: {
        timezone: dataSetupClient.timezone,
        defaultLanguage: dataSetupClient.person_type_terminology
      },
      mandatoryVdr: dataSetupClient.mandatory_vdr,
      personTypeTerminology: dataSetupClient.person_type_terminology,
      onbHasAdditionalFields: dataSetupClient.onbHasAdditionalFields,
      assetsType: dataSetupClient.assetsType,
      setupColors: { color1, color2, color3 },
      typeformUrl: dataSetupClient.typeformUrl,
      currencyOptions: dataSetupClient.currencyOptions.toUpperCase().replaceAll(' ', '').split(','),
      confidentiality: dataSetupClient.confidentiality
    },
    files: {
      logo: { filename: dataClient.logo.name },
      usageConditions: { filename: dataSetupClient.usageConditions.name },
      policies: { filename: dataSetupClient.politicsFile.name },
      declaration: { filename: dataSetupClient.declarationFile.name }
    },
    texts: {
      disclaimer: {
        title: dataSetupClient.disclaimerTitle,
        body: dataSetupClient.disclaimerDescription,
        links: dataSetupClient.disclaimer
      },
      paymentInstructions,
      vdrInstructions,
      valuationText: '',
      assetCharacteristics: [''],
      transmissionRefinement: [''],
      purchaseDisclaimer: ''
    },
    wallet: {
      privateKey: dataClient.private_key,
      address: dataClient.wallet
    }
  }

  const init = {
    body: {
      client
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('post', 'endpoint', '/client-rollouts', init)
}

export const getClientById = async (id: any): Promise<any> => {
  return await UseFetch('get', 'endpoint', `/clients/${id}`, {})
}

export const getBasicClientById = async (id: string): Promise<any> => {
  return await UseFetch('get', 'endpoint', `/client-basics/${id}`, {}, false)
}
