import { memo, useState } from 'react'
import { IconButton, Grid } from '@mui/material'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import { colorPalette } from '../../conf/ColorConfig'
import { MyTextInput } from '../MyTextInput'
import { Paragraph } from '../Paragraph'
import { MyFlexContainer } from '../common/MyFlexContainer'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'

// IMPORTANT: You must wrap this component with a formik form
const USERS_INDEXES = ['second', 'three']

interface Props {
  setValues: (values: any) => void
}

export const AddUserMain = ({ setValues }: Props) => {
  const [indexLength, setIndexLength] = useState(-1)

  const onAddIndex = () => setIndexLength(prev => prev + 1)
  const onDeleteIndex = () => {
    setIndexLength(prev => prev - 1)
    setValues((v: any) => ({
      ...v,
      [`${USERS_INDEXES[indexLength]}User`]: '',
      [`${USERS_INDEXES[indexLength]}LastNameFather`]: '',
      [`${USERS_INDEXES[indexLength]}LastNameMother`]: '',
      [`${USERS_INDEXES[indexLength]}Email`]: ''
    }))
  }

  return (
    <>
      <Grid container spacing={2} mb={2}>
        { [0, 1].map(index =>
          index <= indexLength &&
            <UserFields
              onDelete={onDeleteIndex}
              index={index}
              key={USERS_INDEXES[index]}
            />
        )}
      </Grid>
      <IconButton
        onClick={onAddIndex}
        disabled={indexLength === 1}
      >
        <AddCircleOutlinedIcon
          fontSize='large'
          htmlColor={colorPalette.secondary.HEX_90D2E9}
        />
      </IconButton>
    </>
  )
}

interface UserFieldProps {
  index: number
  onDelete: () => void
}

const UserFields = memo(({ index, onDelete }: UserFieldProps) => {
  const [t] = useTranslation()
  return (
    <>
      <Grid item sm={12} xs={12} md={12} mt={2}>
        <MyFlexContainer style={{ alignItems: 'center' }}>
          <Paragraph type='subheading'><strong>{t('create_company_form.page_two.user_data')} {index + 2}</strong></Paragraph>
          <IconButton onClick={onDelete}>
            <DeleteIcon fontSize='small' color='error' />
          </IconButton>
        </MyFlexContainer>
      </Grid>
      <Grid item md={9} sm={12} xs={12}>
          <MyTextInput
              label={t('name')}
              name={`${USERS_INDEXES[index]}User`}
          />
      </Grid>
      <Grid item md={4.5} sm={6} xs={12}>
          <MyTextInput
              label={t('last_father_name')}
              name={`${USERS_INDEXES[index]}LastNameFather`}
          />
      </Grid>
      <Grid item md={4.5} sm={6} xs={12}>
          <MyTextInput
              label={t('last_mother_name')}
              name={`${USERS_INDEXES[index]}LastNameMother`}
          />
      </Grid>
      <Grid item md={9} sm={12} xs={12}>
          <MyTextInput
              label={t('email')}
              name={`${USERS_INDEXES[index]}Email`}
          />
      </Grid>
    </>
  )
})
