import NotificationContext from '../../../../contexts/NotificationContext'
import LoadingContext from '../../../../contexts/LoadingContext'
import { MyCustomTab, TabPanel } from '../../../../components'
import ClientContext from '../../../../contexts/ClientContext'
import { createIssuer } from '../../../../core/apiIssuers'
import { useContext, useState } from 'react'
import { LayoutForm } from '../../../../layout/LayoutForm'
import { FormCompanyData } from './FormCompanyData'
import { FormCompanyUsers } from './FormCompanyUsers'
import { FormConfirmationPage } from './FormConfirmationPage'
import { useTranslation } from 'react-i18next'

const initialValues = {
  company_name: '',
  mainUser: '',
  mainLastNameFather: '',
  mainLastNameMother: '',
  mainEmail: '',
  secondUser: '',
  secondLastNameFather: '',
  secondLastNameMother: '',
  secondEmail: '',
  threeUser: '',
  threeLastNameFather: '',
  threeLastNameMother: '',
  threeEmail: '',
  nameRepresentOne: '',
  lastFatherRepresentOne: '',
  lastMotherRepresentOne: '',
  nameRepresentTwo: '',
  lastFatherRepresentTwo: '',
  lastMotherRepresentTwo: ''
}

interface Props {
  isOpen: boolean
  onClose: () => void
  refreshTable: () => Promise<any>
}

export const CreateCompanyDialog = ({ isOpen, onClose, refreshTable }: Props) => {
  const [initialCompanyData, setInitialCompanyData] = useState(initialValues)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const [indexTab, setIndexTab] = useState<number>(0)
  const { clientId } = useContext(ClientContext)
  const [t] = useTranslation()

  const headers = [
    { title: t('create_company_form.page_one.tab_title'), subtitle: t('create_company_form.page_one.step') },
    { title: t('create_company_form.page_two.tab_title'), subtitle: t('create_company_form.page_two.step') },
    { title: t('create_company_form.page_three.tab_title'), subtitle: t('create_company_form.page_three.step') }
  ]

  const nextStep = () => setIndexTab(v => v + 1)
  const previousStep = () => setIndexTab(v => v - 1)

  const onSubmitStepOne = (values: any) => {
    setInitialCompanyData(values)
    nextStep()
  }

  const onSubmitStepTwo = async (values: any) => {
    setInitialCompanyData(values)
    await handleCreateIssuer(values)
  }

  const onCloseDialog = () => {
    setInitialCompanyData(initialValues)
    setIndexTab(0)
    onClose()
  }

  const onFinalCloseDialog = async () => {
    showToast('success', t('toasts.company_creation_success'))
    await refreshTable()
    onCloseDialog()
  }

  const handleCreateIssuer = async (values: any) => {
    try {
      showLoading()
      const resp = await createIssuer(values, 2, clientId())
      hideLoading()
      if (resp?.success) {
        nextStep()
      } else if (resp?.errors?.[0]?.code) {
        showToast('error', t(resp?.errors?.[0]?.code))
      } else {
        showToast('error', t('toasts.user_already_exists'))
      }
    } catch (error) {
      console.log(error)
      showToast('error', t('toasts.company_creation_error'))
    }
  }

  return (
    <>
      <LayoutForm open={isOpen} onClose={onCloseDialog}>
        <MyCustomTab headersTab={headers} indexTab={indexTab}>
          <TabPanel value={indexTab} index={0}>
            <FormCompanyData
              values={initialCompanyData}
              previousStep={onCloseDialog}
              onSubmit={onSubmitStepOne}
            />
          </TabPanel>
          <TabPanel value={indexTab} index={1}>
            <FormCompanyUsers
              values={initialCompanyData}
              previousStep={previousStep}
              onClose={onCloseDialog}
              onSubmit={onSubmitStepTwo}
            />
          </TabPanel>
          <TabPanel value={indexTab} index={2}>
            <FormConfirmationPage onFinish={onFinalCloseDialog}/>
          </TabPanel>
        </MyCustomTab>
      </LayoutForm>
    </>
  )
}
