import { Box } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  width?: string
}

export function TabPanel (props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
        style={{ height: 'inherit' }}
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        key={index}
        {...other}
    >
        {value === index && (
            <Box sx={{ p: 3, minHeight: 471 }} >
                {children}
            </Box>
        )}
  </div>
  )
}
