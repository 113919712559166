import { Dialog } from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyTransition } from '../../../../../components/MyTransition'
import ClientContext from '../../../../../contexts/ClientContext'
import LoadingContext from '../../../../../contexts/LoadingContext'
import NotificationContext from '../../../../../contexts/NotificationContext'
import UserContext from '../../../../../contexts/UserContext'
import { postTransactions } from '../../../../../core/apiTransaction'
import { sendEvent } from '../../../../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../../../../core/GA/GoogleAnalyticsEvents'
import { useClientConfig } from '../../../../../hooks/useClientConfig'
import { useResponsive } from '../../../../../hooks/useResponsive'
import { BuyAssetValues, BuyForm } from './BuyForm'
import { BuyInstructions } from './BuyInstructions'
import { assetsTypeByClient } from '../../../../../types/types'

interface Props {
  isOpen: boolean
  onClose: () => void
  asset: any
  goDashboard: () => void
  refreshTokens: () => Promise<void>
  canAccess?: boolean
}

export default function BuyDialog ({ isOpen, onClose, asset, refreshTokens, goDashboard, canAccess = true }: Props) {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [openInstructions, setOpenInstructions] = useState(false)
  const { myUser, investor_id } = useContext(UserContext)
  const { assetsType } = useContext(ClientContext)
  const { showToast } = useContext(NotificationContext)
  const { t } = useTranslation()
  const { isMobileDevice } = useResponsive()
  const { assetTypeLabel } = useClientConfig()

  const handleBuy = async ({ monto, tokens }: BuyAssetValues) => {
    sendEvent(GA_EVENTS.click_send_purchase)
    showLoading()
    const { clientId, issuer_id } = asset
    const resp = await postTransactions({
      clientId,
      asset_id: asset.id,
      investor_id,
      investor: myUser,
      issuer_id,
      amount: Number(monto),
      type: 'BUY',
      asset,
      tokens: Number(tokens),
      authorized: assetsType !== assetsTypeByClient.EQUITIES ? 'approved' : 'pending'
    })
    if (resp.success) {
      // Give some time for queue to process
      setTimeout(() => {
        onClose()
        showToast('success', t('toasts.request_payment_success'))
        setOpenInstructions(true)
        hideLoading()
      }, 2000)
    } else {
      showToast('error', resp.message)
      hideLoading()
    }
  }

  const handleCloseBuyInstructions = async () => {
    goDashboard()
    setOpenInstructions(false)
    await refreshTokens()
  }

  return (
    <>
      <BuyInstructions
        title={t('investor_user.buy.instruction_buy') ?? ''}
        content={t('investor_user.buy.instruction_text', { asset: assetTypeLabel('plural', 'lowercase') }) ?? ''}
        isOpen={openInstructions}
        onClose={handleCloseBuyInstructions}
      />
      <Dialog
        onClose={onClose}
        open={isOpen}
        TransitionComponent={MyTransition}
        PaperProps={{
          style: {
            padding: isMobileDevice ? '14px 16px' : '32px 48px',
            maxWidth: asset?.assetType === 'EQUITY' ? 850 : 450
          }
        }}
      >
        <BuyForm data={asset} onClose={onClose} onSubmit={handleBuy} isConfidentiality={canAccess} />
      </Dialog>
    </>
  )
}
