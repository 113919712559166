import * as yup from 'yup'

export const normalInvestorValidationSchema = yup.object().shape({
  first_names: yup.string().required('required_field'),
  fathers_name: yup.string().required('required_field'),
  mothers_name: yup.string().required('required_field'),
  country: yup.string().required('required_field'),
  email: yup.string().email('email_format_not_valid').required('required_field'),
  phone: yup.string().required('required_field')
})
