import { useContext, useEffect, useState } from 'react'
import { MyTextAction } from '../../../../../components/MyTextLink'
import { AssetsContext } from '../../../../../contexts/AssetsContext'
import { ColorContext } from '../../../../../contexts/ColorContext'
import LoadingContext from '../../../../../contexts/LoadingContext'
import NotificationContext from '../../../../../contexts/NotificationContext'
import { changeStatusAsset } from '../../../../../core/apiAssets'
import { formatCurrency, formatNumber } from '../../../../../core/utils'
import { AuthorizeAssetDialog } from './AuthorizeAssetDialog'
import { MyTable } from '../../../../../components'
import { useClientConfig } from '../../../../../hooks/useClientConfig'
import { useTranslation } from 'react-i18next'

interface ITAXES {
  [prop: string]: string
}

export const RequestAssetTable = () => {
  const { filterData, loadAssetsAndFilter } = useContext(AssetsContext)
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { assetTypeLabel, formOfVerb } = useClientConfig()
  const { showToast } = useContext(NotificationContext)
  const [actualData, setActualData] = useState<any>()
  const [rows, setRows] = useState<any[]>([])
  const { theme } = useContext(ColorContext)
  const [t, i18n] = useTranslation()
  const { primary } = theme

  const openDialog = (data: any) => {
    setActualData(data)
    setIsOpenDialog(true)
  }

  const closeDialog = () => {
    setIsOpenDialog(false)
  }

  const TAXES: ITAXES = {
    1: t('tax_rule.yes'),
    2: t('tax_rule.no'),
    3: t('tax_rule.not_defined')
  }

  useEffect(() => {
    const onlyCompletedSteps = filterData.pendientes.filter((asset: any) => !!asset?.completedSteps)
    const organizeData = Array.from(onlyCompletedSteps,
      (asset: any) => {
        const { name, token_quantity, bidAmount, price, id, status, token_price } = asset
        const asset_currency = asset?.currency
        const taxRuling: keyof ITAXES = asset?.tax_ruling
        const currentTax = TAXES[taxRuling] ?? 'N/A'

        return {
          options: <MyTextAction color={primary} onClick={() => openDialog(asset)}>{t('review')}</MyTextAction>,
          token_price: formatCurrency(token_price, asset_currency, true),
          token_quantity: formatNumber(token_quantity),
          price: formatCurrency(price, asset_currency, true),
          bidAmount: formatCurrency(bidAmount, asset_currency, true),
          asset_currency,
          currentTax,
          status,
          name,
          id
        }
      })
    setRows(organizeData)
  }, [filterData.pendientes, i18n.language])

  const handleChangeAssetStatus = async (asset: any, status: string) => {
    showLoading()
    const resp = await changeStatusAsset(asset, status)
    if (resp?.success) {
      const toastMessage = i18n.language === 'es'
        ? `${assetTypeLabel('singular', 'capitalize')} ${status === 'ACCEPTED' ? 'aceptad' : 'rechazad'}${formOfVerb()} con éxito`
        : `${assetTypeLabel('singular', 'capitalize')} ${status === 'ACCEPTED' ? 'accepted' : 'rejected'} successfully`
      showToast('success', toastMessage)
    } else showToast('error', 'Error')
    await loadAssetsAndFilter()
    hideLoading()
  }

  const headerData = [
    {
      header: assetTypeLabel('singular', 'uppercase'),
      key: 'name'
    },
    {
      header: t('currency'),
      key: 'asset_currency'
    },
    {
      header: t('valuation'),
      key: 'price'
    },
    {
      header: t('bid_amount'),
      key: 'bidAmount'
    },
    {
      header: t('quantity'),
      key: 'token_quantity'
    },
    {
      header: t('token_price'),
      key: 'token_price'
    },
    {
      header: t('tax'),
      key: 'currentTax'
    },
    {
      header: t('options'),
      key: 'options'
    }
  ]

  return (
        <>
            <MyTable
                rowData={rows}
                headerData={headerData}
            />
            <AuthorizeAssetDialog
                onSubmit={handleChangeAssetStatus}
                onClose={closeDialog}
                isOpen={isOpenDialog}
                data={actualData}
            />
        </>
  )
}
