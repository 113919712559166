import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { Paragraph } from './Paragraph'
import { styled } from '@mui/material/styles'
import { colorPalette } from '../conf/ColorConfig'
import { useResponsive } from '../hooks/useResponsive'

interface HeaderTab {
  title: string
  subtitle?: string
  iconUnsolved?: any
  iconSolve?: any
  hidden?: boolean
}

interface Props {
  headersTab: HeaderTab[]
  indexTab: number
  children: any
}

export function MyCustomTab ({ headersTab, indexTab, children }: Props) {
  const { isMobileDevice } = useResponsive()
  const getConditionalIcon = (index: number) => {
    if (indexTab === index) {
      return <RotateRightIcon />
    }
    if (indexTab >= index) {
      return <CheckCircleIcon fontSize='small' color='success'/>
    }
    return <RadioButtonUncheckedIcon fontSize='small' htmlColor={colorPalette.basics.HEX_ADB8CC}/>
  }
  return (
    <>
      <StyledTabs value={indexTab} >
        {headersTab.map((e: HeaderTab, index: number) =>
          <Tab
            key={e.title}
            style={(isMobileDevice && index === indexTab) || !isMobileDevice ? {} : TabStyle}
            iconPosition='start'
            disabled={indexTab !== index}
            icon={getConditionalIcon(index)}
            label={
              <Container>
                <Paragraph><strong>{e.title}</strong></Paragraph>
                <Paragraph type='small'>{e.subtitle}</Paragraph>
              </Container>
            }
          />
        )}
      </StyledTabs>
      {children}
    </>
  )
}

const Container = styled('div')`
  text-transform: initial;
  text-align: left;
`

const TabStyle: React.CSSProperties = {
  display: 'none'
}

const StyledTabs = styled(Tabs)(() => ({
  '@mediaQuery(max-width: 480px)': {
    '& .MuiTabs-flexContainer .css-heg063-MuiTabs-flexContainer': {
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  }
}))
