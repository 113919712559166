import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ClientContext from '../../contexts/ClientContext'
import LoadingContext from '../../contexts/LoadingContext'
import NotificationContext from '../../contexts/NotificationContext'
import { registerInvestor, registerInvestorByPreRegister } from '../../core/apiInvestors'
import { getPreRegistersByEmail } from '../../core/apiPreRegisters'
import { sendEvent } from '../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../core/GA/GoogleAnalyticsEvents'
import { useLoadClient } from '../../hooks/useLoadClient'
import { InitialLayout } from '../../layout/InitialLayout'
import { RegisterAccountForm } from './RegisterAccountForm'

export const RegisterAccountParams = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { clientId } = useContext(ClientContext)
  const { showComponent } = useLoadClient()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCreateAccount = async ({ username }: any) => {
    sendEvent(GA_EVENTS.investor_send_register)
    showLoading()
    const existPreRegister = await getPreRegistersByEmail(clientId(), username)
    let resp
    if (existPreRegister?.records?.length) { resp = await registerInvestorByPreRegister(existPreRegister?.records[0]) } else { resp = await registerInvestor(username, clientId()) }
    if (resp?.success) navigate('/ConfirmationAccount', { state: { username } })
    else showToast('error', t(resp[0].code))
    hideLoading()
  }
  return (
    <> { showComponent
      ? <InitialLayout>
            <RegisterAccountForm onSubmit={handleCreateAccount}/>
      </InitialLayout>
      : <></>
    } </>
  )
}
