import { Client } from '../../../types/types'
import { DialogCreateClient } from './DialogCreateClient'
import { EditClientForm } from './Edit/EditClientForm'

interface Props {
  onClose: () => void
  refreshTable: () => Promise<any>
  data: Client | null
  mode: 'edit' | 'create'
}

export const ClientFormMain = ({ ...props }: Props) => {
  return (
    <>
      { props.mode === 'create'
        ? <DialogCreateClient {...props}/>
        : <EditClientForm {...props} />
      }
    </>
  )
}
