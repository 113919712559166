import styled from '@emotion/styled'
import { useContext } from 'react'
import { colorPalette } from '../../conf/ColorConfig'
import { ColorContext } from '../../contexts/ColorContext'
import { Paragraph } from '../Paragraph'
import { TableProps } from './MyTable'

const TableContainer = styled.div`
  section:nth-of-type(n):not(:first-of-type){
    margin-top: 32px;
  }
`
const RowContainer = styled.section`
  border-radius: 16px;
  border: 1px solid ${colorPalette.basics.HEX_E6EAF2};
  background-color: ${colorPalette.basics.HEX_F2F7FF};
`

const RowLabelContainer = styled.div`
  display: flex;
`
const RowLabelLeft = styled.div`
  width: 50%;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  p {
    text-transform: uppercase;
    color: ${colorPalette.basics.HEX_738099}
  }
`
const RowLabelRight = styled.div<{ color: string }>`
  width: 50%;
  padding-top: 16px;
  padding-bottom: 16px;
  line-height: 2rem;
  font-size: 1rem;
  color: ${({ color }) => color};
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  word-break: break-all;
`

function getLabel (obj: any) {
  if (typeof obj === 'object' && obj?.join) {
    return obj.join(', ')
  }
  return obj
}

export const MobileTable = ({ rowData, headerData }: TableProps) => {
  const { theme } = useContext(ColorContext)
  return (
    <TableContainer >
      {
        rowData.map((e, index) =>
          <RowContainer key={index}>
            { headerData.map(({ header, key }) =>
              <RowLabelContainer key={key}>
                <RowLabelLeft>
                  <Paragraph>
                    <strong>
                      { header }:
                    </strong>
                  </Paragraph>
                </RowLabelLeft>
                <RowLabelRight color={theme.secondary}>
                  <strong>
                    {getLabel(e[key])}
                  </strong>
                </RowLabelRight>
              </RowLabelContainer>
            )}
          </RowContainer>
        )}
    </TableContainer>
  )
}
