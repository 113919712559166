import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyTable } from '../../../../components'
import { FilterTableHOC } from '../../../../components/Tables/FilterTableHOC'
import { AssetsContext } from '../../../../contexts/AssetsContext'
import ClientContext from '../../../../contexts/ClientContext'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { timezoneFormatWithHour } from '../../../../core/timezoneFormat'
import { formatCurrency, formatNumber } from '../../../../core/utils'
import { useClientConfig } from '../../../../hooks/useClientConfig'

export const AvailableAssets = () => {
  const { timezone } = useContext(ClientContext)
  const { filterData } = useContext(AssetsContext)
  const { assetTypeLabel } = useClientConfig()
  const [rows, setRows] = useState<any[]>([])
  const { tokens_actions_price, available_tokens_actions } = useContext(IdiomContext)

  const [t] = useTranslation()

  useEffect(() => {
    setRows(
      Array.from(filterData.disponibles, (asset: any) => {
        const { token_quantity, price, bidAmount, statusPublishedDate, token_price } = asset
        const currency = asset?.currency
        const tokensAvailable = asset?.contract?.tokensAvailable ?? token_quantity
        return {
          ...asset,
          currency,
          bidAmount: formatCurrency(bidAmount, currency, true),
          token_price: formatCurrency(token_price, currency, true),
          price_format: formatCurrency(price, currency, true),
          progress: `${formatNumber(tokensAvailable)} / ${formatNumber(token_quantity)}`,
          options: timezoneFormatWithHour(timezone, statusPublishedDate)
        }
      }))
  }, [filterData.disponibles, timezone])

  const headers = [
    {
      header: assetTypeLabel('singular', 'uppercase'),
      key: 'name'
    },
    {
      header: available_tokens_actions,
      key: 'progress'
    },
    {
      header: tokens_actions_price,
      key: 'token_price'
    },
    {
      header: t('currency'),
      key: 'currency'
    },
    {
      header: t('valuation'),
      key: 'price_format'
    },
    {
      header: t('bid_amount'),
      key: 'bidAmount'
    },
    {
      header: t('availability_date'),
      key: 'options'
    }
  ]

  return (
    <FilterTableHOC
      labelToFilter='options'
      labelToFilterType='date'
      rows={rows}
    >
    {({ dataFilter, key }) =>
      <MyTable key={key} rowData={dataFilter} headerData={headers} />
    }
    </FilterTableHOC>
  )
}
