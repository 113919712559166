import styled from '@emotion/styled'
import { colorPalette } from '../../conf/ColorConfig'
import { ColorContext } from '../../contexts/ColorContext'
import { Paragraph } from '../Paragraph'
import { useContext, Fragment } from 'react'
import { formatCurrency, formatNumber } from '../../core/utils'
import { IdiomContext } from '../../contexts/IdiomContext'
import { MyButton } from '../MyButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import es from 'dayjs/locale/es'
import { useCheckboxes } from '../../hooks/useCheckboxes'

dayjs.locale(es)

interface Props {
  onBuy?: () => void
  assetData: any
  status: string
  canViewConfidentialLabels: boolean
}

interface IATTRIBUTES {
  left: {
    label: any
    value: any
  }
  right: {
    label: any
    value: any
  }
}

export const BusinessAssetData = ({ assetData, onBuy, status, canViewConfidentialLabels }: Props) => {
  const { theme } = useContext(ColorContext)
  const { available_tokens_actions, tokens_actions_price_by } = useContext(IdiomContext)
  const currency = assetData?.currency
  const { t } = useTranslation()
  const tokensAvailable = assetData?.contract?.tokensAvailable ?? 0
  const {
    VALUATION_SOURCE,
    INDEPENDENT_VALUATOR_METHOD,
    LATEST_INVESTMENT_ROUND
  } = useCheckboxes()
  const isEquity = assetData?.assetType === 'EQUITY'
  const hasValuationSource = assetData?.valuation_source?.filter((el: any) => el !== '').length > 0
  const hasIndependentValuator = assetData?.independent_valuator?.filter((el: any) => el !== '').length > 0

  const MyTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.secondary
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.secondary,
      textAlign: 'center',
      maxWidth: 200,
      padding: 5
    }
  }))
  console.log(assetData?.additional_data?.[0])
  let ATTRIBUTES: IATTRIBUTES[] = [
    {
      left: { label: t(assetData?.additional_data?.[0]?.title), value: t(assetData?.additional_data?.[0]?.description) },
      right: {
        label: t('valuation'),
        value:
        <>
        { canViewConfidentialLabels
          ? formatCurrency(assetData?.price, assetData?.currency)
          : <>
            {t('authorization_required')}
            <MyTooltip
              placement='top'
              title={t('valuation_restriction') ?? ''}
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: 15 }} style={{ marginLeft: 6, marginBottom: 5 }} />
            </MyTooltip>
          </>
        }
        </>
      }
    },
    {
      left: { label: tokens_actions_price_by, value: formatCurrency(assetData?.token_price, assetData?.currency) },
      right: { label: available_tokens_actions, value: formatNumber(tokensAvailable) }
    },
    {
      left: { label: t('bid_amount'), value: formatCurrency(assetData?.bidAmount, currency) },
      right: { label: assetData?.additional_data?.[1]?.title, value: assetData?.additional_data?.[1]?.description }
    }
  ]

  if (isEquity) {
    ATTRIBUTES = [
      {
        left: { label: t(assetData?.additional_data?.[0]?.title), value: t(assetData?.additional_data?.[0]?.description) },
        right: {
          label:
          <>
          { !canViewConfidentialLabels
            ? t('valuation')
            : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              { t('valuation') }
              { hasValuationSource || hasIndependentValuator
                ? <MyTooltip
                  placement='top'
                  title={
                    <div>
                      <div>{ t('valuation_date') }</div>
                      <div>{ dayjs(assetData?.valuation_date).format('LL')}</div>
                    </div>
                  }
                  arrow
                >
                  <InfoOutlinedIcon sx={{ fontSize: 15 }} style={{ marginLeft: 6, marginBottom: 5 }} />
                </MyTooltip>
                : null
              }
            </div>
          }
          </>,
          value:
          <>
          { canViewConfidentialLabels
            ? formatCurrency(assetData?.price, assetData?.currency)
            : <>
              {t('authorization_required')}
              <MyTooltip
                placement='top'
                title={t('valuation_authorization_disclaimer') ?? ''}
                arrow
              >
                <InfoOutlinedIcon sx={{ fontSize: 15 }} style={{ marginLeft: 6, marginBottom: 5 }} />
              </MyTooltip>
            </>
          }
          </>
        }
      },
      {
        left: {
          label: t('valuation_source'),
          value:
          <>
          { !canViewConfidentialLabels
            ? <>
              {t('authorization_required')}
              <MyTooltip
                placement='top'
                title={t('valuation_source_authorization_disclaimer') ?? ''}
                arrow
              >
                <InfoOutlinedIcon sx={{ fontSize: 15 }} style={{ marginLeft: 6, marginBottom: 5 }} />
              </MyTooltip>
            </>
            : hasValuationSource
              ? <div>
              { assetData?.valuation_source?.map((source: any, index: number) => {
                return (
                  <>{
                    source?.length > 0
                      ? <ValuationSourceContainer key={index}>
                        <Paragraph type='caption' style={{ color: theme.secondary }} >
                          { VALUATION_SOURCE[index] }
                        </Paragraph>
                        <MyTooltip
                          placement='top'
                          title={ index === 0
                            ? assetData?.independent_valuator_method?.map((method: any, index: number) => {
                              if (method?.length > 0) {
                                return (
                                  <div key={index} style={{ textAlign: 'left' }}>
                                    • { index === INDEPENDENT_VALUATOR_METHOD.length - 1
                                    ? method
                                    : INDEPENDENT_VALUATOR_METHOD[index]
                                    }
                                    <br />
                                  </div>
                                )
                              }
                              return <Fragment key={index} />
                            })
                            : assetData?.latest_investment_round?.map((method: any, index: number) => {
                              if (method?.length > 0) {
                                return (
                                  <div key={index} style={{ textAlign: 'left' }}>
                                    • { index === LATEST_INVESTMENT_ROUND.length - 1
                                    ? method
                                    : LATEST_INVESTMENT_ROUND[index]
                                    }
                                    <br />
                                  </div>
                                )
                              }
                              return <Fragment key={index} />
                            })
                          }
                          arrow
                        >
                          <InfoOutlinedIcon sx={{ fontSize: 15 }} style={{ marginLeft: 6, marginBottom: 5 }} />
                        </MyTooltip>
                    </ValuationSourceContainer>
                      : <Fragment key={index} />
                  }
                  </>
                )
              })
              }
              </div>
              : 'N/A'
          }
          </>
        },
        right: {
          label: t('independent_valuator'),
          value:
          <>
          { !canViewConfidentialLabels
            ? <>
              {t('authorization_required')}
              <MyTooltip
                placement='top'
                title={t('independent_valuator_authorization_disclaimer') ?? ''}
                arrow
              >
                <InfoOutlinedIcon sx={{ fontSize: 15 }} style={{ marginLeft: 6, marginBottom: 5 }} />
              </MyTooltip>
            </>
            : hasIndependentValuator
              ? <>
            { assetData?.independent_valuator?.map((source: any, index: number) =>
                <Paragraph type='caption' style={{ color: theme.secondary }} key={index}>
                  {source}
                </Paragraph>
            )}
            </>
              : 'N/A'
          }
          </>
        }
      },
      {
        left: {
          label:
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            { tokens_actions_price_by }
            <MyTooltip
              placement='top'
              title={t('reference_price') ?? ''}
              arrow
            >
              <InfoOutlinedIcon sx={{ fontSize: 15 }} style={{ marginLeft: 6, marginBottom: 5 }} />
            </MyTooltip>
          </div>,
          value: formatCurrency(assetData?.token_price, assetData?.currency)
        },
        right: { label: available_tokens_actions, value: formatNumber(tokensAvailable) }
      },
      ATTRIBUTES[2]
    ]
  }

  return (
    <Rail>
      <Container>
        { ATTRIBUTES.map(({ left, right }, index) =>
          <Row key={index}>
            <GroupContainer hasTopBorder={index === 0}>
              <TextContainer hasBorder>
                <Paragraph bold style={{ textTransform: 'uppercase' }}>{left.label}</Paragraph>
                <Paragraph bold style={{ color: theme.secondary }}>{left.value}</Paragraph>
              </TextContainer>
              <TextContainer hasBorder={false}>
                <Paragraph bold style={{ textTransform: 'uppercase' }}>{right.label}</Paragraph>
                <Paragraph bold style={{ color: theme.secondary }}>{right.value}</Paragraph>
              </TextContainer>
            </GroupContainer>
          </Row>
        )}
      <MyButton
        fullWidth
        onClick={onBuy}
        disabled={status === 'Próximamente' || status === 'Agotado' || tokensAvailable === 0}
      >{t('buy')}</MyButton>
      </Container>
    </Rail>
  )
}

const Rail = styled.div`
  padding-top: 20px;
  padding-left: 40px;
  width: 50%;
  position: relative;
  z-index: 3;

  @media (max-width: 480px) {
    width: 100% ;
  }
`
const Container = styled.div`
  position: sticky;
  top: 15px;

  @media (max-width: 480px) {
    top: 60px;
    position: relative;
  }
`
const Row = styled.div`
  text-align: center;
  width: 100%;
  display: inline-block;
  
  &:first-of-type section  {
    border-radius: 8px 8px 0 0;
  } 
  &:last-of-type section  {
    border-radius: 0 0 8px 8px;
    margin-bottom: 10px;
  } 
`
const GroupContainer = styled.section<{ hasTopBorder: boolean }>`
  display: flex;
  background-color: ${colorPalette.basics.HEX_F2F7FF};
  border: 1px solid ${colorPalette.basics.HEX_CFD7E6};
  ${({ hasTopBorder }) => hasTopBorder
    ? `border-top: 1px solid ${colorPalette.basics.HEX_CFD7E6};`
    : `border-top: 0px solid ${colorPalette.basics.HEX_CFD7E6};`
  }
  align-items: center;
`
const TextContainer = styled.div<{ hasBorder: boolean }>`
  width: 50%;
  justify-content: center;
  word-break: break-all;
  padding: 10px 20px;
  border-right: ${({ hasBorder }) => hasBorder ? '1px' : '0'} solid ${colorPalette.basics.HEX_CFD7E6};
`
const ValuationSourceContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`
