import { UseFetch } from './UseFetch'

export const getVDR = async (id: any): Promise<any> => {
  return await UseFetch('get', 'endpoint', `/vdrs/${id.split('?asset_id=').join('/')}`, {})
}

export const getVDRByFolder = async (clientId: string, issuer_id: string, asset_id: string, folder: string): Promise<any> => {
  const path = `/vdrs/${clientId}/${issuer_id}/${asset_id}/${folder}`
  return await UseFetch('get', 'endpoint', path, {})
}

export const getSignedUrl = async (id: any, file_name: any): Promise<any> => {
  console.log(id, file_name)
  const init = {
    headers: { 'Content-Type': 'text/plain' }
  }

  return await UseFetch(
    'get',
    'endpoint',
    `/vdrs/${id}/signedUrl?file=${file_name}`,
    init
  )
}

export const postVDR = async (
  id: any,
  folder: any,
  description: any,
  file: any
): Promise<any> => {
  const init = {
    body: {
      folder,
      file,
      description,
      confidentiality: true
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('post', 'endpoint', '/vdrs/' + id, init)
}

export const postVDRWithPosition = async (
  id: any,
  folder: any,
  description: any,
  file: any,
  position: number
): Promise<any> => {
  const init = {
    body: {
      folder,
      file,
      description,
      confidentiality: true,
      position
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('post', 'endpoint', '/vdrs/' + id, init)
}

export const putVDR = async (
  id: any,
  newFile: any,
  oldFile: any
): Promise<any> => {
  console.log(newFile, oldFile)
  const init = {
    body: { old: oldFile, new: newFile },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch(
    'put',
    'endpoint',
    `/vdrs/${id}`,
    init
  )
}

export const deleteVDR = async (
  id: string,
  file: any,
  folder: any
): Promise<any> => {
  const init = {
    headers: { 'Content-Type': 'text/plain' }
  }
  let parserId = ''
  const splitId = id.split('?asset_id=').join('_').split('/').join('_').split('_')
  if (splitId.length === 3) parserId = splitId[0] + '/' + splitId[1] + '_' + splitId[2]
  else parserId = splitId[0] + '/' + splitId[1]
  console.log({ parserId, splitId, id })
  return await UseFetch(
    'delete',
    'endpoint',
    `/vdrs/${parserId}?file=${file}&folder=${folder}`,
    init
  )
}
