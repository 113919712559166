import styled from '@emotion/styled'
import { useContext } from 'react'
import { DDMultiFilesContext } from './DDMultiFiles'

interface Props {
  file: File | string
}

export const FilePreview = ({ file }: Props) => {
  const { acceptedTypes } = useContext(DDMultiFilesContext)
  if (typeof file === 'string' && acceptedTypes === 'image/png, image/jpeg, image/jpg') return <Img src={file} alt='file' />
  if (typeof file === 'string' && acceptedTypes === 'video/*') return <Video src={file} controls/>
  if (typeof file !== 'string' && acceptedTypes === 'video/*') return <Video src={URL.createObjectURL(file)} controls/>
  if (typeof file !== 'string' && acceptedTypes === 'image/png, image/jpeg, image/jpg') return <Img src={URL.createObjectURL(file)} alt='' />
  return null
}

const Img = styled.img`
  border-radius: 20px;
  object-fit: cover;
  height: 100%;
  width: 100%;
`
const Video = styled.video`
  border-radius: 20px;
  object-fit: cover;
  height: 100%;
  width: 100%;
`
