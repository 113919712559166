import { Grid } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { fullWidth } from '../../../layout/LayoutConfig'
import { MainVDR } from '../../../components/VDR/MainVDR'
import { useContext } from 'react'
import ClientContext from '../../../contexts/ClientContext'
import { Paragraph } from '../../../components/Paragraph'
import { LayoutForm } from '../../../layout/LayoutForm'
import { MyButton } from '../../../components/MyButton'
import { useTranslation } from 'react-i18next'

export const ReviewVDR = () => {
  const { client } = useContext(ClientContext)
  const { state }: any = useLocation()
  const navigate = useNavigate()
  const [t] = useTranslation()

  const onClose = () => navigate('/Dashboard/clients')

  return (
    <LayoutForm onClose={onClose} open={true}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Paragraph bold type='subheading'>{t('docs')}</Paragraph>
        </Grid>
        <Grid item sm={12}>
          <Paragraph>{t('company_docs')}</Paragraph>
        </Grid>
        <Grid item {...fullWidth}>
          <MainVDR
            issuer_id={state}
            showHeaders={false}
            vdr_id={`${client?.client?.id}/${state}`}
            type='Empresa'
            canLoad={false}
            canEdit={false}
            canDelete={false}
          />
        </Grid>
        <Grid item sm={12}>
          <MyButton color='secondary' onClick={onClose}>
            {t('close')}
          </MyButton>
        </Grid>
      </Grid>
    </LayoutForm>
  )
}
