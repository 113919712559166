import styled from '@emotion/styled'
import { Paragraph } from '../../components/Paragraph'

export const DrawerHeader = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 65px;
  padding-bottom: 40px;
  gap: 16px;
  padding-left: ${({ isOpen }) => isOpen ? '16px' : '12px'};

  @media (max-height: 780px) {
    padding-top: 35px;
    padding-bottom: 30px;
  }
`
export const LogoContainer = styled.img<{ isOpen: boolean }>`
  max-width: 222px;
  display: ${(props) => props.isOpen ? 'block' : 'none'};
`
export const UserRefContainer = styled.div<{ isOpen: boolean }>`
  padding-left: ${({ isOpen }) => isOpen ? '16px' : '13px'};
  padding-bottom: ${({ isOpen }) => isOpen ? '40px' : '80px'};

  @media (max-height: 700px) {
    padding-bottom: 25px;
  }
`
export const SidebarFooter = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    bottom: 20px;
    width: 100%;

    @media (max-width: 480px) {
        bottom: 0;
    }

    @media (max-height: 700px) {
      position: static;
    }
`

const MyWrapper = styled.div<{ $notPointer: boolean }>`
    display: flex;
    padding-left: 1.5rem;
    gap: 1rem;
    cursor: ${props => props.$notPointer ? 'not-allowed' : 'pointer'};
    color: white;
    align-items: center;

    &:nth-last-of-type(-n + 1){
        padding-bottom: 1rem;
    }
`
export const ButtonBottom = ({ title, onClick, Icon, notPointer, isOpen }: any) => (
    <MyWrapper onClick={!notPointer ? onClick : () => { }} $notPointer={notPointer}>
        {Icon}
        { isOpen &&
          <Paragraph colorType='white' type='small'>
              {title}
          </Paragraph>
        }
    </MyWrapper>
)
