import { Backdrop } from '@mui/material'
import { DotSpinner } from '@uiball/loaders'

import { createContext, useState, useContext } from 'react'
import { ColorContext } from './ColorContext'

interface Props {
  LoadingScreen: () => JSX.Element
  showLoading: () => void
  hideLoading: () => void
  isLoading: boolean
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const LoadingContext = createContext({} as Props)

const LoadingProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { theme } = useContext(ColorContext)
  const { primary } = theme

  const showLoading = () => { setIsOpen(true) }
  const hideLoading = () => { setIsOpen(false) }

  const LoadingScreen = () => (
        <Backdrop
            sx={{ color: '#fff', zIndex: 350001 }}
            open={isOpen}
        >
            <DotSpinner
                size={80}
                speed={0.9}
                color={primary}
            />
        </Backdrop>
  )

  const data: Props = { LoadingScreen, showLoading, hideLoading, isLoading: isOpen }

  return (
        <LoadingContext.Provider value={data}>
            {children}
        </LoadingContext.Provider>
  )
}

export { LoadingProvider }
export default LoadingContext
