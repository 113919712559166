import styled from '@emotion/styled'
import { useContext } from 'react'
import ClientContext from '../contexts/ClientContext'
import QXLogo from '../assets/logo-primary.png'
import { Form } from 'formik'
import { useNavigate } from 'react-router-dom'
import bg from '../assets/background-primary.png'

interface Props {
  children: JSX.Element
}

export const InitialLayout = ({ children }: Props) => {
  const { client, clientId } = useContext(ClientContext)
  const navigate = useNavigate()
  const logo = client?.logo || client?.client?.logo
  return (
    <Container>
      <LeftScreen>
        <LogoAndDisclaimerContainer>
          <Logo src={logo} alt='' onClick={() => navigate('/' + clientId(), { replace: true })} />
          <Disclaimer>
            <DisclaimerText>Powered by</DisclaimerText>
            <DisclaimerLogo src={QXLogo} />
          </Disclaimer>
        </LogoAndDisclaimerContainer>
      </LeftScreen>
      <RightScreen>
      { children }
      </RightScreen>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const LeftScreen = styled.div`
  background-size: cover;
  background-image: url(${bg});
  position: absolute;
  top: 0;
  right: 50%;
  left: 0;
  bottom: 0;
  background-color: white;
  
  @media only screen and (max-width: 600px) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 80px;
  }
`
const LogoAndDisclaimerContainer = styled.div`
  top: calc(50% - 40px);
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    top: 24px;
  }
`
const Logo = styled.img`
  max-height: 120px;
  width: auto;
  cursor: pointer;
  
  @media only screen and (max-width: 600px) {
    height: 32px;
    width: auto;
  }
`
const Disclaimer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 48px;
  align-items: center;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

const DisclaimerLogo = styled.img`
  height: 22px;
  width: auto;
`
const DisclaimerText = styled.p`
  font-size: 12px;
  color: white;
  line-height: 1;
`

const RightScreen = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  left: 50%;
  bottom: 0;
  background-color:#fff;
  text-align:center;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    position: absolute;
    top: 80px;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
  }
`
export const LoginFormLayout = styled(Form)`
  width: 60%;
  @media only screen and (max-width: 600px) {
    width: max-content;
    margin: 25px;
  }
`
export const LoginContentLayout = styled.div`
  width: 60%;
  @media only screen and (max-width: 600px) {
    width: max-content;
    margin: 25px;
  }
`
