import { createContext } from 'react'
import { useAssets } from '../hooks/useAssets'

export const AssetsContext = createContext<any>({})

export const AssetsProvider = ({ children }: any) => {
  const assetsHook = useAssets()
  return (
    <AssetsContext.Provider value={assetsHook}>
      { children }
    </AssetsContext.Provider>
  )
}
