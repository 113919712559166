import { sessionChangePasswordValidationSchema } from '../../validations/sessionChangePassword'
import NotificationContext from '../../contexts/NotificationContext'
import { useLocation } from 'react-router-dom'
import LoadingContext from '../../contexts/LoadingContext'
import { InitialLayout, LoginFormLayout } from '../../layout/InitialLayout'
import { createPassword } from '../../core/apiAuth'
import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { useContext } from 'react'
import ClientContext from '../../contexts/ClientContext'
import { MyButton } from '../../components/MyButton'
import { MyPasswordToggleButton } from '../../components/MyPasswordToggleButton'
import { useLogin } from '../../hooks/useLogin'
import { Paragraph } from '../../components/Paragraph'
import { LoginTitle } from '../../components/Title'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

export const SessionChangePassword = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { clientId } = useContext(ClientContext)
  const { state }: any = useLocation()
  const { t } = useTranslation()
  const { logIn } = useLogin()

  const onSubmit = async ({ newPassword }: any) => {
    showLoading()
    try {
      const resp = await createPassword(state, newPassword, clientId())
      if (!resp?.error) {
        showToast('success', t('toasts.create_password_success'))
        await logIn(state.email, newPassword)
      } else {
        showToast('error', 'Error')
      }
    } catch (error) {
      console.log(error)
      showToast('error', 'Error')
    }
    hideLoading()
  }

  return (
    <InitialLayout >
      <Formik
          validationSchema={sessionChangePasswordValidationSchema}
          initialValues={{ newPassword: '', confirmateNewPassword: '' }}
          onSubmit={onSubmit}
      >
          {() => {
            return (
                  <LoginFormLayout>
                      <Grid container spacing={4} justifyContent='center'>
                          <Grid item xs={12} md={12} sm={12}>
                              <LoginTitle>{t('login_page.create_password.title')}</LoginTitle>
                          </Grid>
                          <Grid item xs={12} md={12} sm={12}>
                              <PasswordRulesContainer>
                              <Paragraph type='small'>{t('login_page.create_password.password_rules')}</Paragraph>
                              <ul style={{ listStyle: 'disc', marginLeft: 30 }}>
                                  <li><Paragraph type='small'>{t('password_rules.rule_one')}</Paragraph></li>
                                  <li><Paragraph type='small'>{t('password_rules.rule_two')}</Paragraph></li>
                                  <li><Paragraph type='small'>{t('password_rules.rule_three')}</Paragraph></li>
                                  <li><Paragraph type='small'>{t('password_rules.rule_four')}</Paragraph></li>
                              </ul>
                              </PasswordRulesContainer>
                          </Grid>
                          <Grid item xs={9} md={9} sm={9}>
                              <MyPasswordToggleButton
                                label={t('password')}
                                name='newPassword'
                              />
                          </Grid>
                          <Grid item xs={9} md={9} sm={9}>
                              <MyPasswordToggleButton
                                label={t('new_password')}
                                name='confirmateNewPassword'
                              />
                          </Grid>
                          <Grid item xs={9} md={9} sm={9}>
                              <MyButton fullWidth type='submit'>{t('login_page.create_password.button')}</MyButton>
                          </Grid>
                      </Grid>
                  </LoginFormLayout>
            )
          }}
      </Formik>
    </InitialLayout>
  )
}

const PasswordRulesContainer = styled.div`
  text-align: left;
`
