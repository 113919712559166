import { useContext, useEffect } from 'react'
import LoadingContext from '../contexts/LoadingContext'
import NotificationContext from '../contexts/NotificationContext'
import { BrowserRouter } from 'react-router-dom'
import { RoutesMaintenance } from './RoutesMaintenance'
import { RoutesOXT } from './RoutesOXT'
import { ChatBot } from '../components/ChatBot'
import ClientContext from '../contexts/ClientContext'
import { useMaintenance } from '../hooks/useMaintenance'
import { LanguagePicker } from '../components/LanguagePicker'
import { isBivaPage } from '../core/utils'

export const AppRouter = () => {
  const { MyToast } = useContext(NotificationContext)
  const { LoadingScreen } = useContext(LoadingContext)
  const { client } = useContext(ClientContext)
  const { isLoading, isMaintenance } = useMaintenance()

  useEffect(() => {
    const client_id = client?.id || client?.client?.id
    if (client_id === 'qxmarkets') ChatBot()
    client_id && isBivaPage(client_id === 'biva')
    // eslint-disable-next-line
  }, [client])

  if (isLoading) return (<></>)

  return (
    <>
      <LanguagePicker />
      {LoadingScreen()}
      {MyToast()}
      <BrowserRouter>
        { isMaintenance
          ? <RoutesMaintenance />
          : <RoutesOXT />
        }
      </BrowserRouter>
    </>

  )
}
