import { Grid, Skeleton } from '@mui/material'
import { BackButton } from './BackButton'
import { MyButton } from '../../../components/MyButton'
import { CreateUserModal } from './CreateUserModal'
import { useDialog } from '../../../hooks/useDialog'
import { User } from '../../../types/types'
import { TableMasterUsers } from './TableMasterUsers'
import { useTranslation } from 'react-i18next'

interface Props {
  isLoading: boolean
  users: User[]
  updateData: (users: any[], userToChange: string, action: 'create' | 'delete') => Promise<void>
}

export const UserMaster = ({ isLoading, users, updateData }: Props) => {
  const { isOpen, openDialog, closeDialog } = useDialog()
  const [t] = useTranslation()
  return (
    <>
    <CreateUserModal isOpen={isOpen} onClose={closeDialog} updateData={updateData} users={users} />
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <BackButton />
      </Grid>
      <Grid item xs={12} sm={12} textAlign='right'>
        { !isLoading && users.length !== 3 && <MyButton onClick={openDialog}>{t('add')}</MyButton> }
      </Grid>
      <Grid item xs={12} sm={12}>
        { isLoading
          ? <div style={{ height: 300, width: '100%' }}>
            <Skeleton width='100%' height='100%' variant='rectangular' />
          </div>
          : <TableMasterUsers users={users} updateData={updateData} />
        }
      </Grid>
    </Grid>
    </>
  )
}
