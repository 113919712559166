import { FastField } from 'formik'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  label: string
  name: string
  placeholder?: string
  [propName: string]: any
}
export const MyTextArea = ({ ...props }: Props) => {
  const { t, i18n: { language } } = useTranslation()

  return (
        <FastField name={props.name} key={`select-${props.name}-${language}`}>
        {(({ field, meta }: any) =>
            <TextField
                id={props.name}
                error={!!meta.error && meta.touched}
                helperText={!!meta.error && meta.touched ? t(`forms.${meta?.error || 'required_field'}`) : ''}
                fullWidth
                multiline
                rows={5}
                {...field}
                {...props}
            />
        )}
        </FastField>
  )
}
