import { TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { ErrorMessage, useField } from 'formik'

interface Props {
  label: string
  name: string
  [propName: string]: any
}

export const DatePicker = ({ ...props }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props)
  const handleChange = (newValue: Dayjs | null) => {
    helpers.setValue(newValue)
  }

  return (
    <>
      <DesktopDatePicker
        inputFormat="DD-MMMM-YYYY"
        {...field}
        {...props}
        value={field?.value || null}
        maxDate={dayjs()}
        disableMaskedInput
        disabled={props.disabled}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
      <ErrorMessage {...props} />
    </>
  )
}
