import * as yup from 'yup'
import { REGEX_PASSWORD } from '../core/utils'

export const recoverPasswordValidationSchema = yup.object().shape({
  confirmationCode: yup.string().required('required_field'),
  password: yup
    .string()
    .matches(REGEX_PASSWORD, 'passwordRule')
    .required('required_field'),
  confirmateNewPassword:
      yup.string()
        .oneOf([yup.ref('password')], 'passwordMustBeEqual')
        .required('required_field')
})
