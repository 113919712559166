import { digitalizationValidationSchema, digitalizationValidationSchemaEquity } from '../../../../validations/digitalizationValidationSchema'
import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { MyButtonIcon, MyCheckboxInput, MyTextInput } from '../../../../components'
import { MyButton } from '../../../../components/MyButton'
import { MyNumberFormat, MyNumberInput } from '../../../../components/MyNumberFormat'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { AssetFormContext } from './DialogAssetForm'
import { Paragraph } from '../../../../components/Paragraph'
import { useScrollToTop } from '../../../../hooks/useScrollToTop'
import { useTranslation } from 'react-i18next'
import { MyRadioGroup } from '../../../../components/MyRadioGroup'
import { ASSET_TYPE } from '../../../../types/types'

interface Props {
  onClose: () => void
  data: any
  onSubmit: (values: any) => void
  previousStep: () => void
}

export const AssetDigitalization = ({ onClose, data, onSubmit, previousStep }: Props) => {
  const { assetType, assetCurrency } = useContext(AssetFormContext)
  const { tokens_actions_lowercase, token_action_lowercase, total_tokens_actions, tokens_actions_price } = useContext(IdiomContext)
  const { t, i18n: { language } } = useTranslation()
  useScrollToTop()

  const sizes = assetType === ASSET_TYPE.EQUITY ? { xs: 12, sm: 8, md: 2.6666 } : { xs: 6, sm: 4, md: 4 }
  const minimumTokenLabel = language === 'es'
    ? `Mínimo de ${tokens_actions_lowercase} adquiribles`
    : `Minimum number of ${tokens_actions_lowercase} to be acquired`
  const maximumTokenLabel = language === 'es'
    ? `Máximo de ${tokens_actions_lowercase} adquiribles`
    : `Maximum number of ${tokens_actions_lowercase} to be acquired`
  const urlLabel = language === 'es'
    ? `URL externa de${assetType === ASSET_TYPE.EQUITY ? ' la empresa' : 'l activo'} (opcional)`
    : `External URL of${assetType === ASSET_TYPE.EQUITY ? ' the company' : ' the asset'} (optional)`
  const confirmLabel = language === 'es'
    ? `Confirmo que los valores considerados para: "Valuación", "Monto a ofertar", "Cantidad de ${tokens_actions_lowercase}" y "Precio de ${token_action_lowercase}" fueron ingresados de forma correcta`
    : `I confirm that the values considered for "${t('bid_amount')}", "${total_tokens_actions}" and "${tokens_actions_price}" were entered correctly`
  const tokenRef = language === 'es'
    ? `Precio de referencia por ${token_action_lowercase}`
    : `Reference price per ${token_action_lowercase}`
  const valuationLabel = language === 'es'
    ? `Valuación${assetType === ASSET_TYPE.EQUITY ? ' (capital)' : ''}`
    : `Valuation${assetType === ASSET_TYPE.EQUITY ? ' (equity value)' : ''}`
  const totalSharesLabel = language === 'es'
    ? `Total de ${tokens_actions_lowercase}`
    : `Total ${tokens_actions_lowercase}`
  return (
        <Formik
            initialValues={data}
            onSubmit={onSubmit}
            validationSchema={assetType !== ASSET_TYPE.EQUITY ? digitalizationValidationSchema : digitalizationValidationSchemaEquity}
        >
            {({ values }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item sm={12} mb={2}>
                            <MyButtonIcon
                                size='small'
                                icon='backArrow'
                                onClick={previousStep}
                                text={t('back')}
                                type='secondary'
                            />
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}>
                            <Paragraph type='subheading'><strong>{t('company_user.create_asset.title_three')}</strong></Paragraph>
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}>
                            <Paragraph>{t('company_user.create_asset.subtitle_two')}</Paragraph>
                        </Grid>
                        { assetType === ASSET_TYPE.EQUITY
                          ? <Grid item sm={8} md={8} xs={12}>
                                <MyNumberFormat
                                    currency={assetCurrency}
                                    name="price"
                                    label={valuationLabel}
                                />
                            </Grid>
                          : <Grid item sm={8} md={8} xs={12}>
                                <MyNumberFormat
                                    currency={assetCurrency}
                                    name="bidAmount"
                                    label={`${t('bid_amount')} (${assetCurrency})`}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} />
                        <Grid item {...sizes} >
                            <MyNumberInput
                                name='token_quantity'
                                currency={assetCurrency}
                                label={totalSharesLabel}
                            />
                        </Grid>
                        <Grid item {...sizes}>
                            <MyNumberFormat
                                currency={assetCurrency}
                                key={values.token_price}
                                value={assetType !== ASSET_TYPE.EQUITY ? values.bidAmount / values.token_quantity : values.price / values.token_quantity}
                                name='token_price'
                                label={tokenRef}
                                disabled
                            />
                        </Grid>
                        { assetType === ASSET_TYPE.EQUITY
                          ? <>
                            <Grid item {...sizes}>
                                <MyNumberInput
                                    currency={assetCurrency}
                                    name='share_offer'
                                    label={t('offer_shares')}
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item sm={8} md={8} xs={12}>
                                <MyNumberFormat
                                    currency={assetCurrency}
                                    name="bidAmount"
                                    key={values.bidAmount}
                                    value={values.share_offer * values.token_price}
                                    label={t('offered_amount') + ` (${assetCurrency})`}
                                    disabled
                                />
                            </Grid>
                          </>
                          : null
                        }
                        <Grid item xs={12} />
                        <Grid item sm={4} md={4} xs={6}>
                            <MyNumberInput
                                currency={assetCurrency}
                                name='minimun_token'
                                label={minimumTokenLabel}
                            />
                        </Grid>
                        <Grid item sm={4} md={4} xs={6}>
                            <MyNumberInput
                                currency={assetCurrency}
                                name='maximum_token'
                                label={maximumTokenLabel}
                            />
                        </Grid>
                        { assetType === ASSET_TYPE.EQUITY
                          ? <>
                            <Grid item sm={12} xs={12} md={12} mt={1} mb={-2}>
                                <Paragraph bold>{ t('tax') }</Paragraph>
                            </Grid>
                            <Grid item sm={12} xs={12} md={12} mt={1} mb={-2}>
                                <Paragraph>{t('tax_labels.description')}</Paragraph>
                            </Grid>
                            <Grid item sm={12} xs={12} md={12}>
                                <MyRadioGroup
                                    name='tax_ruling'
                                    items={[
                                      { value: '1', label: t('tax_labels.La transacción será sujeta a dictamen fiscal') },
                                      { value: '2', label: t('tax_labels.La transacción no será sujeta a dictamen fiscal') },
                                      { value: '3', label: t('tax_labels.No definido') }
                                    ]}
                                />
                                </Grid>
                            </>
                          : <Grid item sm={12} xs={12} md={12} />
                        }
                        <Grid item sm={8} xs={12} md={8}>
                            <MyTextInput
                                label={urlLabel}
                                name='external_url'
                            />
                        </Grid>
                        <Grid item sm={8} xs={12} md={8}>
                            <MyCheckboxInput name='confirmValues' label={confirmLabel}/>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item >
                            <MyButton color='secondary' onMouseUp={onClose}>{t('cancel')}</MyButton>
                        </Grid>
                        <Grid item >
                            <MyButton type='submit'>{t('next')}</MyButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
  )
}
