import { useTranslation } from 'react-i18next'

export const useCheckboxes = () => {
  const { t } = useTranslation()
  const ACTIONS_FEATURES = [
    t('actions_features.Ordinarias'),
    t('actions_features.Con dividendo preferente'),
    t('actions_features.Voto limitado'),
    t('actions_features.Sin dividendo preferente'),
    t('actions_features.Sin voto'),
    t('actions_features.Capital fijo'),
    t('actions_features.Con valor nominal'),
    t('actions_features.Capital variable'),
    t('actions_features.Sin valor nominal'),
    t('actions_features.Otros')
  ]

  const ENHANCEMENT_TRANSMISSION = [
    t('enhancement_transmission.Entrega acciones debidamente endosadas en propiedad y anotación en el registro o libro corporativo de accionistas, a favor de adquiriente'),
    t('enhancement_transmission.Entrega de constancias provisionales y anotación en el registro o libro corporativo de accionistas, a favor del adquiriente'),
    t('enhancement_transmission.Anotación en el registro o libro corporativo de accionistas, a favor del adquiriente'),
    t('enhancement_transmission.Cesión de derechos de fideicomisos sobre fideicomisos de acciones'),
    t('enhancement_transmission.Cesión de derechos de opciones sobre acciones (planes de opciones para consejeros, directivos y empleados)'),
    t('enhancement_transmission.Otra acordada entre las partes (especificar)')
  ]

  const VALUATION_SOURCE = [
    t('valuation_source_checkboxes.Valuador independiente'),
    t('valuation_source_checkboxes.Última ronda de inversión')
  ]

  const INDEPENDENT_VALUATOR_METHOD = [
    t('independent_valuator_checkboxes.Enfoque de costos'),
    t('independent_valuator_checkboxes.Flujos de caja libre descontados de la empresa'),
    t('independent_valuator_checkboxes.Flujos de caja libre descontados del capital'),
    t('independent_valuator_checkboxes.Múltiplos'),
    t('independent_valuator_checkboxes.Valor razonable de mercado'),
    t('independent_valuator_checkboxes.Valuación por CAP SAFE/Nota convertible'),
    t('independent_valuator_checkboxes.Otro')
  ]

  const LATEST_INVESTMENT_ROUND = [
    t('latest_investment_round.Última ronda de inversión institucional en los últimos 12 meses (post money)'),
    t('latest_investment_round.Última ronda de inversión mayor a 12 meses (post money)'),
    t('latest_investment_round.Otra')
  ]

  const INDEPENDENT_VALUATOR = [
    t('independent_valuator_options.414 Capital'),
    t('independent_valuator_options.Bain & Company'),
    t('independent_valuator_options.Booz Allen Hamilton'),
    t('independent_valuator_options.Deloitte'),
    t('independent_valuator_options.KPMG'),
    t('independent_valuator_options.Kroll'),
    t('independent_valuator_options.McKinsey & Company'),
    t('independent_valuator_options.PricewaterhouseCoopers'),
    t('independent_valuator_options.Quantit'),
    t('independent_valuator_options.Otro')
  ]

  return {
    ACTIONS_FEATURES,
    ENHANCEMENT_TRANSMISSION,
    VALUATION_SOURCE,
    INDEPENDENT_VALUATOR_METHOD,
    LATEST_INVESTMENT_ROUND,
    INDEPENDENT_VALUATOR
  }
}
