import * as yup from 'yup'
import { REGEX_PASSWORD } from '../core/utils'

export const sessionChangePasswordValidationSchema = yup.object().shape({
  newPassword:
      yup.string()
        .matches(REGEX_PASSWORD, 'passwordRule')
        .required('required_field'),
  confirmateNewPassword:
      yup.string()
        .oneOf([yup.ref('newPassword')], 'passwordMustBeEqual')
        .required('required_field')
})
