import { useState } from 'react'

interface DialogState {
  isOpen: boolean
  data: any | null
}

const initialState: DialogState = {
  isOpen: false,
  data: null
}

export interface DialogProps {
  isOpen: boolean
  data?: any
  onClose: () => void
}

export const useDialog = () => {
  const [{ isOpen, data }, setDialogState] = useState<DialogState>(initialState)

  const openDialog = (data: any = null) => {
    setDialogState({ isOpen: true, data })
  }

  const closeDialog = () => {
    setDialogState(initialState)
  }

  return {
    openDialog,
    closeDialog,
    isOpen,
    data
  }
}
