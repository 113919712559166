import { useContext, useLayoutEffect, useState } from 'react'
import ClientContext from '../../../../contexts/ClientContext'
import LoadingContext from '../../../../contexts/LoadingContext'
import NotificationContext from '../../../../contexts/NotificationContext'
import { changeStatusAsset } from '../../../../core/apiAssets'
import { formatCurrency, formatNumber } from '../../../../core/utils'
import { timezoneFormatWithHour } from '../../../../core/timezoneFormat'
import { MyTextAction } from '../../../../components/MyTextLink'
import { MyRenderTable } from '../../../../components/Tables/MyRenderTable'
import { AssetsContext } from '../../../../contexts/AssetsContext'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { ColorContext } from '../../../../contexts/ColorContext'
import { useClientConfig } from '../../../../hooks/useClientConfig'
import { useTranslation } from 'react-i18next'

export const ApprovedAssets = () => {
  const { loadAssetsByIssuerAndFilter, filterData } = useContext(AssetsContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { timezone } = useContext(ClientContext)
  const { showToast } = useContext(NotificationContext)
  const [rows, setRows] = useState<any[]>([])
  const { tokens_actions_price, available_tokens_actions } = useContext(IdiomContext)
  const { theme } = useContext(ColorContext)
  const { assetTypeLabel, formOfVerb } = useClientConfig()
  const { primary } = theme
  const { t, i18n: { language } } = useTranslation()

  const handleDisponibilizar = async (asset: any) => {
    showLoading()
    const resp = await changeStatusAsset(asset, 'PUBLISHED')
    if (resp?.success) {
      const message = language === 'es'
        ? assetTypeLabel('singular', 'capitalize') + ' disponibilizad' + formOfVerb() + ' con éxito'
        : assetTypeLabel('singular', 'capitalize') + ' successfully made available'
      showToast('success', message)
    } else showToast('error', 'Error')
    await loadAssetsByIssuerAndFilter()
    hideLoading()
  }

  useLayoutEffect(() => {
    setRows(
      Array.from(filterData.aprobados, (asset: any) => {
        const { name, token_quantity, price, bidAmount, id, status, statusPublishedDate, token_price } = asset
        const currency = asset?.currency
        return {
          token_price: formatCurrency(token_price, currency, true),
          price_format: formatCurrency(price, currency, true),
          status,
          bidAmount: formatCurrency(bidAmount, currency, true),
          progress: `${formatNumber(token_quantity)} / ${formatNumber(token_quantity)}`,
          currency,
          name,
          id,
          options:
            status === 'PUBLISHED'
              ? timezoneFormatWithHour(timezone, statusPublishedDate)
              : <MyTextAction
                    color={primary}
                    onClick={async () => await handleDisponibilizar(asset)}
                >
                    {t('make_available')}
                </MyTextAction>
        }
      }))
    // eslint-disable-next-line
    }, [filterData.aprobados, language])

  const headers = [
    {
      header: assetTypeLabel('singular', 'uppercase'),
      key: 'name'
    },
    {
      header: available_tokens_actions,
      key: 'progress'
    },
    {
      header: tokens_actions_price,
      key: 'token_price'
    },
    {
      header: t('currency'),
      key: 'currency'
    },
    {
      header: t('valuation'),
      key: 'price_format'
    },
    {
      header: t('bid_amount'),
      key: 'bidAmount'
    },
    {
      header: t('options'),
      key: 'options'
    }
  ]

  return (
        <MyRenderTable rowData={rows} headerData={headers} />
  )
}
