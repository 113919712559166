import { useContext, useEffect, useState } from 'react'
import { TransactionsContext } from '../../../../../contexts/TransactionsContext'
import { Transaction } from '../../../../../types/types'
import { ApprovedPurchasedTable } from './ApprovedPurchasedTable'
import { AssetBuyValidation } from './AssetBuyValidation'
import { Grid } from '@mui/material'
import { Title } from '../../../../../components/Title'
import { TabsContainer } from '../../../../../components/DashboardTabs/TabsContainer'
import { useScrollToTop } from '../../../../../hooks/useScrollToTop'
import { useTranslation } from 'react-i18next'

export const ClientsPayments = () => {
  const [approvedTransactions, setApprovedTransactions] = useState<Transaction[]>([])
  const [pendingTransactions, setPendingTransactions] = useState<Transaction[]>([])
  const { transactions } = useContext(TransactionsContext)
  const [t] = useTranslation()
  useScrollToTop()

  useEffect(() => {
    setApprovedTransactions(transactions.filter((el: Transaction) => el.pay_confirmed === 'approved'))
    setPendingTransactions(transactions.filter((el: Transaction) => el.pay_confirmed === 'pending'))
  }, [transactions])

  const Tabs = [
    { title: t('payments_validation_tables.approve'), component: <ApprovedPurchasedTable approvedTransactions={approvedTransactions} /> },
    {
      title: t('payments_validation_tables.request'),
      component: <AssetBuyValidation pendingTransactions={pendingTransactions} />,
      notification: pendingTransactions.length
    }
  ]

  return (
    <Grid container mt={3} spacing={2}>
      <Grid item xs={12} sm={12} md={12} ml={6} mb={2}>
        <Title>{t('payment_validation')}</Title>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TabsContainer tabs={Tabs} />
      </Grid>
    </Grid>
  )
}
