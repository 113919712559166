import { UseFetch } from './UseFetch'
import { Transaction } from '../types/types'
import { getIssuerById } from './apiIssuers'

interface PostBody {
  clientId: string
  issuer_id: string
  investor: any
  amount: number
  tokens: number
  asset_id: number
  investor_id: number
  type: 'BUY' | 'BUYBACK' | 'SELL'
  asset: any
  authorized?: 'pending' | 'approved' | 'rejected'
  pay_confirmed?: 'pending' | 'approved' | 'rejected'
}

export interface PostTransactionResponse {
  success: boolean
  transaction: Transaction
  message: string
}

export const postTransactions = async ({
  clientId,
  issuer_id,
  investor,
  amount,
  tokens,
  type,
  asset,
  asset_id,
  investor_id,
  authorized = 'pending',
  pay_confirmed = 'pending'
}: PostBody): Promise<PostTransactionResponse> => {
  const { records } = await getIssuerById(issuer_id, clientId)
  const init = {
    body: {
      clientId,
      asset_id,
      investor_id,
      issuer_id,
      issuer: records?.[0],
      investor,
      asset,
      amount,
      tokens,
      type,
      authorized,
      pay_confirmed
    },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('post', 'endpoint', '/transactions', init)
}

interface GetBody {
  clientId: string
  issuer_id?: string | null
}

export interface GetTransactionResponse {
  success: boolean
  records: Transaction[]
  message: string
}

export const getTransactions = async ({
  clientId,
  issuer_id = null
}: GetBody): Promise<GetTransactionResponse> => {
  const queryString = issuer_id ? `&issuer_id=${issuer_id}` : ''
  const init = {
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('get', 'endpoint', `/transactions/?clientId=${clientId}` + queryString, init)
}

interface PutBody {
  id: string
  values: { pay_confirmed?: 'approved' | 'rejected', payment_operation?: 'approved' | 'rejected' }
  clientId: string
}

export interface PutTransactionResponse {
  success: boolean
  records: Transaction
  message: string
}

export const putTransactions = async ({ id, values, clientId }: PutBody): Promise<PutTransactionResponse> => {
  const init = {
    body: { ...values },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('put', 'endpoint', `/transactions/${id}?clientId=${clientId}`, init)
}

interface GetHistoryBody {
  investorId: string
  clientId: string
}

export interface GetHistoryTransactionResponse {
  success: boolean
  records: Transaction[]
  message: string
}

export const getHistoryTransactions = async ({ investorId, clientId }: GetHistoryBody): Promise<GetHistoryTransactionResponse> => {
  const init = {
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('get', 'endpoint', `/transactions/?investor_id=${investorId}&clientId=${clientId}`, init)
}
