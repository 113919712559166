import styled from '@emotion/styled'
import MenuIcon from '@mui/icons-material/Menu'
import { useContext } from 'react'
import ClientContext from '../../contexts/ClientContext'
import { ColorContext } from '../../contexts/ColorContext'
import UserContext from '../../contexts/UserContext'
import logoQx from '../../assets/logo-primary.png'
import { IconButton } from '@mui/material'
import { MaterialIcon } from '../Sidebar/SidebarOptions'

const TobarMobile = styled('div') <{ $topPosition: boolean }>`
  background-color: ${props => props.color};
  width: 100%;
  z-index: 1000;
  position: fixed;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  height: 50px;
  justify-content: space-between;
  top: ${props => props.$topPosition ? '-100px' : 0};
  transition: all .5s ease;
  overflow: hidden;

  @media (min-width: 480px) {
    display: none;  
  }
`

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  show: boolean
}

export const TobarMobil = ({ setShow, show }: Props) => {
  const { userRol } = useContext(UserContext)
  const { client } = useContext(ClientContext)
  const { theme } = useContext(ColorContext)
  const { primary } = theme
  return (
    <TobarMobile
      $topPosition={show}
      color={primary}
    >
      <IconButton onClick={() => setShow(true)}>
        <MenuIcon {...MaterialIcon} />
      </IconButton>
      <img
        style={{ width: '30%' }}
        src={
          userRol() === 'admins'
            ? logoQx
            : client?.client?.files?.logo?.path || ''
        }
        alt="Cargando..."
      />

    </TobarMobile>
  )
}
