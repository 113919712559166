import { useContext } from 'react'
import { MyCustomTab, TabPanel } from '../../../../components'
import { AssetForm } from './AssetForm'
import { AssetDigitalization } from './AssetDigitalization'
import { Grid } from '@mui/material'
import LoadingContext from '../../../../contexts/LoadingContext'
import UserContext from '../../../../contexts/UserContext'
import { createAsset, updateAsset } from '../../../../core/apiAssets'
import NotificationContext from '../../../../contexts/NotificationContext'
import ClientContext from '../../../../contexts/ClientContext'
import { AssetVDR } from './AssetVDR'
import { MyButton } from '../../../../components/MyButton'
import { createId } from '../../../../core/utils'
import { AssetFormContext } from './DialogAssetForm'
import { SelectAssetType } from './SelectAssetType'
import { FinalStepForm } from './FinalStepForm'
import { MultimediaStep } from './MultimediaStep'
import { useAssetCreationHeaders } from './HeadersLabelsForm'
import { useTranslation } from 'react-i18next'
import { useClientConfig } from '../../../../hooks/useClientConfig'

export const AssetsMain = () => {
  const { getIssuerId, issuer_logo, VDR_ID, issuer } = useContext(UserContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { clientId } = useContext(ClientContext)
  const { headers } = useAssetCreationHeaders()
  const { assetTypeLabel } = useClientConfig()
  const { t } = useTranslation()
  const {
    assetData,
    setAssetData,
    linkVdr,
    setLinkVdr,
    onClose,
    refreshTables,
    initialValues,
    mode,
    indexTab,
    nextStep,
    previousStep,
    setIndexTab,
    assetType,
    assetCurrency,
    characteristics,
    transmissionRefinement,
    capitalPercentage,
    valuationSource,
    independentValuatorMethod,
    latestInvestmentRound,
    independentValuator
  } = useContext(AssetFormContext)
  const disabledButton = issuer?.typeVdr !== 'tradicional' && linkVdr === ''

  const onChangeVdr = (linkVdr: string) => {
    setLinkVdr(linkVdr)
    setAssetData({ ...assetData, linkVdr })
  }

  const validateAdditionalData = (additional_data = []) => {
    let canPass = true
    for (let i = 0; i < additional_data.length; i++) {
      const { icono, description, title }: any = additional_data[i]
      if (description.length > 64 || title.length > 64) {
        canPass = false
        break
      }
      if (icono === '' || description === '' || title === '') {
        canPass = false
        break
      }
    }
    return canPass
  }

  const validateFreeAsset = () => {
    if (assetType !== 'EQUITY') return true
    let countFilledPosition = 0
    for (let i = 0; i < characteristics.length; i++) {
      if (characteristics[i].length !== 0) countFilledPosition++
    }
    return countFilledPosition >= 2
  }

  const validateTransmissionAsset = () => {
    if (assetType !== 'EQUITY') return true
    let countFilledPosition = 0
    for (let i = 0; i < transmissionRefinement.length; i++) {
      if (transmissionRefinement[i].length !== 0) countFilledPosition++
    }
    return countFilledPosition >= 1
  }

  const validateValuationSource = (values: any) => {
    if (assetType !== 'EQUITY') return true
    if (valuationSource[0].length) {
      if (independentValuatorMethod.filter(item => item !== '').length === 0) {
        showToast('error', t('toasts.min_independent_valuation'))
        return false
      }
      const countIndependentValuator = independentValuator.filter(item => item !== '').length
      if (countIndependentValuator <= 0 || countIndependentValuator > 3) {
        showToast('error', t('toasts.max_three_independent_valuation'))
        return false
      }
    }
    if (valuationSource[1].length) {
      if (latestInvestmentRound.filter(item => item !== '').length === 0) {
        showToast('error', t('toasts.min_one_last_round_investment'))
        return false
      }
    }
    if (valuationSource[0].length || valuationSource[1].length) {
      if (!values?.valuation_date) {
        showToast('error', t('toasts.must_have_valuation_date'))
        return false
      }
    }
    return true
  }

  const validateCapitalPercentage = () => capitalPercentage >= 0 && capitalPercentage <= 100

  const handleClose = () => {
    setIndexTab(0)
    setAssetData(initialValues)
    onClose()
  }

  const handleSubmitTabOne = async (values: any) => {
    if (!validateCapitalPercentage()) {
      showToast('error', t('toasts.capital_percentage_restriction'))
      return false
    }
    if (!validateFreeAsset()) {
      showToast('error', t('toasts.minimal_characteristics'))
      return false
    }
    if (!validateTransmissionAsset()) {
      showToast('error', t('toasts.minimal_ref'))
      return false
    }
    if (!validateValuationSource(values)) return false
    if (validateAdditionalData(values.additional_data)) {
      const dataToSend = { ...values, step: indexTab }
      if (mode === 'crear' && !assetData?.step) {
        const newAsset = { ...dataToSend, id: createId(values.name) }
        setAssetData({ ...newAsset, linkVdr })
        await handleCreateAsset(newAsset)
      } else {
        setAssetData({ ...assetData, ...values, id: assetData.id, linkVdr })
        await handleEditAsset({ ...assetData, ...values, step: indexTab, id: assetData.id })
      }
      setIndexTab(v => v + 1)
    } else {
      showToast('error', t('toasts.frontal_fields_restriction'))
    }
  }

  const handleSubmitTabTwo = async (values: any) => {
    setAssetData({ ...assetData, ...values, linkVdr })
    await handleEditAsset({ ...values, step: indexTab })
    await refreshTables()
    nextStep()
  }

  const handleSubmitTabMultimedia = async (usingLogo: any) => {
    setAssetData({ ...assetData, usingLogo, linkVdr })
    await handleEditAsset({ ...assetData, usingLogo, step: indexTab })
    await refreshTables()
  }

  const handleFinishForm = async () => {
    showLoading()
    await updateAsset({ id: assetData.id, step: 1, issuer_id: getIssuerId(), clientId: clientId(), completedSteps: true, status: 'PENDING' })
    if (!assetData?.completedSteps) showToast('success', t('toasts.asset_creation_success', { asset: assetTypeLabel('singular', 'lowercase') }))
    else showToast('success', t('toasts.asset_updated_success', { asset: assetTypeLabel('singular', 'lowercase') }))
    await refreshTables()
    handleClose()
    hideLoading()
  }

  const createAssetBody = (assetData: any) => {
    const asset = {
      ...assetData,
      issuer_id: getIssuerId(),
      clientId: clientId(),
      currency: assetCurrency,
      expected_return: { title: assetData.title_return, value: assetData.value_return },
      issuer_logo,
      linkVdr,
      assetType,
      price: Number(assetData.price),
      bidAmount: Number(assetData.bidAmount),
      token_quantity: Number(assetData.token_quantity),
      expected_income: Number(assetData.expected_income),
      investment_amount: Number(assetData.investment_amount),
      maximum_token: Number(assetData.maximum_token),
      minimun_token: Number(assetData.minimun_token),
      share_offer: Number(assetData?.share_offer ?? 0),
      transmissionRefinement,
      characteristics,
      valuation_source: valuationSource,
      valuation_date: assetData?.valuation_date ? assetData.valuation_date : new Date(),
      independent_valuator_method: independentValuatorMethod,
      independent_valuator: independentValuator,
      latest_investment_round: latestInvestmentRound,
      capitalPercentage: String(capitalPercentage),
      completedSteps: false
    }
    const token_price = Number(assetData.token_price)
    console.log('token_price', token_price)
    setAssetData({ ...asset, token_price, step: indexTab + 1 })
    return {
      ...asset,
      token_price: isFinite(token_price) ? token_price : 0,
      step: indexTab + 1
    }
  }

  const handleCreateAsset = async (assetData: any) => {
    const asset = createAssetBody(assetData)
    if (!asset) return
    showLoading()
    const resp = await createAsset(asset)
    if (resp?.errors) showToast('error', 'Error')
    await refreshTables()
    hideLoading()
  }

  const handleEditAsset = async (assetData: any) => {
    const asset = createAssetBody(assetData)
    if (!asset) return
    showLoading()
    const resp = await updateAsset(asset)
    if (resp?.errors) showToast('error', 'Error')
    await refreshTables()
    hideLoading()
  }

  return (
        <MyCustomTab headersTab={headers} indexTab={indexTab}>
            <TabPanel key={0} value={indexTab} index={0}>
                <SelectAssetType />
            </TabPanel>
            <TabPanel key={1} value={indexTab} index={1}>
                <AssetForm
                    data={{
                      ...assetData,
                      title_return: assetData?.expected_return?.title || '',
                      value_return: assetData?.expected_return?.value || ''
                    }}
                    onSubmit={handleSubmitTabOne}
                    onClose={handleClose}
                />
            </TabPanel>
            <TabPanel key={2} value={indexTab} index={2}>
                <AssetDigitalization
                    previousStep={previousStep}
                    data={assetData}
                    onSubmit={handleSubmitTabTwo}
                    onClose={handleClose}
                />
            </TabPanel>
            <TabPanel key={3} value={indexTab} index={3}>
                <Grid container spacing={2}>
                    <Grid item sm={9}>
                        <AssetVDR
                            id={`${VDR_ID()}?asset_id=${assetData.id}`}
                            setLinkVdr={onChangeVdr}
                            link={linkVdr}
                        />
                    </Grid>
                    <Grid item sm={12} mb={4} />
                    <Grid item >
                        <MyButton
                            color='secondary'
                            onMouseUp={handleClose}
                        >
                            {t('cancel')}
                        </MyButton>
                    </Grid>
                    <Grid item >
                        <MyButton onMouseUp={nextStep} disabled={disabledButton}>
                          {t('next')}
                        </MyButton>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel key={4} value={indexTab} index={4}>
                <MultimediaStep onNextStep={handleSubmitTabMultimedia} />
            </TabPanel>
            <TabPanel key={5} value={indexTab} index={5}>
                <FinalStepForm handleFinishForm={handleFinishForm} />
            </TabPanel>
        </MyCustomTab>
  )
}
