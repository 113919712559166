import { AuthorizeInvestorsTable } from './AuthorizeInvestorsTable'
import { InvestorsTable } from './InvestorsTable'
import { Grid } from '@mui/material'
import { Title } from '../../../../../components/Title'
import { TabsContainer } from '../../../../../components/DashboardTabs/TabsContainer'
import { useScrollToTop } from '../../../../../hooks/useScrollToTop'
import { useContext } from 'react'
import { InvestorsContext } from '../../../../../contexts/InvestorsContext'
import { useTranslation } from 'react-i18next'

export const InvestorsMain = () => {
  const [t] = useTranslation()
  useScrollToTop()
  const {
    pendingInvestors
  } = useContext(InvestorsContext)

  const Tabs = [
    { title: t('approved_investors'), component: <InvestorsTable /> },
    { title: t('registration_request'), component: <AuthorizeInvestorsTable />, notification: pendingInvestors.length }
  ]

  return (
    <Grid container mt={3} spacing={2}>
      <Grid item xs={12} sm={12} md={12} ml={6} mb={2}>
        <Title>{t('investors_title')}</Title>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TabsContainer tabs={Tabs} />
      </Grid>
    </Grid>
  )
}
