import { useMediaQuery } from '@mui/material'

export const mobileSize = '(max-width:480px)'
export const tabletSize = '(max-width:768px)'
export const desktopSize = '(max-width:1860px)'
export const extraBigSize = '(max-width:2240px)'
export const moreThanExtraBigSize = '(min-width:2240px)'

export const useResponsive = () => {
  const isMobileDevice = useMediaQuery(mobileSize)
  const isTabletDevice = useMediaQuery(tabletSize)
  const isDesktopDevice = useMediaQuery(desktopSize)
  const isExtraBigDevice = useMediaQuery(extraBigSize)
  const isMoreThanExtraBigDevice = useMediaQuery(moreThanExtraBigSize)

  return {
    isMobileDevice,
    isTabletDevice,
    isDesktopDevice,
    isExtraBigDevice,
    isMoreThanExtraBigDevice
  }
}
