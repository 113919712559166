import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { MyTextArea } from '../../../components/MyTextArea'
import { MyTextInput, MyUploadInput } from '../../../components'
import { MyButton } from '../../../components/MyButton'
import UserContext from '../../../contexts/UserContext'
import LoadingContext from '../../../contexts/LoadingContext'
import NotificationContext from '../../../contexts/NotificationContext'
import { getIssuerById, updateConfigIssuer } from '../../../core/apiIssuers'
import * as yup from 'yup'
import { uploadImg } from '../../../core/apiUploadDocs'
import { Paragraph } from '../../../components/Paragraph'
import ClientContext from '../../../contexts/ClientContext'
import { useTranslation } from 'react-i18next'

export const IssuerProfile = React.memo(() => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { issuer, reloadIssuerUser } = useContext(UserContext)
  const { client_id } = useContext(ClientContext)
  const { t } = useTranslation()

  const initialValues = {
    logo: issuer?.logo || '',
    description: issuer?.description,
    nameRepresentOne: issuer?.legal_representative?.[0]?.first_names || '',
    lastFatherRepresentOne: issuer?.legal_representative?.[0]?.fathers_name || '',
    lastMotherRepresentOne: issuer?.legal_representative?.[0]?.mothers_name || '',

    nameRepresentTwo: issuer?.legal_representative?.[1]?.first_names || '',
    lastFatherRepresentTwo: issuer?.legal_representative?.[1]?.fathers_name || '',
    lastMotherRepresentTwo: issuer?.legal_representative?.[1]?.mothers_name || ''
  }

  const handleSubmit = async (values: any) => {
    let resp
    const legal_representative = [
      {
        first_names: values.nameRepresentOne,
        fathers_name: values.lastFatherRepresentOne,
        mothers_name: values.lastMotherRepresentOne
      },
      {
        first_names: values.nameRepresentTwo,
        fathers_name: values.lastFatherRepresentTwo,
        mothers_name: values.lastMotherRepresentTwo
      }
    ]
    showLoading()
    if (typeof values.logo !== 'string') {
      resp = await updateConfigIssuer(
        issuer.id,
        {
          description: values.description,
          logoFile: values.logo.name,
          legal_representative
        },
        client_id
      )
      if (resp?.success) {
        await uploadImg(resp.records?.[0].signedUrl, values.logo)
        showToast('success', t('toasts.company_updated_success'))
      }
    } else {
      resp = await updateConfigIssuer(issuer.id, { description: values.description, legal_representative }, client_id)
      if (resp?.success) {
        showToast('success', t('toasts.company_updated_success'))
      }
    }

    if (resp?.success) {
      const uploadIssuer = await getIssuerById(issuer.id, client_id)
      if (uploadIssuer?.success) reloadIssuerUser(uploadIssuer?.records?.[0])
    }
    hideLoading()
  }

  return (
    <Grid container spacing={2} pl={8}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {() => (
          <Form style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item sm={12} pb={1}>
                <Paragraph bold>{t('description')}</Paragraph>
              </Grid>
              <Grid item sm={9} xs={12} pb={2}>
                <MyTextArea
                  label={t('description')}
                  name='description'
                />
              </Grid>
              <Grid item sm={12}>
                <Paragraph bold>Logo</Paragraph>
              </Grid>
              <Grid item sm={10} xs={12} pb={2}>
                <MyUploadInput
                  name='logo'
                  typeText={t('drag_drop_max_size') ?? ''}
                  labelText={t('drag_drop_disclaimer')}
                  title=''
                  accept={['.jpg', '.png']}
                />
              </Grid>
              <Grid item sm={12} xs={12} my={3}>
                <Paragraph bold>{t('create_company_form.page_one.legal_representative_title')}</Paragraph>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Paragraph bold type='small'>{t('legal_representative_one')}</Paragraph>
              </Grid>
              <Grid item sm={3} xs={12}>
                <MyTextInput
                  label={t('name')}
                  name='nameRepresentOne'
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <MyTextInput
                  label={t('last_father_name')}
                  name='lastFatherRepresentOne'
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <MyTextInput
                  label={t('last_mother_name')}
                  name='lastMotherRepresentOne'
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Paragraph bold type='small'>{t('legal_representative_two')}</Paragraph>
              </Grid>
              <Grid item sm={3} xs={12}>
                <MyTextInput
                  label={t('name')}
                  name='nameRepresentTwo'
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <MyTextInput
                  label={t('last_father_name')}
                  name='lastFatherRepresentTwo'
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <MyTextInput
                  label={t('last_mother_name')}
                  name='lastMotherRepresentTwo'
                />
              </Grid>
              <Grid item sm={12} >
                <MyButton type='submit'>{t('save_changes')}</MyButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
})

export const validationSchema = yup.object().shape({
  logo: yup.string().required('default'),
  description: yup.string().required('default')
})
