export const ChatBot = () => {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = '19ccf51d-7fd4-493d-85f5-fdab15c9ffa6';
  (function () {
    const d = document
    const s = d.createElement('script')
    s.src = 'https://client.crisp.chat/l.js'
    s.async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
  })()
}
