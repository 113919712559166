export enum assetsTypeByClient {
  EQUITIES = 'EQUITIES',
  REAL_ESTATES = 'REAL_ESTATES',
  FUNDS = 'FUNDS',
  ALL = 'ALL',
}

export interface Client {
  assetsType: 'EQUITIES' | 'REAL_ESTATES' | 'FUNDS' | 'ALL'
  configuration: Configuration
  declaration: string
  disclaimer: Disclaimer
  id: string
  logo: string
  name: string
  onbHasAdditionalFields: boolean
  paymentInstructions: PaymentInstruction[]
  politics: string
  private_key: string
  regional_config: RegionalConfig
  setupColors: SetupColors
  subtitle: string
  type: string
  typeformUrl: string
  usageConditions: string
  users: string[]
  vdrInstructions: VdrInstruction[]
  wallet: string
}

interface Configuration {
  currencyOptions: string[]
  distribution: boolean
  expected_return: boolean
  flow_periodicity: boolean
  income_distribution: boolean
  landing_url: string
  mandatory_vdr: boolean
  manual_buyback: boolean
  multi_issuers: boolean
  payments: boolean
  person_type_terminology: string
  purchase_currency: string
  receive_email_address: string
  regional_conf: string
  send_email_address: string
  token_currency: string
}

interface Disclaimer {
  body: string
  links: Link[]
  title: string
}

interface Link {
  body: string
  title: string
}

export interface PaymentInstruction {
  field: string
  language: string
  value: string
}

export interface RegionalConfig {
  timezone: string
}

export interface SetupColors {
  color1: string
  color2: string
  color3: string
}

export interface VdrInstruction {
  description: string
  language: string
  title: string
}

export interface Transaction {
  id: string
  clientId: string
  issuer: any
  asset: any
  investor: Investor
  type: string
  authorized: 'pending' | 'approved' | 'rejected'
  authorizedStatusChangedAt: Date
  pay_confirmed: 'pending' | 'approved' | 'rejected'
  payConfirmedStatusChangedAt: Date
  amount: number
  tokens: number
  asset_id: number
  investor_id: number
  createdAt: Date
  updatedAt: Date
  operations?: Operation[]
}

export interface Operation {
  id?: string
  transactionId: string
  clientId: string
  issuer_id: string
  investor_id: string
  asset_id: string
  tokens: number
  wallet_origin?: string
  wallet_destiny: string
  hash?: string
}

export interface Acceptance {
  clientId: string
  investor_id: string
  issuer_id: string
  updatedAt: Date
  createdConfidentialityApprovedAt?: Date
  status?: 'NO_CONFLICT' | 'REQUESTED' | 'REJECTED' | 'APPROVED'
  investor?: {
    name: string
    email: string
  }
}

export interface Investor {
  id: string
  additional_data: AdditionalData
  classificationType: number
  clientId: string
  completedSteps: boolean
  createdAt: string
  email: string
  fathers_name: string
  first_names: string
  isCompanyRepresentative: boolean
  legal_representative: any[]
  min_investment: number
  mothers_name: string
  role: string
  status: string
  step: number
  wallets: string[]
  acceptedAt?: Date
}

interface AdditionalData {
  country: string
  desired_investment: number
  investment_background: string
  phone: string
  total_previous_investment: number
}

export interface Contract {
  name: string
  clientId: string
  asset_id: string
  trnHash: string
  address: string
  abi: object
  minimumTokens: number
  maximumTokens: number
  initialSupply: number
  tokensAvailable: number
}

export interface User {
  clientId: string
  cognitoId: string
  email: string
  fathers_name: string
  first_names: string
  group: string
  language: string
  mothers_name: string
  national_id: string
  status: boolean
}

export interface Maintenance {
  id: string
  mode: boolean
}

export interface VDRFile {
  confidentiality: boolean
  description: string
  docReady: boolean
  file: string
  folder: string
  id: string
  url: string
  position?: number
}

export enum ASSET_TYPE {
  EQUITY = 'EQUITY',
  REAL_ESTATE = 'REAL_ESTATE',
  FUND = 'FUND',
}
