import { FastField } from 'formik'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ItemProp {
  text: string
  value: string | number
}

interface Props {
  labelText: string
  name: string
  default: string
  disabled?: boolean
  items: ItemProp[]
  [propName: string]: any
}

export const MySelect = ({ items, disabled = false, ...props }: Props) => {
  const { t, i18n: { language } } = useTranslation()

  return (
    <FastField name={props.name} key={`select-${props.name}-${language}`}>
    {(({ field, meta }: any) =>
      <FormControl error={!!meta.error && meta.touched} fullWidth>
        <InputLabel>{ props.labelText }</InputLabel>
        <Select
          MenuProps={{ style: { maxHeight: 310 } }}
          id={field.name}
          disabled={disabled}
          fullWidth
          label={props.labelText}
          {...field}
        >
          { items.map(({ value, text }, index) =>
            <MenuItem key={index} value={value}>{ text }</MenuItem>
          )}
        </Select>
        { !!meta.error && meta.touched
          ? <FormHelperText>{ t(`forms.${meta?.error || 'required_field'}`) }</FormHelperText>
          : <></>
        }
      </FormControl>
    )}
    </FastField>
  )
}
