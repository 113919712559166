import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { MyUploadInput } from '../../../../components'
import { MyButton } from '../../../../components/MyButton'
import { MyTextArea } from '../../../../components/MyTextArea'
import { Paragraph } from '../../../../components/Paragraph'
import { mediumMobil } from '../../../../layout/LayoutConfig'
import { setupIssuerAccountValidationSchema } from '../../../../validations/setupIssuerAccount'

interface Props {
  onSubmit: (values: any) => Promise<any>
  onClose: () => void
  initialValues: any
}

export const SetupAccount = ({ onSubmit, initialValues, onClose }: Props) => {
  const { t } = useTranslation()
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={setupIssuerAccountValidationSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Paragraph type='subheading' bold>{t('company_user.onboarding.title_two')}</Paragraph>
              </Grid>
              <Grid item sm={12}>
                <Paragraph>{t('company_user.onboarding.subtitle_two')}</Paragraph>
              </Grid>
              <Grid item {...mediumMobil}>
                <MyTextArea
                  name='description'
                  label={t('company_user.onboarding.describe_your_company')}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <MyUploadInput
                  name='logo'
                  typeText={t('drag_drop_max_size') ?? ''}
                  labelText={t('drag_drop_disclaimer')}
                  title={t('company_user.onboarding.upload_logo')}
                  accept={['.jpg', '.png']}
                />
              </Grid>
              <Grid item >
                <MyButton color='secondary' onMouseUp={onClose} >
                  {t('review_later')}
                </MyButton>
              </Grid>
              <Grid item >
                <MyButton type='submit' >
                {t('next')}
                </MyButton>
              </Grid>

            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
