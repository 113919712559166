import styled from '@emotion/styled'
import { GenericChildren } from '.'

const SubtitleText = styled.p`
  padding-bottom: 10px;
`

const Subtitle = ({ children }: GenericChildren): JSX.Element => {
  return (
    <SubtitleText>{children}</SubtitleText>
  )
}

export default Subtitle
