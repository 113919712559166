import { API } from 'aws-amplify'
import { format } from 'date-fns'

export const getPreRegisters = async (
  startDate: any,
  endDate: any,
  role: any,
  clientId: any
) => {
  const user = JSON.parse(localStorage.getItem('user') ?? '')
  const init = {
    queryStringParameters: {
      email: user.user.email,
      startDate: format(new Date(startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(endDate), 'yyyy-MM-dd'),
      role
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await API.get('endpoint', `/pre-registers/${clientId}?`, init)
}

export const getPreRegistersByEmail = async (clientId: any, email: any) => {
  const init = {
    queryStringParameters: {
      email
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await API.get('endpoint', `/pre-registers/${clientId}?`, init)
}
