import styled from '@emotion/styled'
import { colorPalette } from '../../conf/ColorConfig'
import { Paragraph } from '../Paragraph'

export const CompanyInfo = ({ company }: { company: any }) => {
  return (
    <Container>
      <Img src={company?.logo} alt=''/>
      <Paragraph type='small'>
        {company?.description}
      </Paragraph>
    </Container>
  )
}

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  gap: 2px;
  padding: 36px;
  background-color: ${colorPalette.basics.HEX_F2F7FF};
`
const Img = styled.img`
  height: 80px;
  width: auto;
`
