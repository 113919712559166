import styled from '@emotion/styled'
import { useState, useEffect, useCallback, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { colorPalette } from '../../conf/ColorConfig'
import { validDate } from '../../core/utils'
import { Paragraph } from '../Paragraph'
import dayjs from 'dayjs'
interface Props {
  rows: any[]
  labelToFilter: any
  labelToFilterType: 'number' | 'string' | 'date'
  children: (args: { dataFilter: any[], key: number }) => JSX.Element
}

export const FilterTableHOC = memo(({
  rows,
  labelToFilter,
  children,
  labelToFilterType
}: Props) => {
  const [dataFilter, setDataFilter] = useState<any[]>([])
  const [orderBy, setOrderBy] = useState<string>('asc')
  const [key, setKey] = useState<number>(Math.random())
  const [t] = useTranslation()

  const cmp = useCallback(() => {
    if (labelToFilterType === 'date') {
      const validDates = rows.filter(row => dayjs(row?.[labelToFilter]).isValid())
      const nonValidDates = rows.filter(row => !dayjs(row?.[labelToFilter]).isValid())
      const sorted = validDates.sort((a, b) => {
        let fieldA, fieldB
        if (orderBy === 'des') {
          fieldA = a?.[labelToFilter]
          fieldB = b?.[labelToFilter]
        } else {
          fieldB = a?.[labelToFilter]
          fieldA = b?.[labelToFilter]
        }
        if (!dayjs(fieldA).isValid()) return -1
        else if (!dayjs(fieldB).isValid()) return 1
        return validDate(new Date(fieldA)).getTime() - validDate(new Date(fieldB)).getTime()
      })
      return [...sorted, ...nonValidDates]
    }
    return rows.sort((a, b) => {
      let fieldA, fieldB
      if (orderBy === 'des') {
        fieldA = a?.[labelToFilter]
        fieldB = b?.[labelToFilter]
      } else {
        fieldB = a?.[labelToFilter]
        fieldA = b?.[labelToFilter]
      }
      return fieldA - fieldB
    })
  }, [orderBy, rows])

  useEffect(() => {
    const output = cmp()
    setDataFilter(output)
    setKey(prev => prev + Math.random())
  }, [rows, orderBy])

  return (
    <>
      <SelectContainer>
        <Paragraph>
          <strong>{t('sort_by')}</strong>
        </Paragraph>
        <SelectStyled value={orderBy} onChange={e => setOrderBy(e.target.value)}>
          <option value='asc'>{t('most_recent')}</option>
          <option value='des'>{t('oldest')}</option>
        </SelectStyled>
      </SelectContainer>
      { children({ dataFilter, key }) }
    </>
  )
})

const SelectContainer = styled.div`
  display: flex;
  gap: 1rem;
`
const SelectStyled = styled.select`
  border: 0;
  color: ${colorPalette.basics.HEX_00266A};
  font-size: 16px;
  font-weight: 400;
`
