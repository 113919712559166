/* eslint-disable no-return-assign */
import { API } from 'aws-amplify'

export const UseFetch = async (
  type: 'post' | 'get' | 'put' | 'delete' | 'patch',
  myEndpoint: any,
  myPath: any,
  data: any,
  tokenRequired: boolean = true
): Promise<any> => {
  let userToken
  let rawData = data
  let response

  if (tokenRequired) {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    userToken = user?.auth?.AuthenticationResult?.IdToken
    rawData = { ...data, headers: { ...{ ...data?.headers }, Authorization: `Bearer ${userToken}` } }
  }
  switch (type) {
    case 'post':
      await API.post(myEndpoint, myPath, rawData)
        .then(resp => response = resp)
        .catch(error => response = error?.response?.data?.errors?.[0] || error?.response?.data)
      return response
    case 'get':
      await API.get(myEndpoint, myPath, rawData)
        .then(resp => response = resp)
        .catch(error => response = error?.response?.data?.errors?.[0] || error?.response?.data)
      return response
    case 'put':
      await API.put(myEndpoint, myPath, rawData)
        .then(resp => response = resp)
        .catch(error => response = error?.response?.data?.errors?.[0] || error?.response?.data)
      return response
    case 'delete':
      await API.del(myEndpoint, myPath, rawData)
        .then(resp => response = resp)
        .catch(error => response = error?.response?.data?.errors?.[0] || error?.response?.data)
      return response
    case 'patch':
      await API.patch(myEndpoint, myPath, rawData)
        .then(resp => response = resp)
        .catch(error => response = error?.response?.data?.errors?.[0] || error?.response?.data)
      return response
  }
}
