import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Grid,
  Paper
} from '@mui/material'
import React, { useContext } from 'react'
import { fullWidth } from '../../layout/LayoutConfig'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { MyButton } from '../MyButton'
import { H1, H3 } from '../MyHeaders'
import { MyTransition } from '../MyTransition'
import ClientContext from '../../contexts/ClientContext'
import NotificationContext from '../../contexts/NotificationContext'
import LoadingContext from '../../contexts/LoadingContext'
import { updateIssuer } from '../../core/apiIssuers'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
  issuerId: string
  disabled: any
  reloadIssuer: () => Promise<void>
}

interface VdrInstructionsProps {
  title: string
  language: string
  description: string
}

export const VDRDetails = ({
  isOpen,
  onClose,
  issuerId,
  disabled,
  reloadIssuer
}: Props) => {
  const { vdrInstructions, client_id }: VdrInstructionsProps[] | any =
    useContext(ClientContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { t, i18n } = useTranslation()

  const onSubmit = async () => {
    showLoading()
    const resp = await updateIssuer(issuerId, { docReady: true }, client_id)
    if (resp?.success) {
      showToast('success', t('toasts.doc_confirmation_success'))
    } else showToast('error', 'Error')
    await reloadIssuer()
    hideLoading()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={MyTransition}
      maxWidth='md'
    >
      <Grid container spacing={2} p={3}>
        <Grid item {...fullWidth}>
          <H1>{t('mandatory_vdr_details')}</H1>
        </Grid>
        <Grid item {...fullWidth}>
          {vdrInstructions?.filter(({ language }: { language: string }) => language === i18n.language).map((el: any, index: number) => (
            <React.Fragment key={index}>
              {el.description
                ? (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {<H3>{el.title}</H3>}
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>{el.description}</p>
                  </AccordionDetails>
                </Accordion>
                  )
                : (
                <Paper
                  elevation={1}
                  style={{ padding: '16px', margin: '12px 0' }}
                >
                  <H3>{el.title}</H3>
                </Paper>
                  )}
            </React.Fragment>
          ))}
        </Grid>
        <Grid container spacing={2} mt={1} justifyContent='flex-end'>
          <Grid item>
            <MyButton color='secondary' onMouseUp={onClose}>
              {t('exit')}
            </MyButton>
          </Grid>
          <Grid item>
            <MyButton disabled={disabled} onMouseUp={onSubmit}>
              {t('i_upload_all_docs')}
            </MyButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}
