import styled from '@emotion/styled'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MyButton } from '../components/MyButton'
import ClientContext from '../contexts/ClientContext'
import { Paragraph } from '../components/Paragraph'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const Bold = styled.strong`
  font-weight: 700;
`

const Modal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation()
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Paragraph bold type='subheading'>{t('inactivity_modal.title')}</Paragraph>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans
            i18nKey='inactivity_modal.content'
            components={{ br: <br />, strong: <Bold /> }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MyButton onMouseUp={onClose}>{t('inactivity_modal.button')}</MyButton>
      </DialogActions>
    </Dialog>
  )
}

export const useIdleTimeout = () => {
  const [showModal, setShowModal] = useState(false)
  const { client_id } = useContext(ClientContext)
  const timeoutRef: { current: NodeJS.Timeout | null } = useRef(null)
  const navigation = useNavigate()

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    clearTimeout(timeoutRef.current as NodeJS.Timeout)
  }

  const logout = () => {
    navigation(`/${client_id || 'admin-qx'}`)
  }

  const onIdle = () => {
    openModal()
    timeoutRef.current = setTimeout(logout, 5 * 60 * 1000)
  }

  const ModalTimeout = () => (
    <Modal isOpen={showModal} onClose={closeModal} />
  )
  return { ModalTimeout, onIdle }
}
