import { useCallback, useContext, useState } from 'react'
import ClientContext from '../contexts/ClientContext'
import LoadingContext from '../contexts/LoadingContext'
import UserContext from '../contexts/UserContext'
import { getAssets, getAssetsByIssuer } from '../core/apiAssets'

interface AssetsCount {
  disponibles: any[]
  aprobados: any[]
  pendientes: any[]
  rechazados: any[]
  agotados: any[]
}

const initialFilterData: AssetsCount = {
  disponibles: [],
  aprobados: [],
  pendientes: [],
  rechazados: [],
  agotados: []
}

export const useAssets = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [assets, setAssets] = useState<any[]>([])
  const [isAssetsLoading, setIsAssetsLoading] = useState<boolean>(true)
  const [filterData, setFilterData] = useState<AssetsCount>(initialFilterData)
  const { getIssuerId } = useContext(UserContext)
  const { clientId, client_id } = useContext(ClientContext)

  const loadAssets = async () => {
    showLoading()
    const client_id = clientId()
    const resp = await getAssets(client_id)
    if (resp?.success) setAssets(resp.records || [])
    hideLoading()
  }

  const loadAssetToInvestorDashboard = useCallback(async () => {
    if (client_id) {
      setIsAssetsLoading(true)
      const resp = await getAssets(client_id)
      if (resp?.success) {
        const filterData = resp?.records?.filter(
          (asset: any) => asset.status !== 'PENDING' && asset.status !== 'REJECTED'
        )
        setAssets(filterData || [])
      }
      setIsAssetsLoading(false)
    }
  }, [client_id])

  const loadAssetsAndFilter = async () => {
    showLoading()
    const client_id = clientId()
    const resp = await getAssets(client_id)
    if (resp?.success) assetCountData(resp.records || [])
    hideLoading()
  }

  const loadAssetsByIssuer = async () => {
    showLoading()
    const issuer_id = getIssuerId()
    const client_id = clientId()
    const resp = await getAssetsByIssuer(issuer_id, client_id)
    if (resp?.success) setAssets(resp.records || [])
    hideLoading()
  }

  const loadAssetsByIssuerAndFilter = async () => {
    showLoading()
    const client_id = clientId()
    const issuer_id = getIssuerId()
    const resp = await getAssetsByIssuer(issuer_id, client_id)
    if (resp?.success) assetCountData(resp.records || [])
    hideLoading()
  }

  const assetCountData = (assets: any[]): void => {
    const aprobados: any[] = []
    const disponibles: any[] = []
    const pendientes: any[] = []
    const rechazados: any[] = []
    const agotados: any[] = []

    assets.forEach(asset => {
      const { status } = asset
      if (status === 'ACCEPTED') aprobados.push(asset)
      if (status === 'PUBLISHED') disponibles.push(asset)
      if (status === 'PENDING') pendientes.push(asset)
      if (status === 'REJECTED') rechazados.push(asset)
      if (status === 'SOLD_OUT') agotados.push(asset)
    })

    setFilterData({ aprobados, pendientes, rechazados, disponibles, agotados })
  }

  const clearAssetContext = () => {
    setIsAssetsLoading(true)
    setFilterData(initialFilterData)
    setAssets([])
  }

  return {
    loadAssetsByIssuer,
    filterData,
    assetCountData,
    loadAssetsByIssuerAndFilter,
    assets,
    loadAssets,
    clearAssetContext,
    loadAssetsAndFilter,
    loadAssetToInvestorDashboard,
    isAssetsLoading
  }
}
