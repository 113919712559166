import { API } from 'aws-amplify'
import { UseFetch } from './UseFetch'

export const getIssuers = async () => {
  return await API.get('endpoint', '/issuers', {})
}

export const getIssuersByClient = async (clientId: any): Promise<any> => {
  return await UseFetch('get', 'endpoint', `/issuers/${clientId}`, {})
}

export const createIssuer = async (issuerData: any, type: any, clientId: any): Promise<any> => {
  const users = []
  users.push({
    email: issuerData.mainEmail,
    first_names: issuerData.mainUser,
    fathers_name: issuerData.mainLastNameFather,
    mothers_name: issuerData.mainLastNameMother,
    clientId
  })
  if (issuerData.secondUser !== '') {
    users.push({
      email: issuerData.secondEmail,
      first_names: issuerData.secondUser,
      fathers_name: issuerData.secondLastNameFather,
      mothers_name: issuerData.secondLastNameMother,
      clientId
    })
  }
  if (issuerData.threeUser !== '') {
    users.push({
      email: issuerData.threeEmail,
      first_names: issuerData.threeUser,
      fathers_name: issuerData.threeLastNameFather,
      mothers_name: issuerData.threeLastNameMother,
      clientId
    })
  }

  const legal_representative = []
  if (issuerData.nameRepresentOne !== '') {
    legal_representative.push({
      first_names: issuerData.nameRepresentOne,
      fathers_name: issuerData.lastFatherRepresentOne,
      mothers_name: issuerData.lastMotherRepresentOne
    })
  }
  if (issuerData.nameRepresentTwo !== '') {
    legal_representative.push({
      first_names: issuerData.nameRepresentTwo,
      fathers_name: issuerData.lastFatherRepresentTwo,
      mothers_name: issuerData.lastMotherRepresentTwo
    })
  }

  const issuer = {
    company_name: issuerData.company_name,
    clientId,
    users,
    legal_representative
  }

  const init = {
    body: {
      issuer
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('post', 'endpoint', '/issuer-rollouts', init)
}

export const getIssuerById = async (id: any, clientId: string): Promise<any> => {
  return await UseFetch('get', 'endpoint', `/issuers/${clientId}/${id}`, {})
}

export const updateIssuer = async (issuerId: any, values: any, clientId: string): Promise<any> => {
  const { logo, id, legal_representative, ...rest } = values
  const init = {
    body: { ...rest },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('put', 'endpoint', `/issuers/${clientId}/${issuerId}`, init)
}

export const updateConfigIssuer = async (issuerId: any, values: any, clientId: string): Promise<any> => {
  const { logo, id, ...rest } = values
  const init = {
    body: { ...rest },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('put', 'endpoint', `/issuers/${clientId}/${issuerId}`, init)
}

export const updateUserCompany = async (id: any, clientId: any, users: any[]): Promise<any> => {
  const init = {
    body: { users, clientId },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('put', 'endpoint', `/issuer-rollouts/${id}`, init)
}
