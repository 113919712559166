import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ClientContext from '../../contexts/ClientContext'
import LoadingContext from '../../contexts/LoadingContext'
import NotificationContext from '../../contexts/NotificationContext'
import { registerInvestor } from '../../core/apiInvestors'
import { InitialLayout } from '../../layout/InitialLayout'
import { RegisterAccountForm } from './RegisterAccountForm'

export const RegisterAccount = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { clientId } = useContext(ClientContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCreateAccount = async ({ username }: any) => {
    showLoading()
    const resp = await registerInvestor(username, clientId())
    if (resp?.success) navigate('/ConfirmationAccount', { state: { username } })
    else showToast('error', t('toasts.AUTH0001'))
    hideLoading()
  }
  return (
        <InitialLayout>
            <RegisterAccountForm onSubmit={handleCreateAccount}/>
        </InitialLayout>
  )
}
