import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { MyButtonIcon, MySelect, MyTextInput } from '../../../../components'
import { normalInvestorValidationSchema } from '../../../../validations/normalInvestorValidationSchema'
import { MyPhoneInput } from '../../../../components/MyPhoneInput'
import { MyButton } from '../../../../components/MyButton'
import { sendEvent } from '../../../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../../../core/GA/GoogleAnalyticsEvents'
import { Paragraph } from '../../../../components/Paragraph'
import { useTranslation } from 'react-i18next'
import { useCountries } from '../../../../hooks/useCountries'

interface Props {
  previousStep: () => void
  initialValues: any
  nextStep: (values: any) => void
  setData: (values: any) => void
  onClose: () => void
}

export const OnboardingInvestorNormalPerson = ({
  previousStep,
  nextStep,
  initialValues,
  setData,
  onClose
}: Props) => {
  const { t } = useTranslation()
  const countries = useCountries()

  const onSubmit = (values: any) => {
    sendEvent(GA_EVENTS.onb_step_one)
    const {
      first_names,
      fathers_name,
      mothers_name,
      country,
      phone,
      email
    } = values
    const investorData = {
      first_names,
      fathers_name,
      mothers_name,
      email,
      additional_data: {
        country,
        phone
      }
    }
    setData(values)
    nextStep(investorData)
  }

  return (
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={normalInvestorValidationSchema}
        >
            {({ errors }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item sm={12} mb={2}>
                            <MyButtonIcon
                                size='small'
                                icon='backArrow'
                                onClick={previousStep}
                                text={t('back')}
                                type='secondary'
                            />
                        </Grid>
                        <Grid item sm={12} mb={2}>
                            <Paragraph type='subheading'><strong>{t('investor_user.onboarding_extended.title_two')}</strong></Paragraph>
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}>
                            <Paragraph>{t('company_user.create_asset.subtitle_two')}</Paragraph>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <MyTextInput
                                name='first_names'
                                label={t('name')}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12} md={4} />
                        <Grid item xs={12} sm={6} md={4}>
                            <MyTextInput
                                name='fathers_name'
                                label={t('last_father_name')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <MyTextInput
                                name='mothers_name'
                                label={t('last_mother_name')}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12} md={4} />
                        <Grid item xs={12} sm={6} md={4}>
                            <MySelect
                                name='country'
                                labelText={t('country')}
                                items={countries}
                                default=''
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <MyPhoneInput
                                name='phone'
                                label={t('phone')}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12} md={4} />
                        <Grid item xs={12} sm={12} md={8}>
                            <MyTextInput
                                name='email'
                                disabled
                                label={t('email')}
                            />
                        </Grid>
                        <Grid item xs={12} mb={2}/>
                        <Grid item xs={12} sm={6} md={3}>
                            <MyButton color='secondary'onMouseUp={onClose} fullWidth>
                                {t('continue_later')}
                            </MyButton>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <MyButton type='submit'disabled={errors.phone} fullWidth>
                                {t('next')}
                            </MyButton>
                        </Grid>

                    </Grid>
                </Form>
            )}
        </Formik>
  )
}
