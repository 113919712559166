import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyTable } from '../../../../components'
import { AssetsContext } from '../../../../contexts/AssetsContext'
import ClientContext from '../../../../contexts/ClientContext'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { formatCurrency, formatNumber } from '../../../../core/utils'
import { useClientConfig } from '../../../../hooks/useClientConfig'

export const RejectedAssets = () => {
  const { currencyOptions } = useContext(ClientContext)
  const [rejectedAsset, setRejectedAsset] = useState<any[]>([])
  const { filterData } = useContext(AssetsContext)
  const { tokens_actions_price, total_tokens_actions } = useContext(IdiomContext)
  const { assetTypeLabel } = useClientConfig()
  const { t } = useTranslation()

  useEffect(() => {
    setRejectedAsset(
      Array.from(filterData.rechazados, (asset: any) => {
        const { token_quantity, price, bidAmount, token_price } = asset
        const currency = asset?.currency
        return {
          ...asset,
          currency,
          token_price: formatCurrency(token_price, currency, true),
          bidAmount: formatCurrency(bidAmount, currency, true),
          price_format: formatCurrency(price, currency, true),
          token_quantity: `${formatNumber(token_quantity)} / ${formatNumber(token_quantity)}`
        }
      }))
  }, [filterData.rechazados, currencyOptions])

  const headers = [
    {
      header: assetTypeLabel('singular', 'uppercase'),
      key: 'name'
    },
    {
      header: total_tokens_actions,
      key: 'token_quantity'
    },
    {
      header: tokens_actions_price,
      key: 'token_price'
    },
    {
      header: t('currency'),
      key: 'currency'
    },
    {
      header: t('valuation'),
      key: 'price_format'
    },
    {
      header: t('bid_amount'),
      key: 'bidAmount'
    }
  ]

  return (
        <MyTable rowData={rejectedAsset} headerData={headers} />
  )
}
