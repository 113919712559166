import { ForgotPasswordScreen } from '../pages/RecoverPassword/ForgotPasswordScreen'
import { ConfigurationDialog } from '../pages/Configuration/ConfigurationDialog'
import { SessionChangePassword } from '../pages/CreatePassword/SessionChangePassword'
import { RegisterAccount } from '../pages/Register/RegisterAccount'
import { ConfirmationAccount } from '../pages/Register/ConfirmationAccount'
import { DashboardAdminQX } from '../pages/Users/AdminQX/DashboardAdminQX'
import { DashboardInvestor } from '../pages/Users/Inversionistas/Dashboard/DashboardInvestor'
import { AssetsDashboard } from '../pages/Users/Emisor/AssetsDashboard'
import { BuyDashboard } from '../pages/Users/Emisor/BuyDashboard'
import { OnboardingInvestorMain } from '../pages/Users/Inversionistas/Onboarding/OnboardingMain'
import { AdminClientMain } from '../pages/Users/Cliente/AdminClientMain'
import { AssetsMain } from '../pages/Users/Cliente/AssetsMain'
import { InvestorsMain } from '../pages/Users/Cliente/Autorizar/Inversionista/InvestorsMain'
import { DocumentationMain } from '../pages/Users/Emisor/VDR/DocumentationMain'
import { PurchaseRequestTable } from '../pages/Users/Inversionistas/PurchaseHistory/PurchasesRequestTable'
import { ReviewVDR } from '../pages/Users/Cliente/ReviewVDR'
import { OnboardingIssuer } from '../pages/Users/Emisor/Onboarding/OnboardingIssuer'
import { LoginScreen } from '../pages/Login/LoginScreen'
import { ErrorPage } from '../pages/Shared/ErrorPage'
import { Dashboard } from '../pages/Shared/Dashboard'
import { RecoverPassword } from '../pages/RecoverPassword/RecoverPassword'
import { RegisterAccountParams } from '../pages/Register/RegisterAccountParams'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { IS_PROD } from '../core/utils'
import { BusinessUsersMaster } from '../pages/Users/Cliente/Empresa/BusinessUsersMaster'
import { ClientsPayments } from '../pages/Users/Cliente/Autorizar/Activos/ClientsPayments'
import { TaxPage } from '../pages/Users/Inversionistas/TaxPage'

export const RoutesOXT = () => {
  const location = useLocation()

  useEffect(() => {
    if (IS_PROD) { ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search }) }
  }, [location])

  return (
    <Routes>
      {/* LOGIN */}
      <Route path="/Register" element={<RegisterAccount />} />
      <Route path="/Register/:client" element={<RegisterAccountParams />} />
      <Route path="/ForgotPassword" element={<ForgotPasswordScreen />} />
      <Route path="/RecoverPassword" element={<RecoverPassword />} />
      <Route path="/SessionChangePassword" element={<SessionChangePassword />} />
      <Route path="/SessionChangePassword" element={<SessionChangePassword />} />
      <Route path="/ConfirmationAccount" element={<ConfirmationAccount />} />
      <Route path="/404" element={<ErrorPage />} />

      {/* DASHBOARD */}
      <Route path="/*" element={<LoginScreen />} />
      <Route path="/Dashboard" element={<Dashboard />}>
        <Route path='admins' element={<DashboardAdminQX />} />
        {/* CLIENTES */}
        <Route path='clients' element={<AdminClientMain />} />
        <Route path='clients-assets' element={<AssetsMain />} />
        <Route path='clients-investors' element={<InvestorsMain />} />
        <Route path='clients-payments' element={<ClientsPayments />} />
        <Route path='clients-vdr-review' element={<ReviewVDR />} />
        <Route path='clients-user-master' element={<BusinessUsersMaster />} />
        {/* EMPRESAS */}
        <Route path='issuers' element={<AssetsDashboard />} />
        <Route path='issuers-onb' element={<OnboardingIssuer />} />
        <Route path='issuers-buy' element={<BuyDashboard />} />
        <Route path='issuers-vdr' element={<DocumentationMain />} />
        <Route path='issuers-tax' element={<TaxPage />} />
        {/* INVERSIONISTAS */}
        <Route path='investors' element={<DashboardInvestor />} />
        <Route path='investors-onb' element={<OnboardingInvestorMain />} />
        <Route path='investors-purchases' element={<PurchaseRequestTable />} />
        <Route path='investors-tax' element={<TaxPage />} />

        <Route path="Configuration" element={<ConfigurationDialog />} />
      </Route>
    </Routes>
  )
}
