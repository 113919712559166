import styled from '@emotion/styled'
import { colorPalette } from '../conf/ColorConfig'

export const Title = styled.h5`
  text-transform: uppercase;
  font-weight: 500;
  line-height: 48px;
  color: ${colorPalette.basics.HEX_3D4D6A};
`

export const LoginTitle = styled.h6`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: ${colorPalette.basics.HEX_3D4D6A};
`
