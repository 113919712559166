import { Grid } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyButton } from '../../../../components/MyButton'
import { MyLabel } from '../../../../components/MyCheckboxInput'
import { MyPDF } from '../../../../components/MyPDF'
import { fullWidth } from '../../../../layout/LayoutConfig'

interface Props {
  onSubmit: (values?: any) => Promise<any>
  onClose: () => void
  url: string
  previousStep: () => void
  hasChecked: boolean
}

export const AcceptPDF = ({ onSubmit, onClose, url, previousStep, hasChecked }: Props) => {
  const [isAccepted, setIsAccepted] = useState(hasChecked)
  const { t } = useTranslation()
  return (
    <Grid container spacing={2}>
      <Grid item {...fullWidth}>
        <MyPDF url={url} />
      </Grid>
      <Grid item {...fullWidth}>
        <MyLabel>
          <input type="checkbox" title='terminos y condiciones' checked={isAccepted} onChange={() => setIsAccepted(!isAccepted)} />
          {t('company_user.onboarding.accept_terms')}
        </MyLabel>
      </Grid>
      <Grid item >
        <MyButton color='info' onClick={onClose}>
          {t('review_later')}
        </MyButton>
      </Grid>
      <Grid item >
        <MyButton color='secondary' onClick={previousStep}>
        {t('back')}
        </MyButton>
      </Grid>
      <Grid item >
        <MyButton onMouseUp={async () => await onSubmit()} disabled={!isAccepted}>{t('next')}</MyButton>
      </Grid>
    </Grid>
  )
}
