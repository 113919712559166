import { IssuerConfirmationAccount } from './IssuerConfirmationAccount'
import { useNavigate } from 'react-router-dom'

export const OnboardingIssuer = () => {
  const navigate = useNavigate()

  const onClose = () => {
    navigate('/Dashboard/issuers')
  }

  return (
    <IssuerConfirmationAccount isOpen={true} onClose={onClose} />
  )
}
