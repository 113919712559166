import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { Grid } from '@mui/material'
import { AssetFormContext } from './DialogAssetForm'
import assetsTypes from '../../../../data/asset-types-form.json'
import { MySelect } from '../../../../components'
import { MyButton } from '../../../../components/MyButton'
import * as yup from 'yup'
import { Paragraph } from '../../../../components/Paragraph'
import ClientContext from '../../../../contexts/ClientContext'
import { useClientConfig } from '../../../../hooks/useClientConfig'
import { useScrollToTop } from '../../../../hooks/useScrollToTop'
import { useTranslation } from 'react-i18next'

export const SelectAssetType = () => {
  const {
    setAssetType,
    nextStep,
    onClose,
    assetType,
    assetCurrency,
    setAssetCurrency
  } = useContext(AssetFormContext)
  const { assetsType, currencyOptions } = useContext(ClientContext)
  const { assetTypeLabel } = useClientConfig()
  const { t, i18n: { language } } = useTranslation()
  useScrollToTop()

  const typeAssetTitle = language === 'es'
    ? `Por favor selecciona el tipo de ${assetTypeLabel('singular', 'lowercase')} que quieres crear`
    : `Please select the type of ${assetTypeLabel('singular', 'lowercase')} you want to create`
  const typeAssetLabel = language === 'es'
    ? `Tipo de ${assetTypeLabel('singular', 'lowercase')}`
    : `Type of ${assetTypeLabel('singular', 'lowercase')}`

  const onSubmit = (assetType: string, assetCurrency: string) => {
    setAssetCurrency(assetCurrency)
    setAssetType(assetType)
    nextStep()
  }

  return (
    <Formik
      initialValues={{ assetType, assetCurrency }}
      validationSchema={validationSchema}
      onSubmit={({ assetType, assetCurrency }) => onSubmit(assetType, assetCurrency)}
    >
    {() =>
      <Form>
        <Paragraph type='subheading'><strong>{t('company_user.create_asset.title_one')}</strong></Paragraph>
        <Grid container spacing={2}>
          { assetsType === 'ALL' &&
            <>
              <Grid item sm={12} xs={12}>
                <Paragraph>{ typeAssetTitle }</Paragraph>
              </Grid>
              <Grid item sm={7} xs={12}>
                <MySelect
                  labelText={typeAssetLabel}
                  name='assetType'
                  items={assetsTypes}
                  default=''
                  disabled={assetsType !== 'ALL'}
                />
              </Grid>
            </>
          }
          <Grid item sm={12} xs={12}>
            <Paragraph>{t('company_user.create_asset.currency_type_title')}</Paragraph>
          </Grid>
          <Grid item sm={7} xs={12}>
            <MySelect
              labelText={t('company_user.create_asset.currency_type_label')}
              name='assetCurrency'
              items={currencyOptions.map((option: string) => ({ value: option, text: option }))}
              default={assetCurrency}
            />
          </Grid>
          <Grid item sm={12} xs={12} />
          <Grid item>
            <MyButton color='secondary' onClick={onClose}>
              {t('cancel')}
            </MyButton>
          </Grid>
          <Grid item>
            <MyButton type='submit'>
              {t('next')}
            </MyButton>
          </Grid>
        </Grid>
      </Form>
    }
    </Formik>
  )
}

export const validationSchema = yup.object().shape({
  assetType: yup.string().required('required_field'),
  assetCurrency: yup.string().required('required_field')
})
