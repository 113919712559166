import styled from '@emotion/styled'
import { GenericChildren } from '.'

const Title = styled.p`
  font-size: 22px;
  padding-bottom: 10px;
`

const TableTitle = ({ children }: GenericChildren): JSX.Element => {
  return (
    <Title>{children}</Title>
  )
}
export default TableTitle
