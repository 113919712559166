import { useContext, useState } from 'react'
import ClientContext from '../contexts/ClientContext'
import LoadingContext from '../contexts/LoadingContext'
import { getInvestorsByFilter } from '../core/apiInvestors'

export const useInvestors = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { clientId } = useContext(ClientContext)
  const [pendingInvestors, setPendingInvestors] = useState<any[]>([])
  const [acceptedInvestors, setAcceptedInvestors] = useState<any[]>([])

  const loadPendingInvestors = async () => {
    showLoading()
    const resp = await getInvestorsByFilter('PENDING', clientId())
    if (resp?.success) setPendingInvestors(resp.records)
    hideLoading()
  }

  const loadAcceptedInvestors = async () => {
    showLoading()
    const resp = await getInvestorsByFilter('ACCEPTED', clientId())
    if (resp?.success) setAcceptedInvestors(resp.records)
    hideLoading()
  }

  return {
    loadPendingInvestors,
    pendingInvestors,
    loadAcceptedInvestors,
    acceptedInvestors
  }
}
