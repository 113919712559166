import { Grid } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MyButtonIcon } from '../../../../components'
import { Paragraph } from '../../../../components/Paragraph'
import { MainVDR } from '../../../../components/VDR/MainVDR'
import UserContext from '../../../../contexts/UserContext'
import { useScrollToTop } from '../../../../hooks/useScrollToTop'
import { AssetFormContext } from './DialogAssetForm'

interface Props {
  id: string
  setLinkVdr: (values: any) => void
  link: string
}

export const AssetVDR = ({ id, setLinkVdr, link }: Props) => {
  const { previousStep } = useContext(AssetFormContext)
  const { issuer_id } = useContext(UserContext)
  const { t } = useTranslation()
  useScrollToTop()

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} mb={2}>
        <MyButtonIcon
          size='small'
          icon='backArrow'
          onClick={previousStep}
          text={t('back')}
          type='secondary'
        />
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Paragraph type='subheading'><strong>{t('company_user.create_asset.title_four')}</strong></Paragraph>
      </Grid>
      <Grid item sm={12} xs={12} md={12}>
        <Paragraph>{t('company_user.create_asset.subtitle_four')}</Paragraph>
      </Grid>
      <Grid item sm={9} xs={12} md={9}>
        <MainVDR
          showHeaders={false}
          type='Activo'
          issuer_id={issuer_id}
          vdr_id={id}
          canDelete={true}
          canEdit={false}
          linkAsset={link}
          setLinkAsset={setLinkVdr}
        />
      </Grid>
    </Grid>
  )
}
