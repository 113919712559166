import { Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { MyButtonIcon, MyCustomTab, TabPanel } from '../../../../components'
import { OnbardingInvestorStatus } from './OnbardingInvestorStatus'
import { OnboardingInvestorAccreditation } from './OnboardingInvestorAccreditation'
import { OnboardingInvestorNormalPerson } from './OnboardingInvestorNormalPerson'
import { OnboardingInvestorSpecialPerson } from './OnboardingInvestorSpecialPerson'
import { OnboardingInvestorTabOne } from './OnboardingInvestorTabOne'
import { MainOnboardingWallet } from './Wallet/MainOnboardingWallet'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import ClientContext from '../../../../contexts/ClientContext'
import { MyButton } from '../../../../components/MyButton'
import { KYC } from './KYC'
import { Conditions } from './Conditions'
import UserContext from '../../../../contexts/UserContext'
import { Paragraph } from '../../../../components/Paragraph'
import { useTranslation } from 'react-i18next'

interface Props {
  initialData: any
  continueLater: () => void
  indexTab: number
  previousStep: () => void
  crudeDate: any
  handleChangeStep: (values?: any) => void
  isLoading: boolean
}

export const ExtendedOnboardingTabs = ({ isLoading, crudeDate, initialData, continueLater, indexTab, previousStep, handleChangeStep }: Props) => {
  const [basicData, setBasicData] = useState<any>(initialData)
  const [personType, setPersonType] = useState<any>(initialData?.classificationType || 0)
  const { reloadInvestorUser } = useContext(UserContext)
  const [wallet, setWallet] = useState<string>('')
  const { t } = useTranslation()
  const {
    typeformUrl,
    usageConditions
  } = useContext(ClientContext)

  const tabsData = [
    {
      title: t('investor_user.onboarding_extended.title_one'),
      subtitle: t('investor_user.onboarding_extended.step_one'),
      iconUnsolved: <CheckCircleOutlineIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('investor_user.onboarding_extended.title_two'),
      subtitle: t('investor_user.onboarding_extended.step_two'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('investor_user.onboarding_extended.title_three'),
      subtitle: t('investor_user.onboarding_extended.step_three'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('investor_user.onboarding_extended.title_four'),
      subtitle: t('investor_user.onboarding_extended.step_four'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('investor_user.onboarding_extended.title_five'),
      subtitle: t('investor_user.onboarding_extended.step_five'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('investor_user.onboarding_extended.title_six'),
      subtitle: t('investor_user.onboarding_extended.step_six'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('investor_user.onboarding_extended.title_seven'),
      subtitle: t('investor_user.onboarding_extended.step_seven'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('investor_user.onboarding_extended.finish'),
      subtitle: '',
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    }
  ]

  const finishOnboarding = () => {
    reloadInvestorUser(crudeDate)
    continueLater()
  }

  const getInvestorOnboarding = () => {
    if (isLoading) {
      return <></>
    }
    if (personType === 1) {
      return <OnboardingInvestorNormalPerson
        onClose={continueLater}
        initialValues={basicData}
        previousStep={previousStep}
        setData={setBasicData}
        nextStep={handleChangeStep}
      />
    }
    return <OnboardingInvestorSpecialPerson
      onClose={continueLater}
      initialValues={basicData}
      previousStep={previousStep}
      setData={setBasicData}
      nextStep={handleChangeStep}
    />
  }

  useEffect(() => {
    setBasicData(initialData)
    setPersonType(initialData?.classificationType || 0)
  }, [initialData])

  return (
    <MyCustomTab headersTab={tabsData} indexTab={indexTab}>
      <TabPanel key={0} value={indexTab} index={0}>
        <OnboardingInvestorTabOne
          onClose={continueLater}
          personType={personType}
          setValue={setPersonType}
          nextStep={handleChangeStep}
        />
      </TabPanel>
      <TabPanel key={1} value={indexTab} index={1}>
        {getInvestorOnboarding()}
      </TabPanel>
      <TabPanel key={2} value={indexTab} index={2}>
        { !isLoading &&
          <OnboardingInvestorAccreditation
            onClose={continueLater}
            initialValues={basicData}
            previousStep={previousStep}
            nextStep={handleChangeStep}
            setData={setBasicData}
          />
        }
      </TabPanel>
      <TabPanel key={3} value={indexTab} index={3}>
        <OnbardingInvestorStatus
          onClose={continueLater}
          previousStep={previousStep}
          nextStep={handleChangeStep}
          initialValue={basicData}
        />
      </TabPanel>
      <TabPanel key={4} value={indexTab} index={4}>
        <MainOnboardingWallet
          onClose={continueLater}
          nextStep={handleChangeStep}
          setWallet={setWallet}
          wallet={wallet}
          previousStep={previousStep}
        />
      </TabPanel>
      <TabPanel key={5} value={indexTab} index={5}>
        <Grid container spacing={2}>
          <Grid item sm={12} mb={2}>
            <MyButtonIcon
              size='small'
              icon='backArrow'
              onClick={previousStep}
              text={t('back')}
              type='secondary'
            />
          </Grid>
          <Grid item sm={12} >
            <KYC onSubmit={handleChangeStep} id={typeformUrl} />
          </Grid>
          <Grid item>
            <MyButton color='secondary' onMouseUp={continueLater}>
              {t('continue_later')}
            </MyButton>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel key={6} value={indexTab} index={6}>
        <Grid container spacing={2}>
          <Grid item sm={12} mb={2}>
            <MyButtonIcon
              size='small'
              icon='backArrow'
              onClick={previousStep}
              text={t('back')}
              type='secondary'
            />
          </Grid>
          <Grid item sm={12} mb={2}>
            <Paragraph type='subheading'><strong>{t('investor_user.onboarding_extended.title_seven')}</strong></Paragraph>
          </Grid>
          <Grid item sm={12} >
            <Conditions url={usageConditions} onSubmit={handleChangeStep} onClose={continueLater} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel key={7} value={indexTab} index={7}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Paragraph type='subheading'><strong>{t('investor_user.onboarding_extended.thanks')}</strong></Paragraph>
          </Grid>
          <Grid item sm={8}>
            <Paragraph>{t('investor_user.onboarding_extended.disclaimer_seven')}</Paragraph>
          </Grid>
          <Grid item sm={12} />
          <Grid item sm={2}>
            <MyButton
              fullWidth
              onMouseUp={finishOnboarding}
            >{t('exit')}</MyButton>
          </Grid>
        </Grid>
      </TabPanel>
    </MyCustomTab>
  )
}
