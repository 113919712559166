import { AcceptedAssetTable } from './Autorizar/Activos/AcceptedAssetTable'
import { RequestAssetTable } from './Autorizar/Activos/RequestAssetTable'
import { Grid } from '@mui/material'
import { Title } from '../../../components/Title'
import { TabsContainer } from '../../../components/DashboardTabs/TabsContainer'
import { useScrollToTop } from '../../../hooks/useScrollToTop'
import { useClientConfig } from '../../../hooks/useClientConfig'
import { useContext } from 'react'
import { AssetsContext } from '../../../contexts/AssetsContext'
import { useTranslation } from 'react-i18next'

export const AssetsMain = () => {
  const { filterData } = useContext(AssetsContext)
  const { assetTypeLabel } = useClientConfig()
  const [t, i18n] = useTranslation()
  useScrollToTop()

  const acceptedAssetsTitle =
    i18n.language === 'es'
      ? `${assetTypeLabel('plural', 'capitalize')} aprobados`
      : `Approved ${assetTypeLabel('plural', 'lowercase')}`
  const Tabs = [
    { title: acceptedAssetsTitle, component: <AcceptedAssetTable /> },
    {
      title: t('registration_request'),
      component: <RequestAssetTable />,
      notification: filterData.pendientes.filter((asset: any) => !!asset?.completedSteps).length
    }
  ]

  return (
    <Grid container mt={3} spacing={2}>
      <Grid item xs={12} sm={12} md={12} ml={6} mb={2}>
        <Title>{assetTypeLabel('plural', 'uppercase')}</Title>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TabsContainer tabs={Tabs} />
      </Grid>
    </Grid>
  )
}
