import * as yup from 'yup'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper } from '@mui/material'
import { Form, Formik } from 'formik'
import { MyButton } from '../../../../components/MyButton'
import { MyRadioGroup } from '../../../../components/MyRadioGroup'
import { fullWidth } from '../../../../layout/LayoutConfig'
import { H3 } from '../../../../components/MyHeaders'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useContext } from 'react'
import ClientContext from '../../../../contexts/ClientContext'
import { Paragraph } from '../../../../components/Paragraph'
import { useTranslation } from 'react-i18next'

interface Props {
  onSubmit: (values: any) => Promise<void>
  onClose: () => void
  initialValues: any
  previousStep: () => void
}

interface VdrInstructionsProps {
  title: string
  language: string
  description: string
}

export const DocumentationIssuer = ({ onSubmit, initialValues, onClose, previousStep }: Props) => {
  const { vdrInstructions }: VdrInstructionsProps[] | any = useContext(ClientContext)
  const { t, i18n } = useTranslation()

  const itemsVDR = [
    { label: t('company_user.onboarding.tradition_vdr'), value: 'tradicional' },
    { label: t('company_user.onboarding.link_vdr'), value: 'externo' }
  ]

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {() => (
        <Form>
          <Grid container spacing={2}>
            <Grid item {...fullWidth}>
              <Paragraph type='subheading' bold>{t('company_user.onboarding.subtitle_five')}</Paragraph>
            </Grid>
            <Grid item {...fullWidth}>
              <Paragraph>{t('company_user.onboarding.description_five')}</Paragraph>
            </Grid>
            <Grid item {...fullWidth}>
              <MyRadioGroup name='typeVdr' items={itemsVDR} />
            </Grid>
            <Grid item {...fullWidth}>
              {vdrInstructions?.filter(({ language }: { language: string }) => language === i18n.language).map((el: any, index: number) => (
                <React.Fragment key={index}>
                  {el.description
                    ? (
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {<H3>{el.title}</H3>}
                      </AccordionSummary>
                      <AccordionDetails>
                        <p>{el.description}</p>
                      </AccordionDetails>
                    </Accordion>
                      )
                    : (
                    <Paper
                      elevation={1}
                      style={{ padding: '16px', margin: '12px 0' }}
                    >
                      <H3>{el.title}</H3>
                    </Paper>
                      )}
                </React.Fragment>
              ))}
            </Grid>
            <Grid item>
              <MyButton onMouseUp={onClose} color='info'>{t('review_later')}</MyButton>
            </Grid>
            <Grid item>
              <MyButton onMouseUp={previousStep} color='secondary'>{t('back')}</MyButton>
            </Grid>
            <Grid item>
              <MyButton type='submit'>{t('next')}</MyButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

const validationSchema = yup.object().shape({
  typeVdr: yup.string().required('default')
})
