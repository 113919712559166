import { useTranslation } from 'react-i18next'
import { validDate } from '../../../../core/utils'

export const useAvailableStates = () => {
  const { t } = useTranslation()
  const AVAILABLE_STATES = [t('Disponible'), t('Próximamente'), t('Agotado')]

  return {
    AVAILABLE_STATES
  }
}

export interface FilterProps {
  availableStates: string[]
  min_valuation: number
  max_valuation: number
  min_price: number
  max_price: number
}

export const initialFilter: FilterProps = {
  min_valuation: 0,
  max_valuation: 0,
  min_price: 0,
  max_price: 0,
  availableStates: []
}

export const coreFilter = (filters: FilterProps, data: any[], orderBy: string, AVAILABLE_STATES: string[]) => {
  const {
    min_valuation,
    max_valuation,
    min_price,
    max_price,
    availableStates
  } = filters
  const max_valuation_cmp = Number(max_valuation) !== 0 ? Number(max_valuation) : 9000000000000000
  const max_price_cmp = Number(max_price) !== 0 ? Number(max_price) : 9000000000000000
  const available = availableStates.includes(AVAILABLE_STATES[0])
  const coming_soon = availableStates.includes(AVAILABLE_STATES[1])
  const sold_out = availableStates.includes(AVAILABLE_STATES[2])

  const filterData = data.filter(asset => {
    let statusPass = false
    const { status, price, token_price } = asset

    const rangePass = (price >= min_valuation && price <= max_valuation_cmp) &&
      (token_price >= min_price && token_price <= max_price_cmp)
    if ((!(available || sold_out || coming_soon)) ||
      (available && status === 'PUBLISHED') ||
      (coming_soon && status === 'ACCEPTED') ||
      (sold_out && status === 'SOLD_OUT')) statusPass = true
    return rangePass && statusPass
  })

  return filterData.sort((a, b) => {
    let fieldA, fieldB
    if (orderBy === 'des') {
      fieldA = a?.createdAt
      fieldB = b?.createdAt
    } else {
      fieldB = a?.createdAt
      fieldA = b?.createdAt
    }
    return validDate(new Date(fieldA)).getTime() - validDate(new Date(fieldB)).getTime()
  })
}
