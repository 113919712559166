import { useCallback, useContext } from 'react'
import { CardFront } from './CardFront'
import { ColorContext } from '../../contexts/ColorContext'
import { Skeleton } from '@mui/material'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const CardContainer = styled.div<{ isPreview: boolean }>`
    ${({ isPreview }) => isPreview ? '' : '&:hover { transform: scale(1.02) }'}
    transition: transform .2s ease;
    max-width: 423.6px;
    min-width: 353px;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0px 2px 3px  rgba(48, 63, 73, 0.4) !important;
    cursor: ${({ isPreview }) => isPreview ? 'default' : 'pointer'};
`

export const LoadingCard = styled(Skeleton)`
  border-radius: 8px;
  max-width: 423.6px;
  min-width: 353px;
  height: 353px;
`

export const AssetCard = ({ ...props }) => {
  const {
    onShowMore,
    isPreview
  } = props

  const showDetails = useCallback(() => {
    if (!(isPreview)) onShowMore(props)
  }, [isPreview, onShowMore, props])

  return (
        <CardContainer isPreview={isPreview} onClick={showDetails}>
            <CardFront {...props} />
        </CardContainer>
  )
}

const enum STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  PUBLISHED = 'PUBLISHED'
}
const COMING_SOON = 'Próximamente'
const AVAILABLE = 'Disponible'
const SOLD_OUT = 'Agotado'

export const AvailableLabelAndColor = (type: string) => {
  const { theme } = useContext(ColorContext)
  const { t } = useTranslation()

  let backgroundColor = ''; let text = ''
  if (type === STATUS.PENDING || type === STATUS.ACCEPTED) {
    backgroundColor = theme.warning
    text = t(COMING_SOON)
  } else if (type === STATUS.PUBLISHED) {
    backgroundColor = theme.success
    text = t(AVAILABLE)
  } else {
    backgroundColor = theme.danger
    text = t(SOLD_OUT)
  }

  return { backgroundColor, text }
}
