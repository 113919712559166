import { ColorPalette, colorThemeInitialValues } from '../contexts/ColorContext'
import { changeFavicon } from './changeFavicon'

/**
 * It takes an object, a string, and a value, and returns a new object with the value at the key of the
 * string.
 * @param {any} oldValues - the current state of the json object
 * @param {string} type - string - this is the key of the object you want to change
 * @param {any} newValue - any - this is the new value that you want to change
 * @returns The oldValues object with the newValue added to it.
 */
export const changeJsonState = (
  oldValues: any,
  type: string,
  newValue: any
) => {
  return { ...oldValues, [type]: newValue }
}

/**
 * It takes two numbers, divides them, and returns the result
 * @param {any} [price=0] - The price of the token
 * @param {any} [token_quantity=0] - number
 * @returns The price of the token.
 */
export const tokenPrice = (
  price: any = 0,
  token_quantity: any = 0
): number => {
  return price / token_quantity || 0
}

/**
 * It takes a string, removes all spaces, splits the string by the period, and returns the last item in
 * the array
 * @param {string} url - string - the url of the file
 * @returns The file extension of the url.
 */
export const getFileExtension = (url: string) => {
  const urlWithoutSpaces = url?.replace(/\s+/g, '')
  return urlWithoutSpaces?.split('.')?.pop()?.toLowerCase()
}

/**
 * It takes a value, adds the current seconds and a random string to it, then removes all
 * non-alphanumeric characters.
 * @param {any} value - any - The value you want to create an ID for.
 * @returns A unique id.
 */
export const createId = (value: any) => {
  const crypto = window.crypto || (window as any).msCrypto
  const uniqueId = value + new Date().getSeconds() + (+crypto.getRandomValues(new Uint32Array(1))).toString(36)
  return uniqueId.replace(/[^0-9a-zA-Z]+/g, '')
}

export const transformGroup = (
  group: 'admins' | 'clients' | 'issuers' | 'investors'
) => {
  if (group === 'admins') return 'Administrador QX'
  if (group === 'clients') return 'Administrador'
  if (group === 'issuers') return 'Empresa'
  if (group === 'investors') return 'Inversionista'
}

export const getLocale = () => {
  return (new Intl.NumberFormat()).resolvedOptions().locale
}

export enum CURRENCIES { CLP = 'CLP' }

export const formatCurrency = (value: number, currency: string, hidePrefix?: boolean) => {
  value = !value ? 0 : value
  if (!currency) {
    return ''
  }
  return `${hidePrefix ? '' : currency + ' '}${new Intl.NumberFormat(getLocale(), { style: 'currency', currency, currencyDisplay: 'narrowSymbol' }).format(value)}`
}

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat(getLocale()).format(value)
}

export const decimalAndGroupSeparator = () => {
  return {
    groupSeparator: Intl.NumberFormat(getLocale()).format(11111).replace(/\p{Number}/gu, ''),
    decimalSeparator: Intl.NumberFormat(getLocale()).format(1.1).replace(/\p{Number}/gu, '')
  }
}

export const colorParse = ({ color1, color2, color3 }: any): ColorPalette => {
  return { ...colorThemeInitialValues, primary: color1, secondary: color2, tertiary: color3 }
}

export const IS_PROD = process.env.REACT_APP_API_IS_PROD !== 'false'

/* Checking if the environment is production or sandbox. */
export const personaEnv: 'sandbox' | 'production' = !IS_PROD ? 'sandbox' : 'production'

export const walletUrlEnv: string =
  !IS_PROD
    ? 'https://testnet.snowtrace.io/address/'
    : 'https://snowtrace.io/address/'

export const transUrlEnv: string =
  !IS_PROD
    ? 'https://testnet.snowtrace.io/tx/'
    : 'https://snowtrace.io/tx/'

export const scUrlEnv: string =
  !IS_PROD
    ? 'https://testnet.snowtrace.io/address/'
    : 'https://snowtrace.io/address/'

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const REGEX_PASSWORD = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
export const REGEX_STRING_WITHOUT_NUMBERS = /^[^0-9]*$/

export const transformUserToSend = (users: any[]) => {
  return Array.from(users, user => {
    const { email, action } = user
    return { email, action }
  })
}

export const assetsTypeTransform = {
  EQUITIES: 'EQUITY',
  REAL_ESTATES: 'REAL_ESTATE',
  FUNDS: 'FUND'
}

export const assetsTypeToAssetType = (assetsType: 'EQUITIES' | 'REAL_ESTATES' | 'FUNDS') => {
  return assetsTypeTransform[assetsType]
}

/**
 * It takes a date string and returns a valid date object.
 * @param {any} date - any - The date you want to validate.
 * @returns A new Date object.
 */
export const validDate = (date: any) => {
  const timestamp = Date.parse(date)

  if (!isNaN(timestamp)) return new Date(timestamp)
  return new Date()
}

/**
 * It takes a file and a maxFileSize and returns if the file size is more than maxFileSize.
 * @param {File} file - File - The file you want to validate.
 * @param {number} maxFileSize - Number - The maximum size allowed.
 * @returns boolean.
 */
export const isFileSizeAllowed = (file: File, maxFileSize: number) => {
  const fileSizeInMB = file.size / (1024 * 1024) // Convert to MB
  return fileSizeInMB <= maxFileSize
}

export const isBivaPage = (flag: boolean) => {
  if (flag) {
    document.title = 'BIVA Mercado Privado'
    changeFavicon('favicon_biva.ico')
  } else {
    document.title = 'Orchextrade'
    changeFavicon('favicon_qx.ico')
  }
}
