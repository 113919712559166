import { useState, useContext, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ClientContext from '../../../../contexts/ClientContext'
import NotificationContext from '../../../../contexts/NotificationContext'
import { getIssuerById, updateUserCompany } from '../../../../core/apiIssuers'
import { getUserByEmail } from '../../../../core/apiUsers'
import { User } from '../../../../types/types'
import { UserMaster } from '../../../Shared/UserMaster/UserMaster'

export const BusinessUsersMaster = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState<User[]>([])
  const { client_id } = useContext(ClientContext)
  const { showToast } = useContext(NotificationContext)
  const { t } = useTranslation()
  const { state }: any = useLocation()

  const loadDataIssuer = useCallback(async () => {
    const resp = await getIssuerById(state.id, client_id)
    if (resp?.success) return resp.records?.[0].users
    return []
  }, [state.id])

  const loadDataUsers = useCallback(async (users: any[] = []) => {
    const completedUser = []
    for (let i = 0; i < users.length; i++) {
      const resp = await getUserByEmail(users[i])
      if (resp?.success) completedUser.push(resp.records?.[0])
    }
    setUsers(completedUser)
    setIsLoading(false)
  }, [])

  const fetchData = useCallback(async () => {
    const issuer_users = await loadDataIssuer()
    await loadDataUsers(issuer_users)
  }, [loadDataIssuer, loadDataUsers])

  const updateUsers = async (users_table: any[], userToChange: string, action: 'create' | 'delete', additionalData?: any) => {
    const transformUsers = Array.from(users_table, user => {
      if (user.email === userToChange) return { email: user.email, action }
      return { email: user.email, action: 'none' }
    })
    if (action === 'create') {
      const userValidation = await getUserByEmail(userToChange)
      if (!userValidation?.success) transformUsers.push({ ...additionalData, action })
      else {
        showToast('error', t('toasts.AUTH0001'))
        return
      }
      setUsers([...users, { ...additionalData }])
    } else if (action === 'delete') setUsers(users.filter(user => user.email !== userToChange))
    setIsLoading(true)
    await updateUserCompany(state.id, client_id, transformUsers)
    setIsLoading(false)
  }

  useEffect(() => {
    async function init () {
      await fetchData()
    }
    void init()
  }, [fetchData])

  return (
    <UserMaster
      isLoading={isLoading}
      users={users}
      updateData={updateUsers}
    />
  )
}
