import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useMultimedia } from '../../hooks/useMultimedia'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { getFileExtension } from '../../core/utils'

interface Props {
  assetData: any
}

export const AssetLogoPreview = ({ assetData }: Props) => {
  const { getMultimediaByFolder } = useMultimedia()
  const [files, setFiles] = useState<any[]>([])

  useEffect(() => {
    async function init () {
      const images = await getMultimediaByFolder(assetData?.issuer_id, assetData?.id, 'image')
      const videos = await getMultimediaByFolder(assetData?.issuer_id, assetData?.id, 'video')
      setFiles([...images, ...videos])
    }
    if (!assetData?.usingLogo) void init()
  }, [])

  return (
    <>
    { assetData?.usingLogo
      ? <Img
        src={assetData?.issuer_logo}
        alt=''
      />
      : <Carousel showThumbs={false} stopOnHover statusFormatter={() => ''}>
        { files.map((file: any) => {
          const extension = getFileExtension(file)
          if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
            return (
              <div key={file}>
                <Img src={file} />
              </div>)
          }
          return (
              <div key={file}>
                <Video src={file} controls/>
              </div>)
        })}
      </Carousel>
    }
    </>
  )
}

const Img = styled('img')`
    width: 100%;
    object-fit: contain;
    height: 480px;

    @media (max-width: 480px){
      height: 280px;
    }
`

const Video = styled('video')`
    width: 100%;
    object-fit: cover;
    height: 480px;
    object-fit: contain;

    @media (max-width: 480px){
      height: 280px;
    }
`
