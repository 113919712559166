import { Grid } from '@mui/material'
import { useContext } from 'react'
import { MyButton } from '../../components/MyButton'
import { Paragraph } from '../../components/Paragraph'
import { IdiomContext } from '../../contexts/IdiomContext'

interface Props {
  onFinish: () => void
}

export const ConfirmationPage = ({ onFinish }: Props) => {
  const {
    issuerCreationLabels,
    issuerCreationButtons
  } = useContext(IdiomContext)
  return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <h5><strong>{issuerCreationLabels.titleTabThree}</strong></h5>
            </Grid>
            <Grid item sm={12}>
                <Paragraph>{issuerCreationLabels.textTabThree}</Paragraph>
            </Grid>
            <Grid item xs={4}>
                <MyButton onMouseUp={onFinish}>
                    {issuerCreationButtons.finish}
                </MyButton>
            </Grid>
        </Grid>
  )
}
