import { Dialog, Grid, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { ReactNode } from 'react'

const Transition = React.forwardRef(
  function Transition (
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) { return <Slide direction="up" ref={ref} {...props} /> }
)

interface Props {
  open: boolean
  children: ReactNode
  closeDialog: () => void
  bg?: string
}

export const LayoutDialog = ({ open, closeDialog, children, bg = '' }: Props) => {
  return (
        <Dialog
            open={open}
            fullScreen
            aria-labelledby="form-dialog-title"
            TransitionComponent={Transition}
            PaperProps={{
              style: { backgroundColor: '' }
            }}
        >
            <Grid container alignItems='center'>
                {children}
            </Grid>
        </Dialog>
  )
}
