import styled from '@emotion/styled'
import React, { useContext } from 'react'
import { colorPalette } from '../../conf/ColorConfig'
import { DDMultiFilesContext } from './DDMultiFiles'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import NotificationContext from '../../contexts/NotificationContext'
import { useTranslation } from 'react-i18next'
import { handleImageCompression } from '../../core/imageCompression'
import { isFileSizeAllowed } from '../../core/utils'

export const Uploader = () => {
  const { acceptedTypes, id, setFiles, title, subtitle, maxFiles } = useContext(DDMultiFilesContext)
  const { showToast } = useContext(NotificationContext)
  const { t } = useTranslation()

  const onUploadFile = async ({ currentTarget, target }: React.ChangeEvent<HTMLInputElement>) => {
    if (currentTarget.files?.length) {
      const compressedFiles = []

      for (let i = 0; i < currentTarget.files.length; i++) {
        const file = currentTarget.files[i]
        compressedFiles.push(await handleImageCompression(file))
      }

      const newFiles = Array.from(compressedFiles) ?? []
      const sizeFlag = newFiles.every(file => isFileSizeAllowed(file, 50))
      if (!sizeFlag) {
        showToast('error', t('toasts.max_size_files'))
        return
      }
      setFiles(prev => {
        const filesToUpdated = [...prev, ...newFiles]
        if (filesToUpdated.length > maxFiles) {
          showToast('error', t('toasts.max_files'))
          return prev
        }
        return filesToUpdated
      })
    }
    if (target.value) {
      target.value = ''
    }
  }

  return (
    <Container>
      <AddAPhotoIcon htmlColor={colorPalette.basics.HEX_3D4D6A} fontSize='small'/>
      <p>{ title }</p>
      <p>{ subtitle }</p>
      <input
        type='file'
        id={id}
        className='dropzone'
        multiple
        onChange={onUploadFile}
        accept={acceptedTypes}
      />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 190px;
  height: 190px;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  border: 1px dashed ${colorPalette.basics.HEX_8F9BB3};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
    text-transform: uppercase;
    color: ${colorPalette.basics.HEX_3D4D6A}
  }

  .dropzone,
  .dropzone:focus {
    top: 0;
    bottom: 0;
    position: absolute;
    outline: none !important;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }
`
