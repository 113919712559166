import { useCallback, useContext, useState } from 'react'
import UserContext from '../contexts/UserContext'
import { deleteVDR, getVDR } from '../core/apiVDR'
import { VDRFile } from '../types/types'

export const useVDR = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState<VDRFile[]>([])
  const { VDR_ID } = useContext(UserContext)

  const getVdrByCompany = useCallback(async () => {
    setIsLoading(true)
    const vdrId = VDR_ID()
    const resp = await getVDR(vdrId)
    if (resp?.success) {
      const extraRecords = resp?.extraRecords ?? []
      setFiles([...resp?.records, ...extraRecords])
    } else {
      setFiles([])
    }
    setIsLoading(false)
  }, [VDR_ID])

  const deleteVdrFile = async (id: string, file: string, folder: string) => {
    setIsLoading(true)
    const resp = await deleteVDR(id, file, folder)
    console.log(resp)
    setIsLoading(false)
  }

  return {
    getVdrByCompany,
    deleteVdrFile,
    isLoading,
    files
  }
}
