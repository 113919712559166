import * as yup from 'yup'

export const buyAssetValidationSchema = yup.object().shape({
  tokens: yup.number()
    .typeError('')
    .integer('integer')
    .positive('positive')
    .min(yup.ref('minimun_token'), 'min_tokens')
    .max(yup.ref('maximum_token'), 'max_tokens')
    .required('required_field'),
  monto: yup.number()
    .typeError('')
    .min(yup.ref('minimum_amount'), 'min_tokens')
    .max(yup.ref('maximun_amount'), 'max_tokens')
    .required('required_field')
})
