import { UserInputProps } from './SharedLabels'

export interface AdminTranslateProps {
  createCompany: {
    tabs: Array<{ title: string, subtitle: string }>
    tabOne: {
      title: string
      subtitle: string
      legalRepresentativeTitle: string
      legalRepresentativeOne: UserInputProps
      legalRepresentativeTwo: UserInputProps
      companyName: string
      primaryButton: string
      secondaryButton: string
    }
    tabTwo: {
      title: string
      subtitle: string
      userInput: UserInputProps
      primaryButton: string
      secondaryButton: string
    }
    tabThree: {
      title: string
      subtitle: string
      primaryButton: string
    }
  }
}

export const AdminES: AdminTranslateProps = {
  createCompany: {
    tabs: [
      { title: 'Datos de empresa', subtitle: 'Paso 1 de 3' },
      { title: 'Usuarios de empresa', subtitle: 'Paso 2 de 3' },
      { title: 'Confirmación', subtitle: 'Paso 3 de 3' }
    ],
    tabOne: {
      title: 'Datos de empresa',
      subtitle: 'Por favor ingresa los datos necesarios para dar de alta a una nueva empresa',
      legalRepresentativeTitle: 'Datos del representante legal',
      companyName: 'Nombre de la empresa',
      legalRepresentativeOne: {
        fatherLastName: 'Apellido paterno',
        label: 'Representante legal 1 (opcional)',
        motherLastName: 'Apellido materno',
        name: 'Nombre(s)'
      },
      legalRepresentativeTwo: {
        fatherLastName: 'Apellido paterno',
        label: 'Representante legal 2 (opcional)',
        motherLastName: 'Apellido materno',
        name: 'Nombre(s)'
      },
      primaryButton: 'Siguiente',
      secondaryButton: 'Cancelar'
    },
    tabTwo: {
      title: 'Usuarios de empresa',
      subtitle: 'Por favor ingresa los datos necesarios para crear los usuarios de la empresa',
      userInput: {
        fatherLastName: 'Apellido paterno',
        label: '',
        email: 'Correo electrónico',
        motherLastName: 'Apellido materno',
        name: 'Nombre(s)'
      },
      primaryButton: 'Terminar',
      secondaryButton: 'Cancelar'
    },
    tabThree: {
      title: 'Confirmación de registro',
      subtitle: 'Hemos enviado un link de confirmación al correo electrónico principal del cliente. Una vez confirme su correo, el cliente podrá empezar a llenar sus datos y completar su registro.',
      primaryButton: 'Salir'
    }
  }
}

export const AdminEN: AdminTranslateProps = {
  createCompany: {
    tabs: [
      { title: 'Company data', subtitle: 'Step 1 of 3' },
      { title: 'Business users', subtitle: 'Step 2 of 3' },
      { title: 'Confirmation', subtitle: 'Step 3 of 3' }
    ],
    tabOne: {
      title: 'Company data',
      subtitle: 'Please enter the necessary data to register a new company.',
      legalRepresentativeTitle: 'Legal representative data',
      legalRepresentativeOne: {
        fatherLastName: 'Paternal surname',
        label: 'Legal representative 1 (optional)',
        motherLastName: 'Mother\'s last name',
        name: 'Names(s)'
      },
      legalRepresentativeTwo: {
        fatherLastName: 'Paternal surname',
        label: 'Legal representative 2 (optional)',
        motherLastName: 'Mother\'s last name',
        name: 'Names(s)'
      },
      companyName: 'Company name',
      primaryButton: 'Next',
      secondaryButton: 'Cancel operation'
    },
    tabTwo: {
      title: 'Company users',
      subtitle: 'Please enter the necessary data to create the company\'s users.',
      userInput: {
        fatherLastName: 'Paternal surname',
        label: '',
        email: 'Email',
        motherLastName: 'Mother\'s last name',
        name: 'Names(s)'
      },
      primaryButton: 'Next',
      secondaryButton: 'Cancel operation'
    },
    tabThree: {
      title: 'Registration confirmation',
      subtitle: 'We have sent a confirmation link to the customer\'s main email address. Once the customer confirms their email, they will be able to start filling in their data and complete their registration.',
      primaryButton: 'Finish'
    }
  }
}
