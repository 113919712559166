import styled from '@emotion/styled'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph } from './Paragraph'

interface Props {
  user: any
  isDrawerOpen: boolean
}

const tagStyle = (user: string) => {
  if (user === 'admins') return { background: '#F6D214', border: '#FFEC89' }
  if (user === 'clients') return { background: '#F69C14', border: '#FFCB7D' }
  if (user === 'issuers') return { background: '#4BBF48', border: '#86FF83' }
  return { background: '#598BFE', border: '#7BDBFF' }
}

const ContentText = styled(Paragraph)`
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const UserIconProps = {
  htmlColor: '#D9D9D9',
  sx: { fontSize: 40 }
}

const Container = styled.div`
  display: flex;
  color: white;
  gap: 0.7rem;
`

export const Tag = styled.div<{ background: string, border: string }>`
  background-color: ${props => props.background};
  border-radius: 3px;
  font-weight: bold;
  margin-top: 0.3rem;
  padding: 5px 10px;
  text-align: center;
  width: fit-content;
`

export const UserSidebarData = React.memo(({ user, isDrawerOpen }: Props) => {
  const [t] = useTranslation()
  const rol = user?.group
  const { background } = tagStyle(rol)
  return (
    <Container>
      <div style={{ marginTop: '0.4rem' }}>
        <AccountCircleIcon {...UserIconProps} style={{ border: `${background} 4px solid`, borderRadius: '50%' }} />
      </div>
      { isDrawerOpen &&
        <div>
          <Paragraph colorType='white' type='small'>
            <strong>
              {`${user?.first_names || ''} ${user?.fathers_name || ''}`}
            </strong>
          </Paragraph>
          <ContentText type='caption' colorType='white'>{user?.email}</ContentText>
          <ContentText type='caption' colorType='white'>{t(rol)}</ContentText>
        </div>
      }
    </Container>
  )
})
