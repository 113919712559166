import { timezoneFormatWithHour } from '../../../../core/timezoneFormat'
import { formatCurrency } from '../../../../core/utils'
import { Transaction } from '../../../../types/types'
import { CodingPurchaseState, PurchaseStatusTranslated, parseStatus } from './CodingPurchaseState'
import { TransactionButton } from './TransactionButton'

export interface FilterTransactionsProps {
  pending: Array<{ [key: string]: Object }>
  executed: Array<{ [key: string]: Object }>
  approved: Array<{ [key: string]: Object }>
  rejected: Array<{ [key: string]: Object }>
}

export const filterTransactions =
  (
    transactions: Transaction[],
    timezone: string
  ): FilterTransactionsProps => {
    const filter_data: FilterTransactionsProps = { pending: [], executed: [], approved: [], rejected: [] }
    for (let i = 0; i < transactions.length; i++) {
      const { asset, issuer, amount, pay_confirmed, authorized } = transactions[i]
      const trnHash = transactions[i]?.operations?.[0]?.hash ?? ''
      const status = parseStatus(pay_confirmed, authorized)

      let date = ''
      if (status === PurchaseStatusTranslated.executed) {
        date = timezoneFormatWithHour(timezone, transactions[i].payConfirmedStatusChangedAt)
      } else if (status === PurchaseStatusTranslated.pending) {
        date = timezoneFormatWithHour(timezone, transactions[i].createdAt)
      } else if (status === PurchaseStatusTranslated.approved) {
        date = timezoneFormatWithHour(timezone, transactions[i].authorizedStatusChangedAt)
      } else {
        if (transactions[i].pay_confirmed === PurchaseStatusTranslated.rejected) {
          date = timezoneFormatWithHour(timezone, transactions[i].payConfirmedStatusChangedAt)
        } else {
          date = timezoneFormatWithHour(timezone, transactions[i].authorizedStatusChangedAt)
        }
      }

      filter_data[status].push({
        name: asset.name,
        transactionKey: <TransactionButton idBlockchain={trnHash} />,
        issuer: issuer.company_name,
        token_price: formatCurrency(asset.token_price, asset?.currency, true),
        currency: asset?.currency,
        price_format: formatCurrency(amount, asset?.currency, true),
        date,
        status: <CodingPurchaseState pay_confirmed={pay_confirmed} authorized={authorized}/>
      })
    }
    return filter_data
  }
