import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { MyTransition } from './MyTransition'
import { MyButton } from './MyButton'
import { Paragraph } from './Paragraph'

interface Props {
  open: boolean
  onClose: () => void
  title: string
  twoButtons?: boolean
  cancel?: string
  text: string
  accept: string
  onAccept: () => void
}

export const MyModal = ({
  open,
  onClose,
  title,
  twoButtons = false,
  cancel,
  text,
  accept,
  onAccept
}: Props) => {
  return (
        <Dialog
            open={open}
            TransitionComponent={MyTransition}
            onClose={onClose}
            PaperProps={{ style: { padding: 15 } }}
        >
            <DialogTitle style={{ textAlign: 'center' }}>
                <Paragraph type='subheading'><strong>{ title }</strong></Paragraph>
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <Paragraph> {text} </Paragraph>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2} justifyContent='center'>
                    {
                        twoButtons
                          ? <>
                            <Grid item sm={7}>
                                <MyButton
                                    fullWidth
                                    onMouseUp={onAccept}
                                >{accept}
                                </MyButton>
                            </Grid>
                            <Grid item sm={7}>
                                <MyButton
                                    fullWidth
                                    color='secondary'
                                    onMouseUp={onClose}
                                >{cancel}</MyButton>
                            </Grid>
                        </>
                          : <Grid item sm={7}>
                            <MyButton
                                fullWidth
                                onMouseUp={onAccept}
                            >{accept}
                            </MyButton>
                        </Grid>
                    }
                </Grid>
            </DialogActions>
        </Dialog>
  )
}

export const MyModalWithoutIcon = ({
  open,
  onClose,
  title,
  twoButtons = false,
  cancel,
  text,
  accept,
  onAccept
}: Props) => {
  return (
        <Dialog
            open={open}
            TransitionComponent={MyTransition}
            onClose={onClose}
        >
            <DialogTitle style={{ color: 'white' }}>
                <Grid container textAlign='center' pb={1} px={1} pt={4}>
                    <Grid item sm={12}> {title} </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ padding: 20 }}>
                <p> {text} </p>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-start' }}>
                {
                    twoButtons
                      ? <Grid container spacing={1}>
                            <Grid item sm={4}>
                                <MyButton
                                    fullWidth
                                    onMouseUp={onClose}
                                    color='secondary'
                                >{cancel}
                                </MyButton>
                            </Grid>
                            <Grid item sm={4}>
                                <MyButton
                                    fullWidth
                                    onMouseUp={onAccept}
                                >{accept}</MyButton>
                            </Grid>
                        </Grid>
                      : <MyButton
                            fullWidth
                            onMouseUp={onAccept}
                        >{accept}
                        </MyButton>
                }
            </DialogActions>
        </Dialog>
  )
}
