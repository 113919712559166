import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { UserSidebarData } from '../../components/UserSidebarData'
import { SidebarTabs, SidebarTabsProps } from './SidebarTabs'
import { ColorContext } from '../../contexts/ColorContext'
import ClientContext from '../../contexts/ClientContext'
import SettingsIcon from '@mui/icons-material/Settings'
import UserContext from '../../contexts/UserContext'
import { List, Toolbar, IconButton } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { MaterialIcon } from './SidebarOptions'
import { useNavigate } from 'react-router-dom'
import logoQx from '../../assets/logo-primary-30x217.png'
import styled from '@emotion/styled'
import { useContext, useEffect } from 'react'
import { walletUrlEnv } from '../../core/utils'
import { Paragraph } from '../../components/Paragraph'
import { DesktopSidebar } from './DesktopSidebar'
import { useResponsive } from '../../hooks/useResponsive'

export interface SidebarProps {
  options: SidebarTabsProps[]
  show: boolean
  setShow: any
}

const SidebarContainer = styled.div<{ show: boolean }>`
  background-color: ${props => props.color};
  position: relative;
  width: 322px;
  flex-shrink: 0;
  clip-path: ${props => props.show ? 'polygon(100% 0, 0 0, 0 100%, 100% 100%)' : 'polygon(0 0, 0 0, 0 100%, 0% 100%)'};
  transition: width .5s ease, clip-path .5s ease;

  @media (max-width: 480px) {
    min-height: 100%;
    width: ${props => props.show ? '100%' : '0px'};
    z-index: 3000;
  }
`

const ContainerOptions = styled.div`
  height: 100vh;
  position: fixed;
  width: inherit;
  @media (max-width: 480px) {
    position: relative;
    width: 100%;
  }
`

const SidebarFooter = styled.div`
  padding-left: 1.5rem;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 20px;

  @media (max-width: 480px) {
    bottom: 0;
  }
`

const MyIconButton = styled(IconButton)`
  color: white;
  padding-top: 50px;

  @media (min-width: 480px) {
    display: none;
  }
`

export const SideBar = ({ options, show, setShow }: SidebarProps) => {
  const { isMobileDevice, isTabletDevice } = useResponsive()
  const { userRol, user, handleLogOutUser } = useContext(UserContext)
  const { client, clientId } = useContext(ClientContext)
  const { theme } = useContext(ColorContext)
  const { primary } = theme
  const navigate = useNavigate()

  const logOutFunction = () => {
    handleLogOutUser()
    navigate(`/${clientId() || 'admin-qx'}`, { replace: true })
  }

  const routeToConfiguration = () => {
    navigate('/Dashboard/Configuration')
  }

  const routeToWallet = () => {
    window.open(`${walletUrlEnv}${user?.user?.wallets?.[0]?.address}#tokentxns`)
  }

  const redirectLogo = () => {
    const rol = userRol()
    if (rol !== 'investors') navigate(`/Dashboard/${rol}`)
    else navigate('/Dashboard/investors-purchases')
  }

  const hideSidebar = () => {
    if (window.screen.width <= 480) setShow(false)
  }

  useEffect(() => {
    if (isTabletDevice) setShow(false)
    else if (!isMobileDevice) setShow(true)
  }, [isTabletDevice])

  return (
    <>
    { !isMobileDevice
      ? <DesktopSidebar options={options} open={show} setOpen={setShow} />
      : <SidebarContainer show={show} color={primary}>
            <ContainerOptions>
                { show
                  ? <>
                    <Toolbar style={{ justifyContent: 'center' }}>
                        <div style={{ display: 'flex', gap: 10 }} onClick={hideSidebar}>
                            <img
                                style={{
                                  maxWidth: 200,
                                  objectFit: 'cover',
                                  padding: '50px 0px 20px 0px',
                                  cursor: 'pointer'
                                }}
                                src={
                                    userRol() === 'admins'
                                      ? logoQx
                                      : client?.client?.files?.logo?.path || ''
                                }
                                onClick={redirectLogo}
                                alt="Cargando..."
                            />
                            <MyIconButton onClick={hideSidebar}>
                                <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
                            </MyIconButton>
                        </div>
                    </Toolbar>
                    <Toolbar style={{ margin: '30px 0 25px 0' }}>
                        <UserSidebarData user={user?.user} isDrawerOpen={show} />
                    </Toolbar>
                    <div onClick={hideSidebar}>
                        <List>
                            {options.map((option: SidebarTabsProps, index: number) =>
                                <SidebarTabs {...option} key={index} isDrawerOpen={show} />
                            )}
                        </List>
                    </div>
                    <SidebarFooter onClick={hideSidebar}>
                        {user?.user?.group === 'investors'
                          ? <ButtonBottom
                                title='Wallet'
                                Icon={<AccountBalanceWalletOutlinedIcon {...MaterialIcon} />}
                                onClick={routeToWallet}
                                notPointer={!user?.user?.wallets?.[0]?.address}
                            />
                          : <></>
                        }
                        <ButtonBottom
                            notPointer={false}
                            title='Configuración'
                            Icon={<SettingsIcon {...MaterialIcon} />}
                            onClick={routeToConfiguration}
                        />
                        <ButtonBottom
                            notPointer={false}
                            title='Cerrar sesión'
                            Icon={<LogoutIcon {...MaterialIcon} />}
                            onClick={logOutFunction}
                        />
                    </SidebarFooter>
                    </>
                  : <></>
                }
            </ContainerOptions>
        </SidebarContainer >
      }
    </>
  )
}

const MyWrapper = styled.div<{ $notPointer: boolean }>`
  display: flex;
  gap: 1rem;
  cursor: ${props => props.$notPointer ? 'not-allowed' : 'pointer'};
  color: white;
  align-items: center;

  &:nth-last-of-type(-n + 1){
    padding-bottom: 1rem;
  }
`
const ButtonBottom = ({ title, onClick, Icon, notPointer }: any) => (
  <MyWrapper onClick={!notPointer ? onClick : () => { }} $notPointer={notPointer}>
    {Icon}
    <Paragraph colorType='white' type='small'>
      {title}
    </Paragraph>
  </MyWrapper>
)
