import styled from '@emotion/styled'
import React, { createContext } from 'react'
import { FileList } from './FileList'
import { Uploader } from './Uploader'

interface Props {
  setFiles: React.Dispatch<React.SetStateAction<Array<File | string>>>
  files: Array<File | string>
  acceptedTypes: 'image/png, image/jpeg, image/jpg' | 'video/*'
  id: string
  title: string
  subtitle: string
  maxFiles: number
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const DDMultiFilesContext = createContext({} as Props)

export const DDMultiFiles = ({ ...props }: Props) => {
  return (
    <DDMultiFilesContext.Provider value={props}>
      <Container hasFiles={props.files.length > 0}>
        <FileList />
        <Uploader />
      </Container>
    </DDMultiFilesContext.Provider>
  )
}

const Container = styled.div<{ hasFiles: boolean }>`
  display: grid;
  grid-template-columns:
    ${({ hasFiles }) => hasFiles
      ? 'repeat(auto-fill, minmax(190px, 1fr))'
      : 'repeat(auto-fill, minmax(100%, 1fr))'};
  grid-auto-rows: auto;
  gap: 1rem;
`
