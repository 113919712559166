import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MyButton } from '../../../../../components/MyButton'
import { Paragraph } from '../../../../../components/Paragraph'
import ClientContext from '../../../../../contexts/ClientContext'
import { formatCurrency } from '../../../../../core/utils'
import { LabelModalRow } from '../Activos/AssetBuyReview'

interface Props {
  onClose: () => void
  investorData: any
  changeStatus: (values: any, status: 'ACCEPTED' | 'REJECTED') => Promise<any>
}

export const AuthorizeSpecialInvestorForm = ({ onClose, investorData, changeStatus }: Props) => {
  const { currencyOptions } = useContext(ClientContext)
  const [t] = useTranslation()
  const legalRepresentative = investorData?.legal_representative
  const legalOne = `${legalRepresentative?.[0]?.first_names} ${legalRepresentative?.[0]?.fathers_name} ${legalRepresentative?.[0]?.mothers_name}`
  const legalTwo = `${legalRepresentative?.[1]?.first_names} ${legalRepresentative?.[1]?.fathers_name} ${legalRepresentative?.[1]?.mothers_name}`
  return (
    <Formik
      initialValues={investorData}
      onSubmit={async () => await changeStatus(investorData, 'ACCEPTED')}
    >
      {() => {
        return (
          <Form>
            <Grid container spacing={1} p={4}>
              <Grid item xs={12} sm={12} textAlign='center'>
                <Paragraph bold>{t('request_investor.title')}</Paragraph>
              </Grid>
              <Grid item xs={6} sm={6} md={6} textAlign='right'>
                <Paragraph bold>{t('request_investor.subtitle')}</Paragraph>
              </Grid>
              <Grid item xs={12} />
              <LabelModalRow title={t('company_name')} label={investorData?.first_names} />
              <LabelModalRow title={t('country')} label={investorData?.country} />
              <LabelModalRow title={t('person_type')} label={investorData?.personType} labelStyle='capitalize' />
              <LabelModalRow title={t('request_investor.desired_investment')} label={formatCurrency(investorData?.desired_investment ?? 0, currencyOptions[0])} />
              <LabelModalRow title={t('asset_under_management')} label={formatCurrency(investorData?.total_previous_investment ?? 0, currencyOptions[0])} />
              <LabelModalRow title={t('min_investment_per_ticket_without_optional')} label={formatCurrency(investorData?.min_investment ?? 0, currencyOptions[0])} />
              <LabelModalRow title={t('request_investor.investment_background')} label={investorData?.investment_background} />
              <LabelModalRow title={t('legal_representative_one')} label={legalOne} />
              <LabelModalRow title={t('legal_representative_two')} label={legalTwo} />
              <Grid container justifyContent='center' mt={2} spacing={1}>
                <Grid item>
                  <MyButton color='info' onMouseUp={onClose}>
                    {t('review_later')}
                  </MyButton>
                </Grid>
                <Grid item>
                  <MyButton
                    color='secondary'
                    onMouseUp={async () => await changeStatus(investorData, 'REJECTED')}
                  >
                    {t('reject_request')}
                  </MyButton>
                </Grid>
                <Grid item>
                  <MyButton type='submit'>
                    {t('approve_request')}
                  </MyButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
