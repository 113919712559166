import { Accordion, AccordionSummary, Grid, AccordionDetails, Paper } from '@mui/material'
import { useContext } from 'react'
import { Paragraph } from '../../../../components/Paragraph'
import ClientContext from '../../../../contexts/ClientContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { H3 } from '../../../../components/MyHeaders'

export const DocumentsDetails = () => {
  const { vdrInstructions } = useContext(ClientContext)
  const { t, i18n } = useTranslation()
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} mb={2}>
        <Paragraph>{t('documentation_details')}</Paragraph>
      </Grid>
      { vdrInstructions.filter(({ language }: { language: string }) => language === i18n.language).map((el: any, index: number) =>
        <Grid item sm={12} key={index}>
          {el?.description
            ? (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {<H3>{el?.title}</H3>}
              </AccordionSummary>
              <AccordionDetails>
                <p>{el?.description}</p>
              </AccordionDetails>
            </Accordion>
              )
            : (
            <Paper
              elevation={1}
              style={{ padding: '16px', margin: '12px 0' }}
            >
              <H3>{el?.title}</H3>
            </Paper>
              )}
        </Grid>
      )}
    </Grid>
  )
}
