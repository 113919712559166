import { useContext, useState } from 'react'
import { assetEquityFormValidationSchema, assetFormValidationSchema } from '../../../../validations/assetFormValidationSchema'
import { MyButtonIcon, MyTextInput } from '../../../../components'
import { ExtraDataComponent } from './ExtraDataComponent'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { MyTextArea } from '../../../../components/MyTextArea'
import { MyButton } from '../../../../components/MyButton'
import { MyNumberFormat } from '../../../../components/MyNumberFormat'
import { AssetFormContext } from './DialogAssetForm'
import { TooltipText } from '../../../../design-system-components'
import { EquityCheckboxes } from './EquityCheckboxes'
import { Paragraph } from '../../../../components/Paragraph'
import { useScrollToTop } from '../../../../hooks/useScrollToTop'
import { useTranslation } from 'react-i18next'
import { ValuationCheckboxes } from './ValuationCheckboxes'
import { ASSET_TYPE } from '../../../../types/types'

interface Props {
  data: any
  onClose: () => void
  onSubmit: (values: any) => void
}

export const AssetForm = ({ data, onClose, onSubmit }: Props) => {
  const { assetType, assetCurrency, previousStep } = useContext(AssetFormContext)
  const [extraData, setExtraData] = useState(data.additional_data)
  const { t, i18n: { language } } = useTranslation()
  useScrollToTop()

  const assetName = language === 'es'
    ? `Nombre de${assetType === ASSET_TYPE.EQUITY ? ' la oferta' : 'l activo'}`
    : `Name of ${assetType === ASSET_TYPE.EQUITY ? 'the offer' : 'the asset'}`
  const valuationLabel = language === 'es'
    ? `Valuación${assetType === ASSET_TYPE.EQUITY ? ' (capital)' : ''}`
    : `Valuation${assetType === ASSET_TYPE.EQUITY ? ' (equity value)' : ''}`
  return (
    <Formik
      initialValues={data}
      validationSchema={assetType !== ASSET_TYPE.EQUITY ? assetFormValidationSchema : assetEquityFormValidationSchema}
      onSubmit={(values) => { onSubmit({ ...values, additional_data: [...extraData] }) }}
    >
      {({ setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item sm={12} mb={2}>
              <MyButtonIcon
                  size='small'
                  icon='backArrow'
                  onClick={previousStep}
                  text={t('back')}
                  type='secondary'
              />
            </Grid>
            <Grid item sm={12} mb={2}>
              <Paragraph type='subheading'><strong>{t('company_user.create_asset.title_two')}</strong></Paragraph>
            </Grid>
            <Grid item sm={12}>
              <Paragraph>{t('company_user.create_asset.subtitle_two')}</Paragraph>
            </Grid>
            <Grid item sm={8} xs={12}>
              <MyTextInput name="name" label={assetName} />
            </Grid>
            <Grid item sm={8} xs={12}>
              <MyNumberFormat
                currency={assetCurrency}
                name="price"
                label={valuationLabel}
              />
                 { assetType === ASSET_TYPE.EQUITY && <TooltipText>{ t('company_user.create_asset.equity_tooltip') }</TooltipText> }
            </Grid>
            <Grid item sm={8} xs={12}>
              { assetType === ASSET_TYPE.EQUITY
                ? <ValuationCheckboxes setFieldValue={setFieldValue} />
                : null
              }
            </Grid>
            {assetType === ASSET_TYPE.EQUITY
              ? null
              : <Grid item sm={8} xs={12}>
                <MyNumberFormat
                  currency={assetCurrency}
                  name="bidAmount"
                  label={t('bid_amount')}
                />
              </Grid>
            }
            <Grid item sm={8} xs={12}>
              <MyTextArea label={t('description')} name="description" />
            </Grid>
            <Grid item sm={12}>
              <Paragraph bold>{ t('company_user.create_asset.front_labels') }</Paragraph>
            </Grid>
            <Grid item sm={8} xs={12}>
              <ExtraDataComponent
                setData={setExtraData}
                data={extraData}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              { assetType === ASSET_TYPE.EQUITY && <EquityCheckboxes /> }
            </Grid>
            <Grid item>
              <MyButton
                color='secondary'
                onMouseUp={onClose}
              >
                {t('cancel')}
              </MyButton>
            </Grid>
            <Grid item>
              <MyButton type="submit">
                {t('next')}
              </MyButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
