import { useState, useEffect } from 'react'
import { useResponsive } from './useResponsive'

export const useAssetDashboardQuantity = () => {
  const [quantity, setQuantity] = useState(6)
  const { isDesktopDevice, isExtraBigDevice, isMoreThanExtraBigDevice } = useResponsive()

  useEffect(() => {
    if (isDesktopDevice) setQuantity(6)
    else if (isExtraBigDevice) setQuantity(8)
    else if (isMoreThanExtraBigDevice) setQuantity(10)
  }, [isDesktopDevice, isExtraBigDevice, isMoreThanExtraBigDevice])

  return {
    quantity
  }
}
