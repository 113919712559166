import { Form, Formik, FormikValues } from 'formik'
import * as yup from 'yup'
import { Accordion, AccordionSummary, Grid } from '@mui/material'
import { MyTextInput, MyUploadInput } from '../../../../components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import { MyButton } from '../../../../components/MyButton'
import { Client } from '../../../../types/types'

interface Props {
  initialValues: Client | any
  handleSubmit: (values: any) => void
  onClose: (values: FormikValues) => void
}

export const StepOneEditClient = ({ handleSubmit, onClose, initialValues }: Props) => {
  return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {() => {
              return (
                    <Form>
                        <h5>Editar datos del cliente</h5>
                        <Grid container spacing={2} mb={2} mt={1}>
                            <Grid item sm={12} xs={12}>
                                <MyTextInput
                                    label='Nombre de cliente'
                                    name='name'
                                    disabled
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <h5>Datos del usuario principal</h5>
                            </Grid>
                            <Grid item sm={5} xs={12}>
                                <MyTextInput
                                    label='Correo electrónico'
                                    name='mainEmail'
                                    placeholder='usuario@ejemplo.com'
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mb={2}>
                            <Grid item sm={12}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <h5>Datos del usuario 2 (opcional)</h5>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item sm={5} xs={12}>
                                                <MyTextInput
                                                    label='Correo electrónico'
                                                    name='secondEmail'
                                                    placeholder='usuario@ejemplo.com'
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item sm={12}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <h5>Datos del usuario 3 (opcional)</h5>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item sm={5} xs={12}>
                                                <MyTextInput
                                                    label='Correo electrónico'
                                                    name='threeEmail'
                                                    placeholder='usuario@ejemplo.com'
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} sm={12}>
                                <MyTextInput
                                    name='wallet'
                                    label='Dirección de la wallet del cliente'
                                    placeholder='Ingresa la wallet del cliente'
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <MyTextInput
                                    name='private_key'
                                    label='Llave privada de la wallet'
                                    placeholder='0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFEBAAEDCE6AF48A03BBFD25E8CD0364140'
                                />
                            </Grid>
                            <Grid item sm={4} md={4} xs={12}>
                                <MyUploadInput
                                    name='logo'
                                    title='Sube el logotipo del cliente'
                                    labelText='Arrastra tu archivo aquí o haz click para subirlo'
                                    accept={['.jpg', '.png']}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item sm={4}>
                                <MyButton color='secondary' fullWidth onMouseUp={onClose}>
                                    Cancelar
                                </MyButton>
                            </Grid>
                            <Grid item sm={4}>
                                <MyButton fullWidth type='submit'>
                                    Siguiente
                                </MyButton>
                            </Grid>
                        </Grid>
                    </Form>
              )
            }}
        </Formik>
  )
}

const validationSchema = yup.object().shape({
  logo: yup.string().required('default'),
  wallet: yup.string().required('default'),
  private_key: yup.string().required('default')
})
