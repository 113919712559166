import { createContext } from 'react'
import { useAcceptances } from '../hooks/useAcceptances'

export const AcceptancesContext = createContext<any>({})

export const AcceptancesProvider = ({ children }: any) => {
  const AcceptancesHook = useAcceptances()
  return (
    <AcceptancesContext.Provider value={AcceptancesHook}>
      { children }
    </AcceptancesContext.Provider>
  )
}
