import { personTypeValidationSchema } from '../../../../validations/personTypeValidationSchema'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { MyButton } from '../../../../components/MyButton'
import { MySelect } from '../../../../components'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { useContext } from 'react'
import { sendEvent } from '../../../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../../../core/GA/GoogleAnalyticsEvents'
import { Paragraph } from '../../../../components/Paragraph'
import { useTranslation } from 'react-i18next'

interface Props {
  nextStep: (values: any) => void
  personType: any
  setValue: (value: any) => void
  onClose: () => void
}

export const OnboardingInvestorTabOne = ({ nextStep, setValue, onClose, personType }: Props) => {
  const { actualTerm } = useContext(IdiomContext)
  const { t } = useTranslation()

  const onSubmit = (data: any) => {
    sendEvent(GA_EVENTS.onb_start)
    const classificationType = parseInt(data.personType)
    setValue(classificationType)
    nextStep({ classificationType })
  }

  return (
    <Formik
      initialValues={{ personType }}
      validationSchema={personTypeValidationSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form style={{ minHeight: 'inherit' }}>
            <Grid container direction="column" justifyContent="space-between" minHeight='inherit'>
              <Grid container spacing={2}>
                <Grid item sm={12} mb={2}>
                  <Paragraph type='subheading'><strong>{t('investor_user.onboarding_extended.subtitle_one')}</strong></Paragraph>
                </Grid>
                <Grid item sm={12}>
                  <Paragraph>{t('investor_user.onboarding_extended.description_one')}</Paragraph>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MySelect
                    name='personType'
                    labelText={t('person_classification')}
                    default=''
                    items={actualTerm}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} sm={4}>
                  <MyButton color='secondary' onMouseUp={onClose} fullWidth>
                    {t('continue_later')}
                  </MyButton>
                </Grid>
                <Grid item xs={12} md={3} sm={4}>
                  <MyButton type='submit' fullWidth>
                    {t('next')}
                  </MyButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
