import { setupClientValidationSchema } from '../../../validations/setupClientValidationSchema'
import { MySelect, MyTextInput, MyToggle, MyUploadInput } from '../../../components'
import terminology from '../../../data/terminology-data.json'
import assetTypes from '../../../data/asset-types.json'
import utf from '../../../data/utf.json'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { MyButton } from '../../../components/MyButton'
import { MyTextArea } from '../../../components/MyTextArea'
import MyDynamicDataGenerator, { DynamicData } from '../../../components/LinkGenerators/MyDynamicDataGenerator'
import { useState } from 'react'
import { MyInputColor } from '../../../components/MyInputColor'
import { Paragraph } from '../../../components/Paragraph'

interface Props {
  initialValues: any
  handleSubmit: (values: any) => void
  previousStep: () => void
}

export const SetupClient = ({ initialValues, handleSubmit, previousStep }: Props) => {
  const [disclaimer, setDisclaimer] = useState<DynamicData[] | any>([])
  const [buyData, setBuyData] = useState<DynamicData[] | any>([])
  const [vdrInstructions, setVdrInstructions] = useState<DynamicData[] | any>([])

  return (
    <Formik
      validationSchema={setupClientValidationSchema}
      initialValues={initialValues}
      onSubmit={values => handleSubmit({ ...values, disclaimer, buyData, vdrInstructions })}
    >
      {() => {
        return (
          <Form>
            <Paragraph><strong>Configuración del cliente</strong></Paragraph>
            <Paragraph>
              Por favor, ingresa los datos necesarios para dar de alta a un nuevo cliente.
            </Paragraph>
            <Grid container spacing={2} mb={1} mt={1}>
              <Grid item sm={6} xs={12} md={6}>
                <MyTextInput
                  name='currencyOptions'
                  label='Divisas (separadas por coma):'
                />
              </Grid>
              <Grid item sm={6} xs={12} md={6} />
              <Grid item sm={6} xs={12} md={6}>
                <MySelect
                  default='Selecciona un tipo de activo'
                  labelText='Tipo de activo'
                  name='assetsType'
                  items={assetTypes}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MyToggle
                  name='flow_periodicity'
                  label='Distribución de flujos'
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MyToggle
                  name='confidentiality'
                  label='Confidencialidad'
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MyToggle
                  name='manual_buyback'
                  label='Recompra'
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MyToggle
                  name='mandatory_vdr'
                  label='Requiere acknowledge para acceder a VDR'
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MyToggle
                  name='onbHasAdditionalFields'
                  label='Plataforma con flujos extendidos'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput
                  name='send_email_address'
                  label='Correo electrónico de salida'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput
                  name='receive_email_address'
                  label='Correo electrónico de entrada'
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <MySelect
                  default='Selecciona una terminología'
                  labelText='Terminología para tipo de persona'
                  name='person_type_terminology'
                  items={terminology}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <MySelect
                  default='Selecciona la configuración regional'
                  labelText='Configuración regional'
                  name='timezone'
                  items={utf}
                />
              </Grid>
              <Grid item sm={4} md={4} xs={12}>
                <MyUploadInput
                  name='usageConditions'
                  typeText='Solo .pdf'
                  title='Archivo con condiciones de uso de la plataforma'
                  labelText='Arrastra tu archivo aquí o haz click para subirlo'
                  accept={['.pdf']}
                />
              </Grid>

              <Grid item sm={4} md={4} xs={12}>
                <MyUploadInput
                  name='politicsFile'
                  typeText='Solo .pdf'
                  title='Documento de políticas de uso para empresa'
                  labelText='Arrastra tu archivo aquí o haz click para subirlo'
                  accept={['.pdf']}
                />
              </Grid>

              <Grid item sm={4} md={4} xs={12}>
                <MyUploadInput
                  name='declarationFile'
                  typeText='Solo .pdf'
                  title='Documento de declaraciones para empresa'
                  labelText='Arrastra tu archivo aquí o haz click para subirlo'
                  accept={['.pdf']}
                />
              </Grid>

              <Grid item sm={12} xs={12}><Paragraph style={{ marginTop: '2rem' }}>Colores personalizados</Paragraph></Grid>
              <Grid item xs={12} sm={12}>
                <MyInputColor
                  title='Color primario'
                  subtitle='Headers,titulares,fondos,...'
                  name='color1'
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <MyInputColor
                  title='Color secundario'
                  subtitle='Botones,links,títulos,pop-ups,...'
                  name='color2'
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <MyInputColor
                  title='Color terciario'
                  subtitle='Barras de progreso'
                  name='color3'
                />
              </Grid>
              <Grid item sm={12} xs={12}><Paragraph style={{ marginTop: '2rem' }}>Instructivo de documentación</Paragraph></Grid>
              <Grid item xs={6} sm={12}>
                <MyDynamicDataGenerator
                  data={vdrInstructions}
                  setData={setVdrInstructions}
                  labelAdd='Agregar más instrucciones para documentación'
                  labelOne='Título'
                  labelTwo='Descripción desarrollada'
                  labelThree='Lenguaje'
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <MyTextInput
                  name='typeformUrl'
                  label='Id de KYC en Typeform'
                />
              </Grid>
              <Grid item sm={12} xs={12}><Paragraph style={{ marginTop: '2rem' }}>Datos de pago</Paragraph></Grid>
              <Grid item xs={6} sm={12}>
                <MyDynamicDataGenerator
                  data={buyData}
                  setData={setBuyData}
                  labelAdd='Agregar más valores a datos de pago'
                  labelOne='Título'
                  labelTwo='Valor'
                  labelThree='Lenguaje'
                />
              </Grid>
              <Grid item sm={12} xs={12}><Paragraph style={{ marginTop: '2rem' }}>Información para mostrar en el disclaimer</Paragraph></Grid>
              <Grid item xs={12} sm={6}>
                <MyTextInput
                  name='disclaimerTitle'
                  label='Titulo del disclaimer'
                />
              </Grid>
              <Grid item xs={6} sm={12}>
                <MyTextArea
                  name='disclaimerDescription'
                  label='Descripción a mostrar en el disclaimer'
                />
              </Grid>
              <Grid item xs={6} sm={12}>
                <MyDynamicDataGenerator
                  data={disclaimer}
                  setData={setDisclaimer}
                  labelAdd='Agregar más valores a datos de disclaimer'
                  labelOne='Título del enlace'
                  labelTwo='Dirección del enlace'
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <MyButton fullWidth color='secondary' onMouseUp={previousStep}>
                  Anterior
                </MyButton>
              </Grid>
              <Grid item sm={4} xs={12}>
                <MyButton fullWidth type='submit'>
                  Siguiente
                </MyButton>
              </Grid>

            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
