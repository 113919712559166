import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'

import { ColorContext } from '../contexts/ColorContext'
import { colorPalette } from '../conf/ColorConfig'

interface Color {
  color: 'primary' | 'secondary' | 'info' | 'error'
}

export const MyButton = React.memo(({ children, size = 'default', ...props }: any) => {
  const { theme } = useContext(ColorContext)
  const { secondary } = theme

  const themeColor = (props: Color) => {
    if (props.color === 'primary') return secondary
    if (props.color === 'secondary') return 'white'
    if (props.color === 'error') return '#EC4141'
    return secondary
  }

  const themeColorText = (props: Color) => {
    if (props.color === 'primary') return 'white'
    if (props.color === 'secondary') return secondary
    return 'white'
  }

  const PrimaryButton = styled(Button)`
    background-color: ${themeColor};
    color: ${themeColorText};
    border-radius: 32px;
    border: 1px solid ${themeColor};
    font-size: ${size === 'default' ? '14px' : '12px'};
    font-weight: 500;
    text-transform: none;
    padding:  ${size === 'default' ? '16px 32px' : '12px 18px'} ;

    @media (max-width: 480px) {
      font-size: 12px;
    }

    &:hover {
      background-color: ${colorPalette.primary.HEX_8CAEFE} ;
      border:1px solid ${colorPalette.primary.HEX_8CAEFE};
      color: ${colorPalette.complementary.white};
    }
    :active{
      background-color: ${colorPalette.primary.HEX_1F53CC};
      color: ${colorPalette.complementary.white};
    }

    :disabled {
      color: #90ADC9;
      background-color: ${colorPalette.basics.HEX_E6EAF2};
      border: 1px solid ${colorPalette.basics.HEX_E6EAF2};
    }
  `
  const SecondaryButton = styled(PrimaryButton)`
    background-color: ${themeColor};
    color: ${themeColorText};
    border: 1px solid ${themeColorText};
  `
  const TertiaryButton = styled(SecondaryButton)`
    background-color: transparent;
    color: ${secondary};
  `

  if (props.color === 'primary') {
    return (
      <PrimaryButton
        {...props}
      >
        {children}
      </PrimaryButton>
    )
  }

  if (props.color === 'secondary') {
    return (
      <SecondaryButton
        {...props}
      >
        {children}
      </SecondaryButton>
    )
  }

  if (props.color === 'info') {
    return (
      <TertiaryButton
        {...props}
      >
        {children}
      </TertiaryButton>
    )
  }

  return (
    <PrimaryButton
      {...props}
    >
      {children}
    </PrimaryButton>
  )
})
