import { useContext, useLayoutEffect, useCallback } from 'react'
import UserContext from '../../../contexts/UserContext'
import PaidIcon from '@mui/icons-material/Paid'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import { MaterialIcon } from '../SidebarOptions'
import { SidebarTabsProps } from '../SidebarTabs'
import HistoryIcon from '@mui/icons-material/History'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { AssetsContext } from '../../../contexts/AssetsContext'
import ClientContext from '../../../contexts/ClientContext'
import { assetsTypeByClient } from '../../../types/types'
import { useTranslation } from 'react-i18next'

export const Investors = (): SidebarTabsProps[] => {
  const { loadAssetToInvestorDashboard, clearAssetContext } = useContext(AssetsContext)
  const { user } = useContext(UserContext)
  const { t, i18n: { language } } = useTranslation()
  const { assetsType } = useContext(ClientContext)

  useLayoutEffect(() => {
    async function init () {
      await loadAssetToInvestorDashboard()
    }
    void init()

    return () => clearAssetContext()
  }, [loadAssetToInvestorDashboard])

  let tabs = [
    {
      index: 1,
      navigateTo: 'investors',
      Icon: <PaidIcon {...MaterialIcon} />,
      title: t('investor_user.investment_opportunities')
    },
    {
      index: 2,
      navigateTo: 'investors-purchases',
      Icon: <HistoryIcon {...MaterialIcon} />,
      title: t('investor_user.my_investments')
    }
  ]

  if (assetsType === assetsTypeByClient.EQUITIES) {
    tabs = [
      ...tabs,
      {
        index: 3,
        navigateTo: 'investors-tax',
        Icon: <AccountBalanceIcon {...MaterialIcon} />,
        title: t('tax')
      }
    ]
  }

  const allowedTabs = useCallback(() => {
    if (user?.user?.status !== 'ACCEPTED' || user?.user?.step !== 7) return tabs.slice(1)
    return tabs
  }, [user, assetsType, language])

  if (user?.user?.step !== 7) {
    return [
      ...allowedTabs(),
      {
        index: 4,
        navigateTo: 'investors-onb',
        Icon: <FactCheckIcon {...MaterialIcon} />,
        title: t('finish_your_registration')
      }
    ]
  }

  return allowedTabs()
}
