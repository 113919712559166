import styled from '@emotion/styled'
import { useContext, useState } from 'react'
import { colorPalette } from '../../conf/ColorConfig'
import { DDMultiFilesContext } from './DDMultiFiles'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { FilePreview } from './FilePreview'
import { IconButton } from '@mui/material'

export const FileList = () => {
  const { files, setFiles, acceptedTypes } = useContext(DDMultiFilesContext)
  const [currentIndexFile, setCurrentIndexFile] = useState<number>(0)

  const moveFile = (from: number, to: number) => {
    setFiles(prev => {
      const newOrderFiles = [...prev]
      const file = newOrderFiles.splice(from, 1)[0]
      newOrderFiles.splice(to, 0, file)
      return newOrderFiles
    })
  }

  const onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault()
    const dropId = Number(ev.currentTarget.id)
    moveFile(currentIndexFile, dropId)
  }

  const onDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault()
  }

  const onDragStart = (ev: React.DragEvent<HTMLDivElement>) => {
    setCurrentIndexFile(Number(ev.currentTarget.id))
  }

  const onDelete = (indexToDelete: number) => {
    setFiles(files => files.filter((file, index) => index !== indexToDelete))
  }

  return (
    <>
    { files.length > 0
      ? files.map((file, index) =>
          <Container
            hasCover={acceptedTypes === 'image/png, image/jpeg, image/jpg'}
            id={String(index)}
            key={index}
            draggable
            onClick={() => setCurrentIndexFile(index)}
            onDragOver={onDragOver}
            onDragStart={onDragStart}
            onDrop={onDrop}
          >
            <div className='icon-button'>
              <IconButton onClick={() => onDelete(index)}>
                <DeleteForeverIcon htmlColor={colorPalette.complementary.black} />
              </IconButton>
            </div>
            <FilePreview file={file} />
          </Container>
      )
      : null
    }
    </>
  )
}

const Container = styled.div<{ hasCover: boolean }>`
  margin: 5px;
  position: relative;
  border-radius: 20px;
  float: left;
  min-width: 190px;
  height: 190px;

  :hover {
    border: 1px solid gray;
  }

  ${({ hasCover }) =>
          hasCover
    ? `&:first-of-type {
      ::before {
        position: absolute;
        content: 'Portada';
        z-index: 200;
        margin: 10px;
        background-color: ${colorPalette.complementary.success};
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 12px;
        color: white;
      }
    }`
: ''
  }

  .icon-button {
    position: absolute;
    right: 0;
    z-index: 10;
    top: 5px;
  }
`
