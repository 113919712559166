import styled from '@emotion/styled'
import { colorPalette } from '../../../../conf/ColorConfig'
import { Paragraph } from '../../../../components/Paragraph'
import { useTranslation } from 'react-i18next'

export type OrderAssetsFilterOptions = 'asc' | 'des'

interface Props {
  orderBy: string
  setOrderBy: (value: string) => void
}

export const OrderAssetsFilter = ({ orderBy, setOrderBy }: Props) => {
  const { t } = useTranslation()
  return (
    <SelectContainer>
        <Paragraph style={{}}>
          <strong>{t('sort_by')}</strong>
        </Paragraph>
        <SelectStyled value={orderBy} onChange={e => setOrderBy(e.target.value)}>
          <option value='asc'>{t('most_recent')}</option>
          <option value='des'>{t('oldest')}</option>
        </SelectStyled>
      </SelectContainer>
  )
}

const SelectContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 18px;
`
const SelectStyled = styled.select`
  border: 0;
  color: ${colorPalette.basics.HEX_00266A};
  font-size: 16px;
  font-weight: 400;
`
