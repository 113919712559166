import { useContext, useEffect, useState } from 'react'
import { MyCustomTab, TabPanel } from '../../../../components'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import { Grid } from '@mui/material'
import { SetupAccount } from './SetupAccount'
import { useNavigate } from 'react-router-dom'
import { LayoutForm } from '../../../../layout/LayoutForm'
import { getIssuerById, updateIssuer } from '../../../../core/apiIssuers'
import UserContext from '../../../../contexts/UserContext'
import NotificationContext from '../../../../contexts/NotificationContext'
import { uploadImg } from '../../../../core/apiUploadDocs'
import LoadingContext from '../../../../contexts/LoadingContext'
import ClientContext from '../../../../contexts/ClientContext'
import { MyButton } from '../../../../components/MyButton'
import { thirdMobil } from '../../../../layout/LayoutConfig'
import { DocumentationIssuer } from './DocumentationIssuer'
import { scrollToTop } from '../../../../core/utils'
import { Paragraph } from '../../../../components/Paragraph'
import { useClientConfig } from '../../../../hooks/useClientConfig'
import { AcceptPDF } from './AcceptPDF'
import { useTranslation } from 'react-i18next'

const initialValues = {
  description: '',
  logo: ''
}

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const IssuerConfirmationAccount = ({ isOpen, onClose }: Props) => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const [indexTab, setIndexTab] = useState<number>(0)
  const [maxIndexTab, setMaxIndexTab] = useState<number>(0)
  const [issuerData, setIssuerData] = useState<any>(initialValues)
  const { getIssuerId, reloadIssuerUser } = useContext(UserContext)
  const { clientId, declarationFile, politicsFile, onbHasAdditionalFields } = useContext(ClientContext)
  const { assetTypeLabel } = useClientConfig()
  const navigation = useNavigate()
  const { t } = useTranslation()

  const data = [
    {
      title: t('company_user.onboarding.title_one'),
      subtitle: t('company_user.onboarding.step_one'),
      iconUnsolved: <CheckCircleOutlineIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('company_user.onboarding.title_two'),
      subtitle: t('company_user.onboarding.step_two'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('company_user.onboarding.title_three'),
      subtitle: t('company_user.onboarding.step_three'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('company_user.onboarding.title_four'),
      subtitle: t('company_user.onboarding.step_four'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('company_user.onboarding.title_five'),
      subtitle: t('company_user.onboarding.step_five'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    },
    {
      title: t('company_user.onboarding.title_six'),
      subtitle: t('company_user.onboarding.step_six'),
      iconUnsolved: <PanoramaFishEyeIcon />,
      iconSolve: <CheckCircleOutlineIcon />
    }
  ]

  useEffect(() => {
    setMaxIndexTab(value => Math.max(indexTab, value))
  }, [indexTab])

  const loadIssuerData = async () => {
    showLoading()
    const resp = await getIssuerById(getIssuerId(), clientId())
    if (resp.success) {
      const data = { ...resp.records?.[0], logo: resp.records?.[0]?.logo ?? '' }
      setIssuerData(data)
      setIndexTab(resp.records?.[0].step || 0)
    }
    hideLoading()
  }

  useEffect(() => {
    async function init () {
      await loadIssuerData()
    }
    void init()
    // eslint-disable-next-line
    }, [])

  const handleChangeStep = async (values?: any) => {
    showLoading()
    await updateIssuer(getIssuerId(), { ...values, step: indexTab + 1 }, clientId())
    await loadIssuerData()
    hideLoading()
    scrollToTop()
  }

  const handleSubmitChanges = async (values: any) => {
    showLoading()
    try {
      const resp = await updateIssuer(getIssuerId(), { ...values, step: indexTab + 1 }, clientId())
      if (resp?.success) {
        showToast('success', t('toasts.company_onboarding_success'))
        nextStep()
      }
    } catch (error) {
      console.log(error)
      showToast('error', 'Error')
      navigation(`/${clientId()}`)
    }
    setIssuerData((prev: any) => ({ ...prev, ...values }))
    hideLoading()
  }
  const nextStep = () => { setIndexTab(v => v + 1) }
  const previousStep = () => { setIndexTab(v => v - 1) }

  const handleSubmitLogo = async (values: any) => {
    showLoading()
    const resp = await updateIssuer(getIssuerId(), { ...values, logoFile: values.logo.name, step: indexTab + 1 }, clientId())
    if (resp?.success) {
      await uploadImg(resp.records?.[0].signedUrl, values.logo)
      await loadIssuerData()
    }
    hideLoading()
  }

  const finishOnboarding = () => {
    showToast('success', t('toasts.information_completed'))
    reloadIssuerUser({ ...issuerData, step: 5 })
    navigation('/Dashboard/issuers')
  }

  return (
        <LayoutForm open={isOpen} onClose={onClose}>
            <MyCustomTab headersTab={data} indexTab={indexTab}>
                <TabPanel key={0} value={indexTab} index={0}>
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            <Paragraph type='subheading'><strong>{t('company_user.onboarding.subtitle_one')}</strong></Paragraph>
                        </Grid>
                        <Grid item sm={12} mb={'6rem'}>
                            <Paragraph>{t('company_user.onboarding.description_one')}</Paragraph>
                        </Grid>
                        <Grid item >
                            <MyButton onMouseUp={async () => await handleChangeStep()} fullWidth>
                              {t('next')}
                            </MyButton>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel key={1} value={indexTab} index={1}>
                    <SetupAccount initialValues={issuerData} onSubmit={handleSubmitLogo} onClose={onClose} />
                </TabPanel>
                <TabPanel key={2} value={indexTab} index={2}>
                    <AcceptPDF onSubmit={handleChangeStep} onClose={onClose} url={politicsFile} previousStep={previousStep} hasChecked={maxIndexTab > 2} />
                </TabPanel>
                <TabPanel key={3} value={indexTab} index={3}>
                    <AcceptPDF onSubmit={handleChangeStep} onClose={onClose} url={declarationFile} previousStep={previousStep} hasChecked={maxIndexTab > 3}/>
                </TabPanel>
                <TabPanel key={4} value={indexTab} index={4}>
                    <DocumentationIssuer initialValues={issuerData} onSubmit={handleSubmitChanges} onClose={onClose} previousStep={previousStep}/>
                </TabPanel>
                <TabPanel key={5} value={indexTab} index={5}>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <Paragraph type='subheading'><strong>{t('company_user.onboarding.description_six')}</strong></Paragraph>
                        </Grid>
                        <Grid item sm={12}>
                          { onbHasAdditionalFields
                            ? <Paragraph>{t('company_user.onboarding.disclaimer_six_biva')}</Paragraph>
                            : <Paragraph>{t('company_user.onboarding.disclaimer_six', { asset: assetTypeLabel('plural', 'lowercase') })}.</Paragraph>
                          }
                        </Grid>
                        <Grid item {...thirdMobil}>
                            <MyButton fullWidth color='secondary' onMouseUp={previousStep}>
                                {t('back')}
                            </MyButton>
                        </Grid>
                        <Grid item {...thirdMobil}>
                            <MyButton fullWidth onMouseUp={finishOnboarding}>
                                {t('finish')}
                            </MyButton>
                        </Grid>
                    </Grid>
                </TabPanel>
            </MyCustomTab>
        </LayoutForm>
  )
}
