import { memo, useContext } from 'react'
import styled from '@emotion/styled'
import { ColorContext } from '../contexts/ColorContext'
import { Link, To } from 'react-router-dom'
import { colorPalette } from '../conf/ColorConfig'

const SIZES = {
  small: '0.75rem',
  regular: '0.875rem',
  large: '1rem'
}

interface Props {
  type?: 'regular' | 'small' | 'large'
  children: any
  to: To
  state?: any
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

const StyledTextButton = styled(Link)<{
  type: 'regular' | 'small' | 'large'
  color: string
}>`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ type }) => SIZES[type]};
  line-height: 2rem;
  color: ${({ color }) => color};
  
  &:hover {
    color: ${colorPalette.complementary.black}
  }
`

export const TextButtonLink = memo(({
  type = 'regular',
  onClick,
  children,
  state,
  to
}: Props) => {
  const { theme: { secondary } } = useContext(ColorContext)
  return (
    <StyledTextButton
      onClick={onClick}
      color={secondary}
      type={type}
      to={to}
      state={state}
    >
      { children }
    </StyledTextButton>
  )
})
