import styled from '@emotion/styled'
import { AppBar, Toolbar } from '@mui/material'
import { useContext } from 'react'
import ClientContext from '../contexts/ClientContext'
import { ColorContext } from '../contexts/ColorContext'
import { Disclaimer } from '../pages/Users/Inversionistas/Dashboard/Disclaimer'
import { LayoutDialog } from './LayoutDialog'

interface Props {
  open: boolean
  onClose: () => void
  children: any
}

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: translateY(-50px);
    z-index: 1100;
    padding: 0 2rem;

    @media (max-width: 480px) {
        padding: 0;
    }
`

const ContainerSinFlex = styled('div')`
    min-height: calc(100vh - 120px);
    width: 85%;
    @media (max-width: 480px) {
        width: 100%;
    }
`

const Layout = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 40px;
    @media (max-width: 480px) {
        padding: 5px;
    }
    min-height: inherit;
`

export const LayoutForm = ({ open, onClose, children }: Props) => {
  const { logo } = useContext(ClientContext)
  const { theme } = useContext(ColorContext)
  const { primary } = theme

  return (
        <LayoutDialog open={open} closeDialog={onClose}>
            <AppBar style={{ backgroundColor: primary, paddingBottom: 70, position: 'static' }}>
                <Toolbar>
                    <img
                        alt='logo'
                        src={logo}
                        className='appbar-img'
                        style={{ margin: 10, cursor: 'pointer' }}
                        onClick={onClose}
                    />
                </Toolbar>
            </AppBar>
            <Container>
                <ContainerSinFlex>
                    <Layout className='box-shadow'>
                        {children}
                    </Layout>
                </ContainerSinFlex>
                <div style={{ width: '100%' }}>
                    <Disclaimer />
                </div>
            </Container>
        </LayoutDialog>
  )
}
