import { createContext, useState } from 'react'

export interface ColorPalette {
  primary: string
  secondary: string
  tertiary: string
  warning: string
  success: string
  danger: string
}

export const colorThemeInitialValues: ColorPalette = {
  primary: '#28406A',
  secondary: '#598BFE',
  tertiary: '#62C6E9',
  warning: '#F4C752',
  success: '#53C753',
  danger: '#D35443'
}

interface ContextProps {
  defaultTheme: () => void
  changeTheme: (newTheme: ColorPalette) => void
  theme: ColorPalette
}
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ColorContext = createContext<ContextProps>({} as ContextProps)

export const ColorProvider = ({ children }: any) => {
  const [theme, setTheme] = useState<ColorPalette>(() =>
    JSON.parse(localStorage.getItem('color') ?? JSON.stringify(colorThemeInitialValues)
    ))

  const defaultTheme = () => {
    setTheme(colorThemeInitialValues)
    localStorage.setItem('color', JSON.stringify(colorThemeInitialValues))
  }

  const changeTheme = (newTheme: ColorPalette) => {
    setTheme(newTheme)
    localStorage.setItem('color', JSON.stringify(newTheme))
    document.documentElement.style.setProperty('--primary', newTheme.primary)
    document.documentElement.style.setProperty('--secondary', newTheme.secondary)
    document.documentElement.style.setProperty('--tertiary', newTheme.tertiary)
  }

  const values = {
    defaultTheme,
    changeTheme,
    theme
  }

  return (
    <ColorContext.Provider value={values}>
      {children}
    </ColorContext.Provider>
  )
}
