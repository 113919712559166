import { memo } from 'react'
import styled from '@emotion/styled'
import { colorPalette } from '../conf/ColorConfig'

const SIZES = {
  default: '1rem',
  subheading: '1.125rem',
  small: '0.875rem',
  caption: '0.75rem'
}

const LINES_HEIGHT = {
  default: '2rem',
  subheading: '2rem',
  small: '2rem',
  caption: '1.5rem'
}

interface Props {
  type?: 'default' | 'subheading' | 'small' | 'caption'
  colorType?: 'black' | 'gray' | 'white'
  children: any
  style?: React.CSSProperties
  className?: string
  bold?: boolean
}

const StyledParagraph = styled.p<{
  type: 'default' | 'subheading' | 'small' | 'caption'
  colorType: 'black' | 'gray' | 'white'
  bold: boolean
}>`
  font-style: normal;
  font-weight: ${({ bold }) => bold ? 600 : 400};
  font-size: ${({ type }) => SIZES[type]};
  line-height: ${({ type }) => LINES_HEIGHT[type]};
  color: ${({ colorType }) => colorType !== 'gray' ? colorPalette.complementary[colorType] : colorPalette.basics.HEX_3D4D6A};
  
  @media (max-width: 1023px) {
    font-size: calc(${({ type }) => SIZES[type]} - 2px);
    line-height: 1.5;
  }
`

export const Paragraph = memo(({
  colorType = 'gray',
  type = 'default',
  className = '',
  bold = false,
  children,
  style
}: Props) => {
  return (
    <StyledParagraph
      colorType={colorType}
      type={type}
      style={style}
      className={className}
      bold={bold}
    >
      { children }
    </StyledParagraph>
  )
})
