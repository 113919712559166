import { styled } from '@mui/system'
import { ErrorMessage, FastField } from 'formik'
import { useTranslation } from 'react-i18next'

interface Props {
  label: string
  name: string

  [propName: string]: any
}

export const MyLabel = styled('label')`
  display: flex;
  justify-content: flex-start;
  line-height: 1.2;
  gap: 1rem;
`

const ErrorLabel = styled('div')`
  font-size: 12px;
  line-height: 2;
  color: #d32f2f;
`

export const MyCheckboxInput = ({ label, ...props }: Props) => {
  const { t, i18n: { language } } = useTranslation()
  return (
    <FastField name={props.name} key={`checkbox-${props.name}-${language}`}>
      {(({ field }: any) =>
      <>
        <MyLabel>
          <input type="checkbox" checked={field.value} {...field} {...props} />
          {label}
        </MyLabel>
        <ErrorMessage
          name={props.name}
          component="span"
          render={(msg: string) => <ErrorLabel>{t('forms.' + msg)}</ErrorLabel>}
        />
      </>
      )}
    </FastField>
  )
}
