import styled from '@emotion/styled'
import React, { useContext } from 'react'
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom'
import { ColorContext } from '../contexts/ColorContext'
import * as MuiIcons from '@mui/icons-material'

interface Props {
  to: string
  children: React.ReactNode
  icon: keyof typeof MuiIcons
  isOpen: boolean
}

const MyStyledNavLink = styled(NavLink)`
  color: white;
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  transition: background .5s ease;
  padding: 12px 1.5rem;
  
  &:hover{
    background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.08) 100%)
  }
`

export const MyNavLink = ({ children, icon, to, isOpen }: Props) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })
  const { theme } = useContext(ColorContext)
  const { secondary } = theme
  const isActiveProps = { backgroundColor: secondary }
  const Icon = icon && MuiIcons[icon]
  return (
    <MyStyledNavLink
      to={to}
      style={(match != null) ? isActiveProps : undefined}
    >
      { Icon ? <Icon /> : null}
      { isOpen ? children : null }
    </MyStyledNavLink>
  )
}
