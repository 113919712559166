import { Dialog, IconButton, InputAdornment, OutlinedInput, Grid } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { MyButton } from '../../../../../components/MyButton'
import { H3 } from '../../../../../components/MyHeaders'
import styled from '@emotion/styled'
import { useContext } from 'react'
import ClientContext from '../../../../../contexts/ClientContext'
import { Paragraph } from '../../../../../components/Paragraph'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
  title?: string
  content?: string
}

const Container = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  padding: 0.3rem 0 0.3rem 0;
  align-items: center;
`

export const BuyInstructions = ({ isOpen, onClose, title, content }: Props) => {
  const { paymentInstructions } = useContext(ClientContext)
  const { t, i18n } = useTranslation()

  const copyContent = (text: string) => {
    void navigator.clipboard.writeText(text)
  }

  return (
    <Dialog open={isOpen} onClose={onClose} PaperProps={{ style: { padding: 30 } }}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={12} xs={12} textAlign='center' mb={2}>
          <Paragraph bold>{t('investor_user.buy.buy_instructions_title')}</Paragraph>
        </Grid>
        <Grid item sm={12} md={12} xs={12}>
          <Paragraph bold>{ title }</Paragraph>
        </Grid>
        <Grid item sm={12} md={12} xs={12}>
          <Paragraph>{ content }</Paragraph>
        </Grid>
        <Grid item sm={12} md={12} xs={12}>
        { paymentInstructions?.filter(({ language }: { language: string }) => language === i18n.language).map(({ field, value }: any, index: any) =>
          <Container key={index}>
            <H3 style={{ width: '40%' }}>{ field }</H3>
            <OutlinedInput
              fullWidth
              value={value}
              readOnly
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onMouseDown={() => copyContent(value)}
                    edge="end"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Container>
        )}
        </Grid>
        <Grid item sm={2} md={2} xs={12} />
        <Grid item sm={8} md={8} xs={12} mt={2}>
          <MyButton onMouseUp={onClose} fullWidth color='secondary'>{t('close')}</MyButton>
        </Grid>
      </Grid>
    </Dialog>
  )
}
