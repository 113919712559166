import { Grid } from '@mui/material'
import { MyDownloadRef } from '../../../../components/MyDownloadRef'
import styled from '@emotion/styled'
import { useContext } from 'react'
import ClientContext from '../../../../contexts/ClientContext'
import { ColorContext } from '../../../../contexts/ColorContext'
import { colorPalette } from '../../../../conf/ColorConfig'
import { assetsTypeByClient } from '../../../../types/types'
import { NoEquityQXLogo } from '../../../../layout/Disclaimer/NoEquityQXLogo'
import { EquityQXLogo } from '../../../../layout/Disclaimer/EquityQXLogo'

const DivHtml = styled.div`
    font-size: 0.75rem;
    color: ${colorPalette.basics.HEX_3D4D6A};
`

const DisclaimerContainer = styled.div<{ isEquity: boolean }>`
    padding: ${({ isEquity }) => isEquity ? '40px 0 0px' : '40px 20px 20px'};
    @media (max-width: 480px) {
      padding: 40px 20px 20px;
    }
`

export const Disclaimer = () => {
  const { disclaimer, assetsType } = useContext(ClientContext)
  const { theme } = useContext(ColorContext)
  const { secondary } = theme

  const isEquity = assetsType === assetsTypeByClient.EQUITIES
  return (
        <DisclaimerContainer isEquity={isEquity}>
            <Grid container spacing={2} mt={1} justifyContent='space-between'>
               { !isEquity
                 ? <Grid item sm={9} xs={12}>
                    <Grid container style={{ hyphens: 'auto' }} textAlign='justify' spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <DivHtml dangerouslySetInnerHTML={{ __html: disclaimer?.body || '' }} />
                        </Grid>
                        {disclaimer?.links?.map((link: any, index: number) =>
                            <Grid item key={index}>
                                <MyDownloadRef href={link?.body} target='_blank' rel="noreferrer noopener" color={secondary}>{link?.title}</MyDownloadRef>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                 : null}
                    { isEquity
                      ? <EquityQXLogo />
                      : <Grid item sm={2} xs={12} alignSelf='center'>
                        <NoEquityQXLogo />
                    </Grid>
                    }
            </Grid>
        </DisclaimerContainer>
  )
}
