import styled from '@emotion/styled'
import { useField } from 'formik'

interface Props {
  name: string
  title: string
  subtitle: string
  [propName: string]: any
}

const Color = styled.input`
  appearance: none;
  width: 60px;
  height: 60px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;

`

export const MyInputColor = ({ title, subtitle, ...props }: Props) => {
  const [field] = useField(props)
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <Color
        title={'color'}
        type="color"
        {...field}
        {...props}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>{title}</p>
        <p>{subtitle}</p>
      </div>
    </div>
  )
}
