import { Divider, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyCheckboxInput } from '../../../../components'
import { MyButton } from '../../../../components/MyButton'
import { Paragraph } from '../../../../components/Paragraph'
import { MainVDR } from '../../../../components/VDR/MainVDR'
import ClientContext from '../../../../contexts/ClientContext'
import LoadingContext from '../../../../contexts/LoadingContext'
import NotificationContext from '../../../../contexts/NotificationContext'
import UserContext from '../../../../contexts/UserContext'
import { getIssuerById, updateIssuer } from '../../../../core/apiIssuers'
import { DialogProps } from '../../../../hooks/useDialog'
import { LayoutForm } from '../../../../layout/LayoutForm'

interface Props extends DialogProps {
  isLinkVdr: boolean
}

export const DialogUploadFiles = ({ isOpen, onClose, isLinkVdr }: Props) => {
  const { getIssuerId, VDR_ID } = useContext(UserContext)
  const { client_id } = useContext(ClientContext)
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext)
  const [issuer, setIssuer] = useState<any>({})
  const [areDocsReady, setAreDocsReady] = useState(false)
  const { showToast } = useContext(NotificationContext)
  const { t } = useTranslation()

  const loadIssuer = async () => {
    showLoading()
    const resp = await getIssuerById(getIssuerId(), client_id)
    if (resp?.success) setIssuer(resp?.records?.[0])
    hideLoading()
  }

  useEffect(() => {
    async function init () {
      await loadIssuer()
    }
    if (isOpen) { void init() }

    return () => setIssuer({})
    // eslint-disable-next-line
  }, [isOpen])

  useEffect(() => {
    setAreDocsReady(issuer?.docReady)
  }, [issuer])

  const onSubmit = async (canUpdated: boolean) => {
    if (canUpdated && !areDocsReady) {
      showLoading()
      const resp = await updateIssuer(getIssuerId(), { docReady: true }, client_id)
      if (resp?.success) {
        showToast('success', t('toasts.confirm_vdr_success'))
      } else showToast('error', 'Error')
      hideLoading()
    }
    onClose()
  }

  return (
    <LayoutForm
      onClose={onClose}
      open={isOpen}
    >
       <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12}>
            <Paragraph type='subheading' bold>{t('Documentation')}</Paragraph>
          </Grid>
          <Grid item sm={12} xs={12} md={12}>
            <Paragraph>
              {t('company_user.create_asset.subtitle_four')}
            </Paragraph>
          </Grid>
          <Grid item sm={12} xs={12} md={12}>
            { !isLoading
              ? <Formik key={String(areDocsReady)} initialValues={{ areDocsReady }} onSubmit={async ({ areDocsReady }) => await onSubmit(areDocsReady)}>
                { () =>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12} md={12} mb={3}>
                      <MainVDR
                        showHeaders={false}
                        issuer_id={getIssuerId()}
                        vdr_id={VDR_ID()}
                        type='Empresa'
                      />
                    </Grid>
                    <Grid item sm={12} xs={12} md={12}>
                      <Divider />
                    </Grid>
                    <Grid item sm={12} xs={12} md={12} mb={3}>
                      <MyCheckboxInput
                        disabled={areDocsReady}
                        name='areDocsReady'
                        label={
                          isLinkVdr
                            ? t('confirm_link')
                            : t('confirm_docs')
                        }
                      />
                    </Grid>
                    <Grid item>
                      <MyButton color='secondary' onClick={onClose}>
                        {t('cancel')}
                      </MyButton>
                    </Grid>
                    <Grid item>
                      <MyButton type='submit'>
                        {t('save')}
                      </MyButton>
                    </Grid>
                  </Grid>
                </Form>
                }
              </Formik>
              : null
            }
          </Grid>
      </Grid>
    </LayoutForm>
  )
}
