import * as yup from 'yup'

export const specialInvestorValidationSchema = yup.object().shape({
  first_names: yup.string().required('required_field'),
  fathers_name: yup.string(),
  mothers_name: yup.string(),
  country: yup.string().required('required_field'),
  email: yup.string().email('email').required('required_field'),
  phone: yup.string().required('required_field'),

  nameRepresentOne: yup.string(),
  lastFatherRepresentOne: yup.string(),
  lastMotherRepresentOne: yup.string(),

  nameRepresentTwo: yup.string(),
  lastFatherRepresentTwo: yup.string(),
  lastMotherRepresentTwo: yup.string()
})
