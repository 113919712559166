import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { FieldArray } from 'formik'
import { memo } from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'inherit'
    }
  }
}

interface Props {
  name: string
  title: string
  options: string[]
  values: any[]
}

export const MySelectCheckmarks = memo(({ values, options, title, ...props }: Props) => {
  const handleClick = (
    push: (obj: any) => void,
    handleRemove: <T>(index: number) => T | undefined,
    newValue: any
  ) => {
    const hasIndex = values.lastIndexOf(newValue)
    if (hasIndex === -1) push(newValue)
    else handleRemove(hasIndex)
  }

  return (
    <FieldArray name={props.name} render={
      ({ push, remove }) =>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel>{ title }</InputLabel>
        <Select
          value={values}
          multiple
          input={<OutlinedInput label={ title } />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          { options.map((name) => (
            <MenuItem key={name} value={name} onClick={() => handleClick(push, remove, name)}>
              <Checkbox checked={values.includes(name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    }/>
  )
})
