import { AppRouter } from './routes/AppRouter'
import { NotificationProvider } from './contexts/NotificationContext'
import { ClientProvider } from './contexts/ClientContext'
import { UserProvider } from './contexts/UserContext'
import { LoadingProvider } from './contexts/LoadingContext'
import IdiomProvider from './contexts/IdiomContext'
import { createTheme, ThemeProvider } from '@mui/material'
import { AssetsProvider } from './contexts/AssetsContext'
import { TransactionsProvider } from './contexts/TransactionsContext'
import { InvestorsProvider } from './contexts/InvestorsContext'
import { AcceptancesProvider } from './contexts/AcceptancesContext'
import { ColorProvider } from './contexts/ColorContext'
import Amplify from 'aws-amplify'
import endpoints from './conf/endpoints'
import auth from './conf/auth'
import ReactGA from 'react-ga4'
import { IS_PROD } from './core/utils'
import { colorPalette } from './conf/ColorConfig'
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from './constants'

IS_PROD && ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID)

Amplify.configure({
  Auth: auth,
  API: {
    endpoints
  }
})

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'Nunito',
      'Helvetica Neue',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  palette: {
    primary: {
      main: colorPalette.basics.HEX_738099
    },
    error: {
      main: colorPalette.complementary.error
    }
  }

})

function App () {
  return (
    <ThemeProvider theme={theme}>
      <ColorProvider>
        <IdiomProvider>
          <NotificationProvider>
            <LoadingProvider>
              <UserProvider>
                <ClientProvider>
                  <AssetsProvider>
                    <InvestorsProvider>
                      <TransactionsProvider>
                        <AcceptancesProvider>
                          <AppRouter />
                        </AcceptancesProvider>
                      </TransactionsProvider>
                    </InvestorsProvider>
                  </AssetsProvider>
                </ClientProvider>
              </UserProvider>
            </LoadingProvider>
          </NotificationProvider>
        </IdiomProvider>
      </ColorProvider>
    </ThemeProvider>

  )
}

export default App
