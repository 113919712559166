import { useEffect, useState } from 'react'
import { MyTable, TableProps } from './MyTable'

export const MyRenderTable = ({ ...props }: TableProps) => {
  const [key, setKey] = useState(Math.random())
  const { rowData } = props

  useEffect(() => {
    setKey(number => number + Math.random())
  }, [rowData])

  return (
    <MyTable key={key} {...props} />
  )
}
