import { createContext, useEffect, useState } from 'react'

const UserContext = createContext<any>({})

let timeout: NodeJS.Timer

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>(() => JSON.parse(localStorage.getItem('user') ?? '{}'))

  useEffect(() => {
    const URL = window.location.href
    if (timeout === undefined && URL.includes('Dashboard')) {
      timeout = setInterval(() => verifySession(), 2000)
    }
    // eslint-disable-next-line
    }, [user])

  const verifySession = () => {
    const URL = window.location.href
    if (URL.includes('Dashboard') && !URL.includes('Configuration')) {
      const myUser = JSON.parse(localStorage.getItem('user') ?? '{}')
      const client = JSON.parse(localStorage.getItem('client') ?? '{}')
      const rol = myUser?.user?.group
      if (!rol || !client.client.wallet || !URL.includes(rol)) {
        clearInterval(timeout)
        window.location.href = `/${client?.client?.id}`
      }
    }
  }

  const handleLogInUser = (values: any) => {
    setUser(values)
    timeout = setInterval(() => verifySession(), 2000)
    localStorage.setItem('user', JSON.stringify(values))
  }

  const handleLogOutUser = () => {
    setUser({})
    clearInterval(timeout)
    localStorage.setItem('user', JSON.stringify({}))
  }

  const reloadIssuerUser = (issuer: any) => {
    const actualUser = JSON.parse(localStorage.getItem('user') ?? '{}')
    localStorage.removeItem('user')
    const userReloaded = { ...actualUser, issuer }
    localStorage.setItem('user', JSON.stringify(userReloaded))
    setUser(userReloaded)
  }

  const reloadInvestorUser = (user: any) => {
    const actualUser = JSON.parse(localStorage.getItem('user') ?? '{}')
    localStorage.removeItem('user')
    const userReloaded = { ...actualUser, user: { ...actualUser.user, ...user } }
    localStorage.setItem('user', JSON.stringify(userReloaded))
    setUser(userReloaded)
  }

  const userRefresh = () => {
    setUser(
      JSON.parse(localStorage.getItem('user') ?? '{}')
    )
  }

  const userRol = () => {
    const myUser = JSON.parse(localStorage.getItem('user') ?? '{}')
    return myUser?.user?.group || ''
  }
  const getIssuerId = () => {
    const myUser = JSON.parse(localStorage.getItem('user') ?? '{}')
    return myUser?.user?.issuerId || ''
  }
  const getIssuerLogoURL = () => { return user?.issuer?.logo || '' }

  const VDR_ID = () => {
    const myUser = JSON.parse(localStorage.getItem('user') ?? '{}')
    return `${myUser?.user?.clientId}/${myUser?.user?.issuerId}`
  }

  const issuer_logo = user?.issuer?.logo
  const user_id = user?.user?.cognitoId
  const user_email = user?.user?.email
  const myUser = user?.user
  const issuer_id = user?.user?.issuerId || ''
  const investor_id = user?.user?.id || ''
  const user_rol = user?.user?.group || ''
  const password = user?.user?.password || ''
  const issuer = user?.issuer
  const data = {
    getIssuerLogoURL,
    handleLogOutUser,
    handleLogInUser,
    getIssuerId,
    userRefresh,
    userRol,
    issuer_logo,
    user,
    VDR_ID,
    user_id,
    myUser,
    issuer_id,
    user_email,
    reloadIssuerUser,
    reloadInvestorUser,
    investor_id,
    user_rol,
    issuer,
    password
  }
  return (
        <UserContext.Provider value={data}>
            {children}
        </UserContext.Provider>
  )
}

export { UserProvider }
export default UserContext
