import { Grid } from '@mui/material'
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded'

export const ErrorPage = () => {
  return (
    <Grid container spacing={3} alignItems='center' overflow='hidden' pl='10vw' pt='10vh'>
        <Grid item sm={1} fontSize={60} textAlign='right'>
            <SentimentDissatisfiedRoundedIcon fontSize='inherit' color='primary' />
        </Grid>
        <Grid item sm={11}>
            <h3>Oh no...</h3>
        </Grid>
        <Grid item sm={1} />
        <Grid item sm={11}>
            <h4>No pudimos encontrar la página que buscabas</h4>
        </Grid>
        <Grid item sm={1} />
        <Grid item sm={11}>
            <h5>Error: 404</h5>
        </Grid>
    </Grid>
  )
}
