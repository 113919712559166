import { useNavigate } from 'react-router-dom'
import { InitialLayout, LoginContentLayout } from '../../layout/InitialLayout'
import { Grid } from '@mui/material'
import { useContext } from 'react'
import { MyButton } from '../../components/MyButton'
import { sendEvent } from '../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../core/GA/GoogleAnalyticsEvents'
import { Paragraph } from '../../components/Paragraph'
import { LoginTitle } from '../../components/Title'
import NotificationContext from '../../contexts/NotificationContext'
import LoadingContext from '../../contexts/LoadingContext'
import { forgotPassword } from '../../core/apiAuth'
import { Form, Formik } from 'formik'
import { MyTextInput } from '../../components'
import ClientContext from '../../contexts/ClientContext'
import { MyFlexContainer } from '../../components/common/MyFlexContainer'
import * as yup from 'yup'
import { colorPalette } from '../../conf/ColorConfig'
import { useTranslation } from 'react-i18next'

export const ForgotPasswordScreen = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { clientId } = useContext(ClientContext)
  const { showToast } = useContext(NotificationContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleForgetPassword = async ({ username }: { username: string }) => {
    showLoading()
    const resp = await forgotPassword(username)
    hideLoading()
    if (resp?.code) showToast('error', t(`toasts.${resp.code}`))
    else {
      sendEvent(GA_EVENTS.request_recover_password)
      navigate('/RecoverPassword', { state: { username } })
    }
  }

  const returnToHomepage = () => navigate('/' + clientId(), { replace: true })

  return (
    <InitialLayout>
      <LoginContentLayout>
        <Formik
          initialValues={{ username: '' }}
          onSubmit={handleForgetPassword}
          validationSchema={validationSchema }
        >
          { () =>
            <Form>
              <Grid container spacing={4}>
                <Grid item sm={12} xs={12} md={12}>
                  <LoginTitle>{t('login_page.recover_password.title')}</LoginTitle>
                </Grid>
                <Grid item sm={12} xs={12} md={12}>
                  <Paragraph>
                    {t('login_page.recover_password.subtitle')}
                  </Paragraph>
                </Grid>
                <Grid item sm={12} xs={12} md={12}>
                  <MyTextInput
                    label={t('email') ?? ''}
                    name='username'
                  />
                </Grid>
                <Grid item sm={12} xs={12} md={12} mt={2}>
                  <MyButton fullWidth type='submit'>{t('login_page.recover_password.button')}</MyButton>
                </Grid>
                <Grid item sm={12} xs={12} md={12} mt={2}>
                  <MyFlexContainer style={{ justifyContent: 'center' }}>
                    <Paragraph type='small'>{t('login_page.recover_password.remember_password')}</Paragraph>
                    <div
                      onClick={returnToHomepage}
                      style={{ cursor: 'pointer' }}
                    >
                      <Paragraph type='small' style={{ color: colorPalette.primary.HEX_598BFE }}>
                      {t('login_page.recover_password.change_to_login')}
                      </Paragraph>
                    </div>
                  </MyFlexContainer>
                </Grid>
              </Grid>
            </Form>
          }
        </Formik>
      </LoginContentLayout>
    </InitialLayout>
  )
}

export const validationSchema = yup.object().shape({
  username: yup.string().email('email').required('required_field')
})
