import { createContext, useState } from 'react'
import { MyNotificationToast } from '../components/MyNotificationToast'

const NotificationContext = createContext<any>({})

interface NotificationProps {
  type: 'error' | 'warning' | 'info' | 'success'
  content: string
}

const initialValues: NotificationProps = {
  type: 'success',
  content: ''
}

const NotificationProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [NotificationValues, setNotificationValues] = useState<NotificationProps>(initialValues)

  const handleOpen = () => { setIsOpen(true) }
  const handleClose = () => { setIsOpen(false) }

  const showToast = (type: 'error' | 'warning' | 'info' | 'success', content: string) => {
    setNotificationValues({ type, content })
    handleOpen()
  }

  const MyToast = () => (
        <MyNotificationToast
            isOpen={isOpen}
            onClose={handleClose}
            {...NotificationValues}
        />
  )

  const data = { MyToast, showToast }

  return (
        <NotificationContext.Provider value={data}>
            {children}
        </NotificationContext.Provider>
  )
}

export { NotificationProvider }
export default NotificationContext
