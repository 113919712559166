import { Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { MyButtonIcon, MyCustomTab, TabPanel } from '../../../../../components'
import { IdiomContext } from '../../../../../contexts/IdiomContext'
import LoadingContext from '../../../../../contexts/LoadingContext'
import NotificationContext from '../../../../../contexts/NotificationContext'
import { OnboardingInvestorNormalPerson } from '../OnboardingInvestorNormalPerson'
import { OnboardingInvestorSpecialPerson } from '../OnboardingInvestorSpecialPerson'
import { OnboardingInvestorTabOne } from '../OnboardingInvestorTabOne'
import { MainOnboardingWallet } from '../Wallet/MainOnboardingWallet'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import { patchInvestor } from '../../../../../core/apiInvestors'
import { useNavigate } from 'react-router-dom'
import ClientContext from '../../../../../contexts/ClientContext'
import { MyButton } from '../../../../../components/MyButton'
import { Conditions } from '../Conditions'
import { PersonaInquiry } from '../Persona/PersonaInquiry'
import UserContext from '../../../../../contexts/UserContext'
import { sendEvent } from '../../../../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../../../../core/GA/GoogleAnalyticsEvents'
import { Paragraph } from '../../../../../components/Paragraph'
import { useTranslation } from 'react-i18next'

const tabsData = [
  {
    title: 'Tipo de clasificación',
    subtitle: 'Paso 1 de 5',
    iconUnsolved: <CheckCircleOutlineIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  },
  {
    title: 'Mis datos',
    subtitle: 'Paso 2 de 5',
    iconUnsolved: <PanoramaFishEyeIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  },
  {
    title: 'Wallet',
    subtitle: 'Paso 3 de 5',
    iconUnsolved: <PanoramaFishEyeIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  },
  {
    title: 'KYC',
    subtitle: 'Paso 4 de 5',
    iconUnsolved: <PanoramaFishEyeIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  },
  {
    title: 'Términos y condiciones',
    subtitle: 'Paso 5 de 5',
    iconUnsolved: <PanoramaFishEyeIcon />,
    iconSolve: <CheckCircleOutlineIcon />
  },
  {
    title: 'Finalizar',
    subtitle: '',
    iconUnsolved: <PanoramaFishEyeIcon />,
    iconSolve: <CheckCircleOutlineIcon />,
    hidden: true
  }
]

interface Props {
  initialData: any
  continueLater: () => void
  indexTab: number
  previousStep: () => void
  crudeDate: any
  handleChangeStep: (values?: any) => void
  handleDefineStep: (step: number) => Promise<void>
  isLoading: boolean
}

export const OnboardingExpressMain = ({ isLoading, crudeDate, initialData, continueLater, indexTab, previousStep, handleChangeStep, handleDefineStep }: Props) => {
  const { onboardingInvestorLabels, onboardingInvestorButtons } = useContext(IdiomContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [basicData, setBasicData] = useState<any>(initialData)
  const [personType, setPersonType] = useState<any>(initialData?.classificationType || 0)
  const { showToast } = useContext(NotificationContext)
  const { reloadInvestorUser } = useContext(UserContext)
  const { clientId } = useContext(ClientContext)
  const [wallet, setWallet] = useState<string>('')
  const { usageConditions } = useContext(ClientContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCreateInvestor = async () => {
    showLoading()
    const resp = await patchInvestor(initialData?.id, clientId(), 'ACCEPTED')
    if (resp?.success) {
      await handleDefineStep(7)
      showToast('success', t('toasts.information_completed'))
    } else {
      showToast('error', 'Error')
    }
    hideLoading()
  }

  const finishOnboarding = () => {
    reloadInvestorUser(crudeDate)
    navigate('/Dashboard/investors')
  }

  const getInvestorOnboarding = () => {
    if (isLoading) {
      return <></>
    }
    if (personType === 1) {
      return <OnboardingInvestorNormalPerson
        onClose={continueLater}
        initialValues={basicData}
        previousStep={previousStep}
        setData={setBasicData}
        nextStep={handleChangeStep}
      />
    }
    return <OnboardingInvestorSpecialPerson
      onClose={continueLater}
      initialValues={basicData}
      previousStep={previousStep}
      setData={setBasicData}
      nextStep={handleChangeStep}
    />
  }

  useEffect(() => {
    setBasicData(initialData)
    setPersonType(initialData?.classificationType || 0)
  }, [initialData])

  return (
    <MyCustomTab headersTab={tabsData} indexTab={indexTab}>
      <TabPanel key={0} value={indexTab} index={0}>
        <OnboardingInvestorTabOne
          onClose={continueLater}
          personType={personType}
          setValue={setPersonType}
          nextStep={handleChangeStep}
        />
      </TabPanel>
      <TabPanel key={1} value={indexTab} index={1}>
        {getInvestorOnboarding()}
      </TabPanel>
      <TabPanel key={2} value={indexTab} index={2}>
        <MainOnboardingWallet
          onClose={continueLater}
          nextStep={handleChangeStep}
          setWallet={setWallet}
          wallet={wallet}
          previousStep={previousStep}
        />
      </TabPanel>
      <TabPanel key={3} value={indexTab} index={3}>
        <Grid container spacing={2}>
          <Grid item sm={12} mb={2}>
            <MyButtonIcon
              size='small'
              icon='backArrow'
              onClick={previousStep}
              text='Anterior'
              type='secondary'
            />
          </Grid>
          <Grid item sm={12} >
            <PersonaInquiry onSubmit={() => { sendEvent(GA_EVENTS.onb_step_three); handleChangeStep() }} id='itmpl_fvLvYbEqynnTXjbUuhPu2M2e' />
          </Grid>
          <Grid item xs={12} md={3} sm={4}>
            <MyButton color='secondary' onMouseUp={continueLater}>
              Continuar después
            </MyButton>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel key={4} value={indexTab} index={4}>
        <Grid container spacing={2}>
          <Grid item sm={12} mb={2}>
            <MyButtonIcon
              size='small'
              icon='backArrow'
              onClick={previousStep}
              text='Anterior'
              type='secondary'
            />
          </Grid>
          <Grid item sm={12} mb={2}>
            <Paragraph type='subheading'><strong>Términos y condiciones</strong></Paragraph>
          </Grid>
          <Grid item sm={12} >
            <Conditions url={usageConditions} onSubmit={handleCreateInvestor} onClose={continueLater} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel key={7} value={indexTab} index={7}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Paragraph type='subheading'><strong>{onboardingInvestorLabels.tabFiveTitleExpress}</strong></Paragraph>
          </Grid>
          <Grid item sm={8}>
            <Paragraph>{onboardingInvestorLabels.tabFiveTextExpress}</Paragraph>
          </Grid>
          <Grid item sm={12} />
          <Grid item sm={2}>
            <MyButton
              onMouseUp={finishOnboarding}
            >{onboardingInvestorButtons.next}</MyButton>
          </Grid>
        </Grid>
      </TabPanel>
    </MyCustomTab>
  )
}
