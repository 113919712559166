import { useContext, useEffect, useState } from 'react'
import { MyTable } from '../../../../components'
import { MyFlexContainer } from '../../../../components/common/MyFlexContainer'
import { AcceptancesContext } from '../../../../contexts/AcceptancesContext'
import ClientContext from '../../../../contexts/ClientContext'
import { ColorContext } from '../../../../contexts/ColorContext'
import NotificationContext from '../../../../contexts/NotificationContext'
import { timezoneFormatWithHour } from '../../../../core/timezoneFormat'
import { Acceptance } from '../../../../types/types'
import MyButtonAsText from '../../../../components/MyButtonAsText'
import { useDialog } from '../../../../hooks/useDialog'
import { ReviewInvestor } from './ReviewInvestor'
import { useTranslation } from 'react-i18next'

export const InvestorsVDR = () => {
  const {
    pendingAcceptances,
    changeConfidentialityStatusByIssuer,
    loadStatusAcceptancesByIssuer
  } = useContext(AcceptancesContext)
  const { isOpen, closeDialog, data, openDialog } = useDialog()
  const { showToast } = useContext(NotificationContext)
  const { timezone } = useContext(ClientContext)
  const [rows, setRows] = useState<any[]>([])
  const { theme } = useContext(ColorContext)
  const { t, i18n: { language } } = useTranslation()
  const { primary } = theme

  const onSubmit = async (investor_data: any, status_to_change: 'ACCEPTED' | 'REJECTED') => {
    const status = status_to_change === 'ACCEPTED' ? 'APPROVED' : 'REJECTED'
    const resp = await changeConfidentialityStatusByIssuer(data.investor_id, data.issuer_id, status)
    if (resp?.success) {
      await loadStatusAcceptancesByIssuer('REQUESTED')
      if (status === 'APPROVED') showToast('success', t('toasts.request_approved'))
      else showToast('success', t('toasts.request_rejected'))
    } else showToast('error', 'Error')
    closeDialog()
  }

  useEffect(() => {
    setRows(Array.from(pendingAcceptances, (e: Acceptance) => {
      const date = timezoneFormatWithHour(timezone, e.updatedAt)
      const { investor_id, issuer_id, investor } = e
      return {
        name: investor?.name,
        email: investor?.email,
        date,
        options:
          <MyFlexContainer>
            <MyButtonAsText color={primary} onClick={() => openDialog({ investor_id, issuer_id, email: investor?.email ?? '' })}>{t('review')}</MyButtonAsText>
          </MyFlexContainer>
      }
    }))
    // eslint-disable-next-line
  }, [pendingAcceptances, language])

  const headerVDR = [
    { header: t('full_name'), key: 'name' },
    { header: t('request_date'), key: 'date' },
    { header: t('options'), key: 'options' }
  ]

  return (
    <>
      <ReviewInvestor
        isOpen={isOpen}
        data={data}
        onClose={closeDialog}
        onSubmit={onSubmit}
      />
      <MyTable title='' rowData={rows} headerData={headerVDR} />
    </>
  )
}
