import { User } from '../types/types'
import { UseFetch } from './UseFetch'

interface GetUserResponse {
  message: string
  records: User[]
  success: boolean
}

export const getUserByEmail = async (email: string): Promise<GetUserResponse> => {
  const init = {
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('get', 'endpoint', `/users/${email}`, init)
}
