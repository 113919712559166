import styled from '@emotion/styled'

export const H1 = styled.p`
  font-weight: 700;
  font-size: 1.4rem;
`

export const H2 = styled.p`
  font-weight: 700;
  font-size: 1.2rem;
`
export const H3 = styled.p`
  font-weight: 700;
  font-size: 1rem;
`

export const H4 = styled.p`
  font-weight: 500;
  font-size: 1rem;
`
