import { Grid } from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TabsContainer } from '../../../components/DashboardTabs/TabsContainer'
import { MyButton } from '../../../components/MyButton'
import { Title } from '../../../components/Title'
import { AssetsContext } from '../../../contexts/AssetsContext'
import UserContext from '../../../contexts/UserContext'
import { useClientConfig } from '../../../hooks/useClientConfig'
import { useScrollToTop } from '../../../hooks/useScrollToTop'
import { DialogAssetForm } from './Activos/DialogAssetForm'
import { ApprovedAssets } from './Tables/ApprovedAssets'
import { AvailableAssets } from './Tables/AvailableAssets'
import { PendingAssets } from './Tables/PendingAssets'
import { RejectedAssets } from './Tables/RejectedAssets'
import { SelloutAssets } from './Tables/SelloutAssets'

export const AssetsDashboard = () => {
  const { loadAssetsByIssuerAndFilter } = useContext(AssetsContext)
  const [assetMode, setAssetMode] = useState<'crear' | 'editar'>('crear')
  const [showAssetForm, setShowAssetForm] = useState<boolean>(false)
  const [assetData, setAssetData] = useState<any>({})
  const { user } = useContext(UserContext)
  const { assetTypeLabel, formOfVerb } = useClientConfig()
  const navigate = useNavigate()
  const { t, i18n: { language } } = useTranslation()
  useScrollToTop()

  const openFormAsset = (mode: 'crear' | 'editar', data?: any) => {
    if (user?.issuer?.step !== 5) {
      navigate('/Dashboard/issuers-onb')
      return
    }
    if (mode === 'editar') setAssetData(data)
    setAssetMode(mode)
    setShowAssetForm(true)
  }

  const closeFormAsset = () => {
    setShowAssetForm(false)
  }

  const soldOutTitle = language === 'es' ? `Agotad${formOfVerb()}s` : t('company_user.sold_out')
  const approvedTitle = language === 'es' ? `Aprobad${formOfVerb()}s` : t('company_user.approved')
  const rejectedTitle = language === 'es' ? `Rechazad${formOfVerb()}s` : t('company_user.rejected')
  const createAsset = language === 'es' ? `Crear ${assetTypeLabel('singular', 'lowercase')}` : `Create ${assetTypeLabel('singular', 'lowercase')}`

  const Tabs = [
    { title: t('company_user.available'), component: <AvailableAssets /> },
    { title: soldOutTitle, component: <SelloutAssets /> },
    { title: approvedTitle, component: <ApprovedAssets /> },
    { title: t('company_user.pending'), component: <PendingAssets openEditForm={openFormAsset} /> },
    { title: rejectedTitle, component: <RejectedAssets /> }
  ]

  return (
    <>
      <Grid container mt={3} spacing={2}>
        <Grid item xs={12} sm={12} md={12} ml={6}>
          <Title>{assetTypeLabel('plural', 'uppercase')}</Title>
        </Grid>
        <Grid item xs={6} sm={8} md={9.5} />
        <Grid item xs={6} md={2.5} sm={4} textAlign='right'>
          <MyButton onMouseUp={() => openFormAsset('crear')}>
            {createAsset}
          </MyButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TabsContainer tabs={Tabs} />
        </Grid>
      </Grid>
      <DialogAssetForm
        refreshTables={loadAssetsByIssuerAndFilter}
        data={assetData}
        mode={assetMode}
        onClose={closeFormAsset}
        open={showAssetForm}
      />
    </>
  )
}
