import { Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { Form, Formik } from 'formik'
import { MyTextInput } from '../../../components'
import { MyButton } from '../../../components/MyButton'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
  updateData: (users: any[], userToChange: string, action: 'create' | 'delete', values?: any) => Promise<void>
  users: any[]
}

const initialValues = {
  first_names: '',
  fathers_name: '',
  mothers_name: '',
  email: ''
}

export const CreateUserModal = ({ isOpen, onClose, updateData, users }: Props) => {
  const { t } = useTranslation()
  const onSubmit = async (values: any) => {
    onClose()
    await updateData(users, values.email, 'create', values)
  }
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {t('add')}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {() => (
        <Form>
          <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <MyTextInput
                label={t('name')}
                name='first_names'
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <MyTextInput
                label={t('last_father_name')}
                name='fathers_name'
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <MyTextInput
                label={t('last_mother_name')}
                name='mothers_name'
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <MyTextInput
                label={t('email')}
                name='email'
              />
            </Grid>
          </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <MyButton onClick={onClose} color='secondary'>
              {t('cancel')}
            </MyButton>
            <MyButton type='submit'>
              {t('confirm')}
            </MyButton>
          </DialogActions>
        </Form>
      )}
      </Formik>
    </Dialog>
  )
}

const validationSchema = yup.object().shape({
  email: yup.string().email('email_format_not_valid').required('required_field'),
  first_names: yup.string().required('required_field'),
  fathers_name: yup.string().required('required_field'),
  mothers_name: yup.string().required('required_field')
})
