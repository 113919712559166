import * as yup from 'yup'

export const setupClientValidationSchema = yup.object().shape({
  currencyOptions: yup.string().required('required_field'),
  assetsType: yup.string().required('required_field'),
  timezone: yup.string().required('required_field'),
  person_type_terminology: yup.string().required('required_field'),
  send_email_address: yup.string().email('email').required('required_field'),
  receive_email_address: yup.string().email('email').required('required_field'),

  typeformUrl: yup.string().required('required_field'),

  disclaimerTitle: yup.string().required('required_field'),
  disclaimerDescription: yup.string().required('required_field')
})
