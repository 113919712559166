import { createContext } from 'react'
import { useInvestors } from '../hooks/useInvestors'

export const InvestorsContext = createContext<any>({})

export const InvestorsProvider = ({ children }: any) => {
  const InvestorsHook = useInvestors()
  return (
    <InvestorsContext.Provider value={InvestorsHook}>
      { children }
    </InvestorsContext.Provider>
  )
}
