import * as yup from 'yup'
import { REGEX_PASSWORD } from '../core/utils'

export const changePasswordValidationSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .matches(REGEX_PASSWORD, 'passwordRule')
    .required('required_field'),
  newPassword: yup
    .string()
    .notOneOf([yup.ref('oldPassword')], 'passwordMustBeDifferent')
    .matches(REGEX_PASSWORD, 'passwordRule')
    .required('required_field'),
  repeatNewPassword: yup.string()
    .oneOf([yup.ref('newPassword')], 'passwordMustBeEqual')
    .required('required_field')
})
