import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext, memo } from 'react'
import { MyButton } from '../../../../components/MyButton'
import { MyNumberFormat } from '../../../../components/MyNumberFormat'
import { MyTextArea } from '../../../../components/MyTextArea'
import * as yup from 'yup'
import ClientContext from '../../../../contexts/ClientContext'
import { Paragraph } from '../../../../components/Paragraph'
import { MyButtonIcon } from '../../../../components'
import { useTranslation } from 'react-i18next'

interface Props {
  setData: (values: any) => void
  nextStep: (values: any) => void
  previousStep: () => void
  initialValues: any
  onClose: () => void
}

export const OnboardingInvestorAccreditation = memo(({
  setData,
  previousStep,
  initialValues,
  nextStep,
  onClose
}: Props) => {
  const { currencyOptions } = useContext(ClientContext)
  const { t } = useTranslation()
  const onSubmit = async (values: any) => {
    const { desired_investment, investment_background, min_investment, total_previous_investment } = values
    let additional_data: { [prop: string]: any } = {
      min_investment: Number(min_investment),
      investment_background
    }
    if (desired_investment) additional_data = { ...additional_data, desired_investment: Number(desired_investment) }
    if (total_previous_investment) additional_data = { ...additional_data, total_previous_investment: Number(total_previous_investment) }

    if (!additional_data.desired_investment) additional_data.desired_investment = 0
    if (!additional_data.total_previous_investment) additional_data.total_previous_investment = 0

    setData(values)
    nextStep({ additional_data })
  }
  return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {() => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item sm={12} mb={2}>
                            <MyButtonIcon
                                size='small'
                                icon='backArrow'
                                onClick={previousStep}
                                text={t('back')}
                                type='secondary'
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Paragraph type='subheading'><strong>{t('investor_user.onboarding_extended.title_three')}</strong></Paragraph>
                        </Grid>
                        <Grid item sm={12}>
                            <Paragraph>{t('company_user.create_asset.subtitle_two')}</Paragraph>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} >
                            <MyNumberFormat
                                currency={currencyOptions[0]}
                                name='desired_investment'
                                label={t('investor_user.onboarding_extended.desired_investment')}
                            />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item sm={12} xs={12} md={6} >
                            <MyNumberFormat
                                currency={currencyOptions[0]}
                                name='total_previous_investment'
                                label={t('asset_under_management_optional')}
                            />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item sm={12} xs={12} md={6} >
                            <MyNumberFormat
                                currency={currencyOptions[0]}
                                name='min_investment'
                                label={t('min_investment_per_ticket')}
                            />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item sm={12} xs={12} md={6}>
                            <MyTextArea
                                label={t('investor_user.onboarding_extended.investment_background')}
                                name='investment_background'
                            />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item sm={6} xs={12} md={3}>
                            <MyButton color='secondary' onMouseUp={onClose} fullWidth>
                                {t('continue_later')}
                            </MyButton>
                        </Grid>
                        <Grid item sm={6} xs={12} md={3} >
                            <MyButton type='submit' fullWidth>
                                {t('next')}
                            </MyButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
  )
})

const validationSchema = yup.object().shape({
  desired_investment: yup.number().typeError(''),
  min_investment: yup.number().typeError(''),
  total_previous_investment: yup.number().typeError(''),
  investment_background: yup.string().trim()
})
