export const GOOGLE_ANALYTICS_MEASUREMENT_ID = 'G-VP9KYVFZW9'
export const AUTH = {
  region: 'us-east-1',
  userPoolId: 'us-east-1_fRS8k6DGU',
  userPoolWebClientId: '1eo7j8h5a995q0qbc9hqc2q8el'
}

export const DEFAULT_LANGUAGE = 'es'
export const SUPPORTED_LANGUAGES = ['es', 'en']

export const getLanguageResources = () => {
  const resources: { [key: string]: any } = {}
  SUPPORTED_LANGUAGES.forEach((language) => {
    resources[language] = {
      translation: require(`./translations/${language}/translation.json`)
    }
  })
  return resources
}
