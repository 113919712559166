import { useField } from 'formik'
import { useState } from 'react'
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useTranslation } from 'react-i18next'

interface Props {
  label: string
  name: string
  placeholder?: string
  [propName: string]: any
}

export const MyPasswordToggleButton = ({ ...props }: Props) => {
  const [t] = useTranslation()
  const [field, meta] = useField(props)
  const [showPassword, setShowPassword] = useState(false)
  const showError: boolean = !!meta.error && meta.touched

  const changeVisibilityPassword = () => {
    setShowPassword(value => !value)
  }

  return (
        <FormControl
            variant='outlined'
            fullWidth
            error={showError}
        >
            <InputLabel htmlFor="outlined-adornment-password">{props.label}</InputLabel>
            <OutlinedInput
                error={showError}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={changeVisibilityPassword}
                            onMouseDown={changeVisibilityPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                {...field}
                {...props}
            />
            {showError
              ? <FormHelperText>{t(`forms.${meta?.error ?? 'required_field'}`)}</FormHelperText>
              : <></>
            }
        </FormControl>
  )
}
