import styled from '@emotion/styled'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { SUPPORTED_LANGUAGES } from '../constants'

const FLAG: { [prop: string]: string } = {
  es: 'mx',
  en: 'us'
}

export const LanguagePicker = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  const changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    void i18n.changeLanguage(event.target.value)
    localStorage.setItem('user_config_language', event.target.value)
  }
  return (
    <Container>
      <span className={`fi fi-${FLAG[language]}`}></span>
      <select onChange={(value) => changeLanguage(value)} defaultValue={i18n.language}>
        {SUPPORTED_LANGUAGES.map((language) =>
          <option key={language} value={language}>{t(language)}</option>
        )}
      </select>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 16px;
  right: 0;
  background-color: white;
  padding: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  z-index: 100;
  
  select {
    cursor: pointer;
    display: none;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 10px;
    border: none;
    border-radius: 5px;
    appearance: none;
    background-color: white;
    :focus-visible {
      outline: none;
    }
  }

  option {
    text-align: left;
  }

  :hover {
    select {
      display: inline;
    }
  }
`
