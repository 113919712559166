import { createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdminEN, AdminES, AdminTranslateProps } from '../data/Translate/AdminTranslate'
import { UserInputEN, UserInputES, UserInputProps } from '../data/Translate/SharedLabels'

interface IdiomProp {
  admin: AdminTranslateProps
  userInput: UserInputProps
  [propsName: string]: any
}

interface Props {
  es: IdiomProp
  en: IdiomProp
}

interface ContextProps {
  adminTranslate: AdminTranslateProps
  userInputTranslate: UserInputProps
  [propsName: string]: any
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const IdiomContext = createContext({} as ContextProps)

const dictionary: Props = {
  es: {
    admin: AdminES,
    userInput: UserInputES,
    selectIdiom: [
      { value: 'es', label: 'Español' },
      { value: 'en', label: 'Inglés' }
    ],
    selectTerminolgy: [
      { value: 'latam', label: 'Latam (natural/jurídica)' },
      { value: 'mex', label: 'México (física/moral)' }
    ],
    actualTerminology: {
      latam: [
        { value: 1, text: 'natural' },
        { value: 2, text: 'jurídica' }
      ],
      mex: [
        { value: 1, text: 'física' },
        { value: 2, text: 'moral' }
      ]
    },
    auth: {
      login: {
        labels: {
          title: 'Iniciar Sesión',
          newAccount: '¿No tienes una cuenta todavía?',
          register: '¡Te damos la bienvenida!',
          haveAccount: '¿Ya tienes una cuenta?',
          forgetEmail: '¿Olvidaste tu correo?',
          forgetPassword: '¿Olvidaste tu contraseña?',
          alternativeLogin: 'Alternativa de inicio de sesión',
          alternativeRegister: 'Alternativas de registro',
          loginWith: 'Iniciando sesión como',
          incorrectEmail: '¿No eres tú?',
          forgetPasswordTitle: 'Recuperar contraseña',
          forgetPasswordBody: 'Se ha enviado un link para recuperar tu contraseña al correo con el que te registraste a la plataforma',
          recoverPasswordTitle: 'Recuperar contraseña',
          passwordRules:
            <ul style={{ listStyle: 'disc' }}>
                <li>Longitud mínima de 8 carácteres</li>
                <li>Al menos una mayúscula y una minúscula</li>
                <li>Al menos un número</li>
                <li>Al menos un caracter especial (e.g. @, #, $, %, etc)</li>
            </ul>,
          createPasswordTitle: 'Crea tu contraseña',
          createPasswordFor: 'Creando contraseña nueva para'
        },
        buttons: {
          rememberMe: 'Recordarme',
          continue: 'Continuar',
          login: 'Iniciar sesión',
          recoverPassword: 'Recuperar contraseña',
          googleLogin: 'Iniciar sesión con Google',
          googleRegister: 'Registrarme con Google',
          createPassword: 'Crear contraseña'
        },
        inputs: {
          email: 'Correo electrónico',
          password: 'Contraseña',
          codeRecover: 'Código de recuperación',
          newPassword: 'Contraseña nueva'
        }
      },
      errors: {
        AUTH0001: 'Usuario ya existente',
        'instance.password does not match pattern "^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$"': 'Por favor verifica la contraseña ingresada',
        'Email not found': 'Email no encontrado',
        'Wrong password': 'Contraseña errónea',
        AUTH0003: 'Email no encontrado',
        AUTH0004: 'Codigo de verificación erróneo',
        AUTH0005: 'Contraseña errónea',
        AUTH0006: 'Primero debes ingresar con tu contraseña temporal antes de recuperar la contraseña',
        ExpiredCodeException: 'Código inválido, por favor solicite un código de nuevo',
        LimitExceededException: 'Límite de intentos superado, por favor, inténtelo después de un tiempo',
        UsernameExistsException: 'Usuario ya existente',
        CodeDeliveryFailureException: 'No se ha podido crear el usuario, por favor reintentar.'
      }
    },
    issuerCreation: {
      labels: {
        titleTabOne: 'Clasificación',
        textTabOne: 'Por favor, selecciona el tipo de clasificación de la empresa.',
        titleTabTwo: 'Datos de empresa',
        textTabTwo: 'Por favor, ingresa los datos necesarios para dar de alta a una nueva empresa.',
        titleTabThree: 'Se ha enviado un correo de confirmación al cliente',
        textTabThree: 'Hemos enviado un link de confirmación al correo electrónico principal del cliente. Una vez confirme su correo, el cliente podrá empezar a llenar sus datos y completar su registro.',
        userTwoData: 'Datos del usuario 2 (opcional)',
        userThreeData: 'Datos del usuario 3 (opcional)',
        representantOne: 'Datos del representante legal 1 (opcional)',
        representantTwo: 'Datos del representante legal 2 (opcional)'
      },
      buttons: {
        next: 'Siguiente',
        cancel: 'Cancelar',
        before: 'Anterior',
        finish: 'Terminar'
      },
      inputs: {
        selectPersonType: 'Tipo de persona',
        issuerName: 'Nombre de la empresa',
        name: 'Nombre(s)',
        fathersName: 'Apellido paterno',
        mothersName: 'Apellido materno',
        email: 'Correo electrónico'
      }
    },
    onboardingInvestor: {
      labels: {
        titleTabOne: 'Termina tu registro para poder comprar',
        textTabOne: 'A continuación podrás completar información faltante sobre tu registro. Para empezar, primer selecciona tu tipo de acreditación.',
        titleTabTwo: 'Mis datos',
        textTabTwo: 'Por favor, ingresar los siguientes datos para completar tu registro',
        titleTabThree: 'Mi experiencia',
        textTabThree: 'Por favor, completa los siguientes datos para completar tu registro.',
        titleTabFour: 'Configura tu Wallet',
        textTabFour: 'Una wallet es una billetera digital similar a una cuenta bancaria, donde se almacenan tus tokens. Las transferencias realizadas son registradas de forma inmutable en la red blockchain. Inicialmente crearemos tu wallet y almacenaremos tus tokens para facilitarte su uso. Prontamente podrás hacerlo tú mismo, si así lo deseas.',
        needHelpTitle: 'Próximamente podremos ayudarte',
        needHelpText: 'Muy pronto podremos ayudarte en esta configuración. Cuando este contenido esté listo, te haremos llegar una notificación dentro de la plataforma y por correo',
        createWalletTitle: '¿Quieres crear una wallet?',
        haveWalletTitle: 'Registro de dirección de tu wallet',
        haveWalletText: 'Por favor, ingresa la dirección de tu wallet para asociarla a tu cuenta en la plataforma. NOTA: Sólo se admiten direcciones de wallets, no de exchanges.',
        haveWalletSuccess: 'Tu wallet con dirección',
        haveWalletMessageSuccess: 'Fue registrada exitosamente y ahora está asociada a tu perfil en la plataforma.',
        userTwoData: 'Datos del usuario 2 (opcional)',
        userThreeData: 'Datos del usuario 3 (opcional)',
        representantOne: 'Datos del representante legal 1 (opcional)',
        representantTwo: 'Datos del representante legal 2 (opcional)',
        tabFiveTitle: '¡Gracias por enviar tu solicitud!',
        tabFiveText: 'Ahora revisaremos tus datos y evaluaremos tu cuenta para que puedas operar las oportunidades de inversión que tenemos para ti. Espera noticias en tu correo electrónico y en las notificaciones de la plataforma.',
        tabFiveTitleExpress: 'Gracias',
        tabFiveTextExpress: 'Ahora revisaremos tus datos y evaluaremos tu cuenta para que puedas operar y aprovechar las oportunidades de inversión que tenemos para ti. Espera noticias en tu correo y en las notificaciones de la plataforma.'
      },
      buttons: {
        next: 'Siguiente',
        cancel: 'Cancelar',
        before: 'Anterior',
        finish: 'Terminar',
        accept: 'De acuerdo',
        registerWallet: 'Registrar wallet',
        needHelp: '¿Necesitas ayuda?',
        createWallet: 'Quiero crear una wallet',
        haveWallet: 'Ya tengo un wallet'
      },
      inputs: {
        wallet: 'Dirección de wallet',
        selectPersonType: 'Tipo de persona',
        issuerName: 'Nombre de la empresa',
        totalAssets: '¿Cuánto quieres invertir esta vez?',
        minInvestor: 'Descripción / Cuéntanos tu experiencia invirtiendo',
        howMuchInvestor: '¿Cuánto quieres invertir esta vez?\n',
        xp: 'Descripción / Cuéntanos tu experiencia invirtiendo',
        name: 'Nombre',
        fathersName: 'Apellido paterno',
        mothersName: 'Apellido materno',
        email: 'Correo electrónico',
        country: 'País',
        phone: 'Teléfono (opcional)'
      }
    },
    adminClient: {
      businessTable: {
        business: 'Empresa',
        labelBusiness: 'Nombre de la empresa',
        users: 'Usuarios',
        newBusinessButton: 'Crear nueva empresa'
      },
      assetsTable: {
        firstLabel: 'Todos los activos',
        secondLabel: 'Solicitudes de alta',
        title: 'Activos aprobados',
        firstHeaderTable: 'Activo',
        secondHeaderTable: 'Digitalisación',
        nameLabel: 'Nombre',
        currencyLabel: 'Moneda',
        valuationLabel: 'Valuación',
        percentageLabel: 'Porcentaje',
        quantityLabel: 'Cantidad',
        priceLabel: 'Precio'
      },
      investorsTable: {
        firstLabel: 'Todos los inversionistas',
        secondLabel: 'Registration requests',
        title: 'Inversionistas Aprobados'
      },
      paymentsTable: {
        label: 'Validaciones de pagos',
        title: 'Purchase payment validation requests'
      }
    },

    yup: {
      email: 'Formato de email inválido',
      passwordRule: 'Formato de contraseña incorrecta',
      default: 'Campo requerido',
      url: 'Formato de url inválido',
      maxIsHundred: 'El número no puede ser mayor a 100',
      minIsZero: 'El número no puede ser inferior o igual a 0',
      integer: 'El número debe ser entero',
      noSpecialChar: 'Carácter especial no permitidos',
      onlyWords: 'Números y carácteres especiales no permitidos',
      positive: 'El número debe ser mayor a 0',
      fieldMaxSize: 'No cumple con el número de caracteres',
      fieldMinSize: 'No cumple con el número de caracteres',
      passwordMustBeDifferent: 'La contraseña actual debe ser diferente a la contraseña nueva',
      passwordMustBeEqual: 'Nueva contraseña no coincide',
      nonNegative: 'No se permite números negativos'
    },
    commonText: {
      investor: {
        generalData: 'Datos generales de inversionistas'
      },
      buttons: {
        authorize: 'Autorizar',
        decline: 'Rechazar',
        checkLater: 'Revisar más tarde',
        createAsset: 'Crear activo',
        next: 'Siguiente',
        cancelOperation: 'Cancelar operación',
        cancel: 'Cancelar',
        uploadDocument: 'Cargar nuevo documento'

      },
      assetsTableTitles: {
        availableAssets: 'Activos disponibles',
        approvedAssets: 'Activos aprobados',
        pendingAssets: 'Activos pendientes',
        rejectedAssets: 'Activos rechazados'
      },
      tokens: {
        availableTokens: 'Tokens disponibles',
        requestedTokens: 'Tokens solicitados',
        tokenPrice: 'Precio token',
        totalTokens: 'Tokens totales'

      },
      admin: 'Administrador',
      approved: 'Aprobados',
      asset: 'Activo',
      assets: 'Activos',
      available: 'Disponibles',
      business: 'Negocio',
      classification: 'Clasificación',
      company: 'Empresa',
      check: 'Revisar',
      complementary: 'Otros',
      confidential: 'Confidencial',
      confirmation: 'Confirmación',
      country: 'País',
      currency: 'Moneda',
      customer: 'Clientes',
      documentation: 'Documentación',
      email: 'Email',
      eliminate: 'Eliminar',
      financial: 'Financiero',
      investors: 'Inversionistas',
      investorType: 'Tipo de inversionista',
      legal: 'Legal',
      makeAvailable: 'Disponibilizar',
      name: 'Nombre',
      option: 'Opciones',
      pending: 'Pendientes',
      percentage: 'Porcentaje',
      price: 'Precio',
      purchase: 'Compras',
      purchaseRequest: 'Mis inversiones',
      purchaseRequestIndividual: 'Solicitud de compra',
      purchaseValue: 'Valor de compra',
      purchaseData: 'Datos de compra',
      quantity: 'Cantidad',
      rejected: 'Rechazados',
      records: 'Registros',
      request: 'Solicitud de',
      rowQuantity: 'Filas por página',
      typeOfPerson: 'Tipo de persona',
      users: 'Usuarios',
      valuation: 'Valuación'

    }
  },
  en: {
    admin: AdminEN,
    userInput: UserInputEN,
    selectIdiom: [
      { value: 'es', label: 'Spanish' },
      { value: 'en', label: 'English' }
    ],
    selectTerminolgy: [
      { value: 'latam', label: 'Natural and Legal' }
    ],
    actualTerminology: {
      latam: [
        { value: 1, text: 'natural' },
        { value: 2, text: 'legal' }
      ],
      mex: [
        { value: 1, text: 'natural' },
        { value: 2, text: 'legal' }
      ]
    },
    auth: {
      login: {
        labels: {
          title: 'Login',
          newAccount: 'Don\'t have an account yet?',
          register: 'Register',
          haveAccount: 'Already have an account?',
          forgetEmail: 'Forgot your email?',
          alternativeLogin: 'Login alternative',
          forgetPassword: 'Forgot my password',
          loginWith: 'Logging in as',
          alternativeRegister: 'Registration alternatives',
          incorrectEmail: '¿Are not you?',
          forgetPasswordTitle: 'Password recovery',
          forgetPasswordBody: 'A link to recover your password has been sent to the e-mail address you used to register to the platform.',
          recoverPasswordTitle: 'Password recovery',
          passwordRules:
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Length from 8 to 16 characters</li>
                            <li>At least one uppercase and one lowercase letter</li>
                            <li>At least one number</li>
                            <li>At least one of the following symbols: (@ # # $ % ^ & + =</li>
                        </ul>,
          createPasswordTitle: 'Create your password',
          createPasswordFor: 'Creating a new password for'
        },
        buttons: {
          rememberMe: 'Remember me',
          continue: 'Continue',
          login: 'Login',
          googleLogin: 'Sign in with Google',
          googleRegister: 'Register with Google',
          recoverPassword: 'Password recovery',
          createPassword: 'Create password'
        },
        inputs: {
          email: 'Username',
          codeRecover: 'Recovery code',
          password: 'Password',
          newPassword: 'New password'
        }
      },
      errors: {
        AUTH0001: 'Email already exists',
        'instance.password does not match pattern "^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$"': 'Por favor verifica la contraseña ingresada',
        'Email not found': 'Email not found',
        AUTH0003: 'Email not found',
        AUTH0004: 'Wrong verification code',
        AUTH0005: 'Wrong password',
        'Wrong password': 'Wrong password',
        AUTH0006: 'You must first log in with your temporary password before recovering your password',
        ExpiredCodeException: 'Invalid code provided, please request a code again',
        LimitExceededException: 'attempt limit exceeded, please try after some time',
        UsernameExistsException: 'Email already exists',
        CodeDeliveryFailureException: 'Unable to send email due SES message limits'
      }
    },
    issuerCreation: {
      labels: {
        titleTabOne: 'Qualification',
        textTabOne: 'Please select the issuer\'s classification type.',
        titleTabTwo: 'Issuer data - ',
        textTabTwo: 'Please enter the necessary data to register a new issuer.',
        titleTabThree: 'A confirmation email has been sent to the customer',
        textTabThree: 'We have sent a confirmation link to the customer\'s main email address. Once the customer confirms their email, they will be able to start filling in their data and complete their registration.',
        userTwoData: 'User data 2 (optional)',
        userThreeData: 'User data 3 (optional)',
        representantOne: 'Data of representative 1 (optional)',
        representantTwo: 'Data of representative 2 (optional)'
      },
      buttons: {
        next: 'Next',
        cancel: 'Cancel',
        before: 'Previous',
        finish: 'Finish'
      },
      inputs: {
        selectPersonType: 'Type of person',
        issuerName: 'Name of issuer',
        name: 'Name(s)',
        fathersName: 'Paternal surname',
        mothersName: 'Mother\'s last name',
        email: 'Email'
      }
    },
    onboardingInvestor: {
      labels: {
        titleTabOne: 'Finish your registration to be able to buy',
        textTabOne: 'Below you will be able to complete missing information about your registration. To begin, first select your accreditation type',
        titleTabTwo: 'My data',
        textTabTwo: 'Please fill in the following information to complete your registration',
        titleTabThree: 'My experience',
        textTabThree: 'Please fill in the following information to complete your registration.',
        titleTabFour: 'Configure your Wallet',
        textTabFour: 'A wallet is a digital wallet where your tokens will be stored, similar to a "bank account", but immutably registered in the blockchain network. Initially, we will store and manage your tokens on your behalf.',
        needHelpTitle: 'Soon we will be able to help you',
        needHelpText: 'We will be able to help you with this configuration very soon. When this content is ready, we will send you a notification within the platform and by mail.',
        createWalletTitle: 'Do you want to create a wallet?',
        haveWalletTitle: 'Wallet address registration',
        haveWalletText: 'Please enter your wallet address to associate it with your account on the platform. NOTE: Only wallet addresses are supported, not exchanges.',
        haveWalletSuccess: 'Your wallet with address',
        haveWalletMessageSuccess: 'It was successfully registered and is now associated with your profile on the platform.',
        representantOne: 'Representative data 1',
        representantTwo: 'Representative\'s data 2 (optional)',
        tabFiveTitle: 'Thanks',
        tabFiveText: 'Now we will review your data and evaluate your account so you can trade and take advantage of the investment opportunities we have for you. Wait for news in your email and in the platform notifications.',
        tabFiveTitleExpress: 'Thanks',
        tabFiveTextExpress: 'Now we will review your data and evaluate your account so you can trade and take advantage of the investment opportunities we have for you. Wait for news in your email and in the platform notifications.'
      },
      buttons: {
        next: 'Next',
        cancel: 'Cancel',
        before: 'Previous',
        finish: 'Finish',
        accept: 'Agreed',
        registerWallet: 'Register wallet',
        needHelp: 'Need help?',
        createWallet: 'I want to create a wallet',
        haveWallet: 'I already have a wallet'
      },
      inputs: {
        wallet: 'Wallet address',
        selectPersonType: 'Type of person',
        issuerName: 'Name of issuer',
        totalAssets: 'Total invested assets',
        minInvestor: 'Preferred minimum investment',
        howMuchInvestor: 'How much do you want to invest?',
        xp: 'Description / Tell us about your investment experience',
        name: 'Name(s)',
        fathersName: 'Paternal surname',
        mothersName: 'Mother\'s last name',
        email: 'Email',
        country: 'Country',
        phone: 'Phone (optional)'
      }
    },
    adminClient: {
      businessTable: {
        business: 'Business',
        labelBusiness: 'Business name',
        users: 'users',
        newBusinessButton: 'Create new business'
      },
      assetsTable: {
        firstLabel: 'All assets',
        secondLabel: 'Registration requests',
        title: 'Approved Assets',
        firstHeaderTable: 'Assets',
        secondHeaderTable: 'Digitalization',
        nameLabel: 'Name',
        currencyLabel: 'Currency',
        valuationLabel: 'Valuation',
        percentageLabel: 'Percentage',
        quantityLabel: 'Quantity',
        priceLabel: 'Price'
      },
      investorsTable: {
        firstLabel: 'All investors',
        secondLabel: 'Registration requests',
        title: 'Approved investors'
      },
      paymentsTable: {
        label: 'Payments validations',
        title: 'Purchase payment validation requests'
      }
    },
    yup: {
      email: 'Invalid email format',
      default: 'Required field',
      passwordRule: 'Invalid password format',
      maxIsHundred: 'The number cannot exceed 100',
      minIsZero: 'The number cannot be less than 0',
      integer: 'The number must be an integer',
      url: 'Invalid url format',
      noSpecialChar: 'Special characters not allowed',
      onlyWords: 'Numbers and special characters not allowed',
      positive: 'Number must be greater than 0',
      fieldMaxSize: 'Does not meet the number of characters',
      fieldMinSize: 'Does not meet the number of characters',
      passwordMustBeDifferent: 'The current password must be different from the new password.',
      passwordMustBeEqual: 'New password does not match',
      nonNegative: 'Negative numbers are not allowed'
    },
    commonText: {
      investor: {
        generalData: 'Investor\'s general data'
      },
      buttons: {
        authorize: 'Authorize',
        decline: 'Decline',
        checkLater: 'Check later',
        createAsset: 'Create asset',
        next: 'Next',
        cancelOperation: 'Cancel Operation',
        cancel: 'Cancel',
        uploadDocument: 'Upload new document'

      },
      assetsTableTitles: {
        availableAssets: 'Available Assets',
        approvedAssets: 'Approved Assets',
        pendingAssets: 'Pending Assets',
        rejectedAssets: 'Rejected Assets'
      },
      tokens: {
        availableTokens: 'Available tokens',
        requestedTokens: 'Requested tokens',
        tokenPrice: 'Token price',
        totalTokens: 'Total tokens'
      },
      admin: 'Administrator',
      approved: 'Approved',
      asset: 'Asset',
      assets: 'Assets',
      available: 'Available',
      business: 'Business',
      classification: 'Classification',
      company: 'Companies',
      check: 'Check',
      complementary: 'Others',
      confidential: 'Confidential',
      confirmation: 'Confirmation',
      country: 'Country',
      currency: 'Currency',
      customer: 'Customers',
      documentation: 'Documentation',
      email: 'Email',
      eliminate: 'Eliminate',
      financial: 'Financial',
      investors: 'Investors',
      investorType: 'Investor type',
      legal: 'Legal',
      makeAvailable: 'Make available',
      name: 'Name',
      option: 'Options',
      pending: 'Pending',
      percentage: 'Percentage',
      price: 'Price',
      purchase: 'Purchase',
      purchaseValue: 'Purchase value',
      purchaseRequest: 'Purchase requisitions',
      purchaseRequestIndividual: 'Purchase request',
      purchaseData: 'Purchase data',
      quantity: 'Quantity',
      rejected: 'Rejected',
      records: 'Records',
      request: 'Request',
      rowQuantity: 'Rows per page',
      typeOfPerson: 'Person type',
      users: 'Users',
      valuation: 'Valuation'
    }
  }
}

const getIdiomConfig = () => {
  const s_client = localStorage.getItem('client')
  if (!s_client) return 'latam'
  const parse_client = JSON.parse(s_client)
  return parse_client?.client?.configuration?.personTypeTerminology
}

const token_action_wording = {
  es: {
    token: 'token',
    Token: 'Token',
    tokens: 'tokens',
    Tokens: 'Tokens',
    requested_token: 'Tokens solicitados',

    action: 'acción',
    Action: 'Acción',
    actions: 'acciones',
    Actions: 'Acciones',
    requested_action: 'Acciones solicitadas',

    token_price: 'Precio token',
    action_price: 'Precio acción',

    price_by_token: 'Precio por token',
    price_by_action: 'Precio por acción',

    token_available: 'Tokens disponibles',
    action_available: 'Acciones disponibles',

    total_tokens: 'Tokens totales',
    total_actions: 'Acciones totales'
  },
  en: {
    token: 'token',
    Token: 'Token',
    tokens: 'tokens',
    Tokens: 'Tokens',
    requested_token: 'Requested tokens ',
    action: 'share',
    Action: 'Share',
    actions: 'shares',
    Actions: 'Shares',
    requested_action: 'Requested shares',

    token_price: 'Token price',
    action_price: 'Share price',

    price_by_token: 'Token price',
    price_by_action: 'Share price',

    token_available: 'Available tokens',
    action_available: 'Available shares',

    total_tokens: 'Total of tokens',
    total_actions: 'Total shares'
  }
}

const IdiomProvider = ({ children }: any) => {
  const [idiom, setIdiom] = useState<'es' | 'en'>('es')
  const [terminolgy, setTerminology] = useState<'latam' | 'mex'>(() => getIdiomConfig())
  const { i18n: { language } } = useTranslation()
  const currentIdiom = language === 'es' ? 'es' : 'en'
  const dict = dictionary?.[idiom]

  const authFlowErrors = dict?.auth?.errors
  const dataSelectIdiom = dict?.selectIdiom
  const loginLabels = dict?.auth?.login?.labels
  const loginButtons = dict?.auth?.login?.buttons
  const loginInputs = dict?.auth?.login?.inputs
  const yupErrors = dict?.yup
  const issuerCreationLabels = dict?.issuerCreation.labels
  const issuerCreationButtons = dict?.issuerCreation.buttons
  const issuerCreationInputs = dict?.issuerCreation.inputs
  const onboardingInvestorLabels = dict?.onboardingInvestor.labels
  const onboardingInvestorButtons = dict?.onboardingInvestor.buttons
  const onboardingInvestorInputs = dict?.onboardingInvestor.inputs

  const dataTerminology = dict?.selectTerminolgy
  const actualTerm = dictionary[currentIdiom].actualTerminology[terminolgy]
  const commonText = dict?.commonText
  const adminClient = dict?.adminClient

  const current_token_action = token_action_wording[currentIdiom]
  const token_action_uppercase = terminolgy === 'latam' ? current_token_action.Token : current_token_action.Action
  const token_action_lowercase = terminolgy === 'latam' ? current_token_action.token : current_token_action.action
  const tokens_actions_lowercase = terminolgy === 'latam' ? current_token_action.tokens : current_token_action.actions
  const tokens_actions_uppercase = terminolgy === 'latam' ? current_token_action.Tokens : current_token_action.Actions
  const tokens_actions_request = terminolgy === 'latam' ? current_token_action.requested_token : current_token_action.requested_action
  const tokens_actions_price = terminolgy === 'latam' ? current_token_action.token_price : current_token_action.action_price
  const tokens_actions_price_by = terminolgy === 'latam' ? current_token_action.price_by_token : current_token_action.price_by_action
  const available_tokens_actions = terminolgy === 'latam' ? current_token_action.token_available : current_token_action.action_available
  const total_tokens_actions = terminolgy === 'latam' ? current_token_action.total_tokens : current_token_action.total_actions
  const adminTranslate = dict.admin
  const userInputTranslate = dict.userInput

  const data = {
    authFlowErrors,
    setIdiom,
    idiom,
    dataSelectIdiom,
    loginLabels,
    loginButtons,
    loginInputs,
    yupErrors,
    issuerCreationLabels,
    issuerCreationButtons,
    issuerCreationInputs,
    onboardingInvestorLabels,
    onboardingInvestorButtons,
    onboardingInvestorInputs,
    dataTerminology,
    setTerminology,
    terminolgy,
    actualTerm,
    commonText,
    adminClient,
    token_action_uppercase,
    token_action_lowercase,
    tokens_actions_lowercase,
    tokens_actions_uppercase,
    tokens_actions_request,
    adminTranslate,
    userInputTranslate,
    tokens_actions_price,
    tokens_actions_price_by,
    available_tokens_actions,
    total_tokens_actions
  }

  return (
        <IdiomContext.Provider value={data}>
            {children}
        </IdiomContext.Provider>
  )
}

export { IdiomContext }
export default IdiomProvider
