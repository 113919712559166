import imageCompression from 'browser-image-compression'

export const handleImageCompression = async (image: File) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(image, options)
    console.log(`originalFile size ${image.size / 1024 / 1024} MB`)
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`)
    return compressedFile
  } catch (error) {
    return image
  }
}
