import { FastField } from 'formik'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  label: string
  name: string
  type?: 'text' | 'email' | 'password' | 'number'
  placeholder?: string
  [propName: string]: any
}

export const MyTextInput = ({ ...props }: Props) => {
  const { t, i18n: { language } } = useTranslation()

  return (
    <FastField name={props.name} key={`select-${props.name}-${language}`}>
      {(({ field, meta }: any) =>
        <TextField
          fullWidth
          id={props.name}
          error={!!meta.error && meta.touched}
          helperText={!!meta.error && meta.touched ? t(`forms.${meta?.error || 'required_field'}`) : ''}
          {...field}
          {...props}
        />
      )}
    </FastField>
  )
}
