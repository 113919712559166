import { Toggle } from 'carbon-components-react'
import { useField } from 'formik'

interface Props {
  name: string
  label: string
  [propName: string]: any
}

export const MyToggle = ({ label, ...props }: Props) => {
  const [field] = useField(props)
  return (
        <Toggle
            id={props.name}
            labelText=''
            labelA={label}
            labelB={label}
            {...props}
            {...field}
        />
  )
}
