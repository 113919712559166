import './persona.css'
import Persona from 'persona'
import { personaEnv } from '../../../../../core/utils'

interface Props {
  onSubmit: () => void
  id: string
}

export const PersonaInquiry = ({ onSubmit, id }: Props) => {
  return (
    <div style={{ width: '100%', height: 800 }}>
      <Persona.Inquiry
        language='es-MX'
        templateId={id}
        environment={personaEnv}
        onComplete={() => onSubmit()}
      />
    </div>
  )
}
