import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ClientContext from '../contexts/ClientContext'
import LoadingContext from '../contexts/LoadingContext'
import NotificationContext from '../contexts/NotificationContext'
import UserContext from '../contexts/UserContext'
import { GetHistoryTransactionResponse, getHistoryTransactions, GetTransactionResponse, getTransactions, putTransactions } from '../core/apiTransaction'
import { Transaction } from '../types/types'

export const useTransactions = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { getIssuerId, investor_id } = useContext(UserContext)
  const { clientId } = useContext(ClientContext)
  const { t } = useTranslation()

  const loadTransactions = async () => {
    showLoading()
    const body = { clientId: clientId() }
    const resp: GetTransactionResponse = await getTransactions(body)
    if (resp.success) setTransactions(resp.records)
    hideLoading()
  }

  const loadHistoryTransactions = async () => {
    showLoading()
    const body = {
      clientId: clientId(),
      investorId: investor_id
    }
    const resp: GetHistoryTransactionResponse = await getHistoryTransactions(body)
    if (resp.success) setTransactions(resp.records)
    hideLoading()
  }

  const loadTransactionsByIssuer = async () => {
    showLoading()
    const issuer_id = getIssuerId()
    const body = { clientId: clientId(), issuer_id }
    const resp: GetTransactionResponse = await getTransactions(body)
    if (resp.success) {
      const filterData = resp.records.filter((transaction: Transaction) =>
        transaction.authorized === 'pending' || transaction.authorized === 'approved'
      )
      setTransactions(filterData)
    }
    hideLoading()
  }

  const loadTransactionsByClient = async () => {
    showLoading()
    const body = { clientId: clientId() }
    const resp: GetTransactionResponse = await getTransactions(body)
    if (resp.success) {
      const filterData = resp.records.filter(({ pay_confirmed, authorized }: Transaction) =>
        authorized === 'approved' && (pay_confirmed === 'pending' || pay_confirmed === 'approved')
      )
      setTransactions(filterData)
    }
    hideLoading()
  }

  const changeTransactionState = async (
    id: string,
    type: 'pay_confirmed' | 'authorized',
    state: 'pending' | 'approved' | 'rejected'
  ) => {
    showLoading()
    const resp = await putTransactions({ id, values: { [type]: state }, clientId: clientId() })
    if (resp.success) {
      if (state === 'approved') {
        showToast('success', t('toasts.request_approved'))
      } else {
        showToast('success', t('toasts.request_rejected'))
      }
    } else {
      showToast('error', 'Error')
    }
    hideLoading()
  }

  const clearTransactionsContext = () => {
    setTransactions([])
  }

  return {
    transactions,
    loadTransactions,
    changeTransactionState,
    loadTransactionsByIssuer,
    loadTransactionsByClient,
    loadHistoryTransactions,
    clearTransactionsContext
  }
}
