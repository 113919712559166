import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { MyButtonIcon, MyTextInput } from '../../../../components'
import { MyButton } from '../../../../components/MyButton'
import { Paragraph } from '../../../../components/Paragraph'
import * as yup from 'yup'
import { AddUserMain } from '../../../../components/AddUser/AddUserMain'
import { useTranslation } from 'react-i18next'
import { REGEX_STRING_WITHOUT_NUMBERS } from '../../../../core/utils'

interface Props {
  onSubmit: (values: any) => Promise<void>
  previousStep: () => void
  values: any
  onClose: () => void
}

export const FormCompanyUsers = ({ previousStep, onSubmit, values, onClose }: Props) => {
  const [t] = useTranslation()

  return (
        <Formik
            onSubmit={onSubmit}
            initialValues={values}
            validationSchema={validationSchema}
        >
            {({ setValues }) => {
              return (
                    <Form>
                        <Grid container mb={2} spacing={2}>
                            <Grid item sm={12} mb={2}>
                                <MyButtonIcon
                                    size='small'
                                    icon='backArrow'
                                    onClick={previousStep}
                                    text={t('back')}
                                    type='secondary'
                                />
                            </Grid>
                            <Grid item sm={12} md={12} xs={12} mb={3}>
                                <Paragraph type='subheading'><strong>{t('create_company_form.page_two.tab_title')}</strong></Paragraph>
                            </Grid>
                            <Grid item sm={12} md={12} xs={12}>
                                <Paragraph>{t('create_company_form.page_two.description')}</Paragraph>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                                <MyTextInput
                                    label={t('name')}
                                    name='mainUser'
                                />
                            </Grid>
                            <Grid item md={4.5} sm={6} xs={12}>
                                <MyTextInput
                                    label={t('last_father_name')}
                                    name='mainLastNameFather'
                                />
                            </Grid>
                            <Grid item md={4.5} sm={6} xs={12}>
                                <MyTextInput
                                    label={t('last_mother_name')}
                                    name='mainLastNameMother'
                                />
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                                <MyTextInput
                                    label={t('email')}
                                    name='mainEmail'
                                />
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <AddUserMain setValues={setValues} />
                            </Grid>
                            <Grid item sm={12} md={12} xs={12} />
                            <Grid item mt={3}>
                                <MyButton color='secondary' onMouseUp={onClose}>
                                    {t('cancel')}
                                </MyButton>
                            </Grid>
                            <Grid item mt={3}>
                                <MyButton type='submit'>
                                    {t('next')}
                                </MyButton>
                            </Grid>
                        </Grid>
                    </Form>
              )
            }}
        </Formik>
  )
}

const validationSchema = yup.object().shape({
  mainUser: yup.string().required('required_field').matches(REGEX_STRING_WITHOUT_NUMBERS, 'string_without_numbers'),
  mainLastNameFather: yup.string().required('required_field'),
  mainLastNameMother: yup.string().required('required_field'),
  mainEmail: yup.string().email('email_format_not_valid').required('required_field'),

  secondUser: yup.string().matches(REGEX_STRING_WITHOUT_NUMBERS, 'string_without_numbers'),
  secondLastNameFather: yup.string(),
  secondLastNameMother: yup.string(),
  secondEmail: yup.string().email('email_format_not_valid'),

  threeUser: yup.string().matches(REGEX_STRING_WITHOUT_NUMBERS, 'string_without_numbers'),
  threeLastNameFather: yup.string(),
  threeLastNameMother: yup.string(),
  threeEmail: yup.string().email('email_format_not_valid')
})
