import { TabsContainerProps } from './TabsContainer'
import { Accordion, AccordionSummary, AccordionDetails, styled } from '@mui/material'
import { colorPalette } from '../../conf/ColorConfig'

const StyledAccordion = styled(Accordion)`
  padding-left: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 16px;
  border-bottom: 1px solid ${colorPalette.basics.HEX_ADB8CC};
  &:first-of-type{
    border-top: 1px solid ${colorPalette.basics.HEX_ADB8CC};
  }
`
const StyledAccordionSummary = styled(AccordionSummary)`
  font-size: 16px;
  font-weight: 400;
`

export const DesktopMobile = ({ tabs }: TabsContainerProps) => {
  return (
    <>
      { tabs.map(({ component, title }) =>
        <StyledAccordion key={title} elevation={0} TransitionProps={{ unmountOnExit: true }}>
          <StyledAccordionSummary>
            {title}
          </StyledAccordionSummary>
          <AccordionDetails>
            {component}
          </AccordionDetails>
        </StyledAccordion>
      )}
    </>
  )
}
