import { Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyButtonIcon } from '../../../../../components'
import { MyButton } from '../../../../../components/MyButton'
import { MyLabel } from '../../../../../components/MyCheckboxInput'
import { MyModal } from '../../../../../components/MyModal'
import { Paragraph } from '../../../../../components/Paragraph'
import { IdiomContext } from '../../../../../contexts/IdiomContext'
import { sendEvent } from '../../../../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../../../../core/GA/GoogleAnalyticsEvents'

interface Props {
  nextStep: (values: any) => void
  setWallet: (values: any) => void
  wallet: string
  previousStep: () => void
  onClose: () => void
}

export const MainOnboardingWallet = ({ nextStep, previousStep, onClose }: Props) => {
  const { tokens_actions_lowercase } = useContext(IdiomContext)
  const { t } = useTranslation()
  const [hasAvalanche, setHasAvalanche] = useState<boolean>(false)
  // eslint-disable-next-line
    const [firstConfirmationModal, setFirstConfirmationModal] = useState<boolean>(false)
  const [sendModal, setSendModal] = useState<boolean>(false)

  const closeFirstConfirmationModal = () => {
    setFirstConfirmationModal(false)
  }

  const acceptFirstConfirmation = () => {
    closeFirstConfirmationModal()
    setSendModal(true)
  }

  const closeSendModal = () => {
    setSendModal(false)
  }

  const handleNextStep = () => {
    sendEvent(GA_EVENTS.onb_step_two)
    closeSendModal()
    nextStep(hasAvalanche)
  }

  const handleChange = () => {
    setHasAvalanche(!hasAvalanche)
  }

  useEffect(() => {
    if (hasAvalanche) setFirstConfirmationModal(true)
  }, [hasAvalanche])

  return (
        <Grid container spacing={2}>
            <Grid item sm={12} mb={2}>
                <MyButtonIcon
                    size='small'
                    icon='backArrow'
                    onClick={previousStep}
                    text={t('back')}
                    type='secondary'
                />
            </Grid>
            <Grid item sm={12}>
                <Paragraph type='subheading'><strong>{t('investor_user.onboarding_extended.wallet_settings')}</strong></Paragraph>
            </Grid>
            <Grid item sm={12} md={9} xs={12}>
                <Paragraph>
                {t('investor_user.onboarding_extended.description_four', { tokens: tokens_actions_lowercase })}
                </Paragraph>
            </Grid>
            <Grid item sm={12} mb={6}>
                <MyLabel>
                    <input title='checkbox para autorizar wallet' type="checkbox" checked={hasAvalanche} onChange={handleChange} />
                    <Paragraph> {t('investor_user.onboarding_extended.authorize_wallet')}</Paragraph>
                </MyLabel>
            </Grid>
            <Grid item >
                <MyButton color='secondary' onMouseUp={onClose}>
                    {t('continue_later')}
                </MyButton>
            </Grid>
            <Grid item >
                <MyButton disabled>
                  {t('next')}
                </MyButton>
            </Grid>
            <MyModal
                accept={t('confirm')}
                cancel={t('cancel') ?? ''}
                onAccept={acceptFirstConfirmation}
                onClose={() => {
                  setFirstConfirmationModal(false)
                  setHasAvalanche(false)
                }}
                open={firstConfirmationModal}
                title={t('authorize')}
                text={t('investor_user.onboarding_extended.authorize_wallet_form')}
                twoButtons
            />
            <MyModal
                accept={t('next')}
                onAccept={handleNextStep}
                onClose={closeSendModal}
                open={sendModal}
                title={t('authorize')}
                text={t('investor_user.onboarding_extended.address_confirm')}
            />
        </Grid>
  )
}
