import { Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabsContainer } from '../../../../components/DashboardTabs/TabsContainer'
import { MyButton } from '../../../../components/MyButton'
import { Title } from '../../../../components/Title'
import ClientContext from '../../../../contexts/ClientContext'
import { scrollToTop } from '../../../../core/utils'
import { useTransactions } from '../../../../hooks/useTransactions'
import { BuyInstructions } from '../Dashboard/Buy/BuyInstructions'
import { ApprovedTransactionsTable } from './ApprovedTransactionsTable'
import { AuthorizationTransactionsTable } from './AuthorizationTransactionsTable'
import { filterTransactions, FilterTransactionsProps } from './filterTransactions'
import { RejectedTransactionsTable } from './RejectedTransactionsTable'
import { TableForNonEjected } from './TableForNonEjected'

const initialRows = {
  pending: [],
  executed: [],
  approved: [],
  rejected: []
}

export const PurchaseRequestTable = () => {
  const { loadHistoryTransactions, transactions } = useTransactions()
  const { timezone } = useContext(ClientContext)
  const [openInstructions, setOpenInstructions] = useState(false)
  const [organizeTransactions, setOrganizeTransactions] = useState<FilterTransactionsProps>(initialRows)
  const [rowsEjected, setRowsEjected] = useState<any[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    async function init () {
      await loadHistoryTransactions()
    }
    void init()
    // eslint-disable-next-line
    }, [])

  useEffect(() => {
    scrollToTop()
    const organize_data = filterTransactions(transactions, timezone)
    setOrganizeTransactions(organize_data)
    setRowsEjected(organize_data.executed)
  }, [transactions, timezone])

  const Tabs = [
    { title: t('investor_user.executed'), component: <AuthorizationTransactionsTable transactions={rowsEjected} /> },
    { title: t('investor_user.pending'), component: <TableForNonEjected transactions={organizeTransactions.pending} /> },
    { title: t('investor_user.approved'), component: <ApprovedTransactionsTable transactions={organizeTransactions.approved} /> },
    { title: t('investor_user.rejected'), component: <RejectedTransactionsTable transactions={organizeTransactions.rejected} /> }
  ]

  return (
    <>
      <BuyInstructions isOpen={openInstructions} onClose={() => setOpenInstructions(false)} />
      <Grid container mt={3} spacing={2}>
        <Grid item xs={12} sm={12} md={12} ml={6} mb={1}>
          <Title>{t('investor_user.my_investments')}</Title>
        </Grid>
        { (transactions.length > 0)
          ? <>
              <Grid item xs={6} sm={8} md={8} />
              <Grid item xs={6} md={4} sm={4} textAlign='right'>
                <MyButton onMouseUp={() => setOpenInstructions(true)}>{t('investor_user.see_buy_instructions')}</MyButton>
              </Grid>
            </>
          : <></>
        }
        <Grid item xs={12} sm={12} md={12}>
          <TabsContainer tabs={Tabs} />
        </Grid>
      </Grid>
    </>
  )
}
