import NotificationContext from '../../../contexts/NotificationContext'
import LoadingContext from '../../../contexts/LoadingContext'
import { CreateCompanyDialog } from './CreateCompany/CreateCompanyDialog'
import { useContext, useEffect, useState } from 'react'
import { getIssuersByClient } from '../../../core/apiIssuers'
import ClientContext from '../../../contexts/ClientContext'
import { MyButton } from '../../../components/MyButton'
import { CompanyTable } from './Empresa/CompanyTable'
import { Grid } from '@mui/material'
import { Title } from '../../../components/Title'
import { useScrollToTop } from '../../../hooks/useScrollToTop'
import { useTranslation } from 'react-i18next'

export const AdminClientMain = () => {
  const [t] = useTranslation()
  const [isOpenCreateIssuer, setIsOpenCreateIssuer] = useState(false)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { clientId } = useContext(ClientContext)
  const [issuers, setIssuers] = useState([])
  useScrollToTop()

  const openCreateIssuer = () => { setIsOpenCreateIssuer(true) }
  const closeCreateIssuer = () => { setIsOpenCreateIssuer(false) }

  const loadIssuersData = async () => {
    showLoading()
    try {
      const { records } = await getIssuersByClient(clientId())
      setIssuers(records || [])
    } catch (error) {
      console.log(error)
      showToast('error', 'Error')
    }
    hideLoading()
  }

  useEffect(() => {
    async function init () {
      await loadIssuersData()
    }
    void init()

    return () => setIssuers([])
  }, [])

  return (
    <Grid container mt={3} spacing={2}>
      <Grid item xs={12} sm={12} md={12} ml={6} >
        <Title>{t('companies')}</Title>
      </Grid>
      <Grid item xs={6} sm={8} md={9.5} />
      <Grid item xs={6} md={2.5} sm={4} textAlign='right'>
        <MyButton onMouseUp={openCreateIssuer}>{t('create_company')}</MyButton>
      </Grid>
      <Grid item xs={12} sm={12} md={12} >
        <CompanyTable companies={issuers} />
      </Grid>
      <CreateCompanyDialog
        isOpen={isOpenCreateIssuer}
        onClose={closeCreateIssuer}
        refreshTable={loadIssuersData}
      />
    </Grid>
  )
}
