import { Grid, TextField } from '@mui/material'
import { TooltipText } from '../../../../design-system-components'
import { createContext, forwardRef, Fragment, useContext, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { MyLabel } from '../../../../components/MyCheckboxInput'
import { AssetFormContext } from './DialogAssetForm'
import { Paragraph } from '../../../../components/Paragraph'
import NumberFormat, { InputAttributes } from 'react-number-format'
import { CURRENCIES, decimalAndGroupSeparator } from '../../../../core/utils'
import { useCheckboxes } from '../../../../hooks/useCheckboxes'
import { useTranslation } from 'react-i18next'

export const VALUATION_SOURCE = [
  'Valuador independiente',
  'Última ronda de inversión'
]

export const INDEPENDENT_VALUATOR_METHOD = [
  'Enfoque de costos',
  'Flujos de caja libre descontados de la empresa',
  'Flujos de caja libre descontados del capital',
  'Múltiplos',
  'Valor razonable de mercado',
  'Valuación por CAP SAFE/Nota convertible',
  'Otro'
]

export const LATEST_INVESTMENT_ROUND = [
  'Última ronda de inversión institucional en los últimos 12 meses (post money)',
  'Última ronda de inversión mayor a 12 meses (post money)',
  'Otra'
]

export const INDEPENDENT_VALUATOR = [
  '414 Capital',
  'Bain & Company',
  'Booz Allen Hamilton',
  'Deloitte',
  'KPMG',
  'Kroll',
  'McKinsey & Company',
  'PricewaterhouseCoopers',
  'Quantit',
  'Otro'
]

const Div = styled.div`
  display: flex;
  gap:10px;
  align-items: center;
  & > div > div > input {
    padding: 2px;  
  }
`

export const EquityCheckboxes = () => {
  const {
    characteristics,
    setCharacteristics,
    setTransmissionRefinement,
    transmissionRefinement,
    capitalPercentage,
    setCapitalPercentage,
    assetCurrency
  } = useContext(AssetFormContext)
  const { ACTIONS_FEATURES, ENHANCEMENT_TRANSMISSION } = useCheckboxes()
  const [t] = useTranslation()

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} xs={12} mt={4}>
        <Paragraph>{t('company_user.create_asset.actions_features_subtitle')}</Paragraph>
        <TooltipText
          style={{ fontSize: 14, fontStyle: 'italic', lineHeight: '24px' }}
        >{t('company_user.create_asset.select_apply')}</TooltipText>
      </Grid>
      {ACTIONS_FEATURES.map((label, index) => (
        <Fragment key={label}>
        <Grid item sm={6} xs={12} key={label} ml={1}>
          <MyLabel>
            <input
              type="checkbox"
              checked={!!characteristics[index].length}
              onChange={() => setCharacteristics(prev =>
                prev.map((el, i) => i === index ? el.length ? '' : index === ACTIONS_FEATURES.length - 1 ? ' ' : label : el)
              )}
            />
            { label }
          </MyLabel>
        </Grid>
        { index === ACTIONS_FEATURES.length - 1 && (
          <Grid item sm={7} xs={12} ml={1}>
            <TextField
              value={characteristics[index].trim().length === 0 ? characteristics[index].trim() : characteristics[index]}
              required={characteristics[index].length > 0}
              disabled={characteristics[index].length === 0}
              onChange={({ target }) => setCharacteristics(prev =>
                prev.map((el, i) => i === index ? target.value : el)
              )}
            />
          </Grid>)
          }
        </Fragment>
      ))}
      <Grid item sm={8} xs={12} mt={4}>
        <Paragraph>{t('company_user.create_asset.enhancement_transmission_subtitle')} </Paragraph>
        <TooltipText
          style={{ fontSize: 14, fontStyle: 'italic', lineHeight: '24px' }}
        >{t('company_user.create_asset.select_apply')}</TooltipText>
      </Grid>
      {ENHANCEMENT_TRANSMISSION.map(
        (label, index) => (
          <Fragment key={label}>
            <Grid item sm={8} xs={12} ml={1}>
              <MyLabel>
                <input
                  type="checkbox"
                  checked={!!transmissionRefinement[index].length}
                  onChange={() => setTransmissionRefinement(prev =>
                    prev.map((el, i) => i === index ? el.length ? '' : index === ENHANCEMENT_TRANSMISSION.length - 1 ? ' ' : label : el)
                  )}
                />
                { label }
              </MyLabel>
            </Grid>
            {index === ENHANCEMENT_TRANSMISSION.length - 1 && (
              <Grid item sm={7} xs={12} ml={1}>
                <TextField
                  value={transmissionRefinement[index].trim().length === 0 ? transmissionRefinement[index].trim() : transmissionRefinement[index]}
                  required={transmissionRefinement[index].length > 0}
                  disabled={transmissionRefinement[index].length === 0}
                  onChange={({ target }) => setTransmissionRefinement(prev =>
                    prev.map((el, i) => i === index ? target.value : el)
                  )}
                />
              </Grid>
            )}
          </Fragment>
        )
      )}
      <Grid item sm={8} xs={12} mt={4}>
        <Paragraph>{t('company_user.create_asset.investor_percentage_subtitle')}</Paragraph>
      </Grid>
      <Grid item sm={12} xs={12} ml={1} mb={4}>
        <Div>
          <MyLabel>
            <input
              type="checkbox"
              checked={!!Number(capitalPercentage)}
              onChange={() => setCapitalPercentage(prev => prev ? 0 : 1)}
            />
          </MyLabel>
          <span>{t('company_user.create_asset.investor_percentage_label_part_one')}</span>
          <MyNumberInput
            value={Number(capitalPercentage)}
            currency={assetCurrency}
            onChange={({ target }: any) => setCapitalPercentage(Number(target.value))}
          />
          <span>{t('company_user.create_asset.investor_percentage_label_part_two')}</span>
        </Div>
      </Grid>
    </Grid>
  )
}

const FormatContext = createContext<{ currency: string } >({ currency: CURRENCIES.CLP })

interface CustomProps {
  onChange: (event: { target: { name: string, value: string } }) => void
  name: string
  currency: string
}

const RenderNumberFormat = forwardRef<
NumberFormat<InputAttributes>,
CustomProps
>(function NumberFormatCustom (props, ref) {
  const { onChange, ...other } = props
  const [currencyFormat, setCurrencyFormat] = useState({ thousandSeparator: '.', decimalSeparator: ',' })
  const { currency } = useContext(FormatContext)

  useEffect(() => {
    const {
      decimalSeparator,
      groupSeparator: thousandSeparator
    } = decimalAndGroupSeparator()
    setCurrencyFormat({ decimalSeparator, thousandSeparator })
  }, [currency])

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      isNumericString
      {...currencyFormat}
    />
  )
})

const MyNumberInput = ({ value, onChange, currency = CURRENCIES.CLP }: any) => {
  return (
    <FormatContext.Provider value={{ currency }}>
      <TextField
      style={{ width: 60 }}
        value={value}
        onChange={onChange}
        InputProps={{
          inputComponent: RenderNumberFormat as any
        }}
        variant='outlined'
      />
    </FormatContext.Provider>
  )
}
