import styled from '@emotion/styled'

interface Color {
  color: string
  children: string
  onClick: any
  [propName: string]: any
}

const MyButtonAsText = ({ children, color, onClick }: Color) => {
  const Button = styled.button`
    color: ${color};
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    transition: opacity .3s ease;
    opacity: 70%;

    &:hover {
      opacity: 100%;
    }
  `

  return (
    <Button
      color={color}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default MyButtonAsText
