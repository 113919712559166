import { Grid } from '@mui/material'
import { Fragment, useContext, useState } from 'react'
import { fullWidth } from '../../layout/LayoutConfig'
import { RequestExitModal } from './RequestExitModal'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import styled from '@emotion/styled'
import { useClientConfig } from '../../hooks/useClientConfig'
import ClientContext from '../../contexts/ClientContext'
import { MyButton } from '../MyButton'
import { Paragraph } from '../Paragraph'
import { useTranslation } from 'react-i18next'

interface Props {
  linkIssuer: string
  linkAsset: string
  canAccess?: boolean
  canShowRequest?: boolean
  requestAccess?: () => void
}

const Container = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: 2rem;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
`

export const VdrOnlyView = ({ linkAsset, linkIssuer, canShowRequest = true, canAccess = true, requestAccess }: Props) => {
  const [showRequestModal, setRequestModal] = useState({ open: false, link: '' })
  const { isConfidentiality } = useContext(ClientContext)
  const { assetTypeLabel } = useClientConfig()
  const { t } = useTranslation()
  const data = [
    { link: linkIssuer, label: t('visit_doc', { SCOPE: t('issuers') }) },
    { link: linkAsset, label: t('visit_doc', { SCOPE: assetTypeLabel('singular', 'lowercase') }) }
  ]

  const onOpen = (link: string) => {
    setRequestModal({ open: true, link })
  }

  const onClose = () => {
    setRequestModal({ open: false, link: '' })
  }

  const onRedirect = () => {
    window.open(showRequestModal.link)
    onClose()
  }

  return (
    <>
    <RequestExitModal isOpen={showRequestModal.open} onClose={onClose} handeSubmit={onRedirect} />
    <Grid container p={2}>
      <Grid item {...fullWidth}>
        <Paragraph bold>{t('external_doc')}</Paragraph>
      </Grid>
    </Grid>
    {
      !(!canAccess && canShowRequest && isConfidentiality)
        ? <>
        <Grid container p={2}>
          <Grid item {...fullWidth}>
          </Grid>
          { data.map(({ label, link }, index) => {
            if (!link || link?.length === 0) return <Fragment key={index} />
            return (
              <Grid item {...fullWidth} key={index}>
                <Container onClick={() => onOpen(link)}>
                  <Paragraph>
                    { label }
                  </Paragraph>
                  <ArrowForwardRoundedIcon />
                </Container>
              </Grid>
            )
          })}
        </Grid>
      </>
        : <Grid container p={2}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <MyButton fullWidth onMouseUp={requestAccess}>
              {t('access_request')}
            </MyButton>
          </Grid>
      </Grid>
    }
    </>
  )
}
