import { Grid, Paper } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { fullWidth } from '../../layout/LayoutConfig'
import { MyButton } from '../MyButton'
import { MyTextInput } from '../MyTextInput'
import * as yup from 'yup'
import { RedirectVDR } from './RedirectVDR'
import NotificationContext from '../../contexts/NotificationContext'
import { useClientConfig } from '../../hooks/useClientConfig'
import { useTranslation } from 'react-i18next'

interface Props {
  setLinkAsset: (value: any) => void
  linkAsset: string
  linkIssuer: string
}

export const UploadLinkAsset = ({ setLinkAsset, linkAsset, linkIssuer }: Props) => {
  const { showToast } = useContext(NotificationContext)
  const { assetTypeLabel } = useClientConfig()
  const { t } = useTranslation()

  const onSubmit = (value: any) => {
    showToast('success', t('toasts.link_vdr_success'))
    setLinkAsset(value.linkAsset)
  }

  return (
    <>
      <Paper elevation={5}>
        { !linkIssuer || linkIssuer?.length === 0
          ? null
          : <RedirectVDR link={linkIssuer}/>
        }
        <Formik key={linkAsset} initialValues={{ linkAsset }} validationSchema={validationSchema} onSubmit={onSubmit}>
          {() => (
            <Form>
              <Grid container p={3} justifyContent="flex-end" spacing={2}>
                <Grid item {...fullWidth}>
                  <MyTextInput
                    label={t('your_link_vdr_asset', { asset: assetTypeLabel('singular', 'lowercase') })}
                    name='linkAsset'
                  />
                </Grid>
                <Grid item >
                  <MyButton type='submit'>{t('save')}</MyButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  )
}

const validationSchema = yup.object().shape({
  linkAsset: yup.string().url('url').required('required_field')
})
