import { useContext, useEffect, useState } from 'react'
import { AssetsContext } from '../../../../../contexts/AssetsContext'
import { formatCurrency, formatNumber } from '../../../../../core/utils'
import { timezoneFormatWithHour } from '../../../../../core/timezoneFormat'
import ClientContext from '../../../../../contexts/ClientContext'
import { MyTable } from '../../../../../components'
import { useClientConfig } from '../../../../../hooks/useClientConfig'
import { FilterTableHOC } from '../../../../../components/Tables/FilterTableHOC'
import { useTranslation } from 'react-i18next'

interface ITAXES {
  [prop: string]: string
}

export const AcceptedAssetTable = () => {
  const { filterData } = useContext(AssetsContext)
  const { assetTypeLabel } = useClientConfig()
  const { timezone } = useContext(ClientContext)
  const [rows, setRows] = useState<any[]>([])
  const [t, i18n] = useTranslation()

  const TAXES: ITAXES = {
    1: t('tax_rule.yes'),
    2: t('tax_rule.no'),
    3: t('tax_rule.not_defined')
  }

  useEffect(() => {
    const acceptedAssets = [...filterData.aprobados, ...filterData.disponibles]
    const organizeData = Array.from(acceptedAssets,
      (asset: any) => {
        const { name, token_quantity, bidAmount, price, id, status, token_price } = asset
        const currency = asset?.currency
        const taxRuling: keyof ITAXES = asset?.tax_ruling
        const currentTax = TAXES[taxRuling] ?? 'N/A'

        return {
          token_price: formatCurrency(token_price, currency, true),
          date: timezoneFormatWithHour(timezone, asset?.statusAcceptedDate),
          token_quantity: formatNumber(token_quantity),
          price: formatCurrency(price, currency, true),
          bidAmount: formatCurrency(bidAmount, currency, true),
          asset_currency: currency,
          currentTax,
          status,
          name,
          id
        }
      })
    setRows(organizeData)

    return () => setRows([])
  }, [timezone, filterData.aprobados, filterData.disponibles, i18n.language])

  const headerData = [
    {
      header: assetTypeLabel('singular', 'uppercase'),
      key: 'name'
    },
    {
      header: t('currency'),
      key: 'asset_currency'
    },
    {
      header: t('valuation'),
      key: 'price'
    },
    {
      header: t('bid_amount'),
      key: 'bidAmount'
    },
    {
      header: t('quantity'),
      key: 'token_quantity'
    },
    {
      header: t('token_price'),
      key: 'token_price'
    },
    {
      header: t('tax'),
      key: 'currentTax'
    },
    {
      header: t('authorization_date'),
      key: 'date'
    }
  ]

  return (
    <FilterTableHOC
      labelToFilter='date'
      labelToFilterType='date'
      rows={rows}
    >
      {({ dataFilter, key }) =>
        <MyTable
          key={key}
          rowData={dataFilter}
          headerData={headerData}
        />
      }
    </FilterTableHOC>
  )
}
