import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ClientContext from '../contexts/ClientContext'
import LoadingContext from '../contexts/LoadingContext'
import NotificationContext from '../contexts/NotificationContext'
import UserContext from '../contexts/UserContext'
import { getAcceptances, getByIssuerAndStatusAcceptances, postAcceptance, putAcceptance, putAcceptanceFirstTime } from '../core/apiAcceptances'
import { Acceptance } from '../types/types'

type ConfidentialityStatus = 'NO_CONFLICT' | 'REQUESTED' | 'REJECTED' | 'APPROVED'

export const useAcceptances = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { clientId, mandatory_vdr } = useContext(ClientContext)
  const [pendingAcceptances, setPendingAcceptances] = useState<Acceptance[]>([])
  const { showToast } = useContext(NotificationContext)
  const { investor_id, user, myUser, getIssuerId } = useContext(UserContext)
  const { t } = useTranslation()

  const loadAcceptances = async (issuer_id: string) => {
    const body = {
      clientId: clientId(),
      investor_id,
      issuer_id
    }
    showLoading()
    const getAccess = await getAcceptances(body)
    hideLoading()
    if (getAccess.success) return getAccess.records
    else return []
  }

  const loadAcceptancesWithoutLoad = async (issuer_id: string) => {
    const body = {
      clientId: clientId(),
      investor_id,
      issuer_id
    }
    const getAccess = await getAcceptances(body)
    if (getAccess.success) return getAccess.records
    else return []
  }

  const canAccess = async (issuer_id: string, triggerToCompleteData: () => void): Promise<'onb' | 'no-auth' | 'yes' | 'no'> => {
    if (user?.user?.step !== 7) {
      triggerToCompleteData()
      return 'onb'
    }
    if (user?.user?.status !== 'ACCEPTED') {
      showToast('error', t('toasts.non_authorized_investor'))
      return 'no-auth'
    }
    if (mandatory_vdr) {
      const resp = await loadAcceptances(issuer_id)
      return resp.length > 0 ? 'yes' : 'no'
    }
    return 'yes'
  }

  const acceptConditions = async (issuer_id: string): Promise<boolean> => {
    const body = {
      clientId: clientId(),
      investor_id,
      issuer_id
    }
    showLoading()
    const resp = await postAcceptance(body)
    hideLoading()
    return resp.success
  }

  const canViewVdrConfidential = async (issuer_id: string) => {
    return await loadAcceptancesWithoutLoad(issuer_id)
  }

  const changeConfidentialityStatus = async (issuer_id: string, status: ConfidentialityStatus) => {
    const body = {
      clientId: clientId(),
      investor_id,
      issuer_id,
      status,
      email: myUser.email,
      name: `${myUser.first_names} ${myUser.fathers_name} ${myUser.mothers_name}`
    }
    showLoading()
    const resp = await putAcceptance(body)
    hideLoading()
    return resp
  }

  const changeConfidentialityStatusFirstTime = async (issuer_id: string) => {
    const body = {
      clientId: clientId(),
      investor_id,
      issuer_id,
      email: myUser.email,
      name: `${myUser.first_names} ${myUser.fathers_name} ${myUser.mothers_name}`
    }
    showLoading()
    const resp = await putAcceptanceFirstTime(body)
    hideLoading()
    return resp
  }

  const changeConfidentialityStatusByIssuer = async (investor_id: string, issuer_id: string, status: ConfidentialityStatus) => {
    const body = {
      clientId: clientId(),
      investor_id,
      issuer_id,
      status
    }
    showLoading()
    const resp = await putAcceptance(body)
    hideLoading()
    return resp
  }

  const loadStatusAcceptancesByIssuer = async (status: ConfidentialityStatus) => {
    showLoading()
    const resp = await getByIssuerAndStatusAcceptances({ clientId: clientId(), issuer_id: getIssuerId(), status })
    hideLoading()
    if (resp.success) setPendingAcceptances(resp.records.filter(el => el?.investor))
  }

  return {
    pendingAcceptances,
    loadStatusAcceptancesByIssuer,
    canAccess,
    acceptConditions,
    canViewVdrConfidential,
    changeConfidentialityStatusByIssuer,
    changeConfidentialityStatus,
    changeConfidentialityStatusFirstTime
  }
}
