import { UseFetch } from './UseFetch'

export const getAssets = async (clientId: any): Promise<any> => {
  const init = {
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('get', 'endpoint', `/assets/${clientId}`, init)
}

export const createAsset = async (asset: any): Promise<any> => {
  const { sub_title, logo, using_logo, confirmValues, title_return, value_return, usingLogo, ...rest } = asset
  const init = {
    body: { ...rest },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('post', 'endpoint', '/assets', init)
}

export const updateAsset = async (asset: any): Promise<any> => {
  const { confirmValues, title_return, value_return, createdAt, id, logo, sub_title, ...rest } = asset

  const init = {
    body: { ...rest },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('put', 'endpoint', `/assets/${asset.clientId}/${asset.issuer_id}/${asset.id}`, init)
}

export const getAssetsByIssuer = async (issuer_id: any, clientId: string): Promise<any> => {
  const init = {
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('get', 'endpoint', `/assets/${clientId}/${issuer_id}`, init)
}

export const changeStatusAsset = async (asset: any, status: any): Promise<any> => {
  const init = {
    queryStringParameters: { id: asset.id },
    body: { status },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('put', 'endpoint', `/assets/${asset.clientId}/${asset.issuer_id}/${asset.id}`, init)
}
