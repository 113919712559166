import { useState } from 'react'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { MyButton } from '../../../../components/MyButton'
import { MyLabel } from '../../../../components/MyCheckboxInput'
import { MyPDF } from '../../../../components/MyPDF'
import { sendEvent } from '../../../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../../../core/GA/GoogleAnalyticsEvents'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  @media (max-width: 480px) {
    height: 65vh;
  }
`

interface Props {
  onClose: () => void
  onSubmit: () => void
  url: any
}

export const Conditions = ({ onClose, onSubmit, url }: Props) => {
  const [isAccepted, setIsAccepted] = useState(false)
  const { t } = useTranslation()
  return (
    <Container>
      <MyPDF url={url} />
      <MyLabel>
        <input type="checkbox" title='Términos y condiciones' checked={isAccepted} onChange={() => setIsAccepted(!isAccepted)} />
        {t('investor_user.onboarding_extended.accept_terms')}
      </MyLabel>
      <Grid container spacing={2}>
        <Grid item sm={3} md={3} xs={12}>
          <MyButton fullWidth color='info' onMouseUp={onClose}>
            {t('continue_later')}
          </MyButton>
        </Grid>
        <Grid item sm={3} md={3} xs={12}>
          <MyButton fullWidth onMouseUp={ () => {
            sendEvent(GA_EVENTS.onb_step_four)
            onSubmit()
          } } disabled={!isAccepted}>{t('next')}</MyButton>
        </Grid>
      </Grid>
    </Container >
  )
}
