import { useState } from 'react'
import { Box } from '@mui/material'
import { SideBar } from './Sidebar/SideBar'
import { Outlet } from 'react-router-dom'
import { SidebarOptions } from './Sidebar/SidebarOptions'
import { Disclaimer } from '../pages/Users/Inversionistas/Dashboard/Disclaimer'
import { IdleTimerProvider } from 'react-idle-timer'
import { useIdleTimeout } from '../hooks/useIdleTimeout'
import styled from '@emotion/styled'
import { TobarMobil } from './AppBarQX/TobarMobil'
import { useResponsive } from '../hooks/useResponsive'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ContainerSinFlex = styled('div')`
  min-height: calc(100% - 54px - 50px);
  max-height: max-content;
`

const AppBarHeader = styled('div')(() => ({
  height: 80,
  zIndex: -1
}))

const AppBarInvisibleContainer = styled.div`
  display: none;

  @media (max-width: 480px) {
    display:none;
  }
`

const Main = styled.main` 
  padding: 40px;
  width: 100%;

  @media (max-width: 480px) {
    padding:80px 10px 10px 10px;  
  }
`

export const LayoutDashboard = () => {
  const { onIdle, ModalTimeout } = useIdleTimeout()
  const { isMobileDevice } = useResponsive()
  const [show, setShow] = useState(window.innerWidth >= 480)
  return (
    <IdleTimerProvider timeout={20 * 60 * 1000} onIdle={onIdle}>
      <ModalTimeout />
      <Box sx={{ display: 'flex', minHeight: 'inherit' }}>
        <TobarMobil setShow={setShow} show={show} />
        <SideBar options={SidebarOptions()} show={show} setShow={setShow} />
        { (!show && isMobileDevice) || !isMobileDevice
          ? <Main>
            <AppBarInvisibleContainer>
              <AppBarHeader />
            </AppBarInvisibleContainer>
            <Container>
              <ContainerSinFlex>
                <Outlet />
              </ContainerSinFlex>
              <Disclaimer />
            </Container>
          </Main>
          : null
        }
      </Box >
    </IdleTimerProvider>
  )
}
