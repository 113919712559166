import { Grid } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { MyButton } from '../../components/MyButton'
import { Paragraph } from '../../components/Paragraph'
import { LoginTitle } from '../../components/Title'
import ClientContext from '../../contexts/ClientContext'
import { sendEvent } from '../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../core/GA/GoogleAnalyticsEvents'
import { InitialLayout, LoginContentLayout } from '../../layout/InitialLayout'

export const ConfirmationAccount = () => {
  const { state }: any = useLocation()
  const { clientId } = useContext(ClientContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onAccept = () => {
    sendEvent(GA_EVENTS.investor_back_to_login)
    navigate(`/${clientId()}`)
  }

  return (
        <InitialLayout>
            <LoginContentLayout>
                <Grid container spacing={4} justifyContent='center'>
                    <Grid item sm={12} xs={12} md={12}>
                        <LoginTitle>{t('login_page.register.confirmation_required')}</LoginTitle>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12}>
                        <Paragraph>{t('login_page.register.confirmation_required_text', { email: state?.username ?? '' })}</Paragraph>
                    </Grid>
                    <Grid item sm={10} xs={10} md={10} >
                        <MyButton fullWidth onMouseUp={onAccept}>{t('login_page.register.back_login')}</MyButton>
                    </Grid>
                </Grid>
            </LoginContentLayout>
        </InitialLayout>
  )
}
