import React, { createContext, useEffect, useState, useContext } from 'react'
import ClientContext from '../../../../contexts/ClientContext'
import { assetsTypeToAssetType } from '../../../../core/utils'
import { useCheckboxes } from '../../../../hooks/useCheckboxes'
import { LayoutForm } from '../../../../layout/LayoutForm'
import { AssetsMain } from './AssetsMain'

const initialValues = {
  id: '',
  name: '',
  sub_title: '',
  quantity: 0,
  price: 0,
  bidAmount: 0,
  external_url: '',
  description: '',
  additional_data: [{ icono: 'refactor', title: '', description: '' }, { icono: 'refactor', title: '', description: '' }],
  logo: '',
  usingLogo: false,
  tax_ruling: '',

  token_quantity: 0,
  expected_income: 0,
  investment_amount: 0,
  token_price: 0,
  maximum_token: 0,
  minimun_token: 0,
  expected_return: 0,
  flow_periodicity: 0,
  confirmValues: false,
  valuation_date: null,

  title_return: '',
  value_return: '',
  share_offer: 0
}

interface Props {
  open: boolean
  onClose: () => void
  mode: 'crear' | 'editar'
  data: any
  refreshTables: () => Promise<any>
}

interface AssetFormContextProps {
  onClose: () => void
  assetType: string
  setAssetType: (value: string) => void
  linkVdr: string
  setLinkVdr: (value: string) => void
  assetData: any
  setAssetData: (value: any) => void
  refreshTables: () => Promise<void>
  initialValues: any
  mode: 'crear' | 'editar'
  data: any
  assetCurrency: string
  setAssetCurrency: React.Dispatch<React.SetStateAction<string>>
  indexTab: number
  setIndexTab: React.Dispatch<React.SetStateAction<number>>
  nextStep: () => void
  previousStep: () => void
  transmissionRefinement: string[]
  setTransmissionRefinement: React.Dispatch<React.SetStateAction<string[]>>
  setCharacteristics: React.Dispatch<React.SetStateAction<string[]>>
  characteristics: string[]
  capitalPercentage: number
  setCapitalPercentage: React.Dispatch<React.SetStateAction<number>>
  valuationSource: string[]
  setValuationSource: React.Dispatch<React.SetStateAction<string[]>>
  independentValuatorMethod: string[]
  setIndependentValuatorMethod: React.Dispatch<React.SetStateAction<string[]>>
  latestInvestmentRound: string[]
  setLatestInvestmentRound: React.Dispatch<React.SetStateAction<string[]>>
  independentValuator: string[]
  setIndependentValuator: React.Dispatch<React.SetStateAction<string[]>>
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const AssetFormContext = createContext({} as AssetFormContextProps)

export const DialogAssetForm = ({ open, onClose, data, mode, refreshTables }: Props) => {
  const [linkVdr, setLinkVdr] = useState('')
  const [assetData, setAssetData] = useState<any>({})
  const [assetType, setAssetType] = useState<string>('')
  const [indexTab, setIndexTab] = useState<number>(0)
  const [assetCurrency, setAssetCurrency] = useState<string>('')
  const [characteristics, setCharacteristics] = useState<string[]>([])
  const [capitalPercentage, setCapitalPercentage] = useState<number>(0)
  const [valuationSource, setValuationSource] = useState<string[]>([])
  const [independentValuatorMethod, setIndependentValuatorMethod] = useState<string[]>([])
  const [latestInvestmentRound, setLatestInvestmentRound] = useState<string[]>([])
  const [independentValuator, setIndependentValuator] = useState<string[]>([])
  const { assetsType, currencyOptions } = useContext(ClientContext)
  const {
    ACTIONS_FEATURES,
    ENHANCEMENT_TRANSMISSION,
    VALUATION_SOURCE,
    INDEPENDENT_VALUATOR_METHOD,
    LATEST_INVESTMENT_ROUND,
    INDEPENDENT_VALUATOR
  } = useCheckboxes()
  const [transmissionRefinement, setTransmissionRefinement] = useState<string[]>([])

  const nextStep = () => setIndexTab(v => v + 1)
  const previousStep = () => setIndexTab(v => v - 1)

  useEffect(() => {
    if (mode === 'crear') {
      setAssetData({ ...initialValues, additional_data: [{ icono: 'refactor', title: '', description: '' }, { icono: 'refactor', title: '', description: '' }] })
      setAssetType(assetsType === 'ALL' ? '' : assetsTypeToAssetType(assetsType))
      setAssetCurrency(currencyOptions[0])
      setCharacteristics(Array(ACTIONS_FEATURES.length).fill('', 0))
      setTransmissionRefinement(Array(ENHANCEMENT_TRANSMISSION.length).fill('', 0))
      setValuationSource(Array(VALUATION_SOURCE.length).fill('', 0))
      setIndependentValuatorMethod(Array(INDEPENDENT_VALUATOR_METHOD.length).fill('', 0))
      setLatestInvestmentRound(Array(LATEST_INVESTMENT_ROUND.length).fill('', 0))
      setIndependentValuator(Array(INDEPENDENT_VALUATOR.length).fill('', 0))
      setCapitalPercentage(0)
      setLinkVdr('')
    } else {
      setAssetType(data?.assetType)
      setAssetCurrency(data?.currency)
      setAssetData({ ...data, confirmValues: false })
      setLinkVdr(data?.linkVdr || '')
      setCharacteristics(data?.characteristics || [])
      setTransmissionRefinement(data?.transmissionRefinement || [])
      // Valuation Source
      if (data?.valuation_source?.length > 0) setValuationSource(data?.valuation_source)
      else setValuationSource(Array(VALUATION_SOURCE.length).fill('', 0))
      // Independent Valuator Method
      if (data?.independent_valuator_method?.length > 0) setIndependentValuatorMethod(data?.independent_valuator_method)
      else setIndependentValuatorMethod(Array(INDEPENDENT_VALUATOR_METHOD.length).fill('', 0))
      // Latest Investment Round
      if (data?.latest_investment_round?.length > 0) setLatestInvestmentRound(data?.latest_investment_round)
      else setLatestInvestmentRound(Array(LATEST_INVESTMENT_ROUND.length).fill('', 0))
      // Independent Valuator
      if (data?.independent_valuator?.length > 0) setIndependentValuator(data?.independent_valuator)
      else setIndependentValuator(Array(INDEPENDENT_VALUATOR.length).fill('', 0))

      setCapitalPercentage(data?.capitalPercentage)
    }
  }, [open, data, mode, assetsType])

  useEffect(() => {
    if (mode === 'crear') setIndexTab(0)
    else setIndexTab(data?.step)
  }, [assetsType, mode, data, open])

  return (
    <LayoutForm open={open} onClose={onClose}>
      <AssetFormContext.Provider
        value={{
          onClose,
          assetData,
          setAssetData,
          setAssetType,
          refreshTables,
          indexTab,
          setIndexTab,
          linkVdr,
          setLinkVdr,
          assetType,
          initialValues,
          mode,
          data,
          setAssetCurrency,
          assetCurrency,
          nextStep,
          previousStep,
          characteristics,
          setCharacteristics,
          transmissionRefinement,
          capitalPercentage,
          setTransmissionRefinement,
          setCapitalPercentage,
          valuationSource,
          setValuationSource,
          independentValuatorMethod,
          setIndependentValuatorMethod,
          latestInvestmentRound,
          setLatestInvestmentRound,
          independentValuator,
          setIndependentValuator
        }}
      >
        <AssetsMain />
      </AssetFormContext.Provider>
    </LayoutForm>
  )
}
