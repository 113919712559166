import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { MyTextInput } from '../../components'
import { MyButton } from '../../components/MyButton'
import { MyPasswordToggleButton } from '../../components/MyPasswordToggleButton'
import { Paragraph } from '../../components/Paragraph'
import { LoginTitle } from '../../components/Title'
import { LoginFormLayout } from '../../layout/InitialLayout'
import { recoverPasswordValidationSchema } from '../../validations/recoverPasswordValidationSchema'

interface Props {
  onSubmit: (values: any) => void
  email: string
}

const initialValues = {
  confirmationCode: '',
  confirmateNewPassword: '',
  password: ''
}

export const RecoverPasswordForm = ({ onSubmit, email }: Props) => {
  const { t } = useTranslation()
  return (
        <Formik
            validationSchema={recoverPasswordValidationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {() => {
              return (
                    <LoginFormLayout>
                        <Grid container spacing={2} justifyContent='center'>
                            <Grid item xs={12} md={12} sm={12}>
                              <LoginTitle>{t('login_page.create_password.title')}</LoginTitle>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} textAlign='initial'>
                                <Paragraph type='small'>{t('login_page.create_password.subtitle', { email })}</Paragraph>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12}>
                                <PasswordRulesContainer>
                                <Paragraph type='small'>{t('login_page.create_password.password_rules')}</Paragraph>
                                <ul style={{ listStyle: 'disc', marginLeft: 30 }}>
                                    <li><Paragraph type='small'>{t('password_rules.rule_one')}</Paragraph></li>
                                    <li><Paragraph type='small'>{t('password_rules.rule_two')}</Paragraph></li>
                                    <li><Paragraph type='small'>{t('password_rules.rule_three')}</Paragraph></li>
                                    <li><Paragraph type='small'>{t('password_rules.rule_four')}</Paragraph></li>
                                </ul>
                                </PasswordRulesContainer>
                            </Grid>
                            <Grid item sm={10} xs={10} >
                                <MyTextInput
                                    size='sm'
                                    label={t('login_page.create_password.code')}
                                    name='confirmationCode'
                                />
                            </Grid>
                            <Grid item sm={10} xs={10} >
                                <MyPasswordToggleButton
                                    size='sm'
                                    label={t('password')}
                                    name='password'
                                />
                            </Grid>
                            <Grid item sm={10} xs={10} >
                                <MyPasswordToggleButton
                                    size='sm'
                                    label={t('new_password')}
                                    name='confirmateNewPassword'
                                />
                            </Grid>
                            <Grid item xs={10} md={10}>
                                <MyButton
                                    fullWidth
                                    type='submit'
                                >{t('login_page.create_password.button')}</MyButton>
                            </Grid>
                        </Grid>
                    </LoginFormLayout>
              )
            }}
        </Formik>
  )
}

const PasswordRulesContainer = styled.div`
  text-align: left;
`
