import { useContext, useEffect, useState } from 'react'
import MyButtonAsText from '../../../../../components/MyButtonAsText'
import { MyRenderTable } from '../../../../../components/Tables/MyRenderTable'
import ClientContext from '../../../../../contexts/ClientContext'
import { ColorContext } from '../../../../../contexts/ColorContext'
import { TransactionsContext } from '../../../../../contexts/TransactionsContext'
import { timezoneFormatWithHour } from '../../../../../core/timezoneFormat'
import { formatCurrency, formatNumber } from '../../../../../core/utils'
import { Transaction } from '../../../../../types/types'
import { AssetBuyReview } from './AssetBuyReview'
import { IdiomContext } from '../../../../../contexts/IdiomContext'
import { useClientConfig } from '../../../../../hooks/useClientConfig'
import { FilterTableHOC } from '../../../../../components/Tables/FilterTableHOC'
import { useTranslation } from 'react-i18next'

interface Props {
  pendingTransactions: Transaction[]
}

export const AssetBuyValidation = ({ pendingTransactions }: Props) => {
  const [transactionsNotConfirmed, setTransactionsNotConfirmed] = useState<any[]>([])
  const { transactions, changeTransactionState } = useContext(TransactionsContext)
  const { timezone } = useContext(ClientContext)
  const [reviewBuyRequest, setReviewBuyRequest] = useState({ isOpen: false, data: null })
  const { theme } = useContext(ColorContext)
  const { tokens_actions_request, tokens_actions_price } = useContext(IdiomContext)
  const { assetTypeLabel } = useClientConfig()
  const [t, i18n] = useTranslation()

  const openReviewBuyAsset = (data: any) => {
    setReviewBuyRequest({ isOpen: true, data })
  }

  const closeReviewBuyAsset = () => {
    setReviewBuyRequest({ isOpen: false, data: null })
  }

  useEffect(() => {
    setTransactionsNotConfirmed(Array.from(pendingTransactions, (transaction: Transaction) => {
      const { asset, tokens, amount, investor } = transaction
      const { token_price } = asset
      const { first_names, fathers_name, mothers_name } = investor
      const currency = asset?.currency
      const payFormat = formatCurrency(amount, currency, true)
      const tokensFormat = formatNumber(tokens)
      const token_priceFormat = formatCurrency(+token_price, currency, true)

      return {
        currency,
        assetName: asset.name,
        token_operation: tokensFormat,
        investor_name: `${first_names} ${fathers_name} ${mothers_name}`,
        payment_operation: payFormat,
        token_quantity: token_priceFormat,
        date: timezoneFormatWithHour(timezone, transaction?.createdAt),
        options:
          <MyButtonAsText
            color={theme.primary}
            onClick={() => openReviewBuyAsset({
              ...transaction,
              payFormat: amount,
              tokensFormat,
              currency
            })}
          >
            {t('review')}
          </MyButtonAsText>
      }
    }))
  }, [transactions, theme.primary, timezone, pendingTransactions, i18n.language])

  const headerData = [
    {
      header: t('investors'),
      key: 'investor_name'
    },
    {
      header: assetTypeLabel('singular', 'uppercase'),
      key: 'assetName'
    },
    {
      header: t('currency'),
      key: 'currency'
    },
    {
      header: tokens_actions_request,
      key: 'token_operation'
    },
    {
      header: t('purchase_value'),
      key: 'payment_operation'
    },
    {
      header: tokens_actions_price,
      key: 'token_quantity'
    },
    {
      header: t('date'),
      key: 'date'
    },
    {
      header: t('options'),
      key: 'options'
    }
  ]

  return (
    <>
      <AssetBuyReview
          open={reviewBuyRequest.isOpen}
          data={reviewBuyRequest.data}
          onClose={closeReviewBuyAsset}
          handleSubmit={changeTransactionState}
      />
        <FilterTableHOC
          labelToFilter='date'
          labelToFilterType='date'
          rows={transactionsNotConfirmed}
        >
          {({ dataFilter, key }) =>
            <MyRenderTable
                key={key}
                headerData={headerData}
                rowData={dataFilter}
                title=''
            />
          }
      </FilterTableHOC>
    </>
  )
}
