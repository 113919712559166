import DescriptionIcon from '@mui/icons-material/Description'
import GridViewIcon from '@mui/icons-material/GridView'
import PaidIcon from '@mui/icons-material/Paid'
import { useContext, useLayoutEffect } from 'react'
import { AcceptancesContext } from '../../../contexts/AcceptancesContext'
import { AssetsContext } from '../../../contexts/AssetsContext'
import { TransactionsContext } from '../../../contexts/TransactionsContext'
import UserContext from '../../../contexts/UserContext'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import { MaterialIcon } from '../SidebarOptions'
import { SidebarTabsProps } from '../SidebarTabs'
import { useClientConfig } from '../../../hooks/useClientConfig'
import { useTranslation } from 'react-i18next'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import ClientContext from '../../../contexts/ClientContext'
import { assetsTypeByClient } from '../../../types/types'

export const Issuers = (): SidebarTabsProps[] => {
  const { loadTransactionsByIssuer, transactions } = useContext(TransactionsContext)
  const { loadStatusAcceptancesByIssuer, pendingAcceptances } = useContext(AcceptancesContext)
  const { loadAssetsByIssuerAndFilter } = useContext(AssetsContext)
  const { user } = useContext(UserContext)
  const { assetTypeLabel } = useClientConfig()
  const { assetsType } = useContext(ClientContext)
  const [t] = useTranslation()

  useLayoutEffect(() => {
    async function init () {
      await Promise.all([
        loadAssetsByIssuerAndFilter(),
        loadTransactionsByIssuer(),
        loadStatusAcceptancesByIssuer('REQUESTED')
      ])
    }
    void init()
    // eslint-disable-next-line
  }, [])

  let defaultOptions = [
    {
      index: 1,
      navigateTo: 'issuers',
      Icon: <GridViewIcon {...MaterialIcon} />,
      title: assetTypeLabel('plural', 'capitalize')
    },
    {
      index: 2,
      navigateTo: 'issuers-buy',
      Icon: <PaidIcon {...MaterialIcon} />,
      title: t('my_purchases'),
      numberAlert: transactions.filter(transaction => transaction.authorized === 'pending')?.length
    }
  ]

  if (user?.issuer?.step !== 5) {
    if (assetsType === assetsTypeByClient.EQUITIES) {
      defaultOptions = [
        ...defaultOptions,
        {
          index: 3,
          navigateTo: 'issuers-tax',
          Icon: <AccountBalanceIcon {...MaterialIcon} />,
          title: t('tax')
        }
      ]
    }
    return [
      ...defaultOptions,
      {
        index: 4,
        navigateTo: 'issuers-onb',
        Icon: <FactCheckIcon {...MaterialIcon} />,
        title: t('finish_your_registration')
      }
    ]
  } else {
    if (assetsType === assetsTypeByClient.EQUITIES) {
      return [
        ...defaultOptions,
        {
          index: 3,
          navigateTo: 'issuers-vdr',
          Icon: <DescriptionIcon {...MaterialIcon} />,
          title: t('Documentation'),
          numberAlert: pendingAcceptances?.length || 0
        },
        {
          index: 4,
          navigateTo: 'issuers-tax',
          Icon: <AccountBalanceIcon {...MaterialIcon} />,
          title: t('tax')
        }
      ]
    }
    return [
      ...defaultOptions,
      {
        index: 3,
        navigateTo: 'issuers-vdr',
        Icon: <DescriptionIcon {...MaterialIcon} />,
        title: t('Documentation'),
        numberAlert: pendingAcceptances?.length || 0
      }
    ]
  }
}
