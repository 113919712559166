import { Grid, IconButton } from '@mui/material'
import { Fragment, useContext, useState } from 'react'
import UserContext from '../../../contexts/UserContext'
import { MyModal } from '../../MyModal'
import { VDRDetails } from '../VDRDetails'
import { Paragraph } from '../../Paragraph'
import { colorPalette } from '../../../conf/ColorConfig'
import { LabelFolderContainer, VDRTag } from './StyledComponents'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { MyDownloadRef } from '../../MyDownloadRef'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { VDRButton } from './VDRButton'
import LoadingContext from '../../../contexts/LoadingContext'
import { postVDR } from '../../../core/apiVDR'
import { uploadImg } from '../../../core/apiUploadDocs'
import NotificationContext from '../../../contexts/NotificationContext'
import { useClientConfig } from '../../../hooks/useClientConfig'
import { useTranslation } from 'react-i18next'

interface Props {
  id: string
  canSeeConfidential: boolean
  folder: any
  type: 'Empresa' | 'Activo'
  data: any[]
  reloadData: () => Promise<void>
  canEdit: boolean
  canDelete: boolean
  deleteVDR: (file: any, folder: string, fileId: string) => Promise<void>
  canLoad?: boolean
  issuerData: any
  reloadIssuer: () => Promise<void>
  label: string
}

export const VDRTable = ({
  id,
  folder,
  data = [],
  reloadData,
  canDelete,
  canSeeConfidential,
  canEdit,
  deleteVDR,
  canLoad = true,
  issuerData,
  reloadIssuer,
  type,
  label
}: Props) => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { assetTypeLabel } = useClientConfig()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const { issuer_id } = useContext(UserContext)
  const [file, setFile] = useState<any>()
  const [t] = useTranslation()

  const closeDetails = () => {
    setShowDetails(false)
  }

  const openDeleteModal = (file: any) => {
    setFile(file)
    setShowDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const OptsVdr = ({ index }: any) => {
    if (!canEdit) {
      return <MyDownloadRef
        href={'https://' + data[index]?.url}
        target='_blank'
        color='#00ADEF'
        rel="noreferrer noopener"
      >{t('download')}</MyDownloadRef>
    }
    return <></>
  }

  const handleDelete = async () => {
    await deleteVDR(file.file, folder, file.id)
    closeDeleteModal()
  }

  const handleSubmit = async (files: any[]) => {
    showLoading()
    try {
      const files_length: number = files?.length || 0
      for (let i = 0; i < files_length; i++) {
        const docName = files[i].name
        const upload = await postVDR(id, folder, docName, files[i].name)
        await uploadImg(upload.records[0].signedUrl, files[i])
      }
      await reloadData()
    } catch (error) {
      console.log(error)
      showToast('error', 'Error')
    }
    hideLoading()
  }

  return (
        <>
            <VDRDetails
                reloadIssuer={reloadIssuer}
                disabled={issuerData?.docReady}
                issuerId={issuer_id}
                isOpen={showDetails}
                onClose={closeDetails}
            />
            <MyModal
                open={showDeleteModal}
                title={t('delete_vdr.title') ?? ''}
                text={t('delete_vdr.content', { FILE: file?.file }) ?? ''}
                twoButtons={true}
                cancel={t('delete_vdr.cancel') ?? ''}
                accept={t('delete_vdr.accept') ?? ''}
                onClose={closeDeleteModal}
                onAccept={handleDelete}
            />
            { canSeeConfidential
              ? <Grid container spacing={3} mt={1}>
                  <Grid item sm={12} xs={12} md={12}>
                    <LabelFolderContainer id={folder}>
                      <Paragraph bold type='subheading'>
                        { label }
                      </Paragraph>
                    </LabelFolderContainer>
                  </Grid>
                  <Grid item sm={12} xs={12} md={12}>
                    { data.map((vdrFile, index) => {
                      const tagType = vdrFile.id.split('_').length !== 3 ? 'empresa' : 'activo'
                      const wordingTag = assetTypeLabel('singular', 'capitalize')
                      const tagLabel = tagType === 'empresa' ? t('admins') : wordingTag
                      if (vdrFile.folder === folder) {
                        return (
                        <Grid item xs={12} key={vdrFile.file} my={1}>
                          <Grid container alignItems='center' spacing={1.5}>
                            <Grid item md={1} sm={2} xs={2} display='flex' justifyContent='center'>
                              <InsertDriveFileIcon
                                sx={{ fontSize: 20 }}
                                htmlColor={colorPalette.basics.HEX_3D4D6A}
                              />
                            </Grid>
                            <Grid item md={6} sm={10} xs={10} style={{ wordBreak: 'break-all' }}>
                              <Paragraph type='small'>{vdrFile.file}</Paragraph>
                            </Grid>
                            <Grid item md={2} sm={6} xs={6} >
                              <VDRTag type={tagType} wordingType={wordingTag} label={tagLabel} />
                            </Grid>
                            <Grid item md={3} sm={6} xs={6}>
                              <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                <OptsVdr index={index} />
                                {canDelete &&
                                <IconButton
                                  onClick={() => openDeleteModal(vdrFile)}
                                  disabled={type === 'Activo' && tagType === 'empresa'}
                                >
                                  <DeleteForeverIcon
                                    sx={{ fontSize: 20 }}
                                    htmlColor={
                                      type === 'Activo' && tagType === 'empresa'
                                        ? colorPalette.complementary.white
                                        : colorPalette.complementary.error
                                    }
                                  />
                                </IconButton>
                                }
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        )
                      }
                      return <Fragment key={index}/>
                    })}
                  </Grid>
                  { canLoad
                    ? <Grid item xs={12}>
                        <VDRButton folder={folder} onSubmit={handleSubmit} />
                    </Grid>
                    : <></>
                  }
              </Grid>
              : null
            }
        </>
  )
}
