import NotificationContext from '../../../contexts/NotificationContext'
import LoadingContext from '../../../contexts/LoadingContext'
import { LayoutDialog } from '../../../layout/LayoutDialog'
import { getClients } from '../../../core/apiClients'
import { useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { MyButton } from '../../../components/MyButton'
import { ClientTable } from './ClientTable'
import { Client } from '../../../types/types'
import { ClientFormMain } from './ClientFormMain'

interface ShowDialogProps {
  mode: 'create' | 'edit'
  data: Client | null
  isOpen: boolean
}

const initialDialog: ShowDialogProps = {
  mode: 'create',
  data: null,
  isOpen: false
}

export const DashboardAdminQX = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [{ data, isOpen, mode }, setShowDialog] = useState<ShowDialogProps>(initialDialog)
  const [clients, setClients] = useState([])
  const { showToast } = useContext(NotificationContext)

  const openDialog = (values: ShowDialogProps) => { setShowDialog(values) }
  const closeDialog = () => { setShowDialog(initialDialog) }

  const loadDataClients = async () => {
    showLoading()
    const resp = await getClients()
    if (resp?.success) setClients(resp.records)
    else showToast('Error', 'Error')
    hideLoading()
  }

  useEffect(() => {
    async function init () {
      await loadDataClients()
    }
    void init()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item>
          <MyButton onMouseUp={() => openDialog({ mode: 'create', data: null, isOpen: true })} >Crear nuevo cliente</MyButton>
        </Grid>
      </Grid>
      <ClientTable data={clients} handleEdit={openDialog} />
      <LayoutDialog open={isOpen} closeDialog={closeDialog}>
        <ClientFormMain
          onClose={closeDialog}
          refreshTable={loadDataClients}
          mode={mode}
          data={data}
        />
      </LayoutDialog>
    </>
  )
}
