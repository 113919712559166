import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AssetFormContext } from './DialogAssetForm'

interface Props {
  data: Extradata[]
  setData: React.Dispatch<any>
}

interface Extradata {
  icono: string
  title: string
  description: string
}

export const ExtraDataComponent = ({ data = [], setData }: Props) => {
  const { assetType } = useContext(AssetFormContext)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    type: string
  ) => {
    const { value } = e.target
    setData(data.map((e, i) => i === index ? { ...e, [type]: value } : e))
  }

  return (
    <>
      {data.map((e: any, index: number) => {
        if (assetType === 'EQUITY' && index === 0) {
          return (
            <EquityListItem
              handleChange={handleChange}
              setData={setData}
              index={index}
              key={index}
              data={data}
            />
          )
        }
        return (
        <ListItem
          handleChange={handleChange}
          index={index}
          key={index}
          data={data}
        />)
      })}
    </>
  )
}

const ListItem = ({ index, handleChange, data }: any) => {
  const [t] = useTranslation()
  return (
  <Grid container spacing={1} key={index} mb={1}>
    <Grid item sm={6} xs={6}>
      <TextField
        id={`title-${index}`}
        label={t('title')}
        fullWidth
        value={data[index].title}
        onChange={event => handleChange(event, index, 'title')}
        required={data[index]?.icono !== ''}
      />
    </Grid>
    <Grid item sm={6} xs={6}>
      <TextField
        id={`description-${index}`}
        label={t('description')}
        fullWidth
        value={data[index].description}
        onChange={event => handleChange(event, index, 'description')}
        required={data[index]?.icono !== ''}
      />
    </Grid>
  </Grid>)
}

const EquityListItem = ({ index, setData, handleChange, data }: any) => {
  const { t, i18n: { language } } = useTranslation()
  const { assetType } = useContext(AssetFormContext)
  useEffect(() => {
    if (assetType === 'EQUITY') {
      const cloneData = [...data]
      const title = language === 'es' ? 'Tipo de oferta' : 'Offer type'
      cloneData[0].title = title
      setData(cloneData)
    }
  // eslint-disable-next-line
  }, [])

  return (
    <Grid container spacing={1} key={index} mb={1}>
      <Grid item sm={6} xs={6}>
        <TextField
          id={`title-${index}`}
          label={t('title')}
          fullWidth
          disabled
          value={data[index].title}
        />
      </Grid>
      <Grid item sm={6} xs={6}>
        <FormControl fullWidth>
          <InputLabel id={`description-${index}`}>{t('description')}</InputLabel>
          <Select
            id={`description-${index}`}
            label={t('description')}
            fullWidth
            value={data[index].description}
            onChange={event => handleChange(event, index, 'description')}
            required={data[index]?.icono !== ''}
          >
            <MenuItem value='Primaria'>{t('primary')}</MenuItem>
            <MenuItem value='Secundaria'>{t('secondary')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
