import { useResponsive } from '../../hooks/useResponsive'
import { DesktopMobile } from './DesktopMobile'
import { DesktopTabs } from './DesktopTabs'

interface TabProps {
  title: string
  component: JSX.Element
  notification?: number
}

export interface TabsContainerProps {
  tabs: TabProps[]
}

export const TabsContainer = (props: TabsContainerProps) => {
  const { isMobileDevice } = useResponsive()
  return (
    <>
      {
        isMobileDevice
          ? <DesktopMobile {...props} />
          : <DesktopTabs {...props} />
      }
    </>
  )
}
