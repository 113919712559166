import { Dialog, Grid } from '@mui/material'
import { useContext } from 'react'
import { MyTransition } from '../../../../components/MyTransition'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { MyButton } from '../../../../components/MyButton'
import { TransactionsContext } from '../../../../contexts/TransactionsContext'
import ClientContext from '../../../../contexts/ClientContext'
import { timezoneFormatWithHour } from '../../../../core/timezoneFormat'
import { Paragraph } from '../../../../components/Paragraph'
import { LabelModalRow } from '../../Cliente/Autorizar/Activos/AssetBuyReview'
import { useResponsive } from '../../../../hooks/useResponsive'
import { useClientConfig } from '../../../../hooks/useClientConfig'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../../../core/utils'

interface Props {
  open: boolean
  onClose: () => void
  data: any
  handleSubmit: (
    id: string,
    type: 'pay_confirmed' | 'authorized',
    state: 'pending' | 'approved' | 'rejected'
  ) => Promise<void>
}

export const ReviewBuyRequest = ({ open, onClose, data, handleSubmit }: Props) => {
  const { loadTransactionsByIssuer } = useContext(TransactionsContext)
  const { tokens_actions_request, tokens_actions_price_by } = useContext(IdiomContext)
  const { timezone } = useContext(ClientContext)
  const { isMobileDevice } = useResponsive()
  const { assetTypeLabel } = useClientConfig()
  const { t } = useTranslation()

  const onSubmit = async (state: 'approved' | 'rejected') => {
    await handleSubmit(data?.id, 'authorized', state)
    await loadTransactionsByIssuer()
    onClose()
  }

  const isEquity = data?.asset?.assetType === 'EQUITY'

  return (
    <Dialog
      PaperProps={{ style: { padding: isMobileDevice ? '14px 16px' : '32px 48px', maxWidth: 650 } }}
      open={open}
      onClose={onClose}
      TransitionComponent={MyTransition}
    >
      <Grid container spacing={1} >
        <Grid item xs={12} sm={12} textAlign='center'>
          <Paragraph bold>{t('purchase_details')}</Paragraph>
        </Grid>
        <Grid item xs={6} sm={6} md={6} textAlign='right' mt={2}>
          <Paragraph bold>{t('investor_data')}</Paragraph>
        </Grid>
        <Grid item xs={6} />
        <LabelModalRow title={t('full_name')} label={`${data?.investor?.first_names} ${data?.investor?.fathers_name} ${data?.investor?.mothers_name}`} />
        <LabelModalRow title={t('country')} label={t(`countries.${data?.investor?.additional_data?.country}`)} />
        <LabelModalRow title={t('sum_to_invest')} label={formatCurrency(data?.payFormat, data?.asset?.currency)} />
        { !isEquity && <LabelModalRow title={t('request_investor.investment_background')} label={data?.investor?.additional_data?.investment_background} /> }
        <Grid item xs={6} sm={6} md={6} textAlign='right' mt={2}>
          <Paragraph bold>Datos de la compra</Paragraph>
        </Grid>
        <Grid item xs={6} />
        <LabelModalRow title={assetTypeLabel('singular', 'capitalize')} label={data?.asset?.name} />
        <LabelModalRow title={tokens_actions_request} label={data?.tokensFormat} />
        <LabelModalRow title={tokens_actions_price_by} label={formatCurrency(data?.token_priceFormat, data?.asset?.currency)} />
        <LabelModalRow title={t('request_date')} label={timezoneFormatWithHour(timezone, data?.createdAt)} />
        <Grid item xs={12} mt={3}/>
        <Grid item xs={12} sm={4} >
          <MyButton color='info' onMouseUp={onClose} fullWidth>{t('review_later')}</MyButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <MyButton
            color='secondary'
            fullWidth
            onMouseUp={async () => await onSubmit('rejected')}
          >{t('reject')}</MyButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <MyButton
            fullWidth
            onMouseUp={async () => await onSubmit('approved')}
          >{t('authorize')}</MyButton>
        </Grid>
      </Grid>
    </Dialog>
  )
}
