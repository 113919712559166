import { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ClientContext from '../contexts/ClientContext'
import { ColorContext } from '../contexts/ColorContext'
import LoadingContext from '../contexts/LoadingContext'
import { getBasicClientById } from '../core/apiClients'
import { colorParse } from '../core/utils'

export const useLoadClient = () => {
  const { handleSetClient, clientClear } = useContext(ClientContext)
  const { changeTheme, defaultTheme } = useContext(ColorContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [showComponent, setShowComponent] = useState(false)
  const navigate = useNavigate()

  const setUpInitialClient = (client: any) => {
    handleSetClient(client)
    changeTheme(colorParse(client.setupColors))
  }

  const loadClient = async () => {
    clientClear()
    setShowComponent(false)
    showLoading()
    const address = window.location.href
    const slicedAddress = address.split('/').filter(i => i !== '')
    const client = slicedAddress[slicedAddress.length - 1]
    let resp
    if (client !== 'admin-qx') resp = await getBasicClientById(client)
    if (resp?.success && resp?.records) {
      setUpInitialClient(resp.records?.[0])
      setShowComponent(true)
    } else if (client === 'admin-qx') {
      defaultTheme()
      setShowComponent(true)
    } else navigate('/404', { replace: true })
    hideLoading()
  }

  useEffect(() => {
    async function init () {
      await loadClient()
    }
    void init()
    // eslint-disable-next-line
  }, [])

  return { showComponent }
}
