import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './index.scss'
import { DEFAULT_LANGUAGE, getLanguageResources } from './constants'

void i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('user_config_language') ?? DEFAULT_LANGUAGE,
  resources: getLanguageResources()
  /* Usage:
  1) Create a file in src/translations/<lng>/translation.json
  2) Add the language to the SUPPORTED_LANGUAGES array in src/constants.ts
  3) On each component, use the hook useTranslation() to get the translation function
  const [t, i18n] = useTranslation()
  4) Use the translation function to get the translation of a key
  {t('key')}
  Where 'key' is the key of the translation in the translation.json file
  {
    ...
    "key": "translation"
  }
  In case you need to use a variable in the translation, use the following syntax:
  {t('key', { variable: 'value' })}
  And in the translation.json file:
  {
    ...
    "key": "translation {{variable}}"
  }
  */
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App/>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
