import { useState, useEffect } from 'react'
import { MyTable } from '../../../components'
import { User } from '../../../types/types'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDialog } from '../../../hooks/useDialog'
import { DeleteUserModal } from './DeleteUserModal'
import { useTranslation } from 'react-i18next'

interface Props {
  users: User[]
  updateData: (users: any[], userToChange: string, action: 'create' | 'delete') => Promise<void>
}

export const TableMasterUsers = ({ users, updateData }: Props) => {
  const { closeDialog, openDialog, isOpen, data: emailToDelete } = useDialog()
  const [rows, setRows] = useState<any[]>([])
  const { t } = useTranslation()

  const handleDelete = async () => {
    await updateData(rows, emailToDelete, 'delete')
  }

  useEffect(() => {
    setRows(Array.from(users, user => {
      return {
        ...user,
        options:
          <IconButton onClick={() => openDialog(user.email)} disabled={users.length === 1}>
            <DeleteIcon color={users.length === 1 ? 'disabled' : 'error'} />
          </IconButton>,
        action: 'none'
      }
    }))
    return () => {
      setRows([])
    }
  // eslint-disable-next-line
  }, [users])

  const headerData = [
    { header: t('user'), key: 'email' },
    { header: t('name'), key: 'first_names' },
    { header: t('last_father_name'), key: 'fathers_name' },
    { header: t('last_father_name'), key: 'mothers_name' },
    { header: t('options'), key: 'options' }
  ]

  return (
    <>
      <DeleteUserModal
        onAccept={handleDelete}
        onClose={closeDialog}
        open={isOpen}
      />
      <MyTable rowData={rows} headerData={headerData}/>
    </>
  )
}
