import { TabsContainer } from '../../../../components/DashboardTabs/TabsContainer'
import { InvestorsVDR } from './InvestorsVDR'
import { Grid } from '@mui/material'
import { useScrollToTop } from '../../../../hooks/useScrollToTop'
import { Title } from '../../../../components/Title'
import { VDRCompany } from './VDRCompany'
import { DocumentsDetails } from './DocumentsDetails'
import { AcceptancesContext } from '../../../../contexts/AcceptancesContext'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

export const DocumentationMain = () => {
  const { pendingAcceptances } = useContext(AcceptancesContext)
  const { t } = useTranslation()
  useScrollToTop()

  const Tabs = [
    {
      title: t('issuers'), component: <VDRCompany />
    },
    { title: t('access_requests'), component: <InvestorsVDR />, notification: pendingAcceptances.length },
    { title: t('documentation_details'), component: <DocumentsDetails /> }
  ]

  return (
    <Grid container mt={3} spacing={2}>
      <Grid item xs={12} sm={12} md={12} ml={6} mb={2}>
        <Title>{t('Documentation')}</Title>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TabsContainer tabs={Tabs} />
      </Grid>
    </Grid>
  )
}
