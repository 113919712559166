import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { MyTextInput } from '../../../../components'
import { MyButton } from '../../../../components/MyButton'
import { Paragraph } from '../../../../components/Paragraph'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { REGEX_STRING_WITHOUT_NUMBERS } from '../../../../core/utils'

interface Props {
  onSubmit: (values: any) => void
  previousStep: () => void
  values: any
}

export const FormCompanyData = ({ previousStep, onSubmit, values }: Props) => {
  const [t] = useTranslation()

  return (
        <Formik
            onSubmit={onSubmit}
            initialValues={values}
            validationSchema={validationSchema}
        >
            {() => {
              return (
                    <Form>
                        <Grid container mb={2} spacing={2}>
                            <Grid item sm={12} md={12} xs={12} mb={3}>
                                <Paragraph type='subheading'><strong>{t('create_company_form.page_one.tab_title')}</strong></Paragraph>
                            </Grid>
                            <Grid item sm={12} md={9} xs={12}>
                                <Paragraph>{t('create_company_form.page_one.data_company_description')}</Paragraph>
                            </Grid>
                            <Grid item sm={12} xs={12} md={9}>
                                <MyTextInput
                                    name='company_name'
                                    label={t('company_name')}
                                />
                            </Grid>
                            <Grid item sm={12} md={12} xs={12} my={3}>
                                <Paragraph type='subheading'><strong>{t('create_company_form.page_one.legal_representative_title')}</strong></Paragraph>
                            </Grid>
                            <Grid item sm={12} md={12} xs={12}>
                                <Paragraph><strong>{t('legal_representative_one')}</strong></Paragraph>
                            </Grid>
                            <Grid item sm={4} md={3} xs={12}>
                                <MyTextInput
                                    label={t('name')}
                                    name='nameRepresentOne'
                                />
                            </Grid>
                            <Grid item sm={4} md={3} xs={12}>
                                <MyTextInput
                                    label={t('last_father_name')}
                                    name='lastFatherRepresentOne'
                                />
                            </Grid>
                            <Grid item sm={4} md={3} xs={12}>
                                <MyTextInput
                                    label={t('last_mother_name')}
                                    name='lastMotherRepresentOne'
                                />
                            </Grid>
                            <Grid item sm={12} md={12} xs={12} >
                                <Paragraph><strong>{t('legal_representative_two')}</strong></Paragraph>
                            </Grid>
                            <Grid item sm={4} md={3} xs={12}>
                                <MyTextInput
                                    label={t('name')}
                                    name='nameRepresentTwo'
                                />
                            </Grid>
                            <Grid item sm={4} md={3} xs={12}>
                                <MyTextInput
                                    label={t('last_father_name')}
                                    name='lastFatherRepresentTwo'
                                />
                            </Grid>
                            <Grid item sm={4} md={3} xs={12}>
                                <MyTextInput
                                    label={t('last_mother_name')}
                                    name='lastMotherRepresentTwo'
                                />
                            </Grid>
                            <Grid item sm={12} md={12} xs={12} />
                            <Grid item mt={3}>
                                <MyButton color='secondary' onMouseUp={previousStep} >
                                    {t('cancel')}
                                </MyButton>
                            </Grid>
                            <Grid item mt={3}>
                                <MyButton type='submit' >
                                    {t('next')}
                                </MyButton>
                            </Grid>
                        </Grid>
                    </Form>
              )
            }}
        </Formik>
  )
}

const validationSchema = yup.object().shape({
  company_name: yup.string().required('required_field'),
  nameRepresentOne: yup.string().matches(REGEX_STRING_WITHOUT_NUMBERS, 'string_without_numbers'),
  lastFatherRepresentOne: yup.string(),
  lastMotherRepresentOne: yup.string(),

  nameRepresentTwo: yup.string().matches(REGEX_STRING_WITHOUT_NUMBERS, 'string_without_numbers'),
  lastFatherRepresentTwo: yup.string(),
  lastMotherRepresentTwo: yup.string()
})
