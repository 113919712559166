import { useCallback, useContext, useEffect, useState } from 'react'
import ClientContext from '../contexts/ClientContext'
import { uploadImg } from '../core/apiUploadDocs'
import { getVDRByFolder, postVDRWithPosition, putVDR } from '../core/apiVDR'
import { VDRFile } from '../types/types'
import { useVDR } from './useVDR'

export const useMultimedia = () => {
  const { client_id } = useContext(ClientContext)
  const { deleteVdrFile } = useVDR()
  const [files, setFiles] = useState<VDRFile[]>([])

  useEffect(() => {
    setFiles([])

    return () => setFiles([])
  }, [])

  const getMultimediaByFolder = useCallback(async (
    issuer_id: string,
    asset_id: string,
    folder: 'image' | 'video'
  ): Promise<string[]> => {
    const resp = await getVDRByFolder(client_id, issuer_id, asset_id, folder)
    if (resp?.success) {
      const vdrFiles: VDRFile[] = resp.records
      const orderedFiles = vdrFiles.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))
      const respFiles = orderedFiles.map((file: any) => 'https://' + file.url)
      setFiles(prev => [...prev, ...orderedFiles])
      return respFiles
    }
    return []
  },
  [client_id]
  )

  const postAllMultimediaFilesByFolder = useCallback(async (
    issuer_id: string,
    asset_id: string,
    folder: 'image' | 'video',
    files: Array<File | string>
  ): Promise<string[]> => {
    const urlFiles = []
    const id = `${client_id}/${issuer_id}?asset_id=${asset_id}`
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (typeof file === 'string') {
        urlFiles.push(file)
        continue
      }

      const fileName = file.name
      const upload = await postVDRWithPosition(id, folder, fileName, fileName, i)
      await uploadImg(upload.records[0].signedUrl, file)
      urlFiles.push('https://' + upload.records[0].url)
    }
    return urlFiles
  },
  [client_id]
  )

  const deleteMultimediaFiles = useCallback(async (
    newFiles: string[]
  ) => {
    for (let i = 0; i < files.length; i++) {
      const { url, id, file, folder } = files[i]
      if (!newFiles.includes('https://' + url)) {
        const splitId = id.split('_')
        const parseId = splitId[0] + '/' + splitId[1] + '_' + splitId[2]
        await deleteVdrFile(parseId, file, folder)
      }
    }
  }, [client_id, files])

  const deleteAllMultimediaFiles = useCallback(async () => {
    for (let i = 0; i < files.length; i++) {
      const { id, file, folder } = files[i]
      const splitId = id.split('_')
      const parseId = splitId[0] + '/' + splitId[1] + '_' + splitId[2]
      await deleteVdrFile(parseId, file, folder)
    }
  }, [client_id, files])

  const updatedPosition = useCallback(async (newFiles) => {
    for (let i = 0; i < newFiles.length; i++) {
      const urlToCompare = newFiles[i]
      for (let j = 0; j < files.length; j++) {
        if (urlToCompare === ('https://' + files[j].url) && i !== j) {
          console.log(i, j)
          const splitId = files[j].id.split('_')
          await putVDR(
            `${splitId[0]}/${splitId[1]}?asset_id=${splitId[2]}`,
            { ...files[j], position: i },
            files[j]
          )
          break
        }
      }
    }
  }, [client_id, files])

  return {
    getMultimediaByFolder,
    updatedPosition,
    postAllMultimediaFilesByFolder,
    deleteMultimediaFiles,
    deleteAllMultimediaFiles
  }
}
