import { useField } from 'formik'
import { FileUploaderDropContainer } from 'carbon-components-react'
import { useContext } from 'react'
import { IdiomContext } from '../contexts/IdiomContext'
import { Grid, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Paragraph } from './Paragraph'
import { isFileSizeAllowed } from '../core/utils'
import NotificationContext from '../contexts/NotificationContext'
import { handleImageCompression } from '../core/imageCompression'
import { useTranslation } from 'react-i18next'

interface Props {
  labelText: string
  title: string
  name: string
  accept: string[]
  typeText?: string
  [propName: string]: any
}

export const MyUploadInput = ({ typeText = 'Solo .jpg y .png. 500kb máximo', ...props }: Props) => {
  const [field, meta, helpers] = useField(props)
  const { yupErrors } = useContext(IdiomContext)
  const { showToast } = useContext(NotificationContext)
  const showError: boolean = !!meta.error && meta.touched
  const customClassName = showError ? 'upload-error' : ''
  const { t } = useTranslation()

  let customLabel
  if (!showError && field.value?.name === undefined) customLabel = props.labelText
  else if (showError) customLabel = yupErrors[meta?.error ?? 'default']
  else customLabel = field.value?.name

  const handleUploadFiles = async (evt: any, { addedFiles }: any) => {
    if (isFileSizeAllowed(addedFiles[0], 50)) {
      const compressedFile = await handleImageCompression(addedFiles[0])
      helpers.setValue(compressedFile)
    } else {
      showToast('error', t('toasts.max_size_files'))
      helpers.setValue('')
    }
  }

  const createImg = () => {
    try {
      if (typeof field.value === 'string') return field.value
      else return URL?.createObjectURL(field.value)
    } catch (e) {
      return ''
    }
  }

  return (
    <>
    <Paragraph type='subheading'><strong>{props.title}</strong></Paragraph>
      {
        field.value !== ''
          ? <Grid container spacing={2}>
          <Grid item>
            { props.accept.includes('.png')
              ? <img
                alt={field?.value?.name || 'file'}
                src={ createImg() }
                style={{
                  maxWidth: 360,
                  maxHeight: 100,
                  border: 'groove',
                  marginTop: 5,
                  color: 'black'
                }}
              />
              : <Paragraph>{typeof field?.value === 'string' ? field.value : field.value?.name}</Paragraph>

            }
          </Grid>
          <Grid item alignSelf='center'>
            <IconButton
              disabled={props?.disabled}
              size="medium"
              onClick={() => helpers.setValue('')}
            >
              <DeleteIcon color='error' />
            </IconButton>
          </Grid>
        </Grid>
          : <>
          <Paragraph>{ typeText }</Paragraph>
          <FileUploaderDropContainer
            className={`bx--file-browse-btn upload-styled ${customClassName}`}
            id={props.name}
            onAddFiles={handleUploadFiles}
            {...props}
            labelText={customLabel}
          />
        </>
      }
    </>
  )
}
