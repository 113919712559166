import { Divider, Grid } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph } from '../../components/Paragraph'
import UserContext from '../../contexts/UserContext'
import { IdiomConfiguration } from './IdiomConfiguration'

export const GeneralConfig = () => {
  const { myUser } = useContext(UserContext)
  const [t] = useTranslation()

  return (
    <Grid container spacing={2} pl={8}>
      <DataRow title={t('name')} text={`${myUser?.first_names} ${myUser?.fathers_name} ${myUser?.mothers_name}`}/>
      <DataRow title={t('email')} text={myUser?.email}/>
      <Grid item sm={12} xs={12}>
        <Divider />
      </Grid>
      <Grid item sm={12} xs={12}>
        <IdiomConfiguration />
      </Grid>
    </Grid>
  )
}

const DataRow = ({ title, text }: { title: string, text: string }) => (
  <>{ text
    ? <Grid item xs={12} sm={12}>
      <Grid container>
        <Grid item sm={12} xs={12}>
            <Paragraph><strong>{ title }</strong></Paragraph>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Paragraph>{ text }</Paragraph>
          </Grid>
      </Grid>
    </Grid>
    : <></>
  }</>
)
