import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useEffect, useState, useContext, Fragment } from 'react'
import { formatCurrency, formatNumber } from '../../../../../core/utils'
import { buyAssetValidationSchema } from '../../../../../validations/buyAssetValidationSchema'
import { MyNumberFormat, MyNumberInput } from '../../../../../components/MyNumberFormat'
import { IdiomContext } from '../../../../../contexts/IdiomContext'
import { DisclaimerText } from '../../../../../design-system-components'
import styled from '@emotion/styled'
import checkboxOn from '../../../../../assets/checkbox-on.svg'
import { Paragraph } from '../../../../../components/Paragraph'
import { MyButton } from '../../../../../components/MyButton'
import { useTranslation } from 'react-i18next'
import { useCheckboxes } from '../../../../../hooks/useCheckboxes'

interface Props {
  data: any
  onClose: () => void
  onSubmit: (values: BuyAssetValues) => Promise<void>
  isConfidentiality: boolean
}

export interface BuyAssetValues {
  tokens: number
  monto: number
  precio: number
  maximum_token: any
  minimun_token: any
  maximun_amount: any
  minimum_amount: any
}

const CustomCheckbox = styled.div`
  display:flex;
  gap: 15px;
  align-items:center;
  padding-bottom: 0.5rem;
`
interface ITAXES {
  [prop: string]: string
}

// NEED A REFACTOR
export const BuyForm = ({ data, onClose, onSubmit, isConfidentiality }: Props) => {
  const [values, setValues] = useState<BuyAssetValues>({
    tokens: 0,
    monto: 0,
    precio: 0,
    minimun_token: 0,
    maximum_token: 0,
    maximun_amount: 0,
    minimum_amount: 0
  })
  const [key, setKey] = useState(Math.random())
  const { precio, minimun_token, maximum_token, maximun_amount, minimum_amount } = values
  const { currency } = data
  const { tokens_actions_uppercase, tokens_actions_price_by } = useContext(IdiomContext)
  const { ACTIONS_FEATURES, ENHANCEMENT_TRANSMISSION } = useCheckboxes()
  const { token_action_lowercase } = useContext(IdiomContext)
  const { t, i18n: { language } } = useTranslation()

  const TAXES: ITAXES = {
    1: t('tax_rule.yes'),
    2: t('tax_rule.no'),
    3: t('tax_rule.not_defined')
  }

  const taxRuling: keyof ITAXES = data?.tax_ruling
  const currentTax = TAXES[taxRuling]

  useEffect(() => {
    const { token_quantity, maximum_token, minimun_token, token_price } = data
    let maxToken = token_quantity
    if (data?.contract?.tokensAvailable) { maxToken = data?.contract?.tokensAvailable <= maximum_token ? data?.contract?.tokensAvailable : maximum_token }
    setValues({
      maximum_token: maxToken,
      minimun_token,
      tokens: 0,
      monto: 0,
      precio: token_price,
      maximun_amount: maxToken * token_price,
      minimum_amount: minimun_token * token_price
    })
    setKey(v => v + Math.random())
  }, [data])

  const handleChange = (e: any, setFieldValue: any) => {
    const { name, value } = e.target
    setFieldValue(name, value)
    setFieldValue('monto', value * precio)
  }

  const tokenRef = language === 'es'
    ? `Precio de referencia por ${token_action_lowercase}`
    : `Reference price per ${token_action_lowercase}`

  return (
    <Formik key={key} initialValues={values} onSubmit={onSubmit} validationSchema={buyAssetValidationSchema}>
      {({ setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} mb={2} textAlign='center'>
              <Paragraph bold>{t('investor_user.buy.title')}</Paragraph>
            </Grid>
            { data?.assetType === 'EQUITY'
              ? <>
                <Grid item sm={6} xs={12} mb={2}>
                  <MyNumberInput
                    id='tokens'
                    name='tokens'
                    currency={currency}
                    label={tokens_actions_uppercase}
                    onChange={(e: any) => handleChange(e, setFieldValue)}
                  />
                  <Paragraph type='caption'>
                    {`${t('investor_user.buy.min')} ${formatNumber(minimun_token)} - ${t('investor_user.buy.max')} ${formatNumber(maximum_token)}`}
                  </Paragraph>
                </Grid>
                <Grid item sm={6} xs={12} mb={2}>
                  <MyNumberFormat
                    currency={currency}
                    id='monto'
                    name='monto'
                    label={t('amount')}
                    disabled
                  />
                  <Paragraph type='caption'>
                  {`${t('investor_user.buy.min')} ${formatCurrency(minimum_amount, currency)} - ${t('investor_user.buy.max')}  ${formatCurrency(maximun_amount, currency)}`}
                  </Paragraph>
                </Grid>
                <Grid item sm={5} xs={6} mb={2}>
                  <Paragraph bold>{tokenRef}:</Paragraph>
                </Grid>
                <Grid item sm={7} xs={6}>
                  <Paragraph> {formatCurrency(precio, currency)}</Paragraph>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Paragraph bold>{t('company_user.create_asset.actions_features_subtitle')}</Paragraph>
                </Grid>
                <Grid item sm={12} xs={12}>
                  {data?.characteristics?.map((el: string, index: number) => {
                    if (el === '') return <Fragment key={index}/>
                    return (
                      <CustomCheckbox key={el}>
                        <img src={checkboxOn} alt="checked" />
                        <Paragraph type='small'>{
                          ACTIONS_FEATURES.length - 1 === index
                            ? el
                            : ACTIONS_FEATURES[index]
                        }</Paragraph>
                      </CustomCheckbox>
                    )
                  })}
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Paragraph bold>{t('company_user.create_asset.enhancement_transmission_subtitle')}</Paragraph>
                </Grid>
                <Grid item sm={12} xs={12}>
                  {data?.transmissionRefinement?.map((el: string, index: number) => {
                    if (el === '') return <Fragment key={index}/>
                    return (
                      <CustomCheckbox key={el}>
                        <img src={checkboxOn} alt="checked" />
                        <Paragraph type='small'>{
                          ENHANCEMENT_TRANSMISSION.length - 1 === index
                            ? el
                            : ENHANCEMENT_TRANSMISSION[index]
                        }</Paragraph>
                      </CustomCheckbox>
                    )
                  })}
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Paragraph bold>{t('tax_rule.title')}</Paragraph>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <CustomCheckbox>
                    <img src={checkboxOn} alt="checked" />
                    <Paragraph type='small'>{ currentTax }</Paragraph>
                  </CustomCheckbox>
                </Grid>
                <Grid item sm={12} xs={12} mb={2}>
                  { isConfidentiality && Number(data?.capitalPercentage) !== 0 && <Paragraph bold>{t('company_user.create_asset.investor_percentage_label_part_one')} {data?.capitalPercentage}{t('company_user.create_asset.investor_percentage_label_part_two')}</Paragraph> }
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MyButton fullWidth color='secondary' onClick={onClose}>{t('cancel')}</MyButton>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MyButton type='submit' fullWidth>{t('investor_user.buy.send_buy_request')}</MyButton>
                </Grid>
              </>
              : <>
                <Grid item sm={12} xs={12} mb={2}>
                  <MyNumberInput
                    id='tokens'
                    name='tokens'
                    currency={currency}
                    label={tokens_actions_uppercase}
                    onChange={(e: any) => handleChange(e, setFieldValue)}
                  />
                  <Paragraph type='caption'>
                  {`${t('investor_user.buy.min')} ${formatNumber(minimun_token)} - ${t('investor_user.buy.max')} ${formatNumber(maximum_token)}`}
                  </Paragraph>
                </Grid>
                <Grid item sm={12} xs={12} mb={2}>
                  <MyNumberFormat
                    currency={currency}
                    id='monto'
                    name='monto'
                    label='Monto'
                    disabled
                  />
                  <Paragraph type='caption'>
                  {`${t('investor_user.buy.min')} ${formatCurrency(minimum_amount, currency)} - ${t('investor_user.buy.max')} ${formatCurrency(maximun_amount, currency)}`}
                  </Paragraph>
                </Grid>
                <Grid item sm={6} xs={6} mb={2}>
                  <Paragraph bold>{tokens_actions_price_by}:</Paragraph>
                </Grid>
                <Grid item sm={6} xs={6}>
                  <Paragraph> {formatCurrency(precio, currency)}</Paragraph>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <MyButton type='submit' fullWidth>{t('investor_user.buy.send_buy_request')}</MyButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <MyButton fullWidth color='secondary' onClick={onClose}>{t('cancel')}</MyButton>
                </Grid>
              </>
            }
            { data?.assetType === 'EQUITY' &&
              <Grid item sm={12} xs={12}>
                <DisclaimerText>
                  { t('investor_user.buy.disclaimer') ?? '' }
                </DisclaimerText>
              </Grid>
            }
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
