import { useCallback } from 'react'
import countries from '../data/country-data.json'
import { useTranslation } from 'react-i18next'

export const useCountries = () => {
  const { t, i18n: { language } } = useTranslation()
  const list = useCallback(() =>
    countries.map(({ text, value }) => {
      return {
        value,
        text: t(`countries.${value}`)
      }
    })
  , [language])

  return list()
}
