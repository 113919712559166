export const uploadImg = async (url: any, doc: any) => {
  console.log(url, doc)
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', doc.type)

  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: myHeaders,
    body: doc,
    redirect: 'follow'
  }

  await fetch(url, requestOptions)
    .then(async response => await response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error))
}
