import { Grid } from '@mui/material'
import { FormikValues } from 'formik'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph } from '../../components/Paragraph'
import ClientContext from '../../contexts/ClientContext'
import LoadingContext from '../../contexts/LoadingContext'
import NotificationContext from '../../contexts/NotificationContext'
import { changePassword } from '../../core/apiAuth'
import { ChangePasswordForm } from './ChangePasswordForm'

export const ChangePassword = () => {
  const { showToast } = useContext(NotificationContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { clientId } = useContext(ClientContext)
  const { t } = useTranslation()

  const handleChangePassword = async (values: FormikValues, resetForm: any) => {
    showLoading()
    try {
      const resp = await changePassword({ ...values, clientId: clientId() })
      if (resp.success) {
        showToast('success', t('toasts.change_password_success'))
        resetForm()
      } else if (resp.code === 'NotAuthorizedException') {
        showToast('error', t('toasts.change_password_incorrect'))
      } else {
        showToast('error', t('toasts.change_password_error'))
      }
    } catch (error) {
      showToast('error', t('toasts.change_password_success'))
    } finally {
      hideLoading()
    }
  }

  return (
        <Grid container spacing={2} pl={8}>
            <Grid item sm={12}>
                <Paragraph> {t('password_rules.subtitle')}</Paragraph>
                <ul style={{ listStyle: 'disc', fontSize: 16, marginLeft: 17, lineHeight: 1.5 }}>
                    <li><Paragraph>{t('password_rules.rule_one')}</Paragraph></li>
                    <li><Paragraph>{t('password_rules.rule_two')}</Paragraph></li>
                    <li><Paragraph>{t('password_rules.rule_three')}</Paragraph></li>
                    <li><Paragraph>{t('password_rules.rule_four')}</Paragraph></li>
                </ul>
            </Grid>
            <Grid item sm={12}>
                <ChangePasswordForm onSubmit={handleChangePassword} />
            </Grid>
        </Grid>
  )
}
