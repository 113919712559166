import { AuthorizeSpecialInvestorForm } from './AuthorizeSpecialInvestorForm'
import { AuthorizeNaturalInvestorForm } from './AuthorizeNaturalInvestorForm'
import NotificationContext from '../../../../../contexts/NotificationContext'
import { InvestorsContext } from '../../../../../contexts/InvestorsContext'
import { MyTransition } from '../../../../../components/MyTransition'
import LoadingContext from '../../../../../contexts/LoadingContext'
import { MyTextAction } from '../../../../../components/MyTextLink'
import { IdiomContext } from '../../../../../contexts/IdiomContext'
import ClientContext from '../../../../../contexts/ClientContext'
import { patchInvestor } from '../../../../../core/apiInvestors'
import { useContext, useEffect, useState } from 'react'
import { MyTable } from '../../../../../components'
import { Dialog } from '@mui/material'
import { useResponsive } from '../../../../../hooks/useResponsive'
import { useTranslation } from 'react-i18next'

export const AuthorizeInvestorsTable = () => {
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
  const [typePersonDialog, setTypePersonDialog] = useState<any>(1)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [rowsData, setRowsData] = useState<any[]>([])
  const [investorData, setInvestorData] = useState<any>()
  const { showToast } = useContext(NotificationContext)
  const { actualTerm } = useContext(IdiomContext)
  const { isMobileDevice } = useResponsive()
  const { clientId } = useContext(ClientContext)
  const [t, i18n] = useTranslation()
  const {
    pendingInvestors,
    loadPendingInvestors,
    loadAcceptedInvestors
  } = useContext(InvestorsContext)

  const closeDialog = () => {
    setIsOpenDialog(false)
  }
  const openDialog = () => {
    setIsOpenDialog(true)
  }
  const defineInvestorModal = (value: any, investor: any) => {
    setInvestorData(investor)
    setTypePersonDialog(value)
    openDialog()
  }

  const changeInvestorState = async (values: any, type: 'ACCEPTED' | 'REJECTED') => {
    showLoading()
    const resp = await patchInvestor(values.id, clientId(), type)
    if (resp?.success) {
      await Promise.all([
        loadPendingInvestors(),
        loadAcceptedInvestors()
      ])
      showToast(
        'success',
        type === 'ACCEPTED' ? t('toasts.investor_approved') : t('toasts.investor_rejected')
      )
      closeDialog()
    } else {
      showToast('error', 'Error')
    }
    hideLoading()
  }

  useEffect(() => {
    setRowsData(Array.from(pendingInvestors, (investor: any, index) => {
      const aux = {
        name: `${investor?.first_names || ''} ${investor?.fathers_name || ''} ${investor?.mothers_name || ''}` || '',
        country: t(`countries.${investor?.additional_data?.country}`),
        phone: investor?.additional_data?.phone,
        desired_investment: investor?.additional_data?.desired_investment,
        total_previous_investment: investor?.additional_data?.total_previous_investment,
        investment_background: investor?.additional_data?.investment_background,
        min_investment: investor?.additional_data?.min_investment,
        personType: actualTerm[investor?.classificationType - 1]?.text,
        nameRepresentOne: investor?.legal_representative?.[0]?.first_names || 'N/A',
        lastFatherRepresentOne: investor?.legal_representative?.[0]?.fathers_name || 'N/A',
        lastMotherRepresentOne: investor?.legal_representative?.[0]?.mothers_name || 'N/A',
        nameRepresentTwo: investor?.legal_representative?.[1]?.first_names || 'N/A',
        lastFatherRepresentTwo: investor?.legal_representative?.[1]?.fathers_name || 'N/A',
        lastMotherRepresentTwo: investor?.legal_representative?.[1]?.mothers_name || 'N/A'
      }
      return {
        ...investor,
        ...aux,
        type: 1,
        index,
        options:
          <MyTextAction
              style={{ fontSize: 14, fontWeight: 600 }}
              color='#00ADEF'
              onClick={
                  () =>
                    defineInvestorModal(investor.classificationType, { ...investor, ...aux })
              }
          >
              {t('review')}
          </MyTextAction>
      }
    }))
    // eslint-disable-next-line
    }, [pendingInvestors, i18n.language])

  const headerData = [
    {
      header: t('name_title'),
      key: 'name'
    },
    {
      header: t('email'),
      key: 'email'
    },
    {
      header: t('country'),
      key: 'country'
    },
    {
      header: t('person_type'),
      key: 'personType'
    },
    {
      header: t('options'),
      key: 'options'
    }
  ]

  return (
        <>
            <MyTable rowData={rowsData} headerData={headerData} />
            <Dialog
                PaperProps={{ style: { padding: isMobileDevice ? '48px 16px' : '32px 32px', maxWidth: 800 } }}
                open={isOpenDialog}
                onClose={closeDialog}
                TransitionComponent={MyTransition}
            >
                {
                    typePersonDialog === 1
                      ? <AuthorizeNaturalInvestorForm
                            changeStatus={changeInvestorState}
                            investorData={investorData}
                            onClose={closeDialog}
                        />
                      : <AuthorizeSpecialInvestorForm
                            investorData={investorData}
                            changeStatus={changeInvestorState}
                            onClose={closeDialog}
                        />
                }
            </Dialog>
        </>
  )
}
