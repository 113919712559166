import { Grid } from '@mui/material'
import { useState } from 'react'
import { fullWidth } from '../../layout/LayoutConfig'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import styled from '@emotion/styled'
import { RequestExitModal } from './RequestExitModal'
import { useTranslation } from 'react-i18next'
import { Paragraph } from '../Paragraph'

interface Props {
  link: string
}

const Container = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: 2rem;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
`

export const RedirectVDR = ({ link }: Props) => {
  const [showRequestModal, setRequestModal] = useState(false)
  const { t } = useTranslation()

  const onOpen = () => {
    setRequestModal(true)
  }

  const onClose = () => {
    setRequestModal(false)
  }

  const onRedirect = () => {
    window.open(link)
    onClose()
  }

  return (
    <>
      <RequestExitModal isOpen={showRequestModal} onClose={onClose} handeSubmit={onRedirect} />
      <Grid container p={2}>
        <Grid item {...fullWidth}>
          <Paragraph bold>{t('external_doc')}</Paragraph>
        </Grid>
        <Grid item {...fullWidth} >
          <Container onClick={onOpen}>
            <Paragraph>{t('visit_doc', { SCOPE: t('issuers') })}</Paragraph>
            <ArrowForwardRoundedIcon />
          </Container>
        </Grid>
      </Grid>
    </>
  )
}
