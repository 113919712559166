import { Investor } from '../types/types'
import { UseFetch } from './UseFetch'

export const getInvestors = async (): Promise<any> => {
  return await UseFetch('get', 'endpoint', '/investors', {})
}

export const getInvestorsByFilter = async (
  status: string,
  clientId: any
): Promise<any> => {
  const init = {
    queryStringParameters: { status },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('get', 'endpoint', `/investors/${clientId}`, init)
}

export const updateInvestorState = async (
  email: any,
  status: any,
  clientId: any
): Promise<any> => {
  const emailEncoded = encodeURI(email)
  console.log(emailEncoded)
  const init = {
    queryStringParameters: { emailEncoded },
    body: { status },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('put', 'endpoint', `/investors/${clientId}`, init)
}

export const registerInvestor = async (
  email: any,
  clientId: any
): Promise<any> => {
  const investor = {
    clientId,
    email
  }

  const init = {
    body: {
      ...investor
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }

  return await UseFetch('post', 'endpoint', '/investors', init)
}

export const updateInvestor = async ({
  clientId,
  first_names,
  fathers_name,
  mothers_name,
  country,
  email,
  phone,
  id,
  nameRepresentOne,
  lastFatherRepresentOne,
  lastMotherRepresentOne,
  nameRepresentTwo,
  lastFatherRepresentTwo,
  lastMotherRepresentTwo,
  classificationType,
  total_investment,
  min_investment,
  investment_background,
  desired_investment,
  status
}: any): Promise<any> => {
  const legal_representative = []
  if (classificationType === 2) {
    legal_representative.push({
      first_names: nameRepresentOne,
      fathers_name: lastFatherRepresentOne,
      mothers_name: lastMotherRepresentOne
    })
  }

  if (classificationType === 2 && nameRepresentTwo !== '') {
    legal_representative.push({
      first_names: nameRepresentTwo,
      fathers_name: lastFatherRepresentTwo,
      mothers_name: lastMotherRepresentTwo
    })
  }

  const investor = {
    clientId,
    classificationType,
    first_names,
    fathers_name,
    mothers_name,
    additional_data: {
      country,
      phone,
      investment_background,
      desired_investment
    },
    email,
    legal_representative,
    total_investment,
    min_investment,
    status,
    id
  }

  const init = {
    body: {
      ...investor
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }

  return await UseFetch(
    'put',
    'endpoint',
    `/investors/${investor.clientId}`,
    init
  )
}

export const getInvestorsById = async (
  clientId: any,
  id: any
): Promise<any> => {
  return await UseFetch(
    'get',
    'endpoint',
    `/investors/${clientId}?id=${id}`,
    {}
  )
}

export const patchInvestor = async (
  id: any,
  clientId: any,
  status: any
): Promise<any> => {
  const init = {
    body: { status, id },
    headers: { 'Content-Type': 'text/plain' }
  }

  return await UseFetch('patch', 'endpoint', `/investors/${clientId}`, init)
}

export const registerInvestorByPreRegister = async (values: any): Promise<any> => {
  const investor = {
    ...values
  }

  const init = {
    body: {
      ...investor
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }

  return await UseFetch('post', 'endpoint', '/investors', init)
}

interface ResponsePutFormStep {
  message: string
  records: Investor
  success: boolean
}

interface BodyPutFormStep {
  id?: string
  additional_data?: AdditionalData
  classificationType?: number
  clientId?: string
  completedSteps?: boolean
  createdAt?: string
  email?: string
  fathers_name?: string
  first_names?: string
  isCompanyRepresentative?: boolean
  legal_representative?: any[]
  min_investment?: number
  mothers_name?: string
  role?: string
  status?: string
  step?: number
  wallets?: string[]
}

interface AdditionalData {
  country?: string
  desired_investment?: number
  investment_background?: string
  phone?: string
  total_previous_investment?: number
}

export const updateInvestorStep = async (body: BodyPutFormStep, clientId: string): Promise<ResponsePutFormStep> => {
  const init = {
    body,
    headers: { 'Content-Type': 'text/plain' }
  }

  return await UseFetch('put', 'endpoint', `/investors/${clientId}`, init)
}

export interface ResponseSetStepForm {
  message: string
  records: Investor[]
  success: boolean
}

export const getInvestorsByEmail = async (
  clientId: string,
  id: string
): Promise<ResponseSetStepForm> => {
  return await UseFetch('get', 'endpoint', `/investors/${clientId}?id=${id}`, {})
}
