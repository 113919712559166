import styled from '@emotion/styled'
import { ListItem } from '@mui/material'
import { useContext } from 'react'
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom'
import { Paragraph } from '../../components/Paragraph'
import { ColorContext } from '../../contexts/ColorContext'

export interface SidebarTabsProps {
  index: number
  navigateTo: string
  Icon: any
  title: string
  numberAlert?: number
  params?: any
  isDrawerOpen?: boolean
}

const MyStyledListItem = styled(ListItem)`
    color: white;
    width: 100%;
    
    &:hover{
        background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.08) 100%)
    }
`
const MyStyledNavLink = styled(NavLink)`
    color: white;
    width: 100%;
    transition: background .5s ease;
`
export const MyDiv = styled('div')`
    display: flex;
    justify-content: flex-start;
    padding: 0.75rem 1.5rem;
    align-items: center;
    gap: 1rem;

    @media (max-width: 480px) {
        padding: 10px 20px;
    }
`
const MyAlert = styled('div')`
    background-color: ${props => props.color};
    border-radius: 50%;
    font-size: 0.8rem;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const SidebarTabs = ({ index, navigateTo, Icon, title, numberAlert = 0, params, isDrawerOpen }: SidebarTabsProps) => {
  const resolved = useResolvedPath(navigateTo)
  const match = useMatch({ path: resolved.pathname, end: true })
  const { theme } = useContext(ColorContext)
  const { danger, secondary } = theme
  const isActiveProps = { backgroundColor: secondary }

  return (
    <MyStyledListItem key={index} disablePadding>
        <MyStyledNavLink to={navigateTo} state={params} style={(match != null) ? isActiveProps : undefined}>
            <MyDiv>
                {Icon}
                { isDrawerOpen &&
                <>
                    <Paragraph colorType='white' type='small'>
                        {title}
                    </Paragraph>
                    {numberAlert === 0 ? <></> : <MyAlert color={danger}>{numberAlert}</MyAlert>}
                </>}
            </MyDiv>
        </MyStyledNavLink>
    </MyStyledListItem>
  )
}
