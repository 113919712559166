import { useContext } from 'react'
import { GenericChildren } from '.'
import styled from '@emotion/styled'
import { ColorContext } from '../contexts/ColorContext'

const Text = styled.p<{ color: string }>`
  font-weight: 600;
  font-style: italic;
  font-size: 15px;
  line-height: 20px;
  color: ${props => props.color};
  padding: 1rem 0;
`

const DisclaimerText = ({ children }: GenericChildren): JSX.Element => {
  const { theme } = useContext(ColorContext)
  const { secondary } = theme
  return (
    <Text color={secondary}>{children}</Text>
  )
}

export default DisclaimerText
