import { TextField } from '@mui/material'
import { useField } from 'formik'
import { forwardRef, useContext, useEffect, useState, createContext } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat, { InputAttributes } from 'react-number-format'
import ClientContext from '../contexts/ClientContext'
import { CURRENCIES, decimalAndGroupSeparator } from '../core/utils'

const FormatContext = createContext<{ currency: string } >({ currency: CURRENCIES.CLP })

interface Props {
  label: string
  name: string
  type?: 'text' | 'email' | 'password' | 'number'
  placeholder?: string
  [propName: string]: any
}

interface CustomProps {
  onChange: (event: { target: { name: string, value: string } }) => void
  name: string
  currency: string
}

const NumberFormatCustom = forwardRef<
NumberFormat<InputAttributes>,
CustomProps
>(function NumberFormatCustom (props, ref) {
  const { onChange, ...other } = props
  const [currencyFormat, setCurrencyFormat] = useState({ thousandSeparator: '.', decimalSeparator: ',' })
  const { currency } = useContext(FormatContext)

  useEffect(() => {
    const {
      decimalSeparator,
      groupSeparator: thousandSeparator
    } = decimalAndGroupSeparator()
    setCurrencyFormat({ decimalSeparator, thousandSeparator })
  }, [currency])

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      isNumericString
      decimalScale={2}
      fixedDecimalScale={currency !== CURRENCIES.CLP}
      suffix={' ' + currency}
      prefix="$"
      {...currencyFormat}
    />
  )
})

const NumberFormatCustomPrefix = forwardRef<
NumberFormat<InputAttributes>,
CustomProps
>(function NumberFormatCustom (props, ref) {
  const { onChange, currency, ...other } = props
  const [currencyFormat, setCurrencyFormat] = useState({ thousandSeparator: '.', decimalSeparator: ',' })
  const { currencyOptions } = useContext(ClientContext)

  useEffect(() => {
    const {
      decimalSeparator,
      groupSeparator: thousandSeparator
    } = decimalAndGroupSeparator()
    setCurrencyFormat({ decimalSeparator, thousandSeparator })
  }, [currencyOptions])

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      isNumericString
      suffix='%'
      {...currencyFormat}
    />
  )
})

const NumberFormatCustomWithoutPrefix = forwardRef<
NumberFormat<InputAttributes>,
CustomProps
>(function NumberFormatCustom (props, ref) {
  const { onChange, ...other } = props
  const [currencyFormat, setCurrencyFormat] = useState({ thousandSeparator: '.', decimalSeparator: ',' })
  const { currency } = useContext(FormatContext)

  useEffect(() => {
    const {
      decimalSeparator,
      groupSeparator: thousandSeparator
    } = decimalAndGroupSeparator()
    setCurrencyFormat({ decimalSeparator, thousandSeparator })
  }, [currency])

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      isNumericString
      {...currencyFormat}
    />
  )
})

export const MyNumberFormat = ({ ...props }: Props) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props)
  const showError: boolean = !!meta.error && meta.touched

  if (meta?.error === 'Introduzca un valor valido,id price') {
    field.value = field.value.substring(0, 18)
  }

  return (
    <FormatContext.Provider value={{ currency: props?.currency || CURRENCIES.CLP }}>
      <TextField
        fullWidth
        id={props.name}
        error={showError}
        helperText={showError ? t('forms.' + meta?.error ?? 'required_field') : ''}
        {...field}
        {...props}
        InputProps={{
          inputComponent: NumberFormatCustom as any
        }}
        variant='outlined'
      />
    </FormatContext.Provider>
  )
}

export const MyNumberFormatSuffix = ({ ...props }: Props) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props)
  const showError: boolean = !!meta.error && meta.touched

  return (
    <TextField
      fullWidth
      id={props.name}
      error={showError}
      helperText={showError ? t('forms.' + meta?.error ?? 'required_field') : ''}
      {...field}
      {...props}
      InputProps={{
        inputComponent: NumberFormatCustomPrefix as any
      }}
      variant='outlined'
    />
  )
}

export const MyNumberInput = ({ ...props }: Props) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props)
  const showError: boolean = !!meta.error && meta.touched

  return (
    <FormatContext.Provider value={{ currency: props?.currency || CURRENCIES.CLP }}>
      <TextField
        fullWidth
        id={props.name}
        error={showError}
        helperText={showError ? t('forms.' + meta?.error ?? 'required_field') : ''}
        {...field}
        {...props}
        InputProps={{
          inputComponent: NumberFormatCustomWithoutPrefix as any
        }}
        variant='outlined'
      />
    </FormatContext.Provider>
  )
}
