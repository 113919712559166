// import { Button } from '@mui/material'
import { MyButton } from './MyButton'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'

const Icons = {
  backArrow: KeyboardDoubleArrowLeftIcon
}

interface Props {
  text: string
  icon: 'backArrow'
  type?: 'primary' | 'secondary'
  iconPosition?: 'start' | 'end'
  onClick: React.MouseEventHandler<HTMLButtonElement>
  [propName: string]: any
}

export const MyButtonIcon = ({ icon, text, onClick, iconPosition, type = 'primary', ...props }: Props) => {
  const Icon = Icons[icon]
  const position = {
    [iconPosition === 'end' ? 'endIcon' : 'startIcon']: <Icon />
  }
  return (
    <MyButton onClick={onClick} {...props} {...position} color={type}>
      { text }
    </MyButton>
  )
}
