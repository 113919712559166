import { useEffect, useState, memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MyTable } from '../../../../components'
import { FilterTableHOC } from '../../../../components/Tables/FilterTableHOC'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { useClientConfig } from '../../../../hooks/useClientConfig'

interface Props {
  transactions: any[]
}

export const ApprovedTransactionsTable = memo(({ transactions }: Props) => {
  const [rows, setRows] = useState<any[]>([])
  const { tokens_actions_price } = useContext(IdiomContext)
  const { assetTypeLabel } = useClientConfig()
  const { t } = useTranslation()

  const headers = [
    {
      header: assetTypeLabel('singular', 'capitalize'),
      key: 'name'
    },
    {
      header: t('issuers'),
      key: 'issuer'
    },
    {
      header: tokens_actions_price,
      key: 'token_price'
    },
    {
      header: t('purchase'),
      key: 'price_format'
    },
    {
      header: t('currency'),
      key: 'currency'
    },
    {
      header: t('approbation_date'),
      key: 'date'
    },
    {
      header: t('status'),
      key: 'status'
    }
  ]

  useEffect(() => {
    setRows(transactions)
  }, [transactions])

  return (
    <FilterTableHOC
      labelToFilter='date'
      labelToFilterType='date'
      rows={rows}
    >
      {({ dataFilter, key }) =>
        <MyTable key={key} headerData={headers} rowData={dataFilter} />
      }
    </FilterTableHOC>
  )
})
