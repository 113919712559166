import styled from '@emotion/styled'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MyTable } from '../../../../components'
import { MyFlexContainer } from '../../../../components/common/MyFlexContainer'
import { ColorContext } from '../../../../contexts/ColorContext'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { IconButton } from '@mui/material'
import { timezoneFormatWithHour } from '../../../../core/timezoneFormat'
import ClientContext from '../../../../contexts/ClientContext'
import { FilterTableHOC } from '../../../../components/Tables/FilterTableHOC'
import { Paragraph } from '../../../../components/Paragraph'
import { TextButtonLink } from '../../../../components/TextButtonLink'
import { useTranslation } from 'react-i18next'

interface Props {
  companies: any[]
}

const Container = styled.div`
  display: flex;
  flex-flow: wrap;
`

const CenterContainer = styled(MyFlexContainer)`
  align-items: center;
`

export const CompanyTable = ({ companies = [] }: Props) => {
  const [rows, setRows] = useState<any[]>([])
  const navigate = useNavigate()
  const { theme } = useContext(ColorContext)
  const { timezone } = useContext(ClientContext)
  const [t, i18n] = useTranslation()
  const { primary } = theme

  const headerData = [
    { header: t('company_name'), key: 'company_name' },
    { header: t('users'), key: 'users' },
    { header: t('onboarding_status'), key: 'status' },
    { header: t('authorization_date'), key: 'authorizationDate' },
    { header: t('documentation_status'), key: 'statusDoc' },
    { header: t('docs'), key: 'doc' }
  ]

  useEffect(() => {
    setRows(Array.from(companies, (el: any) => {
      const { id, users } = el
      const docReadyLabel = el?.docReady ? t('complete') : t('incomplete')
      const authorizationDate = el?.active ? timezoneFormatWithHour(timezone, el?.activationDate) : 'N/A'
      return {
        ...el,
        authorizationDate,
        users:
          <CenterContainer>
            <Paragraph type='small'>{ users.join(', ') }</Paragraph>
            <IconButton onClick={() => navigate('/Dashboard/clients-user-master', { state: { id } })}>
              <ManageAccountsIcon htmlColor={primary} />
            </IconButton>
          </CenterContainer>,
        status: el.step === 5 ? t('sended') : t('in_progress'),
        statusDoc: docReadyLabel,
        doc:
          el.step === 5
            ? <Container>
              <TextButtonLink to='/Dashboard/clients-vdr-review' state={el.id}>{t('review')}</TextButtonLink>
            </Container>
            : <Container>
              <Paragraph type='small'>
              {t('review')}
              </Paragraph>
            </Container>
      }
    }))

    return () => setRows([])
  }, [companies, navigate, timezone, i18n.language])

  return (
    <FilterTableHOC
      labelToFilterType='date'
      labelToFilter='authorizationDate'
      rows={rows}
    >
    { ({ dataFilter, key }) =>
      <MyTable rowData={dataFilter} headerData={headerData} key={key} />
    }
    </FilterTableHOC>
  )
}
