import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { MyButton } from '../../../../../components/MyButton'
import { MyTransition } from '../../../../../components/MyTransition'
import { Paragraph } from '../../../../../components/Paragraph'
import { useClientConfig } from '../../../../../hooks/useClientConfig'
import { StyledPaper } from '../../Styles/StylesComponents'

interface Props {
  open: boolean
  onClose: () => void
  onAccept: () => void
  assetName: any
}

export const RequestVDR = memo(({ open, onClose, assetName, onAccept }: Props) => {
  const { assetTypeLabel } = useClientConfig()
  const { t } = useTranslation()

  return (
        <StyledPaper
            open={open}
            TransitionComponent={MyTransition}
            onClose={onClose}
        >
            <DialogTitle style={{ textAlign: 'center' }}>
                <Paragraph>
                    <strong>{t('investor_user.access_complementary_doc.title', { name: assetName })}</strong>
                </Paragraph>
            </DialogTitle>
            <DialogContent style={{ padding: 20 }}>
                <Paragraph style={{ textAlign: 'justify' }}>{t('investor_user.access_complementary_doc.description', { token: assetTypeLabel('plural', 'lowercase') })}</Paragraph>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <MyButton
                            fullWidth
                            onMouseUp={onAccept}
                        >{t('confirm')}</MyButton>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <MyButton
                            fullWidth
                            color='secondary'
                            onMouseUp={onClose}
                        >{t('cancel')}</MyButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </StyledPaper >
  )
})
