import { Grid } from '@mui/material'
import { AssetPreview } from '../../../../../components/AssetDetails/AssetPreview'
import { MyButton } from '../../../../../components/MyButton'
import { Paragraph } from '../../../../../components/Paragraph'
import { MainVDR } from '../../../../../components/VDR/MainVDR'
import { colorPalette } from '../../../../../conf/ColorConfig'
import { getIssuerById } from '../../../../../core/apiIssuers'
import { useClientConfig } from '../../../../../hooks/useClientConfig'
import { LayoutForm } from '../../../../../layout/LayoutForm'
import { useState, useEffect, useCallback, useContext } from 'react'
import ClientContext from '../../../../../contexts/ClientContext'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
  data: any
  onSubmit: (asset: any, status: string) => Promise<any>
}

export const AuthorizeAssetDialog = ({ isOpen, onClose, data, onSubmit }: Props) => {
  const { assetTypeLabel } = useClientConfig()
  const { client_id } = useContext(ClientContext)
  const [issuer, setIssuer] = useState<any>({})
  const { t, i18n: { language } } = useTranslation()

  const loadIssuer = useCallback(async () => {
    const resp = await getIssuerById(data?.issuer_id, client_id)
    if (resp?.success) setIssuer(resp?.records?.[0])
  }, [data?.issuer_id])

  useEffect(() => {
    async function init () {
      if (isOpen) { await loadIssuer() }
    }
    void init()
  }, [loadIssuer, isOpen])

  const handleSubmit = async (type: string) => {
    await onSubmit(data, type)
    onClose()
  }

  const title = language === 'es' ? `Detalle de ${assetTypeLabel('singular', 'lowercase')}` : `${assetTypeLabel('singular', 'capitalize')} details`
  return (
    <LayoutForm open={isOpen} onClose={onClose}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Paragraph bold type='subheading'>{ title }</Paragraph>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paragraph>{t('asset_preview')}</Paragraph>
        </Grid>
        <Grid item >
          <MyButton color='info' onClick={onClose}>{t('review_later')}</MyButton>
        </Grid>
        <Grid item >
          <MyButton
            color='secondary'
            onClick={async () => await handleSubmit('REJECTED')}
          >{t('reject')}</MyButton>
        </Grid>
        <Grid item >
          <MyButton
            onClick={async () => await handleSubmit('ACCEPTED')}
          >{t('authorize')}</MyButton>
        </Grid>
        <Grid item xs={12} sm={12} mb={4}>
          <Paragraph bold >{t('preview')}</Paragraph>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          borderRadius={8}
          border={`1px solid ${colorPalette.basics.HEX_738099}`}
          p={2}
        >
          <AssetPreview
            assetData={{ ...data, issuer, contract: { tokensAvailable: data?.token_quantity } }}
            canViewConfidentialLabels
            VDRComponent={
              <MainVDR
                issuer_id={data?.issuer_id}
                vdr_id={`${data?.clientId}/${data?.issuer_id}/${data?.id}`}
                linkAsset={data?.linkVdr}
                type='Activo'
                canDelete={false}
                canEdit={false}
                canLoad={false}
                canAccess
                canSeeConfidential
              />
            }
          />
        </Grid>
      </Grid>
    </LayoutForm>

  )
}
