import { Skeleton } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { getIssuerById } from '../../core/apiIssuers'
import { LinkVDR } from './LinkVDR'
import { RedirectVDR } from './RedirectVDR'
import { UploadLinkAsset } from './UploadLinkAsset'
import { VDRDialog } from './TraditionalVDR/VDRDialog'
import { VdrOnlyView } from './VdrOnlyView'
import ClientContext from '../../contexts/ClientContext'

interface Props {
  issuer_id: string
  vdr_id: string
  type: 'Empresa' | 'Activo'
  canSeeConfidential?: boolean
  canDelete?: boolean
  canEdit?: boolean
  canLoad?: boolean
  requestAccess?: () => void
  canAccess?: boolean
  setLinkAsset?: (value: any) => void
  linkAsset?: string
  canShowRequest?: boolean
  showHeaders?: boolean
}

export const MainVDR = ({
  canSeeConfidential = true,
  issuer_id,
  vdr_id,
  canDelete = true,
  canEdit = true,
  canLoad = true,
  requestAccess,
  canAccess = true,
  type,
  setLinkAsset = (values: any) => { },
  linkAsset = '',
  canShowRequest = true,
  showHeaders = true
}: Props) => {
  const [issuer, setIssuer] = useState<any>({})
  const { client_id } = useContext(ClientContext)

  const loadIssuer = async () => {
    const resp = await getIssuerById(issuer_id, client_id)
    if (resp?.success) setIssuer(resp?.records?.[0])
  }

  useEffect(() => {
    async function init () {
      await loadIssuer()
    }
    void init()

    return () => setIssuer({})
    // eslint-disable-next-line
  }, [issuer_id])

  const getVDRComponent = () => {
    if (issuer?.typeVdr === 'tradicional') {
      return <VDRDialog
        canShowRequest={canShowRequest}
        reloadIssuer={loadIssuer}
        issuerData={issuer}
        id={vdr_id}
        type={type}
        canDelete={canDelete}
        canEdit={canEdit}
        canLoad={canLoad}
        requestAccess={requestAccess}
        canAccess={canAccess}
        showHeaders={showHeaders}
        canSeeConfidential={canSeeConfidential}
      />
    }
    if (issuer?.typeVdr === 'externo' && type === 'Activo' && !canLoad) {
      return <VdrOnlyView
        linkIssuer={issuer?.linkVdr}
        linkAsset={linkAsset}
        canAccess={canAccess}
        canShowRequest={canShowRequest}
        requestAccess={requestAccess}
      />
    }
    if (issuer?.typeVdr === 'externo' && !canLoad) {
      return <RedirectVDR link={issuer?.linkVdr} />
    }
    if (issuer?.typeVdr === 'externo' && type === 'Empresa') {
      return <LinkVDR
        disabled={issuer?.docReady}
        linkVdr={issuer?.linkVdr}
        issuer_id={issuer_id}
        reloadLink={loadIssuer}
      />
    }
    if (issuer?.typeVdr === 'externo' && type === 'Activo') {
      return <UploadLinkAsset
        linkIssuer={issuer?.linkVdr}
        setLinkAsset={setLinkAsset}
        linkAsset={linkAsset}
      />
    }
    return <Skeleton variant="rectangular" height='15rem' animation="wave" />
  }

  return getVDRComponent()
}
