import { MyTransition } from '../../../../../components/MyTransition'
import { Dialog, DialogTitle, DialogActions } from '@mui/material'
import { MyButton } from '../../../../../components/MyButton'
import { Investor } from '../../../../../types/types'

interface Props {
  open: boolean
  onClose: () => void
  data: Investor | null
}

export const DeleteInvestorModal = ({ open, onClose, data }: Props) => {
  return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={MyTransition}
        PaperProps={{ style: { maxWidth: 400, padding: '10px 0', borderRadius: 20 } }}
      >
        <DialogTitle style={{ textAlign: 'center', fontSize: 14 }}>
          ¿Estás seguro de que quieres eliminar este usuario?
        </DialogTitle>
        <DialogActions style={{ justifyContent: 'center' }}>
          <MyButton onClick={onClose} color='secondary'>
            Cancelar
          </MyButton>
          <MyButton onClick={onClose}>
            Confirmar
          </MyButton>
        </DialogActions>
      </Dialog>
  )
}
