import styled from '@emotion/styled'
import maintenance from '../../assets/maintenance.png'
import { H1 } from '../../components/MyHeaders'

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`
const Caption = styled.figcaption`
  margin: 1.4rem;
  text-align: center;
  font-size: 1.4rem;
  width: inherit;
`
const Title = styled(H1)`
  font-size: 2rem;
  text-align: center;
`

export const MaintenanceMain = () => {
  return (
    <Container>
      <figure>
        <img src={maintenance} alt='Mantenimiento' style={{ maxHeight: 400 }} />
        <Title>
          ¡La plataforma está en mantenimiento!
        </Title>
        <Caption>
          Volveremos prontamente
        </Caption>
      </figure>
    </Container>
  )
}
