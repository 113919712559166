import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

type Status = 'pending' | 'approved' | 'rejected'

interface Props {
  pay_confirmed: Status
  authorized: Status
}

export enum PurchaseStatusTranslated {
  executed = 'executed',
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected'
}

export const parseStatus = (
  pay_confirmed: Status,
  authorized: Status
): keyof (typeof PurchaseStatusTranslated) => {
  if (authorized === 'pending') return 'pending'
  if (pay_confirmed === 'approved') return 'executed'
  if (authorized === 'approved') return 'approved'
  if (pay_confirmed === 'rejected' || authorized === 'rejected') return 'rejected'
  return 'rejected'
}

const MyP = styled.p`
  font-size: 14px;
  font-weight: 500;
`

export const CodingPurchaseState = ({ ...props }: Props) => {
  const { authorized, pay_confirmed } = props
  const { t } = useTranslation()
  const label = parseStatus(pay_confirmed, authorized)
  return (
    <MyP>{ t(`investor_user.${label}`) }</MyP>
  )
}
