import styled from '@emotion/styled'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { MyButton } from '../MyButton'
import { Paragraph } from '../Paragraph'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
  handeSubmit: () => void
}
const MyContent = styled(DialogContentText)`
  font-size: 0.9rem;
  font-weight: 400;
`

export const RequestExitModal = ({ isOpen, onClose, handeSubmit }: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        <Paragraph bold>{t('exit_platform_advice.title')}</Paragraph>
      </DialogTitle>
      <DialogContent>
        <MyContent>{t('exit_platform_advice.content')}</MyContent>
      </DialogContent>
      <DialogActions>
        <MyButton color='secondary' onMouseUp={onClose}>{t('exit_platform_advice.cancel')}</MyButton>
        <MyButton onMouseUp={handeSubmit}>{t('exit_platform_advice.accept')}</MyButton>
      </DialogActions>
    </Dialog>
  )
}
