import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AssetsContext } from '../contexts/AssetsContext'
import ClientContext from '../contexts/ClientContext'
import { ColorContext } from '../contexts/ColorContext'
import { IdiomContext } from '../contexts/IdiomContext'
import NotificationContext from '../contexts/NotificationContext'
import UserContext from '../contexts/UserContext'
import { signIn } from '../core/apiAuth'
import { getClientById } from '../core/apiClients'
import { getIssuerById } from '../core/apiIssuers'
import { getInvestorsById } from '../core/apiInvestors'
import { colorParse } from '../core/utils'
import { useTranslation } from 'react-i18next'

export const useLogin = () => {
  const { changeTheme } = useContext(ColorContext)
  const { handleLogInUser } = useContext(UserContext)
  const { showToast } = useContext(NotificationContext)
  const { handleSetClient, clientId } = useContext(ClientContext)
  const { setTerminology } = useContext(IdiomContext)
  const { clearAssetContext } = useContext(AssetsContext)
  const [t] = useTranslation()

  const navigate = useNavigate()

  const setUpClient = (client: any) => {
    handleSetClient(client)
    changeTheme(colorParse(client.configuration.setupColors))
    setTerminology(client.configuration.personTypeTerminology)
  }

  const refreshAllClient = async () => {
    const client_id = clientId()
    if (!client_id) return
    const resp = await getClientById(client_id)
    if (resp?.success) setUpClient(resp?.records?.[0])
  }

  const goDashboard = (rol: 'admins' | 'clients' | 'issuers' | 'investors' | 'investors-purchases') => {
    navigate(`/Dashboard/${rol}`)
  }

  const logIn = async (username: string, password: string) => {
    localStorage.removeItem('user')
    const resp = await signIn({ username, password })
    if (!resp?.success) {
      showToast('error', t(`toasts.${resp?.message}`))
      return
    }
    const { auth, user } = resp?.records?.[0]
    localStorage.setItem('user', JSON.stringify({ user, auth }))
    if (resp?.code) {
      showToast('error', t(`toasts.${resp.code}`))
    } else if (auth?.ChallengeName) {
      // Si el usuario es nuevo y requiere un cambio de clave
      showToast('info', t('toasts.enter_new_password'))
      navigate('/SessionChangePassword', { state: { session: auth.Session, email: user.email } })
    } else if (user?.clientId !== clientId() && user?.group !== 'admins') {
      // Usuario intenta ingresar en un cliente que no es
      showToast('error', t('toasts.different_client'))
    } else if (auth?.AuthenticationResult && user?.group === 'admins') {
      // Si el usuario es un administrador de QX
      handleLogInUser({ user, auth })
      await refreshAllClient()
      goDashboard('admins')
    } else if (auth?.AuthenticationResult && user?.group === 'clients') {
      // Si el usuario es de un cliente
      handleLogInUser({ user, auth })
      await refreshAllClient()
      goDashboard('clients')
    } else if (auth?.AuthenticationResult && user?.group === 'issuers') {
      // Si el usuario es un emisor
      const client_id = clientId()
      const issuer = await getIssuerById(user.issuerId, client_id)
      handleLogInUser({ user, auth, issuer: issuer.records?.[0] })
      clearAssetContext()
      await refreshAllClient()
      goDashboard('issuers')
    } else if (auth?.AuthenticationResult && user?.group === 'investors') {
      // Si el usuario es de un inversionista
      const investor = await getInvestorsById(user.clientId, user.investorId)
      if (investor?.success) {
        handleLogInUser({ user: { ...user, ...investor.records?.[0] }, auth })
        await refreshAllClient()

        if (investor.records?.[0]?.status !== 'ACCEPTED') goDashboard('investors-purchases')
        else goDashboard('investors')
      } else {
        showToast('error', t('toasts.cant_access'))
      }
    } else {
      showToast('error', t('toasts.email_or_password_incorrect'))
    }
  }

  return { logIn }
}
