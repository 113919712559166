import { Dialog } from '@mui/material'
import { MyTransition } from '../../../../components/MyTransition'
import { useEffect, useContext, useState, memo } from 'react'
import { getInvestorsById } from '../../../../core/apiInvestors'
import ClientContext from '../../../../contexts/ClientContext'
import LoadingContext from '../../../../contexts/LoadingContext'
import { Investor } from '../../../../types/types'
import { useResponsive } from '../../../../hooks/useResponsive'
import { IdiomContext } from '../../../../contexts/IdiomContext'
import { NaturalInvestorVDRForm } from './NaturalInvestorVDRForm'
import { SpecialInvestorVDRForm } from './SpecialInvestorVDRForm'
import { useTranslation } from 'react-i18next'

interface Props {
  data: { investor_id: string, issuer_id: string, email: string }
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: any, status_to_change: 'ACCEPTED' | 'REJECTED') => Promise<void>
}

export const ReviewInvestor = memo(({ isOpen, data, onClose, onSubmit }: Props) => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [investor, setInvestor] = useState<Investor>()
  const { isMobileDevice } = useResponsive()
  const { client_id } = useContext(ClientContext)
  const { actualTerm } = useContext(IdiomContext)
  const { t } = useTranslation()

  const loadInvestorData = async () => {
    showLoading()
    const resp = await getInvestorsById(client_id, data.investor_id)
    if (resp?.success) setInvestor(resp.records?.[0])
    else onClose()
    hideLoading()
  }

  const getVrInvestorForm = () => {
    if (investor?.classificationType === 1) {
      return <NaturalInvestorVDRForm
        changeStatus={onSubmit}
        investorData={{
          ...investor,
          ...investor.additional_data,
          country: t(`countries.${investor?.additional_data?.country}`),
          personType: actualTerm[investor?.classificationType - 1]?.text
        }}
        onClose={onClose}
      />
    }
    if (investor?.classificationType === 2) {
      return <SpecialInvestorVDRForm
        investorData={{
          ...investor,
          ...investor.additional_data,
          country: t(`countries.${investor?.additional_data?.country}`),
          personType: actualTerm[investor?.classificationType - 1]?.text
        }}
        changeStatus={onSubmit}
        onClose={onClose}
      />
    }
    return <></>
  }

  useEffect(() => {
    async function init () {
      if (data?.investor_id) await loadInvestorData()
      else setInvestor(undefined)
    }
    void init()
  // eslint-disable-next-line
  }, [data?.investor_id])

  return (
    <Dialog
      PaperProps={{ style: { padding: isMobileDevice ? '14px 16px' : '32x 48px', maxWidth: 650 } }}
      open={isOpen}
      onClose={onClose}
      TransitionComponent={MyTransition}
    >
    {getVrInvestorForm()}
    </Dialog>
  )
})
