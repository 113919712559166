import { useState, useEffect, useContext, useCallback } from 'react'
import { ColorContext } from '../contexts/ColorContext'

export const useStatus = (status: string) => {
  const [statusInfo, setStatusInfo] = useState<any>(status || '')
  const { theme } = useContext(ColorContext)
  const { warning, danger, success } = theme

  const getTextAndColor = useCallback(
    (status: string) => {
      if (status === 'PENDING' || status === 'ACCEPTED') {
        setStatusInfo({
          statusColor: warning,
          statusText: 'Próximamente'
        })
      } else if (status === 'PUBLISHED') {
        setStatusInfo({
          statusColor: success,
          statusText: 'Disponible'
        })
      } else {
        setStatusInfo({
          statusColor: danger,
          statusText: 'Agotado'
        })
      }
    },
    [danger, success, warning]
  )

  useEffect(() => {
    getTextAndColor(status)
  }, [status, getTextAndColor])

  return statusInfo
}
