import { FormikValues } from 'formik'
import { UseFetch } from './UseFetch'

export const signIn = async ({
  username,
  password
}: FormikValues): Promise<any> => {
  const init = {
    body: {
      password
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch(
    'post',
    'endpoint',
    `/users/${username}/authenticate`,
    init,
    false
  )
}

export const forgotPassword = async (username: string): Promise<any> => {
  return await UseFetch('get', 'endpoint', `/users/${username}/forgot-password`, {}, false)
}

export const refreshSession = async (): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '')
  const init = {
    body: {
      refreshToken: user.auth.AuthenticationResult.RefreshToken
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('post', 'endpoint', '/users/refresh-token', init)
}

export const recoverPassword = async ({
  password,
  username,
  confirmationCode
}: any): Promise<any> => {
  const init = {
    body: {
      password,
      confirmationCode
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch(
    'post',
    'endpoint',
    `/users/${username}/forgot-password`,
    init
  )
}

export const changePassword = async ({ oldPassword, newPassword, clientId }: any): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '')
  const init = {
    body: {
      oldPassword,
      newPassword,
      accessToken: user.auth.AuthenticationResult.AccessToken,
      email: user.user.email,
      clientId
    },
    headers: {
      'Content-Type': 'text/plain'
    }
  }
  return await UseFetch('post', 'endpoint', '/users/password', init)
}

export const createPassword = async (params: any, password: any, clientId: any): Promise<any> => {
  const { email, session } = params
  const init = {
    body: { session, password, clientId },
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('post', 'endpoint', `/users/${email}/auth-challenge`, init, false)
}
