import { Divider, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MyButtonIcon, MyCheckboxInput } from '../../../../components'
import { DDMultiFiles } from '../../../../components/DragAndDropUploadFiles/DDMultiFiles'
import { MyButton } from '../../../../components/MyButton'
import { Paragraph } from '../../../../components/Paragraph'
import LoadingContext from '../../../../contexts/LoadingContext'
import NotificationContext from '../../../../contexts/NotificationContext'
import UserContext from '../../../../contexts/UserContext'
import { useMultimedia } from '../../../../hooks/useMultimedia'
import { AssetFormContext } from './DialogAssetForm'

const MAX_IMAGES = 10
const MAX_VIDEO = 3

interface Props {
  onNextStep: (values: any) => Promise<void>
}

export const MultimediaStep = ({ onNextStep }: Props) => {
  const [imageFiles, setImageFiles] = useState<Array<File | string>>([])
  const [videoFiles, setVideoFiles] = useState<Array<File | string>>([])
  const { previousStep, assetData, nextStep, onClose } = useContext(AssetFormContext)
  const { showToast } = useContext(NotificationContext)
  const { getIssuerId } = useContext(UserContext)
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { t } = useTranslation()
  const {
    getMultimediaByFolder,
    postAllMultimediaFilesByFolder,
    deleteMultimediaFiles,
    updatedPosition,
    deleteAllMultimediaFiles
  } = useMultimedia()

  useEffect(() => {
    async function init () {
      showLoading()
      const issuer_id = getIssuerId()
      const images = await getMultimediaByFolder(issuer_id, assetData?.id, 'image')
      const videos = await getMultimediaByFolder(issuer_id, assetData?.id, 'video')
      setImageFiles(images)
      setVideoFiles(videos)
      hideLoading()
    }
    void init()
  }, [])

  const onSubmit = async ({ usingLogo }: any) => {
    if (!usingLogo) {
      if (imageFiles.length !== 0) {
        showLoading()
        const issuer_id = getIssuerId()
        const urlsImages = await postAllMultimediaFilesByFolder(issuer_id, assetData?.id, 'image', imageFiles)
        const urlsVideos = await postAllMultimediaFilesByFolder(issuer_id, assetData?.id, 'video', videoFiles)
        await deleteMultimediaFiles([...urlsImages, ...urlsVideos])
        await updatedPosition([...urlsImages, ...urlsVideos])
        hideLoading()
      } else {
        showToast('error', t('toasts.min_one_img'))
        return
      }
    }
    if (usingLogo) {
      showLoading()
      await deleteAllMultimediaFiles()
      hideLoading()
    }
    if (assetData?.usingLogo !== usingLogo) await onNextStep(usingLogo)
    nextStep()
  }

  return (
    <Formik initialValues={assetData} onSubmit={async (values) => await onSubmit(values)}>
      {({ values }) =>
        <Form>
          <Grid container spacing={2}>
            <Grid item sm={12} mb={2}>
                <MyButtonIcon
                    size='small'
                    icon='backArrow'
                    onClick={previousStep}
                    text='Anterior'
                    type='secondary'
                />
            </Grid>
            <Grid item sm={12}>
              <Paragraph bold type='subheading'>{t('company_user.create_asset.title_five')}</Paragraph>
            </Grid>
            { !values.usingLogo
              ? <>
              <Grid item sm={12}>
                <Paragraph>{t('company_user.create_asset.subtitle_five')}</Paragraph>
              </Grid>
              <Grid item sm={12}>
                <Paragraph bold>{t('company_user.create_asset.photo', { MAX_IMAGES })}</Paragraph>
              </Grid>
              <Grid item sm={12}>
                <DDMultiFiles
                  maxFiles={MAX_IMAGES}
                  files={imageFiles}
                  title={t('company_user.create_asset.add_file', { file: t('photo') })}
                  subtitle={t('company_user.create_asset.accepted_files', { formats: 'PNG, JPEG, JPG' })}
                  id='upload-images'
                  setFiles={setImageFiles}
                  acceptedTypes='image/png, image/jpeg, image/jpg'
                />
              </Grid>
              <Grid item sm={12} my={2}>
                <Divider />
              </Grid>
              <Grid item sm={12} >
                <Paragraph bold>{t('company_user.create_asset.video', { MAX_VIDEO })}</Paragraph>
              </Grid>
              <Grid item sm={12}>
                <DDMultiFiles
                  maxFiles={MAX_VIDEO}
                  files={videoFiles}
                  title={t('company_user.create_asset.add_file', { file: t('video') })}
                  subtitle={t('company_user.create_asset.accepted_files', { formats: 'MP4' })}
                  id='upload-videos'
                  setFiles={setVideoFiles}
                  acceptedTypes='video/*'
                />
              </Grid>
              <Grid item sm={12} my={2}>
                <Divider />
              </Grid>
            </>
              : null
            }
            <Grid item sm={12} >
              <MyCheckboxInput label={t('company_user.create_asset.use_company_logo')} name='usingLogo'/>
            </Grid>
            <Grid item >
              <MyButton onClick={onClose} color='secondary'>{t('cancel')}</MyButton>
            </Grid>
            <Grid item >
              <MyButton
                type='submit'
                disabled={!values.usingLogo && !(imageFiles.length || videoFiles.length)}
              >{t('next')}</MyButton>
            </Grid>
          </Grid>
        </Form>
      }
    </Formik>
  )
}
