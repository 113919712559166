import { UseFetch } from './UseFetch'
import { Maintenance } from '../types/types'

export interface GetMaintenanceResponse {
  message: string
  records: Maintenance[]
  success: boolean
}

export const getMaintenance = async (): Promise<GetMaintenanceResponse> => {
  const init = {
    headers: { 'Content-Type': 'text/plain' }
  }
  return await UseFetch('get', 'endpoint', '/maintenances/maintenance', init, false)
}
