import { MaintenanceMain } from '../pages/Maintenance/MaintenanceMain'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { IS_PROD } from '../core/utils'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'

export const RoutesMaintenance = () => {
  const location = useLocation()

  useEffect(() => {
    if (IS_PROD) { ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search }) }
  }, [location])

  return (
    <Routes>
      <Route path='/Maintenance' element={<MaintenanceMain />} />
      <Route path='*' element={<Navigate to='/Maintenance' replace />} />
    </Routes>
  )
}
