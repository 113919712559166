import ReactGA from 'react-ga4'
import { IS_PROD } from '../utils'
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from '../../constants'

export const startGoogleAnalytics = () => {
  GoogleAnalyticsForProd()
}

const GoogleAnalyticsForProd = () => {
  if (IS_PROD) { GoogleAnalyticsCore() }
}

const GoogleAnalyticsCore = () => {
  window.dataLayer = window.dataLayer || []
  function gtag () {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', GOOGLE_ANALYTICS_MEASUREMENT_ID)
}

export const sendEvent = ({ action = '', label = '' }) => {
  if (IS_PROD) ReactGA.event({ category: action, action, label })
}
