import { useTranslation } from 'react-i18next'
import { MyButton } from '../../../../components/MyButton'
import { transUrlEnv } from '../../../../core/utils'

interface Props {
  idBlockchain: string
}

export const TransactionButton = ({ idBlockchain = '' }: Props) => {
  const { t } = useTranslation()
  const onClick = () => {
    if (idBlockchain !== '') { window.open(`${transUrlEnv}${idBlockchain}`) }
  }

  return (
    <MyButton
      disabled={idBlockchain === ''}
      color='info'
      onClick={onClick}
    >
      { idBlockchain === '' ? 'N/A' : t('see_in_blockchain')}
    </MyButton>
  )
}
