import { InvestorsContext } from '../../../../../contexts/InvestorsContext'
import { useContext, useEffect, useState } from 'react'
import { IdiomContext } from '../../../../../contexts/IdiomContext'
import { MyTable } from '../../../../../components'
import { DeleteInvestorModal } from './DeleteInvestorModal'
import { Investor } from '../../../../../types/types'
import { useDialog } from '../../../../../hooks/useDialog'
import { timezoneFormatWithHour } from '../../../../../core/timezoneFormat'
import ClientContext from '../../../../../contexts/ClientContext'
import { FilterTableHOC } from '../../../../../components/Tables/FilterTableHOC'
import { MyTextAction } from '../../../../../components/MyTextLink'
import { useTranslation } from 'react-i18next'

export const InvestorsTable = () => {
  const { acceptedInvestors } = useContext(InvestorsContext)
  const [rows, setRows] = useState<any[]>([])
  const { actualTerm } = useContext(IdiomContext)
  const { timezone } = useContext(ClientContext)
  const { closeDialog, openDialog, isOpen, data } = useDialog()
  const { t, i18n: { language } } = useTranslation()

  useEffect(() => {
    setRows(Array.from(acceptedInvestors, (investor: Investor) => {
      return {
        name: `${investor?.first_names || ''} ${investor?.fathers_name || ''} ${investor?.mothers_name || ''}` || '',
        country: t(`countries.${investor?.additional_data?.country}`),
        phone: investor?.additional_data?.phone,
        email: investor?.email,
        personType: actualTerm[investor.classificationType - 1]?.text,
        acceptedAt: timezoneFormatWithHour(timezone, investor?.acceptedAt),
        actions:
        <MyTextAction
          style={{ fontSize: 14, fontWeight: 600 }}
          color='#00ADEF'
          onClick={() => openDialog(investor)}
        >
          Desactivar
        </MyTextAction>
      }
    }))
  }, [acceptedInvestors, actualTerm, language])

  const headers = [
    {
      header: t('name_title'),
      key: 'name'
    },
    {
      header: t('email'),
      key: 'email'
    },
    {
      header: t('country'),
      key: 'country'
    },
    {
      header: t('person_type'),
      key: 'personType'
    },
    {
      header: t('authorization_date'),
      key: 'acceptedAt'
    }
  ]

  return (
    <>
    <DeleteInvestorModal
        open={isOpen}
        data={data}
        onClose={closeDialog}
    />
    <FilterTableHOC
      rows={rows}
      labelToFilter='acceptedAt'
      labelToFilterType='date'
    >
      {({ dataFilter, key }) =>
        <MyTable key={key} title='' rowData={dataFilter} headerData={headers}/>
      }
    </FilterTableHOC>
    </>
  )
}
