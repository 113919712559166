import { Grid, TextField } from '@mui/material'
import React, { Fragment, useContext, useEffect } from 'react'
import { MyLabel } from '../../../../components/MyCheckboxInput'
import { Paragraph } from '../../../../components/Paragraph'
import { AssetFormContext } from './DialogAssetForm'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import es from 'dayjs/locale/es'
import en from 'dayjs/locale/en-au'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '../../../../components/DatePicker'
import { useCheckboxes } from '../../../../hooks/useCheckboxes'
import { useTranslation } from 'react-i18next'

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const ValuationCheckboxes = ({ setFieldValue }: Props) => {
  const {
    INDEPENDENT_VALUATOR,
    INDEPENDENT_VALUATOR_METHOD,
    LATEST_INVESTMENT_ROUND,
    VALUATION_SOURCE
  } = useCheckboxes()
  const { t, i18n: { language } } = useTranslation()

  const {
    valuationSource,
    setValuationSource,
    independentValuatorMethod,
    setIndependentValuatorMethod,
    latestInvestmentRound,
    setLatestInvestmentRound,
    independentValuator,
    setIndependentValuator
  } = useContext(AssetFormContext)

  useEffect(() => {
    if (!valuationSource[0].length) {
      setIndependentValuatorMethod(Array(INDEPENDENT_VALUATOR_METHOD.length).fill('', 0))
      setIndependentValuator(Array(INDEPENDENT_VALUATOR.length).fill('', 0))
    }
    if (!valuationSource[1].length) setLatestInvestmentRound(Array(LATEST_INVESTMENT_ROUND.length).fill('', 0))
    if (!valuationSource[0].length && !valuationSource[1].length) setFieldValue('valuation_date', null)
  }, [valuationSource])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language === 'es' ? es : en}>
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12}>
          <Paragraph bold>{ t('valuation_source') }</Paragraph>
        </Grid>
        { VALUATION_SOURCE.map((label, index) =>
          <Grid item xs={12} sm={12} md={6} lg={6} key={label}>
            <MyLabel>
              <input
                type="checkbox"
                checked={!!valuationSource[index]?.length}
                onChange={() => setValuationSource(prev =>
                  prev.map((el, i) => i === index ? el.length ? '' : label : el)
                )}
              />
              { label }
            </MyLabel>
          </Grid>
        )}
        <Grid item sm={8} xs={12} mt={2}>
          <Paragraph bold>{t('by_independent_valuator')}</Paragraph>
        </Grid>
        <Checkboxes
          values={independentValuatorMethod}
          setValues={setIndependentValuatorMethod}
          array={INDEPENDENT_VALUATOR_METHOD}
          disabled={!valuationSource[0].length}
        />
        <Grid item sm={8} xs={12} mt={2}>
          <Paragraph bold>{t('by_latest_investment_round')}</Paragraph>
        </Grid>
        <Checkboxes
          values={latestInvestmentRound}
          setValues={setLatestInvestmentRound}
          array={LATEST_INVESTMENT_ROUND}
          disabled={!valuationSource[1].length}
        />
        <Grid item sm={8} xs={12} mt={2}>
          <Paragraph bold>{t('independent_valuator')}</Paragraph>
        </Grid>
        <Checkboxes
          values={independentValuator}
          setValues={setIndependentValuator}
          array={INDEPENDENT_VALUATOR}
          disabled={!valuationSource[0].length}
        />
        <Grid item xs={12} sm={6} md={6} lg={6} mt={4}>
          <DatePicker
            name='valuation_date'
            label={t('valuation_date')}
            disabled={!valuationSource[0].length && !valuationSource[1].length}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

interface ICheckboxes {
  values: string[]
  setValues: React.Dispatch<React.SetStateAction<string[]>>
  array: string[]
  disabled?: boolean
}

const Checkboxes = ({ values, setValues, array, disabled }: ICheckboxes) => {
  return (
    <>
    {array.map((label, index) => (
        <Fragment key={label}>
          <Grid item sm={6} xs={12} key={label} ml={1}>
            <MyLabel>
              <input
                type="checkbox"
                disabled={disabled}
                checked={!!values[index]?.length}
                onChange={() => setValues(prev =>
                  prev.map((el, i) => i === index ? el.length ? '' : index === array.length - 1 ? ' ' : label : el)
                )}
              />
              { label }
            </MyLabel>
          </Grid>
          { index === array.length - 1 &&
            values[index]?.length !== 0 &&
            (
            <Grid item sm={7} xs={12} ml={1}>
              <TextField
                value={values[index].trim().length === 0 ? values[index].trim() : values[index]}
                required={values[index]?.length > 0}
                disabled={values[index]?.length === 0}
                onChange={({ target }) => setValues(prev =>
                  prev.map((el, i) => i === index ? target.value : el)
                )}
              />
            </Grid>)
            }
          </Fragment>
    ))}
    </>
  )
}
