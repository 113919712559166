import { LayoutForm } from '../../../../layout/LayoutForm'
import { useContext, useEffect, useState } from 'react'
import UserContext from '../../../../contexts/UserContext'
import { ExtendedOnboardingTabs } from './ExtendedOnboardingTabs'
import { useNavigate } from 'react-router-dom'
import LoadingContext from '../../../../contexts/LoadingContext'
import { getInvestorsByEmail, updateInvestorStep } from '../../../../core/apiInvestors'
import ClientContext from '../../../../contexts/ClientContext'
import { Investor } from '../../../../types/types'
import { OnboardingExpressMain } from './Express/OnboardingExpressMain'

const basicDataInitialValues = {
  first_names: '',
  fathers_name: '',
  mothers_name: '',
  country: '',
  email: '',
  phone: '',
  nameRepresentOne: '',
  lastFatherRepresentOne: '',
  lastMotherRepresentOne: '',
  nameRepresentTwo: '',
  lastFatherRepresentTwo: '',
  lastMotherRepresentTwo: '',
  investment_background: '',
  min_investment: 0
}

const transformLegal = (values: any) => {
  return {
    nameRepresentOne: values?.legal_representative?.[0]?.first_names || '',
    lastFatherRepresentOne: values?.legal_representative?.[0]?.fathers_name || '',
    lastMotherRepresentOne: values?.legal_representative?.[0]?.mothers_name || '',

    nameRepresentTwo: values?.legal_representative?.[1]?.first_names || '',
    lastFatherRepresentTwo: values?.legal_representative?.[1]?.fathers_name || '',
    lastMotherRepresentTwo: values?.legal_representative?.[1]?.mothers_name || ''
  }
}

export const OnboardingInvestorMain = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const [investorStep, setInvestorStep] = useState<Investor>()
  const [indexTab, setIndexTab] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { clientId, onbHasAdditionalFields } = useContext(ClientContext)
  const { myUser } = useContext(UserContext)
  const navigate = useNavigate()

  const onClose = () => {
    const path = myUser?.status === 'ACCEPTED' && myUser?.step === 7 ? '/Dashboard/investors' : '/Dashboard/investors-purchases'
    navigate(path)
  }

  const loadInvestorStep = async () => {
    setIsLoading(true)
    showLoading()
    const resp = await getInvestorsByEmail(clientId(), myUser?.id)
    if (resp.success) {
      setIndexTab(resp.records?.[0].step)
      setInvestorStep(resp.records?.[0])
    }
    hideLoading()
    setIsLoading(false)
  }

  const previousStep = () => { setIndexTab(v => v - 1) }

  const handleChangeStep = async (values?: any) => {
    showLoading()
    await updateInvestorStep({ ...values, step: indexTab + 1, email: investorStep?.email, id: investorStep?.id }, clientId())
    await loadInvestorStep()
    hideLoading()
  }

  const handleDefineStep = async (step: number) => {
    showLoading()
    await updateInvestorStep({ step, email: investorStep?.email, id: investorStep?.id }, clientId())
    await loadInvestorStep()
    hideLoading()
  }

  useEffect(() => {
    async function init () {
      await loadInvestorStep()
    }
    void init()
    // eslint-disable-next-line
    }, [])

  return (
        <LayoutForm open={true} onClose={onClose}>
            {onbHasAdditionalFields
              ? <ExtendedOnboardingTabs
                    isLoading={isLoading}
                    indexTab={indexTab}
                    previousStep={previousStep}
                    continueLater={onClose}
                    handleChangeStep={handleChangeStep}
                    crudeDate={investorStep}
                    initialData={{
                      ...basicDataInitialValues,
                      ...investorStep,
                      ...investorStep?.additional_data,
                      ...transformLegal(investorStep)
                    }}
                />
              : <OnboardingExpressMain
                    isLoading={isLoading}
                    indexTab={indexTab}
                    previousStep={previousStep}
                    continueLater={onClose}
                    handleChangeStep={handleChangeStep}
                    crudeDate={investorStep}
                    initialData={{
                      ...basicDataInitialValues,
                      ...investorStep,
                      ...investorStep?.additional_data,
                      ...transformLegal(investorStep)
                    }}
                    handleDefineStep={handleDefineStep}
                />
            }
        </LayoutForm>
  )
}
