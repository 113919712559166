import { Dialog, Grid } from '@mui/material'
import { useContext } from 'react'
import { MyTransition } from '../../../../../components/MyTransition'
import { IdiomContext } from '../../../../../contexts/IdiomContext'
import { MyButton } from '../../../../../components/MyButton'
import { TransactionsContext } from '../../../../../contexts/TransactionsContext'
import ClientContext from '../../../../../contexts/ClientContext'
import { timezoneFormatWithHour } from '../../../../../core/timezoneFormat'
import { Paragraph } from '../../../../../components/Paragraph'
import { useResponsive } from '../../../../../hooks/useResponsive'
import { useClientConfig } from '../../../../../hooks/useClientConfig'
import { formatCurrency } from '../../../../../core/utils'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onClose: () => void
  data: any
  handleSubmit: (
    id: string,
    type: 'pay_confirmed' | 'authorized',
    state: 'pending' | 'approved' | 'rejected'
  ) => Promise<void>
}

export const AssetBuyReview = ({ open, onClose, data, handleSubmit }: Props) => {
  const { loadTransactionsByClient } = useContext(TransactionsContext)
  const { isMobileDevice } = useResponsive()
  const { actualTerm, tokens_actions_request, tokens_actions_price_by } = useContext(IdiomContext)
  const { timezone } = useContext(ClientContext)
  const { assetTypeLabel } = useClientConfig()
  const [t] = useTranslation()

  const onSubmit = async (state: 'approved' | 'rejected') => {
    await handleSubmit(data?.id, 'pay_confirmed', state)
    await loadTransactionsByClient()
    onClose()
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={MyTransition}
      onClose={onClose}
      PaperProps={{ style: { padding: isMobileDevice ? '48px 16px' : '32px 32px', maxWidth: 800 } }}
    >
      <Grid container spacing={1}>
          <Grid item xs={12} sm={12} textAlign='center'>
            <Paragraph bold>{t('payment_confirmation')}</Paragraph>
          </Grid>
          <Grid item xs={6} sm={6} md={6} textAlign='right'>
              <Paragraph bold>{t('investor_information')}</Paragraph>
          </Grid>
          <Grid item xs={12} />
          <LabelModalRow title={t('name')} label={`${data?.investor?.first_names} ${data?.investor?.fathers_name} ${data?.investor?.mothers_name}`} />
          <LabelModalRow title={t('email')} label={data?.investor?.email} />
          <LabelModalRow title={t('country')} label={t(`countries.${data?.investor?.additional_data?.country}`)} />
          <LabelModalRow title={t('person_type')} label={actualTerm[data?.investor.classificationType - 1]?.text ?? ''} />
          <Grid item xs={6} sm={6} md={6} textAlign='right' mt={2}>
            <Paragraph bold>{t('purchase_data')}</Paragraph>
          </Grid>
          <Grid item xs={12} />
          <LabelModalRow title={assetTypeLabel('singular', 'capitalize')} label={data?.asset?.name} />
          <LabelModalRow title={tokens_actions_request} label={data?.tokensFormat} />
          <LabelModalRow title={tokens_actions_price_by} label={formatCurrency(data?.asset?.token_price, data?.asset?.currency)} />
          <LabelModalRow title={t('total_amount_purchase')} label={formatCurrency(data?.payFormat, data?.asset?.currency)} />
          <LabelModalRow title={t('sended_date')} label={timezoneFormatWithHour(timezone, data?.createdAt)} />
          <Grid item sm={2.2} />
          <Grid item mt={2}>
            <MyButton
              color='info'
              onMouseUp={onClose}
            >{t('review_later')}</MyButton>
          </Grid>
          <Grid item mt={2}>
            <MyButton
              color='secondary'
              onMouseUp={async () => await onSubmit('rejected')}
            >{t('reject')}</MyButton>
          </Grid>
          <Grid item mt={2}>
            <MyButton
              onMouseUp={async () => await onSubmit('approved')}
            >{t('authorize')}</MyButton>
          </Grid>
      </Grid>
    </Dialog>
  )
}

export const LabelModalRow = (
  { title, label, labelStyle = 'default' }:
  { title: string, label: string, labelStyle?: 'default' | 'capitalize' }
) => (
  <>
    <Grid item xs={6} sm={6} md={6} textAlign='right'>
        <Paragraph type='small' bold>{ title }</Paragraph>
    </Grid>
    <Grid item xs={6} sm={6} md={6} style={{ wordBreak: 'break-all' }}>
      <Paragraph type='small' style={labelStyle === 'default' ? undefined : { textTransform: 'capitalize' }}>{ label }</Paragraph>
    </Grid>
  </>
)
