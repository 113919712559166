import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { coreFilter, FilterProps, useAvailableStates } from './coreFilter'
import { scrollToTop } from '../../../../core/utils'
import { styled } from '@mui/system'
import Pagination from '@mui/material/Pagination'
import { useAssetDashboardQuantity } from '../../../../hooks/useAssetDashboardQuantity'

interface Props {
  data: any[]
  setData: (values: any) => void
  filters: FilterProps
  orderBy: string
  filterData?: any[]
}

export const PaginationAsset = React.memo(({ data, setData, filters, orderBy }: Props) => {
  const { quantity: QUANTITY } = useAssetDashboardQuantity()
  const { AVAILABLE_STATES } = useAvailableStates()
  const [actualPage, setActualPage] = useState(1)
  const [pageNumber, setPageNumber] = useState(0)
  const [hasPaginationLabel, setHasPaginationLabel] = useState(false)

  const handleFilterData = () => {
    const constrainFilter = actualPage * QUANTITY
    const filterData = coreFilter(filters, data, orderBy, AVAILABLE_STATES)
    const paginationData = filterData.filter((e, index) =>
      index >= (constrainFilter - QUANTITY) && index < constrainFilter
    )
    setHasPaginationLabel(filterData.length > QUANTITY)
    setData(paginationData)
    if (data.length === filterData.length) setPageNumber(Math.ceil(data.length / QUANTITY))
    else setPageNumber(Math.ceil(filterData.length / QUANTITY))
  }

  useEffect(() => {
    scrollToTop()
    handleFilterData()
  }, [actualPage, data, filters, QUANTITY])

  useEffect(() => {
    const constrainFilter = actualPage * QUANTITY
    const dataFilter = coreFilter(filters, data, orderBy, AVAILABLE_STATES)
    const paginationData = dataFilter.filter((e, index) =>
      index >= (constrainFilter - QUANTITY) && index < constrainFilter
    )
    setData(paginationData)
    setPageNumber(Math.ceil(dataFilter.length / QUANTITY))
    setActualPage(1)
  }, [filters, orderBy])

  return (
    <Grid container >
      <Grid item xs={12} mt={2}>
        { hasPaginationLabel && <Paginator items={pageNumber} setPage={setActualPage} page={actualPage} /> }
      </Grid>
    </Grid>
  )
})

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center'
})

interface PaginatorProps {
  items: number
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const Paginator = ({ items, setPage, page }: PaginatorProps) => {
  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page)
  }
  return (
    <Container>
      <Pagination count={items} onChange={onChangePage} page={page} />
    </Container>
  )
}
