import { Grid } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MySelectIdiom } from '../../components/MySelectIdiom'
import { Paragraph } from '../../components/Paragraph'
import { IdiomContext } from '../../contexts/IdiomContext'
import { scrollToTop } from '../../core/utils'

export const IdiomConfiguration = () => {
  const {
    dataSelectIdiom,
    setIdiom,
    dataTerminology,
    setTerminology,
    terminolgy
  } = useContext(IdiomContext)
  const { t, i18n } = useTranslation()

  const onChange = (currentIdiom: string) => {
    console.log(currentIdiom)
    setIdiom(currentIdiom)
    void i18n.changeLanguage(currentIdiom)
    localStorage.setItem('user_config_language', currentIdiom)
  }

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
        <Grid container spacing={2}>
            <Grid item sm={9} alignSelf='center'>
                <Paragraph><strong>{t('settings_page.idiom_select')}</strong></Paragraph>
            </Grid>
            <Grid item sm={5} xs={12} mb={1} alignSelf='center'>
                <MySelectIdiom
                    setData={onChange}
                    value={i18n.language}
                    items={dataSelectIdiom}
                />
            </Grid>
        { i18n.language === 'es'
          ? <>
                <Grid item sm={12}>
                    <Paragraph><strong>Selector de terminología</strong></Paragraph>
                </Grid>
                <Grid item sm={5} xs={12}>
                    <MySelectIdiom
                        setData={setTerminology}
                        value={terminolgy}
                        items={dataTerminology}
                    />
                </Grid>
            </>
          : null
            }
        </Grid>
  )
}
