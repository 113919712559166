import { Grid } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { MyButton } from '../../components/MyButton'
import { MyPasswordToggleButton } from '../../components/MyPasswordToggleButton'
import { changePasswordValidationSchema } from '../../validations/changePasswordValidationSchema'

const initialValues = {
  oldPassword: '',
  newPassword: '',
  repeatNewPassword: ''
}

interface Props {
  onSubmit: (values: FormikValues, resetForm: any) => void
}

export const ChangePasswordForm = ({ onSubmit }: Props) => {
  const { t } = useTranslation()
  return (
        <Formik
            initialValues={initialValues}
            validationSchema={changePasswordValidationSchema}
            onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
        >
            {() => {
              return (
                    <Form>
                        <Grid container spacing={3}>
                            <Grid item sm={5} xs={12}>
                                <MyPasswordToggleButton
                                    name='oldPassword'
                                    label={t('current_password')}
                                />
                            </Grid>
                            <Grid item sm={7} xs={12} />
                            <Grid item sm={5} xs={12}>
                                <MyPasswordToggleButton
                                    name='newPassword'
                                    label={t('new_password')}
                                />
                            </Grid>
                            <Grid item sm={7} xs={12} />
                            <Grid item sm={5} xs={12}>
                                <MyPasswordToggleButton
                                    name='repeatNewPassword'
                                    label={t('confirm_new_password')}
                                />
                            </Grid>
                            <Grid item sm={7} xs={12} />
                            <Grid item xs={12}>
                                <MyButton type='submit'>
                                    {t('save_changes')}
                                </MyButton>
                            </Grid>
                        </Grid>
                    </Form>
              )
            }}
        </Formik>
  )
}
