import styled from '@emotion/styled'
import { colorPalette } from '../../../conf/ColorConfig'
import { Chip } from '@mui/material'

export const ChipFolderTag = styled.div`
  text-align: center;
  align-items: center;
  padding: min(15px, 10%) min(25px, 5%);
  text-transform: uppercase;
  border: 1px solid ${colorPalette.basics.HEX_ADB8CC};
  border-radius: 8px;
  overflow-wrap: break-word;
  cursor: pointer;
`
export const LabelFolderContainer = styled.div`
  width: 120px;
  padding-left: 8px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${colorPalette.basics.HEX_3D4D6A};
`

const TAG_BG_COLOR = {
  activo: '#BFDEE9',
  empresa: '#B6EAB6'
}

export const VDRTag = styled(Chip)<{ type: 'empresa' | 'activo', wordingType: string, label: string }>`
  background-color: ${({ type }) => TAG_BG_COLOR[type]};
  text-align: center;
  font-size: 12px;
  ::after {
    content: "";
    color: ${colorPalette.basics.HEX_3D4D6A}
  }
`
