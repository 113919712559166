import { InitialLayout } from '../../layout/InitialLayout'
import LoadingContext from '../../contexts/LoadingContext'
import { LoginForm } from './LoginForm'
import { useNavigate } from 'react-router-dom'
import { FormikValues } from 'formik'
import { useContext } from 'react'
import { useLoadClient } from '../../hooks/useLoadClient'
import { useLogin } from '../../hooks/useLogin'

export const LOGIN_SHADOW = {
  borderRadius: '5px'
}

export const LoginScreen = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showComponent } = useLoadClient()
  const { logIn } = useLogin()

  const navigate = useNavigate()

  const handleSubmit = async ({ username, password }: FormikValues) => {
    showLoading()
    await logIn(username, password)
    hideLoading()
  }

  const handleForgetPassword = () => {
    navigate('/ForgotPassword')
  }

  if (showComponent) {
    return (
      <InitialLayout>
        <LoginForm submit={handleSubmit} onForgetPassword={handleForgetPassword} />
      </InitialLayout>
    )
  }
  return (<></>)
}
