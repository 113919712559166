import { MyButtonIcon, MySelect } from '../../../../components'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import * as yup from 'yup'
import { MyButton } from '../../../../components/MyButton'
import { Paragraph } from '../../../../components/Paragraph'
import { useTranslation } from 'react-i18next'

interface Props {
  nextStep: (values: any) => void
  previousStep: () => void
  initialValue: any
  onClose: () => void
}

const INPUT_VALUES = {
  INSTITUTIONAL: true,
  QUALIFIED: false
}

export const OnbardingInvestorStatus = ({ previousStep, nextStep, initialValue, onClose }: Props) => {
  const { t } = useTranslation()

  const onSubmit = (values: any) => {
    const value: 'INSTITUTIONAL' | 'QUALIFIED' = values?.isCompanyRepresentative
    nextStep({ isCompanyRepresentative: INPUT_VALUES[value] })
  }

  const initialValueTransform = () => {
    if (initialValue?.isCompanyRepresentative === undefined) return ''
    else if (initialValue?.isCompanyRepresentative) return 'INSTITUTIONAL'
    return 'QUALIFIED'
  }

  return (
        <Formik
            initialValues={{ isCompanyRepresentative: initialValueTransform() }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {() => {
              return (
                    <Form style={{ minHeight: 'inherit' }}>
                        <Grid container direction="column" justifyContent="space-between" minHeight='inherit'>
                          <Grid container spacing={2} >
                            <Grid item sm={12} mb={2}>
                                <MyButtonIcon
                                    size='small'
                                    icon='backArrow'
                                    onClick={previousStep}
                                    text={t('back')}
                                    type='secondary'
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Paragraph type='subheading'><strong>{t('investor_user.onboarding_extended.title_four')}</strong></Paragraph>
                            </Grid>
                            <Grid item sm={12}>
                                <Paragraph>{t('investor_user.onboarding_extended.subtitle_four')}</Paragraph>
                            </Grid>
                            <Grid item sm={12} />
                            <Grid item sm={6} xs={12}>
                                <MySelect
                                    name='isCompanyRepresentative'
                                    labelText={t('investor_accreditation')}
                                    default=''
                                    items={[
                                      { text: t('institutional'), value: 'INSTITUTIONAL' },
                                      { text: t('qualified'), value: 'QUALIFIED' }
                                    ]}
                                />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                              <Grid item xs={12} md={3} sm={4} >
                                  <MyButton color='secondary' onMouseUp={onClose} fullWidth>
                                      {t('review_later')}
                                  </MyButton>
                              </Grid>
                              <Grid item xs={12} md={3} sm={4}>
                                  <MyButton type='submit' fullWidth>
                                    {t('next')}
                                  </MyButton>
                              </Grid>
                          </Grid>
                      </Grid>
                    </Form>
              )
            }}
        </Formik>
  )
}

const validationSchema = yup.object().shape({
  isCompanyRepresentative: yup.string().required('required_field')
})
