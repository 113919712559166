import logo from '../../assets/logo-disclaimer.png'
import styled from '@emotion/styled'
import { Paragraph } from '../../components/Paragraph'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: center;
  max-width: 165px;

  @media (max-width: 480px) {
      align-items: flex-start;
      max-width: 125px;
  }
`
const QXContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 480px) {
      justify-content: center;
  }
`

export const NoEquityQXLogo = () => {
  return (
    <QXContainer>
      <Container>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <img src={logo} alt='logo' height={22}/>
        </div>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
          <Paragraph type='caption'>
              Powered by QuantumX
          </Paragraph>
          <Paragraph type='caption'>
              All rights reserved © 2022
          </Paragraph>
        </div>
      </Container>
    </QXContainer>
  )
}
