import { Grid } from '@mui/material'
import { useEffect, useState, useCallback, useContext } from 'react'
import { useAcceptances } from '../../../../../hooks/useAcceptances'
import { getIssuerById } from '../../../../../core/apiIssuers'
import { AssetPreview } from '../../../../../components/AssetDetails/AssetPreview'
import { MyButtonIcon } from '../../../../../components'
import { VDRAssetPreview } from './VDRAssetPreview'
import ClientContext from '../../../../../contexts/ClientContext'
import { useTranslation } from 'react-i18next'

interface Props {
  onClose: () => void
  onBuy: (data: any, canAccess: boolean) => void
  [propName: string]: any
  issuerId: string
  assetData: any
}

export const AssetDetails = ({ onClose, onBuy, issuerId, assetData }: Props) => {
  const [canAccess, setCanAccess] = useState(false)
  const [isAvailable, setIsAvailable] = useState(true)
  const { canViewVdrConfidential } = useAcceptances()
  const { client_id, isConfidentiality } = useContext(ClientContext)
  const [issuer, setIssuer] = useState<any>({})
  const { t } = useTranslation()

  const loadIssuer = useCallback(async () => {
    const resp = await getIssuerById(issuerId, client_id)
    if (resp?.success) setIssuer(resp?.records?.[0])
  }, [issuerId])

  useEffect(() => {
    async function init () {
      await loadIssuer()
    }
    void init()
  }, [loadIssuer])

  useEffect(() => {
    async function init () {
      if (isConfidentiality) {
        const resp = await canViewVdrConfidential(issuerId)
        setCanAccess(!!resp[0]?.status && resp[0]?.status === 'APPROVED')
        setIsAvailable(resp?.[0]?.status !== 'REJECTED')
      } else {
        setCanAccess(true)
      }
    }
    void init()
    // eslint-disable-next-line
  }, [issuerId, isConfidentiality])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={2}>
        <MyButtonIcon
            size='small'
            icon='backArrow'
            onClick={onClose}
            text={t('back')}
            type='secondary'
        />
      </Grid>
      <Grid item xs={12}>
        <AssetPreview
          onBuy={() => onBuy(assetData, canAccess)}
          assetData={{ ...assetData, issuer }}
          canViewConfidentialLabels={canAccess}
          VDRComponent={<VDRAssetPreview {...{ ...assetData, canAccess, isAvailable }} />}
        />
      </Grid>
  </Grid>
  )
}
