import { Widget } from '@typeform/embed-react'

interface Props {
  onSubmit: () => void
  id: string
}

export const KYC = ({ onSubmit, id }: Props) => {
  return (
        <Widget style={{ width: '100%', height: 800 }} id={id} onSubmit={onSubmit}/>
  )
}
