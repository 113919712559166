import styled from '@emotion/styled'
import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@mui/material'
import { useField } from 'formik'
import { useContext } from 'react'
import { IdiomContext } from '../contexts/IdiomContext'

interface ItemProp {
  label: string
  value: string
}

interface Props {
  name: string
  items: ItemProp[]
  [propName: string]: any
}

const MyHelperText = styled(FormHelperText)`
  color: #d32f2f;
`

export const MyRadioGroup = ({ items, ...props }: Props) => {
  const { yupErrors } = useContext(IdiomContext)
  const [field, meta] = useField(props)

  const showError: boolean = !!meta.error && meta.touched
  const error = meta.error ? yupErrors[meta?.error || 'default'] : ''
  return (
    <>
      <RadioGroup row {...field} >
        <Grid container >
        {items.map(el =>
          <Grid item sm={12} xs={12} lg={12} key={el.value}>
            <FormControlLabel control={<Radio />} {...el} />
          </Grid>
        )}
        </Grid>
      </RadioGroup>
      { showError
        ? <MyHelperText>{error}</MyHelperText>
        : null
      }
    </>
  )
}
