import { Grid } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, FormikValues } from 'formik'
import { MyTextInput } from '../../components'
import { loginValidationSchema } from '../../validations/loginValidationSchema'
import { MyButton } from '../../components/MyButton'
import { MyTextAction } from '../../components/MyTextLink'
import { MyPasswordToggleButton } from '../../components/MyPasswordToggleButton'
import { ColorContext } from '../../contexts/ColorContext'
import { MyFlexContainer } from '../../components/common/MyFlexContainer'
import { sendEvent } from '../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../core/GA/GoogleAnalyticsEvents'
import { Paragraph } from '../../components/Paragraph'
import { TextButtonLink } from '../../components/TextButtonLink'
import { LoginTitle } from '../../components/Title'
import { LoginFormLayout } from '../../layout/InitialLayout'

const initialValues = {
  username: '',
  password: '',
  remember: ''
}

interface Props {
  submit: (values: FormikValues) => void
  onForgetPassword: (username: string) => void
}

export const LoginForm = ({ submit, onForgetPassword }: Props) => {
  const [t] = useTranslation()
  const { theme } = useContext(ColorContext)
  const { tertiary } = theme

  return (
    <Formik
      validationSchema={loginValidationSchema}
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ values }) => {
        return (
          <LoginFormLayout>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={12} mb={2}>
                <LoginTitle>{t('login_title')}</LoginTitle>
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <MyTextInput
                  name="username"
                  label={t('email')}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <MyPasswordToggleButton
                  name="password"
                  label={t('password')}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12} textAlign="right">
                <MyTextAction
                  color={tertiary}
                  onClick={() => {
                    sendEvent(GA_EVENTS.forgot_password)
                    onForgetPassword(values.username)
                  }}
                >
                  {t('question_did_you_forget_your_password')}
                </MyTextAction>
              </Grid>
              <Grid item sm={12} xs={12} md={12} mt={2}>
                <MyButton fullWidth type="submit">
                  {t('login')}
                </MyButton>
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <MyFlexContainer style={{ justifyContent: 'center' }}>
                  <Paragraph type="small">{t('question_you_dont_have_an_account_yet')}</Paragraph>
                  <TextButtonLink
                    to="/Register"
                    onClick={() => sendEvent(GA_EVENTS.investor_register)}
                  >
                    {t('sign_up')}
                  </TextButtonLink>
                </MyFlexContainer>
              </Grid>
            </Grid>
          </LoginFormLayout>
        )
      }}
    </Formik>
  )
}
