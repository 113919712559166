import { Grid } from '@mui/material'
import { Paragraph } from '../Paragraph'
import styled from '@emotion/styled'
import { memo } from 'react'
import { useStatus } from '../../hooks/useStatus'
import { MyButton } from '../MyButton'
import { CompanyInfo } from './CompanyInfo'
import { BusinessAssetData } from './BusinessAssetData'
import { scUrlEnv } from '../../core/utils'
import { AssetLogoPreview } from './AssetLogoPreview'
import { colorPalette } from '../../conf/ColorConfig'
import { useTranslation } from 'react-i18next'

interface Props {
  assetData: any
  VDRComponent: JSX.Element
  onBuy?: () => void
  canViewConfidentialLabels?: boolean
}

export const AssetPreview = memo(({ assetData, VDRComponent, onBuy, canViewConfidentialLabels = true }: Props) => {
  const { statusText, statusColor } = useStatus(assetData?.status)
  const { t } = useTranslation()

  const onClickSmartContract = () => {
    const address = assetData?.contract?.address || ''
    window.open(scUrlEnv + address)
  }

  return (
    <Grid container spacing={1} maxHeight='100%'>
      <Grid item md={12} sm={12} xs={12} mb={2}>
        <Paragraph style={{ fontSize: 30, fontWeight: 700, textTransform: 'uppercase', wordBreak: 'break-all' }}>
          { assetData?.name}
        </Paragraph>
      </Grid>
      <Grid item alignSelf='center'>
        <Tag color={statusColor}>
          <Paragraph colorType='white' type='small'>{t(statusText)}</Paragraph>
        </Tag>
      </Grid>
      <Grid item alignSelf='center'>
        <a href='#docs' style={{ color: 'inherit' }}>
          <ButtonRef >{t('Documentation')}</ButtonRef>
        </a>
      </Grid>
      <Grid item alignSelf='center'>
        <ButtonRef
          disabled={!(assetData?.contract?.address?.length > 0)}
          onClick={onClickSmartContract}
          title={t('smart_contract_tooltip') ?? ''}
        >{t('smart_contract')}</ButtonRef>
      </Grid>
      <Grid item md={12} sm={12} xs={12} mt={3} mb={2}>
        <AssetLogoPreview assetData={assetData}/>
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Paragraph>{ assetData?.description }</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <MyButton
              color='info'
              onClick={() => window.open(assetData?.external_url)}
              disabled={assetData?.external_url === ''}
            >{t('view_web_site')}</MyButton>
          </Grid>
          <Grid item xs={12}>
            <CompanyInfo company={assetData?.issuer} />
          </Grid>
          <Grid id='docs' item xs={12} mt={4}>
            <Paragraph bold type='subheading'>{t('Documentation')}</Paragraph>
          </Grid>
          <Grid id='docs' item xs={12}>
            { VDRComponent }
          </Grid>
        </Grid>
      </Grid>
      <BusinessAssetData
        canViewConfidentialLabels={canViewConfidentialLabels}
        assetData={assetData}
        status={statusText}
        onBuy={onBuy}
      />
    </Grid>
  )
})

const Tag = styled('div') <{ color: string }>`
    font-weight: 500;
    color: white;
    border-radius: 8px;
    text-align: center;
    background-color: ${props => props.color};
    padding: 12px 24px;
`
const ButtonRef = styled('button')`
    font-weight: 500;
    color: ${colorPalette.basics.HEX_738099};
    font-size: 14px;
    border: none;
    cursor: pointer;
    text-align: center;
    background-color: white;
    padding: 7px 10px;

    :disabled {
      color: #CCD8E3;
      cursor: not-allowed;
    }
`
