import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const timezoneFormat = (timezone: any, myDate: any) => {
  const parseDate = new Date(myDate)
  const zonedDate = utcToZonedTime(parseDate, timezone)
  return format(new Date(zonedDate), 'yyyy-MM-dd')
}

export const timezoneFormatWithHour = (timezone: any, myDate: any = new Date()) => {
  const parseDate = new Date(myDate)
  const zonedDate = utcToZonedTime(parseDate, timezone)
  return format(new Date(zonedDate), 'yyyy-MM-dd hh:mm a')
}
