import { createContext } from 'react'
import { useTransactions } from '../hooks/useTransactions'
import { Transaction } from '../types/types'

interface Props {
  transactions: Transaction[]
  loadTransactions: () => Promise<void>
  changeTransactionState: (id: string, type: 'pay_confirmed' | 'authorized', state: 'pending' | 'approved' | 'rejected') => Promise<void>
  loadTransactionsByIssuer: () => Promise<void>
  loadTransactionsByClient: () => Promise<void>
  loadHistoryTransactions: () => Promise<void>
  clearTransactionsContext: () => void
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const TransactionsContext = createContext({} as Props)

export const TransactionsProvider = ({ children }: any) => {
  const transactionsHook = useTransactions()
  return (
    <TransactionsContext.Provider value={transactionsHook}>
      { children }
    </TransactionsContext.Provider>
  )
}
