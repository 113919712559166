import { RequestBuyAsset } from './Tables/RequestBuyAsset'
import { Grid } from '@mui/material'
import { Title } from '../../../components/Title'
import { TabsContainer } from '../../../components/DashboardTabs/TabsContainer'
import { useScrollToTop } from '../../../hooks/useScrollToTop'
import { HistoricalBuyAsset } from './Tables/HistoricalBuyAsset '
import { TransactionsContext } from '../../../contexts/TransactionsContext'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

export const BuyDashboard = () => {
  const { transactions } = useContext(TransactionsContext)
  const { t } = useTranslation()
  useScrollToTop()

  const Tabs = [
    {
      title: t('purchase_request'),
      component: <RequestBuyAsset />,
      notification: transactions.filter((el: any) => el.authorized === 'pending').length
    },
    {
      title: t('purchase_history'),
      component: <HistoricalBuyAsset />
    }
  ]

  return (
    <Grid container mt={3} spacing={2}>
      <Grid item xs={12} sm={12} md={12} ml={6} mb={2}>
        <Title>{t('my_purchases')}</Title>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TabsContainer tabs={Tabs} />
      </Grid>
    </Grid>
  )
}
