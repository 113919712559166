import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ClientContext from '../contexts/ClientContext'

const LABEL_TRANSFORM = {
  es: {
    asset_lowercase: 'activo',
    assets_lowercase: 'activos',

    asset_capitalize: 'Activo',
    assets_capitalize: 'Activos',

    asset_uppercase: 'ACTIVO',
    assets_uppercase: 'ACTIVOS',

    offer_lowercase: 'oferta',
    offers_lowercase: 'ofertas',

    offer_capitalize: 'Oferta',
    offers_capitalize: 'Ofertas',

    offer_uppercase: 'OFERTA',
    offers_uppercase: 'OFERTAS'
  },
  en: {
    asset_lowercase: 'asset',
    assets_lowercase: 'assets',

    asset_capitalize: 'Asset',
    assets_capitalize: 'Assets',

    asset_uppercase: 'ASSET',
    assets_uppercase: 'ASSETS',

    offer_lowercase: 'offer',
    offers_lowercase: 'offers',

    offer_capitalize: 'Offer',
    offers_capitalize: 'Offers',

    offer_uppercase: 'OFFER',
    offers_uppercase: 'OFFERS'
  }

}

const clientAssetsTypeTransform = {
  EQUITIES: 'EQUITIES',
  REAL_ESTATES: 'REAL_ESTATES',
  FUNDS: 'FUNDS'
}

export const useClientConfig = () => {
  const { assetsType } = useContext(ClientContext)
  const { i18n: { language } } = useTranslation()

  const assetTypeLabel = useCallback((format: 'singular' | 'plural', transform: 'lowercase' | 'uppercase' | 'capitalize') => {
    const LABEL_TRANSFORM_IDIOM = LABEL_TRANSFORM[language === 'es' ? 'es' : 'en']
    if (assetsType !== clientAssetsTypeTransform.EQUITIES) return LABEL_TRANSFORM_IDIOM[`asset${format === 'plural' ? 's' : ''}_${transform}`]
    else return LABEL_TRANSFORM_IDIOM[`offer${format === 'plural' ? 's' : ''}_${transform}`]
  }, [language, assetsType])

  const formOfVerb = () => {
    if (assetsType !== clientAssetsTypeTransform.EQUITIES) return 'o'
    return 'a'
  }
  return {
    assetTypeLabel,
    formOfVerb
  }
}
