import styled from '@emotion/styled'
import { Slide, Snackbar } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import ReportIcon from '@mui/icons-material/Report'
import { useTranslation } from 'react-i18next'

interface Props {
  content: string
  isOpen: boolean
  onClose: () => void
  type: 'error' | 'warning' | 'info' | 'success'
}

const primaryColors = {
  error: '#FF5C5C',
  success: '#41BE64',
  info: '#1454F6',
  warning: '#F1C21B'
}

const borderColors = {
  error: '#FFA8A8',
  success: '#99DDAC',
  info: '#D9E4FE',
  warning: '#F8DF84'
}

function TransitionLeft (props: any) {
  return <Slide {...props} direction="left" />
}

const ToastContainer = styled.div<{ type: 'error' | 'warning' | 'info' | 'success' }>`
    background-color: white;
    width: 300px;
    padding: 15px 20px 20px 10px;
    border: 1px solid ${({ type }) => borderColors[type]};
    border-left: 5px solid ${({ type }) => primaryColors[type]};
    display: flex;
    gap: 0.8rem;
`

export const MyNotificationToast = ({ type, content, isOpen = false, onClose }: Props) => {
  const [t] = useTranslation()
  const titleAndIcon = {
    error: { title: t('error'), Icon: WarningIcon },
    success: { title: t('success'), Icon: CheckCircleIcon },
    info: { title: t('info'), Icon: InfoIcon },
    warning: { title: t('warning'), Icon: ReportIcon }
  }
  const { title, Icon } = titleAndIcon[type]
  return (
        <Snackbar
            style={{ right: '3rem', top: '2.5rem' }}
            open={isOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClose={onClose}
            autoHideDuration={5000}
            TransitionComponent={TransitionLeft}
        >
            <ToastContainer type={type} className='box-shadow'>
                <Icon htmlColor={primaryColors[type]} />
                <div style={{ lineHeight: 1.5 }}>
                    <div style={{ fontWeight: 500 }}>
                        { title }
                    </div>
                    <div>
                        { content }
                    </div>
                </div>
            </ToastContainer>
        </Snackbar>
  )
}
