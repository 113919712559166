import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import LoadingContext from '../../contexts/LoadingContext'
import NotificationContext from '../../contexts/NotificationContext'
import { recoverPassword } from '../../core/apiAuth'
import { InitialLayout } from '../../layout/InitialLayout'
import { RecoverPasswordForm } from './RecoverPasswordForm'
import { sendEvent } from '../../core/GA/GoogleAnalytics'
import { GA_EVENTS } from '../../core/GA/GoogleAnalyticsEvents'
import { useLogin } from '../../hooks/useLogin'
import { useTranslation } from 'react-i18next'

export const RecoverPassword = () => {
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const { showToast } = useContext(NotificationContext)
  const { state }: any = useLocation()
  const { t } = useTranslation()
  const { logIn } = useLogin()

  const onSubmit = async (values: any) => {
    sendEvent(GA_EVENTS.recover_password)
    showLoading()
    const resp = await recoverPassword({ ...values, username: state?.username })
    if (resp?.success) {
      showToast('success', t('toasts.recover_password_success'))
      await logIn(state.username, values.password)
    } else if (resp?.code) {
      showToast('error', t(`toasts.${resp.code}`))
    } else {
      showToast('error', 'Error')
    }
    hideLoading()
  }

  return (
      <InitialLayout >
        <RecoverPasswordForm onSubmit={onSubmit} email={state?.username}/>
      </InitialLayout>
  )
}
