import { Form, Formik } from 'formik'
import { MyButton } from '../../../../components/MyButton'
import { MyNumberInput } from '../../../../components/MyNumberFormat'
import { MySelectCheckmarks } from '../../../../components/MySelectCheckmarks'
import { initialFilter, useAvailableStates } from './coreFilter'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Grid } from '@mui/material'
import { Paragraph } from '../../../../components/Paragraph'
import { colorPalette } from '../../../../conf/ColorConfig'
import { useState } from 'react'
import { MyFlexContainer } from '../../../../components/common/MyFlexContainer'
import SouthIcon from '@mui/icons-material/South'
import NorthIcon from '@mui/icons-material/North'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

interface Props {
  setData: (values: any) => void
  data: any
}

export const Filters = ({ ...props }: Props) => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const isExpanded = expanded === 'panel1'
  const { t } = useTranslation()

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Accordion elevation={0} expanded={isExpanded} onChange={handleChange('panel1')}>
            <AccordionSummary>
              <MyFlexContainer style={{ alignItems: 'center', gap: 4 }}>
                <Paragraph type='small'>
                  { isExpanded
                    ? t('investor_user.hide_filters')
                    : t('investor_user.show_filters')
                  }
                </Paragraph>
                { isExpanded
                  ? <NorthIcon sx={{ fontSize: 12 }}/>
                  : <SouthIcon sx={{ fontSize: 12 }}/>
                }
              </MyFlexContainer>
            </AccordionSummary>
            <AccordionDetails>
              <FormFilters {...props} />
            </AccordionDetails>
        </Accordion>
        </Grid>
      </Grid>
  )
}

interface FormProps {
  setData: (values: any) => void
}

const FormFilters = ({ setData }: FormProps) => {
  const { AVAILABLE_STATES } = useAvailableStates()
  const { t } = useTranslation()
  return (
  <Formik
    initialValues={initialFilter}
    validationSchema={validationSchema}
    onSubmit={setData}
    onReset={setData}
  >
      {({ values, errors }) => (
          <Form>
                <Grid
                  container
                  spacing={1}
                  pb={3}
                  borderBottom={`1px solid ${colorPalette.basics.HEX_CFD7E6}`}
                >
                    <Grid item md={3} sm={12} xs={12}>
                      <MySelectCheckmarks
                          name='availableStates'
                          values={values.availableStates}
                          title={t('availability')}
                          options={AVAILABLE_STATES}
                      />
                    </Grid>
                    <Grid item md={5} sm={12} xs={12} >
                        <div style={JSON.stringify(errors).length === 2 ? NORMAL_STYLES_INPUT_VALUATION : ERROR_STYLES_INPUT_VALUATION }>
                            <MyNumberInput
                                label={t('min')}
                                name='min_valuation'
                            />
                            <div style={{ height: 56, display: 'flex', alignItems: 'center' }}>
                             <p>-</p>
                            </div>
                            <MyNumberInput
                                label={t('max')}
                                name='max_valuation'
                            />
                        </div>
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <MyButton type='submit' fullWidth> {t('apply_filters')} </MyButton>
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <MyButton type='reset' color='secondary' fullWidth> {t('clear_filters')} </MyButton>
                    </Grid>
                </Grid>
          </Form >
      )}
  </Formik >
  )
}

const NORMAL_STYLES_INPUT_VALUATION = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center'
}

const ERROR_STYLES_INPUT_VALUATION = {
  display: 'flex',
  gap: '10px'
}

const validationSchema = yup.object().shape({
  min_valuation: yup.number().typeError('').min(0, 'positive'),
  max_valuation: yup.number().typeError('').min(0, 'positive')
})
