import { LayoutDashboard } from '../../layout/LayoutDashboard'
import ClientContext from '../../contexts/ClientContext'
import UserContext from '../../contexts/UserContext'
import { refreshSession } from '../../core/apiAuth'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect } from 'react'

export const Dashboard = () => {
  const { userRefresh, handleLogOutUser } = useContext(UserContext)
  const { clientRefresh, clientId } = useContext(ClientContext)
  const navigate = useNavigate()

  const logOutFunction = async () => {
    handleLogOutUser()
    navigate(`/${clientId() || 'admin-qx'}`, { replace: true })
  }

  useEffect(() => {
    async function init () {
      try {
        const resp = await refreshSession()
        if (resp) {
          userRefresh()
          clientRefresh()
        } else void logOutFunction()
      } catch (error) {
        void logOutFunction()
      }
    }
    void init()
    // eslint-disable-next-line
    }, []);

  return (
        <LayoutDashboard />
  )
}
