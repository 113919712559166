import { MyTransition } from '../../../components/MyTransition'
import { Dialog, DialogTitle, DialogActions } from '@mui/material'
import { MyButton } from '../../../components/MyButton'
import { Investor } from '../../../types/types'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onClose: () => void
  data?: Investor | null
  onAccept: () => Promise<void>
}

export const DeleteUserModal = ({ open, onClose, data, onAccept }: Props) => {
  const { t } = useTranslation()
  const handleDelete = async () => {
    await onAccept()
    onClose()
  }

  return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={MyTransition}
        PaperProps={{ style: { maxWidth: 400, padding: '10px 0', borderRadius: 20 } }}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          {t('delete_user_question')}
        </DialogTitle>
        <DialogActions style={{ justifyContent: 'center' }}>
          <MyButton onClick={onClose} color='secondary'>
            {t('cancel')}
          </MyButton>
          <MyButton onClick={handleDelete}>
            {t('confirm')}
          </MyButton>
        </DialogActions>
      </Dialog>
  )
}
