import { useContext, useState } from 'react'
import { ConfidentialDocRequest } from './ConfidentialDocRequest'
import { useAcceptances } from '../../../../../hooks/useAcceptances'
import NotificationContext from '../../../../../contexts/NotificationContext'
import { MainVDR } from '../../../../../components/VDR/MainVDR'
import ClientContext from '../../../../../contexts/ClientContext'
import { useTranslation } from 'react-i18next'

export const VDRAssetPreview = ({ ...props }) => {
  const [openAccess, setOpenAccess] = useState(false)
  const { showToast } = useContext(NotificationContext)
  const { mandatory_vdr } = useContext(ClientContext)
  const { changeConfidentialityStatus, acceptConditions } = useAcceptances()
  const { t } = useTranslation()
  const {
    clientId,
    issuer_id,
    canAccess,
    isAvailable,
    id
  } = props

  const onClose = () => setOpenAccess(false)
  const onOpen = () => setOpenAccess(true)

  const handleSubmit = async () => {
    if (!mandatory_vdr) await acceptConditions(issuer_id)
    const resp = await changeConfidentialityStatus(issuer_id, 'REQUESTED')
    if (resp.success) showToast('success', t('toasts.request_sended_success'))
    else showToast('error', 'Error')
    onClose()
  }

  return (
    <>
      <ConfidentialDocRequest
        isOpen={openAccess}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
      <>
        <MainVDR
          canSeeConfidential={canAccess}
          type='Activo'
          linkAsset={props?.linkVdr}
          issuer_id={issuer_id}
          vdr_id={`${clientId}/${issuer_id}/${id}`}
          canDelete={false}
          canEdit={false}
          canLoad={false}
          canShowRequest={isAvailable}
          canAccess={canAccess}
          requestAccess={onOpen}
        />
      </>
    </>
  )
}
