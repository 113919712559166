import * as yup from 'yup'

export const assetFormValidationSchema = yup.object().shape({
  name: yup.string().max(80, 'string_limit').required('required_field'),
  price: yup.number().typeError('').min(0.001, 'bigger_than_zero').max(999999999999999, 'big_number').required('required_field'),
  bidAmount: yup.number().typeError('').min(0.001, 'bigger_than_zero').max(999999999999999, 'big_number').required('required_field'),
  description: yup.string().max(1000, 'string_limit'),
  usingLogo: yup.boolean()
})

export const assetEquityFormValidationSchema = yup.object().shape({
  name: yup.string().max(80, 'string_limit').required('required_field'),
  price: yup.number().typeError('').min(0.001, 'bigger_than_zero').max(999999999999999, 'big_number').required('required_field'),
  description: yup.string().max(1000, 'string_limit'),
  usingLogo: yup.boolean()
})
