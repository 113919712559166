import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MyButton } from '../../../../components/MyButton'
import { Paragraph } from '../../../../components/Paragraph'
import ClientContext from '../../../../contexts/ClientContext'
import { formatCurrency } from '../../../../core/utils'
import { LabelModalRow } from '../../Cliente/Autorizar/Activos/AssetBuyReview'

interface Props {
  onClose: () => void
  investorData: any
  changeStatus: (values: any, status: 'ACCEPTED' | 'REJECTED') => Promise<any>
}

export const SpecialInvestorVDRForm = ({ onClose, investorData, changeStatus }: Props) => {
  const { currencyOptions } = useContext(ClientContext)
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={investorData}
      onSubmit={async () => await changeStatus(investorData, 'ACCEPTED')}
    >
      {() => {
        return (
          <Form>
            <Grid container spacing={1} p={4}>
              <Grid item xs={12} sm={12} textAlign='center'>
                <Paragraph bold>{t('request_vdr.title')}</Paragraph>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Paragraph type='small'>{t('request_vdr.description')}</Paragraph>
              </Grid>
              <Grid item xs={12} />
              <LabelModalRow title={t('company_name')} label={investorData?.first_names} />
              <LabelModalRow title={t('country')} label={investorData?.country} />
              <LabelModalRow title={t('person_type')} label={investorData?.personType} />
              { investorData?.additional_data?.min_investment > 0 && <LabelModalRow title={t('min_investment_per_ticket_without_optional')} label={formatCurrency(investorData?.additional_data?.min_investment, currencyOptions[0])} />}
              { investorData?.investment_background?.length > 0 && <LabelModalRow title={t('request_investor.investment_background')} label={investorData?.investment_background} />}
              <Grid container justifySelf='center' mt={2} spacing={1}>
                <Grid item sm={4} xs={12}>
                  <MyButton fullWidth color='info' onMouseUp={onClose}>
                  {t('review_later')}
                  </MyButton>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <MyButton
                    fullWidth
                    color='secondary'
                    onMouseUp={async () => await changeStatus(investorData, 'REJECTED')}
                  >
                    {t('reject_request')}
                  </MyButton>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <MyButton fullWidth type='submit'>
                  {t('approve_request')}
                  </MyButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
